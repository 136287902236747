import { IAccountFilAllClientsData, IAccountFitMessages } from "types/accountFitTooltip";

interface IProps {
  isPositive?: boolean;
  limit: number;
}

const featuresMessages: IAccountFitMessages = {
  BillingCity: "Geography",
  BillingCountry: "Country",
  Industry: "Industry",
  AnnualRevenue: "Company Revenue",
  NumberOfEmployees: "Number of Employees",
  NAICS: "Sector, sub-sector, industry, and sub-industry",
  "IT Budget Total": "Company’s IT Budget",
  cumulative_losses: "Past opportunity history",
  "revenue/employee": "Revenue/Employee Ratio",
  win_ratio_per_indusry: "Win rate for this industry",
  win_ratio_per_NAICS: "Win rate for this sector, sub-sector, industry and sub-industry",
  win_ratio_per_BillingCity: "Win rate for this geography",
  win_ratio_per_BillingCountry: "Win rate for this country",
  revenue_industry: "Revenue maturity of this industry.",
  wins_losses_per_industry: "The number of wins to losses per industry",
  wins_losses_per_NAICS: "The number of wins to losses per national industry",
  "budget/employee": "Avg. IT Spending per employee",
  NAICS_Code__c: "Sector, sub-sector, industry, and sub-industry",
  win_ratio_per_industry: "Win rate per industry",
  win_ratio_per_City: "Win rate for this geography",
  win_ratio_per_country: "Win rate for this country",
  CB_Employees__c: "Number of Employees (from Clearbit)",
  CB_Primary_Industry__c: "Industry (from Clearbit)",
  Sub_Industry_CB__c: "Sub-industry (from Clearbit)",
  Revenue_Range_CB__c: "Company revenue (from Clearbit)",
  Company_Sector_CB__c: "Company Sector (from Clearbit)",
  win_ratio_per_subIndustry: "Win rate per subIndustry",
  revenue_ratio_per_industry: "Revenue rate per industry",
};

// The "featuresMessages" doesn't include all features, the features that are not found in this object mean this feature will not be displayed
const activeFeaturesKeys = Object.keys(featuresMessages);

export const getActiveMessages = (data?: IAccountFilAllClientsData) => (props: IProps) => {
  const { isPositive, limit } = props || {};
  if (!data) return [];

  const displayedFeatures = Object.entries(data).filter(([key]) => activeFeaturesKeys.includes(key));

  const sortedKeys = displayedFeatures
    ? displayedFeatures.sort(([keyA, a], [keyB, b]) => b - a).map(([key]) => key)
    : [];
  const sliceRange = isPositive ? [0, limit] : [-limit];
  const activeKeys = sortedKeys.slice(...sliceRange);

  const activeMessages = activeKeys.map(
    (key) => featuresMessages[key as keyof typeof featuresMessages],
  );

  return activeMessages as string[];
};
