/** todo: abstract the single employee selection here as well */
import type { IExtendedEmployee } from "../types";

// sets all employee to checked or unchecked
export const updateAllEmployeeStatus = (
  EmployeeHierarchy: IExtendedEmployee[],
  checkStatus: boolean,
  values?: string[],
) => {
  const updatedList: IExtendedEmployee[] = EmployeeHierarchy.map((employee) => {
    const updatedEmployee = employee;
    employee.checked = values?.includes(employee.id) ?? checkStatus;
    return {
      ...updatedEmployee,
      subordinates: updateAllEmployeeStatus(employee.subordinates, checkStatus, values ?? []),
    };
  });
  return updatedList;
};
