import { Select, SliderInput, TextInput } from "components/common/Inputs";

// todo: rework to work as fuzzy search.
export const textIncludes = (rows: any, id: string, filterValue: string) => rows.filter((row: any) => {
  const rowValue = row.values[id];
  return rowValue !== undefined
    ? String(rowValue).toLowerCase().includes(filterValue.toLowerCase())
    : false;
});

export const tableDefaultFilterComponents = {
  get: (filterType: string) => {
    switch (filterType) {
      case "text":
      case "search":
        return TextInput;
      case "dropdown":
      case "options":
      case "select":
        return function (props: any) {
          return <Select {...props} onChange={(event: any) => props.onChange(event.target.value)} />;
        };
      // @todo: this number should be moved and replaced with input type number
      case "number":
      case "date":
      case "range":
      case "slider":
        return SliderInput;
      default:
        return null;
    }
  },
};
