import userPermissionsAllowanceMap, { FeatureGroupSet } from "data/userPermissionsMap";
import { useSettings } from "services/shooks";


export enum UserRoles {
  PaidUser = 'paid_user',
  PaidAdmin = 'paid_admin',
  TrailUser = 'trial_user',
  FreeAdmin = 'free_admin',
  FreeUser = 'free_user',
}

function getUserRoleName({ free, admin, trial }: { free: boolean | undefined; admin: boolean | undefined; trial?: boolean; }) {
  // for paid users
  if (!free && !admin) return UserRoles.PaidUser
  if (!free && admin) return UserRoles.PaidAdmin
  // for free users
  if (free && trial) return UserRoles.TrailUser
  if (free && !trial && admin) return UserRoles.FreeAdmin
  // when the user is free (true), trial (false), and admin (false)
  return UserRoles.FreeUser
}

const useAccessManager = () => {
  const { state: userSettings } = useSettings();
  const checkIfUserHasAccess = (groupName: FeatureGroupSet) => {
    const userRoleName = getUserRoleName({
      free: userSettings?.isFree,
      admin: userSettings?.isAdmin,
      trial: userSettings?.isTrial,
    });
    const isUserAllowed = userPermissionsAllowanceMap[userRoleName].includes(groupName);
    return isUserAllowed;
  };
  const isFreeUser = () => {
    return userSettings?.isFree
  }
  return {
    checkIfUserHasAccess,
    isFreeUser
  };
};

export default useAccessManager;
