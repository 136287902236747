import { Box, SxProps } from "@mui/material";
import { ReactNode } from "react";

export function Head({
  children,
  sx,
  ...props
}: {
  children: ReactNode;
  className?: string;
  sx?: SxProps;
}) {
  return (
    <Box
      sx={{
        fontSize: "14px",
        color: "#262626",
        padding: "0 10px",
        textTransform: "capitalize",
        ...sx,
      }}
      {...props}
    >
      {children}
    </Box>
  );
}
