/**
 * Dynamically sets a deeply nested value in an object.
 * Optionally "bores" a path to it if its undefined.
 * @function
 * @param {!object} obj  - The object which contains the value you want to change/set.
 * @param {!array} fullpath  - The array representation of path to the value you want to change/set. e.g formatt => root.sub.nested
 * @param {!mixed} value - The value you want to set it to.
 * @param {boolean} setrecursively - If true, will set value of non-existing path as well.
 */
export function setObjectDeep(obj: any, fullPath: string, value: any, setrecursively = false) {
  const pathArr = fullPath?.split(".") ?? [];
  let currentObj = obj;

  const newObj = { ...currentObj }; // Create a shallow copy of the root object

  let nestedObj = newObj;

  for (let i = 0; i < pathArr.length - 1; i++) {
    const key = pathArr[i];

    if (currentObj.hasOwnProperty(key)) {
      currentObj = currentObj[key];
      nestedObj[key] = { ...currentObj }; // Create a shallow copy of the nested object
      nestedObj = nestedObj[key]; // Move the reference to the nested object
    } else if (setrecursively) {
      nestedObj[key] = {}; // Create a new empty object at the key
      nestedObj = nestedObj[key]; // Move the reference to the newly created object
    } else {
      console.warn(`Path "${fullPath}" does not exist in the object.`);
    }
  }

  const lastKey = pathArr[pathArr.length - 1];

  if (typeof value === "function") {
    const currentValue = currentObj[lastKey];
    const updatedValue = value(currentValue);
    nestedObj[lastKey] = updatedValue;
  } else {
    nestedObj[lastKey] = value;
  }

  return newObj;
}

export const getObjectNestedProperty = (obj: any, path: string) => path.split(".").reduce((acc, part) => acc && acc[part], obj);
