import { Typography } from "@mui/material";
import { Box } from "@mui/system";

import React from "react";
import { useLocation } from "react-router-dom";

import { Page } from "components/layout";

function ErrorPage() {
  const { state } = useLocation();
  const errorMessage = state?.error?.message || "An error occurred, please try again later";

  return (
    <Page hasHeader={false} hasSidebar={false}>
      <Box sx={{
        display: "flex", flex: 1, justifyContent: "center", alignItems: "center",
      }}
      >
        <Typography variant="h4" color="error">{errorMessage}</Typography>
      </Box>
    </Page>
  );
}

export default ErrorPage;
