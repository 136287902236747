
import { Check, LinkOutlined, RefreshOutlined } from "@mui/icons-material";
import { Button, Checkbox, CircularProgress, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import { onGoToExternalLink } from "components/Activity.components/utils";
import { Table } from "components/common/Tables";
import { dateDefaultFormate } from "constants/dates";
import { format } from "date-fns";
import useToggle from "hooks/useToggle";
import { HeaderCell } from "pages/Settings/UsersManagement/common";
import { useMemo } from "react";
import { AccountSimilarWins, CompanySecData } from "types/api";
import { delay } from "utils/general";
import { numberFormats } from "utils/numbers";
import { getFaviconByDomain } from "utils/urlParsers";

interface AccountBusinessCardProps {
  details?: CompanySecData['companyInfo'],
  lastUpdated?: string,
  onUpdateSecDataForCompany: (ticker: string) => Promise<void>
}


const StatementBox = ({ title, value }: { title: string, value: string }) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "5px" }}>
        <Typography>{title}</Typography>
        <Typography variant="caption" sx={{ color: "#666666" }}>{value || 'N/A'}</Typography>
      </Box>
    </Box>
  )
}


function DetailsWidget({ details, onUpdateSecDataForCompany, lastUpdated }: AccountBusinessCardProps) {
  const [isLoading, , asyncToggler] = useToggle(false);

  const onRefresh = async () => {
    // await asyncToggler(onUpdateSecDataForCompany(details?.ticker!));
    await asyncToggler(delay(2000));
  }

  if (!details) return (
    <Box sx={{ padding: "20px", border: "1px solid #e0e0e0", borderRadius: "10px", backgroundColor: "#fff", }}>
      No data available
    </Box>
  )

  return (
    <Box
      sx={{
        padding: "20px",
        border: "1px solid #e0e0e0",
        borderRadius: "10px",
        backgroundColor: "#fff",
        minWidth: '400px',
        gap: "30px",
        flexDirection: "column",
        display: "flex",
        flexWrap: "wrap",
      }}>
      <Box
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          gap: "10px",
          alignItems: "center",
        }}
      >
        <Box>
          <StatementBox title="Name" value={details.name} />
        </Box>
        <Stack direction="row" gap={2} alignItems="center">
          {lastUpdated && (
            <Typography sx={{ color: '#aaa', fontSize: '12px' }}>
              Last filing date: {lastUpdated ? format(new Date(lastUpdated), dateDefaultFormate) : 'N/A'}
            </Typography>
          )}
          <Button
            variant="outlined"
            onClick={onRefresh}
            disabled={isLoading}
            size="small"
            startIcon={isLoading ? <CircularProgress size={13} /> : <RefreshOutlined />}
          >
            Refresh
          </Button>
        </Stack>
      </Box>
      <Stack direction="row" gap={3} sx={{ maxHeight: '150px' }} flexWrap="wrap" alignContent={'flex-start'} justifyContent='flex-start'>
        <StatementBox title="Ticker" value={details.ticker} />
        <StatementBox title="Exchange" value={details.exchange} />
        <StatementBox title="Sector" value={details.sector} />
        <StatementBox title="Industry" value={details.industry} />
        <StatementBox title="Location" value={details.location} />
        <StatementBox title="Currency" value={details.currency} />
      </Stack>
    </Box>
  )
}

export default DetailsWidget;
