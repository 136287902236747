import { SnackbarProvider } from "notistack";
import { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import AuthProvider from "components/AuthProvider";
import "react-toastify/dist/ReactToastify.css";
import "index.css";
import ErrorBoundary from "components/ErrorBoundaries";
import Loader from "components/Loader";
import ProtectedRoute from "components/ProtectedRoute";
import ThemeCustomization from "components/themes";

import "assets/css/common.css";
import { AppContextProvider } from "contexts/App";

import { RouteI, Routes as appRoutes } from "./utils/routes";

function App() {
  return (
    <AuthProvider>
      <AppContextProvider>
        <ErrorBoundary>
          <ThemeCustomization>
            <SnackbarProvider maxSnack={3} autoHideDuration={2500} preventDuplicate>
              <Routes>
                {appRoutes.map((route: RouteI) => (route.isProtected ? (
                  <Route key={route.path} element={<ProtectedRoute />}>
                    <Route
                      path={route.path}
                      element={(
                        <Suspense fallback={<Loader />}>
                          {route.element}
                        </Suspense>
                      )}
                    />
                  </Route>
                ) : (
                  <Route
                    key={route.path}
                    path={route.path}
                    element={
                      <Suspense fallback={<Loader />}>{route.element}</Suspense>
                    }
                  />
                )))}
              </Routes>
            </SnackbarProvider>
            <ToastContainer position="bottom-right" />
          </ThemeCustomization>
        </ErrorBoundary>
      </AppContextProvider>
    </AuthProvider>
  );
}

export default App;
