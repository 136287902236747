import { useCallback, useEffect, useState } from "react";

const useWindowScreenHeight = () => {
  const [fullScreenHeight, setFullScreenHeight] = useState(window.innerHeight);
  const [fullScreenWidth, setFullScreenWidth] = useState(window.innerWidth);

  const onScreenResize = useCallback((event: any) => {
    const screenHeight = (event.target) ? (event.target).innerHeight : 0;
    const screenWidth = (event.target) ? (event.target).innerWidth : 0;
    setFullScreenHeight(screenHeight);
    setFullScreenWidth(screenWidth);
  }, []);

  useEffect(() => {
    window.addEventListener("resize", onScreenResize);
    return () => {
      window.removeEventListener("resize", onScreenResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [fullScreenHeight, fullScreenWidth];
};

export default useWindowScreenHeight;
