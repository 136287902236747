import { create } from "zustand";

import { TERRITORY_SELECTED_USER_IDS } from "constants/territory";
import { WhitespaceAPI } from "services";
import {
  Account, BriefAccount, IAccountRecoedType, IAccountsSignals,
} from "types/api";

import { StateRecord } from "./main";
import { userStorage } from "utils/auth";

type TerritoryAccounts = {
  results: Account[];
  total: number;
  selectedAccounts?: { [key: string]: boolean };
  selectedUserIds: string[];
  accountsBriefList?: BriefAccount[];
  accountsSignalsActiveValues?: number[];
  accountRecordTypes?: IAccountRecoedType[];
};
export interface TerritoryStateType extends StateRecord {
  isLoading?: boolean;
  state: TerritoryAccounts;
  actions: {
    get: (
      payload: Parameters<typeof WhitespaceAPI.whitespace.accounts>[0],
      shouldUpdateState?: boolean,
      options?: Parameters<typeof WhitespaceAPI.whitespace.accounts>[1]
    ) => Promise<any>;
    getTargetAccounts: () => Account[];
    initialize: () => Promise<void>;
    updateList: (listNewAccounts: Account[]) => void;
    updateAccount: (
      payload: Parameters<typeof WhitespaceAPI.whitespace.updateAccount>[0]
    ) => Promise<Account>;
    onToggleAccountSelection: (accountsIds: string | string[]) => void;
    updateSelectedUserIds: (selectedUserIds: string[]) => void;
    getAccountsBriefFullList: (
      payload: Parameters<typeof WhitespaceAPI.whitespace.accountsBriefList>[0]
    ) => Promise<BriefAccount[]>;
    getAccountsSignalsActiveValues: () => Promise<IAccountsSignals>;
  };
}

const initialTerritoryState = {
  results: [],
  selectedAccounts: {},
  total: 0,
  selectedUserIds: userStorage.get(TERRITORY_SELECTED_USER_IDS) || [],
};

const useTerritoryAccounts = create<TerritoryStateType>((setState, getState) => ({
  isLoading: false,
  state: initialTerritoryState,
  actions: {
    async get(payload, shouldUpdateState, options) {
      const { selectedUserIds } = getState().state;
      const userIdsFromPayload = payload.userIds;

      setState({ isLoading: true });
      const _payload = { userIds: selectedUserIds, ...payload };
      const response = await WhitespaceAPI.whitespace.accounts(_payload, options);

      if (shouldUpdateState) {
        setState({
          state: {
            ...getState().state,
            results: response.results,
            total: response.total,
            selectedUserIds: userIdsFromPayload || [],
          },
          isLoading: false,
        });
        return response;
      }
      setState({ isLoading: false });
      return response;
    },
    getTargetAccounts: () => {
      const { state } = getState();
      const targetAccounts = state.results.filter((account) => account.targetAccount);
      return targetAccounts;
    },
    updateList: async (listNewAccounts) => {
      const { state } = getState();
      const currentAccountsList = state.results;
      const updatedAccountsList = [...currentAccountsList, ...listNewAccounts];
      setState({ state: { ...state, results: updatedAccountsList } });
    },
    updateSelectedUserIds: async (selectedUserIds: string[]) => {
      // keep the initial state value in sync and allow accounts to fetch by selection on initialization.
      userStorage.set(TERRITORY_SELECTED_USER_IDS, selectedUserIds);
      const filteredSelectedUserIds = selectedUserIds
        ? (selectedUserIds.filter((id) => !id.endsWith("parent")))
        : selectedUserIds;
      const { actions } = getState();
      await actions.get({ limit: 100, offset: 0, userIds: filteredSelectedUserIds }, true);
    },
    initialize: async () => {
      const { getAccountsBriefFullList, getAccountsSignalsActiveValues } = getState().actions;
      const { selectedUserIds } = getState().state;
      setState({ isLoading: true });
      // this will have the benefit of two words, parallelism and control!
      const getTerritoryAccounts = WhitespaceAPI.whitespace.accounts({
        limit: 150,
        offset: 0,
        userIds: selectedUserIds,
      });
      const getFullAccountList = getAccountsBriefFullList({ limit: 5000, offset: 0 });

      const accountsSignalsActiveValues = await getAccountsSignalsActiveValues();
      const response = await getTerritoryAccounts;

      setState({
        isLoading: false,
        state: {
          ...response,
          selectedUserIds,
          accountsSignalsActiveValues: accountsSignalsActiveValues?.availableActivityActions || [],
          accountRecordTypes:
            accountsSignalsActiveValues?.recordTypes?.filter(
              ({ SobjectType }) => SobjectType === "Account",
            ) || [],
        } as TerritoryAccounts,
      });

      // @note: this is a side effect that we need to get the full list of accounts
      const accountsBriefList = await getFullAccountList;
      setState(({ state }) => ({ state: { ...state, accountsBriefList } }));
    },
    getAccountsBriefFullList: async (payload) => {
      const { /* totalCount = 0, */ items = [] } = await WhitespaceAPI.whitespace.accountsBriefList({
        limit: 3000,
        offset: 0,
      });
      // const pages = getPageRanges(totalCount, 3000);
      // const accountsPagesRequests = pages.map(([offset, limit]) => WhitespaceAPI.whitespace.accountsBriefList({ offset, limit }));
      // const resultAccountsPages = await Promise.all(accountsPagesRequests);
      // const accounts = resultAccountsPages.map(({ items = [] }) => items).flat();
      return items;
    },
    getAccountsSignalsActiveValues: async () => await WhitespaceAPI.whitespace.accountsSignal(),
    updateAccount: async (payload) => {
      const { resource: updatedAccount } = await WhitespaceAPI.whitespace.updateAccount(payload);
      const { state } = getState();
      const currentAccountsList = state.results;
      const updatedAccountsList = currentAccountsList.map((account) => {
        if (account.id === updatedAccount.id) {
          // temporary: we don't take the full updated account because we don't want to mess up the rankings. If unsure, ask Farah or Eslam about that.
          return { ...updatedAccount, rank: account.rank };
        }
        return account;
      });
      setState({ state: { ...state, results: updatedAccountsList } });
      return updatedAccount;
    },
    onToggleAccountSelection: (accountsIds: string | string[]) => {
      // @notes: this handle three cases:
      // 1. accountsIds is an array of ids
      // 2. accountsIds is a single id
      // 3. accountsIds is [] it means that we want to clear the selection
      if (!accountsIds) {
        return;
      }
      const { state } = getState();
      const currentSelectedAccounts = state.selectedAccounts || {};

      if (Array.isArray(accountsIds)) {
        const newSelectedRows = accountsIds.reduce(
          (acc, rowId: string) => {
            acc[rowId] = true;
            return acc;
          },
          {} as { [key: string]: boolean },
        );
        setState({ state: { ...state, selectedAccounts: newSelectedRows } });
        return;
      }
      // single id case;
      const accountId = accountsIds;
      const isSelected = currentSelectedAccounts[accountId];
      setState({
        state: {
          ...state,
          selectedAccounts: { ...currentSelectedAccounts, [accountId]: !isSelected },
        },
      });
    },
  },
}));

export default useTerritoryAccounts;
