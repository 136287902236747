import { Box } from "@mui/material";

import { Account } from "types/api";

import AccountWidget from "./AccountWidget";
import OpportunitiesWidget from "./OpportunitiesWidget";
import PeopleWidget from "./PeopleWidget";
import ProductSalesWidget from "./ProductSalesWidget";

function OverviewTab({ account }: { account: Account }) {
  // in case visiting territory page with an account selected then we will wait for the accounts to load
  if (!account) return null;

  return (
    <Box sx={{ display: "flex", gap: "20px" }}>
      <Box sx={{ flex: 1, minHeight: 420, maxWidth: 600 }}>
        <AccountWidget selectedAccount={account} />
      </Box>
      <Box
        sx={{
          flex: 1,
          minHeight: 420,
          maxWidth: 600,
          display: "flex",
          flexDirection: "column",
          gap: "20px",
        }}
      >
        <Box sx={{ flex: 1 }}>
          <ProductSalesWidget products={account.products || []} />
        </Box>
        <Box sx={{ display: "flex", gap: "10px", flex: 1 }}>
          <Box sx={{ flex: 1 }}>
            <OpportunitiesWidget {...account} />
          </Box>
          <Box sx={{ flex: 1 }}>
            <PeopleWidget accountId={account?.id} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default OverviewTab;
