import { EditOutlined } from "@ant-design/icons";
import { useAuth0 } from "@auth0/auth0-react";
import { Close } from "@mui/icons-material";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import {
  Box, Button, Stack, TextField, Typography,
} from "@mui/material";

import {
  useEffect, useMemo, useRef, useState,
} from "react";

import { useNavigate } from "react-router-dom";

import { Switcher } from "components/common";
import { calcThePercent } from "components/PipelineWidget/Bar";
import { openPipelineGoal } from "components/PipelineWidget/utils";

import usePipelineState from "services/shooks/pipeline";
import { getOnboardingStatus } from "utils/auth";
import { numberFormats } from "utils/numbers";

import {
  PipelineBarChart, SkeletonMask, WidgetBox, WidgetLink,
} from "./common";
import useTerritoryAccounts from "services/shooks/territory";

enum PipelineWidgetStatus {
  PlanNotSet = "PlanNotSet",
  PlanSet = "PlanSet",
  Edit = "Edit",
}
interface EditPipelineGoalFormProps {
  onCancel: () => void;
  onSave: (goal: number) => Promise<void>;
  isLoading?: boolean;
  goal?: number;
}

function EditPipelineGoalForm(props: EditPipelineGoalFormProps) {
  const [goal, setGoal] = useState<number | undefined>(props.goal);
  const inputRef = useRef<HTMLInputElement>(null);
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setGoal(Number(e.target.value));
  };

  const onSave = async () => {
    if (goal) {
      await props.onSave(goal);
      props.onCancel();
    }
  };

  const onReset = () => {
    setGoal(0);
  };

  useEffect(() => {
    if (props.goal) {
      setGoal(props.goal);
    }
  }, [props.goal]);

  const onKeyDown = async (e: KeyboardEvent) => {
    if (e.key === "Escape") {
      props.onCancel();
    }
    // enter to save
    if (e.key === "Enter") {
      await onSave();
    }
  };
  // cancel on hit Esc
  useEffect(() => {
    // focus on mount
    if (inputRef.current) {
      inputRef.current.focus();
    }

    window.addEventListener("keydown", onKeyDown);
    return () => {
      window.removeEventListener("keydown", onKeyDown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onSave]);

  return (
    <Box display="flex" flexDirection="column" gap="16px">
      <TextField
        inputRef={inputRef}
        placeholder="Enter pipeline goal here"
        variant="outlined"
        size="small"
        type="number"
        value={goal}
        disabled={props.isLoading}
        onChange={onChange}
        sx={{
          p: 0,
          m: 0,
        }}
        InputProps={{
          sx: {
            p: "5px 8px",
            color: "#1A1A1A",
            lineHeight: "24px",
            fontSize: "16px",
            height: "40px",
            borderRadius: "4px",
            "& input::-webkit-inner-spin-button,& input::-webkit-outer-spin-button": {
              "-webkit-appearance": "none",
              margin: 0,
            },
          },
          endAdornment: goal ? (
            <Close
              style={{ color: "#1A1A1A", height: "16px", cursor: "pointer" }}
              onClick={onReset}
            />
          ) : null,
        }}
      />
      <Stack direction="row" gap="8px" justifyContent="space-between" alignItems="center">
        <WidgetLink onClick={props.onCancel} sx={{ p: "9px 16px" }}>
          Cancel
        </WidgetLink>
        <Button
          variant="contained"
          size="small"
          disabled={props.isLoading}
          onClick={onSave}
          sx={{
            p: "9px 16px",
            color: "#fff",
            fontSize: "14px",
            lineHeight: "22px",
            bgcolor: "#004E87",
            "&:hover": {
              bgcolor: "#004E87",
              opacity: 0.8,
            },
            "&:disabled": {
              bgcolor: "#C8C8C8",
              color: "#333333",
            },
          }}
        >
          Save
        </Button>
      </Stack>
    </Box>
  );
}

function PipelineWidget() {
  const [status, setStatus] = useState<PipelineWidgetStatus>(PipelineWidgetStatus.PlanNotSet);
  const { state: pipelineState, isLoading, actions: pipelineActions } = usePipelineState();
  const navigator = useNavigate();
  const { selectedUserIds } = useTerritoryAccounts(({ state }) => state);
  // const totalMarketValue = pipelineState?.totalPipeline?.TAM || 15e6
  const pipelineGoal = numberFormats.millify(pipelineState.pipelineGoal || 0);
  const onUpdatePipelineMode = (screen: PipelineWidgetStatus) => {
    setStatus(screen);
  };
  // calcThePercent(openOpportunities, pipelineGoal);
  const onEditPipelineGoal = () => {
    onUpdatePipelineMode(PipelineWidgetStatus.Edit);
  };

  useEffect(() => {
    if (selectedUserIds && selectedUserIds.length > 0) {
      pipelineActions.get({ userIds: selectedUserIds })
    }
  }, [JSON.stringify(selectedUserIds)])

  const { user } = useAuth0<User>();

  const shouldRedirectToPlanWizard = useMemo(() => getOnboardingStatus(user).isIncomplete, [user]);
  const onOpenPlanWizard = () => {
    navigator("/plan-wizard");
  };
  const onUpdatePipeline = async (
    newPipelineRates: Parameters<typeof pipelineActions.update>[0],
  ) => {
    await pipelineActions.update(newPipelineRates);
    onUpdatePipelineMode(PipelineWidgetStatus.PlanSet);
  };

  const onSavePipelineGoal = async (pipelineGoal: number) => {
    const { conversionRate } = pipelineState;
    return onUpdatePipeline({ pipelineGoal, conversionRate });
  };

  useEffect(() => {
    if (shouldRedirectToPlanWizard) {
      onUpdatePipelineMode(PipelineWidgetStatus.PlanNotSet);
    } else {
      onUpdatePipelineMode(PipelineWidgetStatus.PlanSet);
    }
  }, [shouldRedirectToPlanWizard]);

  const pipelineCoverageValue = useMemo(() => {
    const { openOpportunities, uncapturedOpportunities } = pipelineState;
    const effectiveAmount = openOpportunities + uncapturedOpportunities;
    return effectiveAmount;
  }, [pipelineState]);

  const pipelineCoverageRate = useMemo(() => {
    const { pipelineGoal } = pipelineState;
    const goal = openPipelineGoal(pipelineCoverageValue, pipelineGoal);
    return (goal * 100).toFixed();
  }, [pipelineState, pipelineCoverageValue]);

  const [openPercentage, uncapturedPercentage] = useMemo(() => {
    const { openOpportunities, pipelineGoal, uncapturedOpportunities } = pipelineState;
    const hasPipelineGoal = Boolean(pipelineGoal);

    const openOpportunitiesPercentage = hasPipelineGoal
      ? calcThePercent(openOpportunities, pipelineGoal)
      : 0;
    const uncapturedOpportunitiesPercentage = calcThePercent(uncapturedOpportunities, pipelineGoal);
    return [openOpportunitiesPercentage, uncapturedOpportunitiesPercentage];
  }, [pipelineState]);

  const onCancelEditingPipelineGoal = () => {
    const status = shouldRedirectToPlanWizard
      ? PipelineWidgetStatus.PlanNotSet
      : PipelineWidgetStatus.PlanSet;
    onUpdatePipelineMode(status);
  };

  return (
    <WidgetBox title="Open Pipeline">
      {/* <WidgetBox title="Pipeline"> */}
      <Box sx={{ display: "flex", flexDirection: "column", gap: "12px" }}>
        <Stack
          gap="8px"
          direction="row"
          alignItems="flex-end"
          justifyContent="flex-start"
          flexGrow={0}
          lineHeight="36px"
        >
          <SkeletonMask loading={isLoading}>
            <Box
              sx={{
                color: "#1A1A1A",
                alignItems: "flex-end",
                fontWeight: "bold",
                fontSize: "27px",
                textTransform: "capitalize",
              }}
            >
              {/* temporarily replace actual pipeline data with open pipeline */}
              $
              {numberFormats.millify(
                pipelineState.openOpportunities > 0 ? pipelineState.openOpportunities : 0,
              )}
            </Box>
          </SkeletonMask>
          {pipelineState.pipelineGoal > 0 && (
            <SkeletonMask loading={isLoading}>
              <Box
                sx={{
                  color: "#AAAAAA",
                  alignItems: "flex-end",
                  overflow: "hidden",
                  wordWrap: "nowrap",
                  fontSize: "16px",
                  lineHeight: "26px",
                }}
              >
                /$
                {pipelineGoal}
                {" "}
                goal
              </Box>
            </SkeletonMask>
          )}
        </Stack>
        <Switcher active={status}>
          <Switcher.Case id={PipelineWidgetStatus.PlanNotSet}>
            <Stack direction="column">
              <PipelineBarChart />
              <Typography
                mt="3px"
                variant="body2"
                sx={{
                  color: "#802929", fontSize: "14px", lineHeight: "22px", fontWeight: "700",
                }}
              >
                Pipeline goal is not set yet
              </Typography>
            </Stack>
            <WidgetLink
              onClick={onEditPipelineGoal}
              icon={<EditOutlined style={{ height: "15px", width: "15px" }} />}
            >
              Set pipeline goal
            </WidgetLink>
          </Switcher.Case>
          <Switcher.Case id={PipelineWidgetStatus.PlanSet}>
            <Stack direction="column">
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                gap="8px"
                sx={{
                  "&:hover": {
                    ".edit-icon": {
                      opacity: 1,
                    },
                  },
                }}
              >
                <PipelineBarChart
                  total={100}
                  closedOpportunities={0} // temporarily disabled and should be named uncaptured opportunities
                  openOpportunities={openPercentage}
                />
                <ModeEditIcon
                  className="edit-icon"
                  onClick={onEditPipelineGoal}
                  sx={{
                    cursor: "pointer",
                    color: "currentColor",
                    fontSize: "18px",
                    opacity: 0,
                  }}
                />
              </Stack>
              <Box
                alignItems="center"
                mt="3px"
                gap="4px"
                display="flex"
                sx={{
                  color: "#205423",
                  fontSize: "14px",
                  lineHeight: "22px",
                  fontWeight: "700",
                }}
              >
                <SkeletonMask loading={isLoading}>
                  <span style={{ color: "#1A1A1A", fontWeight: 700 }}>
                    {openPercentage.toFixed()}
                    %
                  </span>
                </SkeletonMask>
                <span
                  style={{
                    color: Number(pipelineCoverageRate) >= 100 ? "#205423" : "#A86600",
                    fontWeight: 700,
                  }}
                >
                  of your goal is covered
                </span>
              </Box>
            </Stack>
            {/* <WidgetLink
              sx={{ mt: '15px' }}
              onClick={onOpenPlanWizard}
              icon={<ReloadOutlined style={{ height: '15px', width: '15px' }} />}
            >
              Revise Plan
            </WidgetLink> */}
          </Switcher.Case>
          <Switcher.Case id={PipelineWidgetStatus.Edit}>
            <EditPipelineGoalForm
              onSave={onSavePipelineGoal}
              onCancel={onCancelEditingPipelineGoal}
              isLoading={isLoading}
              goal={pipelineState.pipelineGoal}
            />
          </Switcher.Case>
        </Switcher>
      </Box>
    </WidgetBox>
  );
}

export default PipelineWidget;
