import {
  Button, CircularProgress, Tooltip, Typography,
} from "@mui/material";

import { Box } from "@mui/system";
import { SecCompaniesList } from "data/companiesSecFiling";
import { useSearchQuery } from "hooks";
import React from "react";
import { AiWorker, WorkerPreCachedParams } from "types/api";
import { isSubOrEqualString } from "utils/comparison";
import { captureExceptionWithLogRocket } from "utils/userActivitiesTracker/logRocket";
import { useWorkerPrePopulateParams } from "../shared/hooks";

import SecCompaniesAutoComplete from "./SecCompaniesAutoComplete";

interface SecWorkerFormProps {
  onFind: (accountTicker: string) => void;
  onResetErrorMessage: () => void;
  isLoading?: boolean;
  onCancelRequest: () => void;
  onResetForm?: () => void;
  secCompanies: SecCompaniesList;
}

function SecWorkerForm(props: SecWorkerFormProps) {
  const { onFind, onResetErrorMessage, isLoading, onCancelRequest, secCompanies } = props;
  const [accountTicker, setAccountsTicker] = React.useState<string>();

  const onAccountTickerChange = (value: string) => {
    setAccountsTicker(value);
    onResetErrorMessage();
  };

  const onFindSecWorker = (id: string) => () => {
    id && onFind(id);
  }
  const [{ id: preStoredParamsId }] = useSearchQuery<{ id: string }>();
  useWorkerPrePopulateParams((params: WorkerPreCachedParams) => {
    if (params.worker !== AiWorker.SEC_FILING) return;
    const { accountName } = params.parameters;
    if (!accountName) {
      const error = new Error(`Cached AI worker params was invalid, ${preStoredParamsId}`)
      captureExceptionWithLogRocket(error)
      return
    }
    const matchedCompany = Object.values(secCompanies).find((company) => isSubOrEqualString(company.title, accountName))
    const foundTicker = matchedCompany?.ticker
    if (foundTicker) {
      onAccountTickerChange(foundTicker)
      onFindSecWorker(foundTicker)()
    }
  }, preStoredParamsId, [Object.keys(secCompanies).length])


  const onResetForm = () => {
    setAccountsTicker("");
    props.onResetForm?.();
  }


  const shouldShowResetButton = !isLoading && Boolean(accountTicker);
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
      <Typography sx={{ fontSize: "12px", color: "#333333" }}>
        Enter the account(s) name to find the accounts Priorities and Risks.
      </Typography>
      <div style={{ display: "flex", gap: "10px", alignItems: "start" }}>
        <SecCompaniesAutoComplete
          companyTicker={accountTicker!}
          onCompanySelect={onAccountTickerChange}
          companies={secCompanies}
          placeholder="Account Name"
        />
        <Tooltip title={Boolean(accountTicker) ? "" : "Please enter the account name"}>
          <div>
            <Button sx={{ height: "42px", gap: "5px" }} variant="contained" color="primary" disabled={!shouldShowResetButton} onClick={onFindSecWorker(accountTicker!)}>
              Search
              {isLoading && <CircularProgress size={12} color="inherit" sx={{ display: isLoading ? "block" : "none" }} />}
            </Button>
          </div>
        </Tooltip>
        {isLoading ? (
          <Button sx={{ height: "42px" }} variant="text" onClick={onCancelRequest}>Cancel</Button>
        ) : shouldShowResetButton && (
          <Button sx={{ height: "42px" }} variant="text" onClick={onResetForm}>Reset</Button>
        )}
      </div>
    </Box>
  );
}

export default SecWorkerForm;
