import { Box, SxProps } from "@mui/system";
import React from "react";

interface ModalProps {
  open: boolean;
  onClose: () => void;
  children: React.ReactNode;
  sx?: SxProps
  containerSx?: SxProps
}

function Modal(props: ModalProps) {
  const {
    open, onClose, sx, containerSx,
  } = props;

  return (
    <Box
      sx={{
        display: open ? "flex" : "none",
        position: "absolute",
        zIndex: 1,
        left: 0,
        top: 0,
        bottom: 0,
        right: 0,
        backgroundColor: "rgba(0,0,0,0.4)",
        justifyContent: "center",
        alignItems: "center",
        ...sx,
      }}
    >
      <Box
        sx={{
          backgroundColor: "#fefefe",
          position: "relative",
          padding: "20px",
          border: "1px solid #888",
          width: "80%",
          maxWidth: "80%",
          borderRadius: "12px",
          ...containerSx,
        }}
      >
        <span
          style={{
            position: "absolute",
            right: "15px",
            top: "10px",
            color: "#888",
            float: "right",
            fontSize: "28px",
            fontWeight: "bold",
            transform: "scale(1.2)",
            marginRight: "10px",
            cursor: "pointer",
          }}
          onClick={onClose}
        >
          &times;
        </span>
        {props.children}
      </Box>
    </Box>
  );
}

export default Modal;
