import type {
  PipelineGoalResponse,
  UpdatePipelinePayload,
  UpdatePipelineResponse,
} from "types/api";
import { AxiosNode } from "utils/axios";

class PipelineAPI {
  static getTheGoal = async (
    { allAccounts = false, userIds = [] }: { allAccounts?: boolean; userIds?: string[] } = {
      allAccounts: true,
    },
  ) => {
    try {
      const payload = { allAccounts, userIds };
      const { data } = await AxiosNode.post<PipelineGoalResponse>("/pipeline/goal", payload);
      return data;
    } catch (error) {
      return {} as PipelineGoalResponse
    }
  };

  // @deprecated
  static fetchPipelineGoal = this.getTheGoal;

  static updateGoal = async (payload: UpdatePipelinePayload) => {
    await AxiosNode.patch<UpdatePipelineResponse>("/pipeline/goal", payload);
    const triggerActions = [
      this.getTheGoal({ allAccounts: true }),
      this.getTheGoal({ allAccounts: false }),
    ];

    const [pipelineForAllAccounts, pipelineGoalForTargetAccounts] = await Promise.all(triggerActions);
    const _state = {
      ...pipelineGoalForTargetAccounts,
      openOpportunities: pipelineForAllAccounts.openOpportunities,
    } as PipelineGoalResponse;
    // const { data } = await AxiosNode.get<PipelineGoalResponse>(`/pipeline/goal?allAccounts=true`);
    return _state;
  };

  // @deprecated
  static updatePipelineGoal = this.updateGoal;
}

export default PipelineAPI;
