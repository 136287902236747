/* eslint-disable react/display-name */
import { Box, Typography } from "@mui/material";
import dayjs from "dayjs";

import "./columnsOverrides.css";

import { AIValueIcon } from "assets/icons";
import { editIcon } from "assets/images/brands";
import { ActivitySignals, CheckboxWithDropdown, Head } from "components/AccountTables";

import { PipelineSignal, signalStyles } from "components/AccountTables/PipelineSignal";
import Tooltip from "components/Tooltip";

import envConfig from "config/envConfig";
import { standardUSDateFormat } from "constants/dates";
import { IActivitySegment } from "types/ActivityStream";
import {
  Account,
  DynFilterColumn, ValueOf,
} from "types/api";
import { TerritoryTableColumnTitles, TerritoryTableColumnsAccessors } from "types/api/territory";
import { AccountStatus, PipelineSignalEnums } from "types/api/territoryFilters";
import { IAccountStatus, ISimilarWin } from "types/territory";
import { formatDateDifference, formatRenews, timeFromNow } from "utils/dates";
import { truncateText } from "utils/generics";
import { numberFormats } from "utils/numbers";

import {
  CellProps,
  Params,
  TableColumn,
  getSourceIcon,
  getWebsiteIcon,
} from "./utils";

import {
  formatArrayOfStrings, isArrayOfObjectsContainingName, isArrayOfStrings, isISODate,
  spaceAndCapitalize,
} from "../../utils";
import AccountStatusBox from "../AccountStatus";
import { WalletShareClassic, WalletShareHeader } from "../AnnualRecurringRevenue";
import EditableColumn from "../EditableColumn";
import { Priority } from "../Priority";
import { SelectionCheckbox } from "../SelectionCheckbox";
import { SimilarWins } from "../SimilarWins";
import CircularProgressBar from "components/common/AccountRankBadge";

export const defaultColumnsMap = (
  {
    instanceURL,
    onAccountFocus,
    onPriorityChange,
    onFilterByAccountLastSignal,
    onRowSelection,
    onAccountOpportunitiesFocus
  }: Params,
): Map<TerritoryTableColumnsAccessors, TableColumn> => {
  const mappings: Map<TerritoryTableColumnsAccessors, TableColumn> = new Map([
    [TerritoryTableColumnsAccessors.selection, {
      Header: (
        <Head sx={{ width: "35px", display: "flex", justifyContent: "flex-start" }}>
          <CheckboxWithDropdown />
        </Head>
      ),
      accessor: TerritoryTableColumnsAccessors.selection,
      disableSortBy: true,
      className: "collapse-column sticky-lift-0 clickable",
      style: { width: "35px", padding: "0px", zIndex: 101 },
      onClick: onRowSelection,
      Cell: ({ row }: { row: any }) => (
        <Box
          id={row.original.id}
          sx={{
            height: "51px", width: '35px', margin: 0, display: "flex", alignItems: "center",
          }}
        >
          <SelectionCheckbox id={row.original.id} isSelected={row.original.selected} />
        </Box>
      ),
    }],
    [TerritoryTableColumnsAccessors.account, {
      Header: <Head>{TerritoryTableColumnTitles.name}</Head>,
      accessor: TerritoryTableColumnsAccessors.account,
      regularBorder: true,
      hasBorderRight: true,
      className: "collapse-column sticky-lift-35",
      style: { width: "297px", minWidth: "297px", zIndex: 101 },
      Cell: ({
        value, row, isEditable, data,
      }: CellProps) => {
        const { source, website } = data[row.index];
        const hideTooltip = value.length < 40;
        const accountId = row?.original?.id;
        return (
          <Tooltip placement="top" hide={hideTooltip} title={value}>
            <Box
              key={accountId}
              className="truncate"
              sx={{
                cursor: `${isEditable ? `url(${editIcon}) 16 16, auto` : "pointer"}`,
                height: "70px",
                padding: "5px 0px",
                display: "flex",
                flexDirection: "column",
                fontSize: "14px",
                maxWidth: "297px",
                width: "297px",
              }}
            >
              <button
                type="button"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginBottom: "7px",
                }}
                onClick={() => onAccountFocus(row?.original?.id)}
              >
                <Typography
                  sx={{
                    cursor: "pointer",
                    "&:hover": {
                      fontWeight: "600",
                      color: "#004e87",
                      textDecoration: "underline",
                    },
                    maxWidth: "200px",
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                  }}
                >
                  {value}
                </Typography>
              </button>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                {getSourceIcon(instanceURL, source)}
                {getWebsiteIcon(website)}
              </div>
            </Box>
          </Tooltip>
        );
      },
    }],
    [TerritoryTableColumnsAccessors.ownerName, {
      Header: <Head>{TerritoryTableColumnTitles.ownerName}</Head>,
      accessor: TerritoryTableColumnsAccessors.ownerName,
      className: "collapse-column",
      style: { minWidth: "150px", width: "150px", wordBreak: "none" },
      regularBorder: true,
      Cell: ({ value, isEditable }: { value: string, isEditable: boolean }) => {
        const renderValue = value ? truncateText(value, 16) : "--";
        const isTooltipVisible = value?.length > 16;
        return (
          <Tooltip placement="top" hide={!isTooltipVisible} title={value}>
            <Box
              sx={{
                cursor: `${isEditable ? `url(${editIcon}) 16 16, auto` : "pointer"}`,
                display: "flex",
                justifyContent: "center",
                fontSize: "12px",
                wordBreak: "keep-all",
              }}
            >
              {renderValue}
            </Box>
          </Tooltip>
        );
      },
    }],
    [TerritoryTableColumnsAccessors.sdrPersonName, {
      Header: <Head>{TerritoryTableColumnTitles.sdrPersonName}</Head>,
      accessor: TerritoryTableColumnsAccessors.sdrPersonName,
      className: "collapse-column",
      style: { minWidth: "150px", width: "150px", wordBreak: "none" },
      regularBorder: true,
      Cell: ({ value, isEditable }: { value: string, isEditable: boolean }) => {
        const renderValue = value ? truncateText(value, 16) : "--";
        const isTooltipVisible = value?.length > 16;
        return (
          <Tooltip placement="top" hide={!isTooltipVisible} title={value}>
            <Box
              sx={{
                cursor: `${isEditable ? `url(${editIcon}) 16 16, auto` : "pointer"}`,
                display: "flex",
                justifyContent: "center",
                fontSize: "12px",
                wordBreak: "keep-all",
              }}
            >
              {renderValue}
            </Box>
          </Tooltip>
        );
      },
    }],
    [TerritoryTableColumnsAccessors.pipelineSignal, {
      Header: <Head>{TerritoryTableColumnTitles.pipelineSignal}</Head>,
      accessor: TerritoryTableColumnsAccessors.pipelineSignal,
      className: "collapse-column",
      style: { minWidth: "150px", width: "150px" },
      Cell: ({ value, row }: { value: PipelineSignalEnums; row: any }) => {
        const accountFitData = row?.original?.annualRecurringRevenue?.accountMLInterpretation?.shap || {};

        return (
          <Box className="truncate" sx={{ display: "flex", pl: "2px", width: "100%" }}>
            <PipelineSignal signal={value} accountFitData={accountFitData} hasTooltip />
          </Box>
        );
      },
    }],
    [TerritoryTableColumnsAccessors.rankValue, {
      Header: <Head>{TerritoryTableColumnTitles.rankValue}</Head>,
      accessor: TerritoryTableColumnsAccessors.rankValue,
      className: "collapse-column",
      style: { minWidth: "150px", width: "150px" },
      Cell: ({ value, row }: { value: PipelineSignalEnums; row: any }) => {
        const rank = row?.original?.rankValue
        const color = signalStyles[row?.original?.pipelineSignal]?.backgroundColor || '#3498db'
        return (
          <Box className="truncate" sx={{ display: "flex", justifyContent: 'center', alignItems: 'center', width: "100%" }}>
            <CircularProgressBar progress={rank} size={43} strokeWidth={7} gradientColors={[color]} />
          </Box>
        );
      },
    }],
    [TerritoryTableColumnsAccessors.priority, {
      Header: <Head>{TerritoryTableColumnTitles.priority}</Head>,
      accessor: TerritoryTableColumnsAccessors.priority,
      style: { minWidth: "90px", width: "90px", height: "54px" },
      className: "collapse-column",
      Cell: ({ value, row }: { value: string; row: any }) => (
        <Box
          className="truncate"
          sx={{
            minWidth: "66px", height: "54px", display: "flex", alignItems: "center",
          }}
        >
          <Priority onPriorityChange={onPriorityChange} priority={value} id={row.original.id} />
        </Box>
      ),
    }],
    [TerritoryTableColumnsAccessors.accountStatus, {
      Header: <Head>{TerritoryTableColumnTitles.accountStatus}</Head>,
      accessor: TerritoryTableColumnsAccessors.accountStatus,
      className: "collapse-column",
      style: { width: "198px", minWidth: "198px" },
      regularBorder: true,
      Cell: ({ value }: { value: IAccountStatus }) => (
        <Box sx={{ width: "177px" }}>
          <AccountStatusBox accountStatus={value as AccountStatus} />
        </Box>
      ),
    }],
    [TerritoryTableColumnsAccessors.lastSalesActivity, {
      Header: (
        <Head sx={{ minWidth: "170px" }}>{TerritoryTableColumnTitles.lastSalesActivity}</Head>
      ),
      accessor: TerritoryTableColumnsAccessors.lastSalesActivity,
      className: "collapse-column cell-center",
      style: { width: "192px", minWidth: "192px" },
      regularBorder: true,
      Cell: ({ value, row }: { value: string; row: any }) => {
        const dateInfo = timeFromNow(value);
        const renderValue = dateInfo.date
          ? formatDateDifference(dateInfo.date, dateInfo.difference)
          : "--";
        const hasValidSignal = Boolean(dateInfo.date);

        const details = {
          id: row.original.id,
          name: row.original.name,
          segment: IActivitySegment.SALES,
          hasValidSignal,
        };
        return (
          <ActivitySignals
            value={renderValue}
            onClick={onFilterByAccountLastSignal(details)}
            hasValidSignal={hasValidSignal}
          />
        );
      },
    }],
    [TerritoryTableColumnsAccessors.lastCustomerSignal, {
      Header: (
        <Head sx={{ minWidth: "250px" }}>{TerritoryTableColumnTitles.lastCustomerSignal}</Head>
      ),
      accessor: TerritoryTableColumnsAccessors.lastCustomerSignal,
      className: "collapse-column cell-center",
      style: { width: "250px", minWidth: "250px" },
      regularBorder: true,
      Cell: ({ value, row }: { value: string; row: any }) => {
        const dateInfo = timeFromNow(value);
        const renderValue = dateInfo.date
          ? formatDateDifference(dateInfo.date, dateInfo.difference)
          : "--";
        const hasValidSignal = Boolean(dateInfo.date);

        const details = {
          id: row.original.id,
          name: row.original.name,
          segment: IActivitySegment.CUSTOMER,
          hasValidSignal: Boolean(dateInfo.date),
        };
        return (
          <ActivitySignals
            value={renderValue}
            onClick={onFilterByAccountLastSignal(details)}
            hasValidSignal={hasValidSignal}
          />
        );
      },
    }],
    [TerritoryTableColumnsAccessors.nextRenewalDate, {
      Header: (
        <Head sx={{ display: "flex", justifyContent: "center" }}>
          {TerritoryTableColumnTitles.nextRenewalDate}
        </Head>
      ),
      accessor: TerritoryTableColumnsAccessors.nextRenewalDate,
      className: "cell-center collapse-column",
      style: { width: "192px", minWidth: "192px" },
      regularBorder: true,
      Cell: ({ value, isEditable }: { value: string, isEditable: boolean }) => {
        const dateInfo = timeFromNow(value);
        const renderValue = dateInfo.date ? formatRenews(dateInfo.date, dateInfo.difference) : "";
        const isLessThanAMonth = dateInfo?.difference && dateInfo?.difference < 30;
        const background = isLessThanAMonth ? "#FFEBEB" : "#F2F2F2";
        const color = isLessThanAMonth ? "#802929" : "#1A1A1A";
        const formattedDate = value ? dayjs(new Date(value)).format("MM/DD/YYYY") : "";

        return (
          <Tooltip title={formattedDate} placement="top">
            <Box
              sx={{
                cursor: `${isEditable ? `url(${editIcon}) 16 16, auto` : "pointer"}`,
                background,
                color,
                padding: "2px 5px",
                borderRadius: "5px",
                textAlign: "center",
                width: "106.5px",
                margin: "0 auto",
                fontSize: "14px",
              }}
            >
              {renderValue || "--"}
            </Box>
          </Tooltip>
        );
      },
    }],
    [TerritoryTableColumnsAccessors.employees, {
      Header: <Head>{TerritoryTableColumnTitles.employees}</Head>,
      accessor: TerritoryTableColumnsAccessors.employees,
      style: { minWidth: "110px", width: "110px", maxWidth: "110px" },
      className: "collapse-column cell-center",
      regularBorder: true,
      Cell: ({ value, isEditable }: { value: string, isEditable: boolean }) => {
        const renderValue = value ? parseInt(value).toLocaleString() : "--";
        return (
          <Box
            sx={{
              cursor: `${isEditable ? `url(${editIcon}) 16 16, auto` : "pointer"}`,
              display: "flex",
              justifyContent: "center",
              width: "89px",
              maxWidth: "89px",
              fontSize: "12px",
            }}
          >
            {renderValue}
          </Box>
        );
      },
    }],
    [TerritoryTableColumnsAccessors.industry, {
      Header: (
        <Head sx={{ maxWidth: "200px", width: "200px" }}>
          {TerritoryTableColumnTitles.industry}
        </Head>
      ),
      accessor: TerritoryTableColumnsAccessors.industry,
      className: "collapse-column",
      style: { maxWidth: "200px", width: "200px", wordBreak: "none" },
      regularBorder: true,
      Cell: ({ value, isEditable }: { value: string, isEditable: boolean }) => (
        <Tooltip placement="top" title={value}>
          <Box
            className="truncate"
            sx={{
              cursor: `${isEditable ? `url(${editIcon}) 16 16, auto` : "pointer"}`,
              width: "200px",
              maxWidth: "200px",
              fontSize: "12px",
              wordBreak: "keep-all",
            }}
          >
            {value}
          </Box>
        </Tooltip>
      ),
    }],
    [TerritoryTableColumnsAccessors.annualRevenue, {
      Header: (
        <Head sx={{ display: "flex", justifyContent: "center", width: "70px" }}>
          {TerritoryTableColumnTitles.annualRevenue}
        </Head>
      ),
      accessor: TerritoryTableColumnsAccessors.annualRevenue,
      className: "collapse-column",
      style: { width: "95px", maxWidth: "95px", minWidth: "95px" },
      regularBorder: true,
      Cell: ({ value, isEditable }: { value: number, isEditable: boolean }) => {
        const formattedRevenue = numberFormats.millify(value);
        const renderValue = formattedRevenue === "0" ? "--" : `$${formattedRevenue}`;
        return (
          <Box
            sx={{
              cursor: `${isEditable ? `url(${editIcon}) 16 16, auto` : "pointer"}`,
              display: "flex",
              justifyContent: "center",
              width: "70px",
              fontSize: "12px",
              maxWidth: "91px",
            }}
            className="truncate"
          >
            {renderValue}
          </Box>
        );
      },
    }],
    [TerritoryTableColumnsAccessors.similarWins, {
      Header: <Head sx={{ width: "107px" }}>{TerritoryTableColumnTitles.similarWins}</Head>,
      accessor: TerritoryTableColumnsAccessors.similarWins,
      className: "collapse-column cell-center",
      regularBorder: true,
      style: { width: "131px" },
      Cell: ({ value }: { value: ISimilarWin[] }) => <SimilarWins similarWins={value} label="Accounts" />,
    }],
    [TerritoryTableColumnsAccessors.whitespace, {
      Header: (
        <Head sx={{ display: "flex", justifyContent: "center" }}>
          {TerritoryTableColumnTitles.whitespace}
        </Head>
      ),
      accessor: TerritoryTableColumnsAccessors.whitespace,
      className: "collapse-column cell-center",
      regularBorder: true,
      hasBorderRight: true,
      style: { width: "160px" },
      Cell: ({ value, isEditable }: { value: number, isEditable: boolean }) => {
        const formattedWhitespace = numberFormats.millify(value);
        const renderValue = formattedWhitespace === "0" ? "--" : `$${formattedWhitespace}`;
        return (
          <Box
            sx={{
              minWidth: "130px",
              fontSize: "14px",
              fontWeight: "500",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "5px",
            }}
          >
            {/* Temporary placeholder. This should correspond to a check from the server */}
            {renderValue}
            {" "}
            <AIValueIcon />
          </Box>
        );
      },
    }],
    [TerritoryTableColumnsAccessors.annualRecurringRevenue, {
      Header: (
        <Head sx={{ width: "153px" }}>
          <WalletShareHeader />
        </Head>
      ),
      accessor: TerritoryTableColumnsAccessors.annualRecurringRevenue,
      disableSortBy: true,
      className: "cell-left collapse-column",
      style: { width: "175px", maxWidth: "175px", minWidth: "175px" },
      regularBorder: true,
      Cell: ({ value }: { value: Account }) => {
        const openOpportunitiesAmount = value?.openOpportunitiesAmount || 0;
        const closedOpportunitiesAmount = value?.closedOpportunitiesAmount || 0;
        // defaulting TAM to 0 results in NaN and infinity values when
        // we calculate percentages (open/TAM...etc)
        // see the walletshare widget component for context
        const TAM = value?.TAMamount || openOpportunitiesAmount + closedOpportunitiesAmount;
        const uncapturedValue = (value?.TAMamount || 0)
          - (openOpportunitiesAmount + closedOpportunitiesAmount);
        let uncaptured = 0;
        if (envConfig.apiVersion === "V1") {
          uncaptured = uncapturedValue > 0 ? uncapturedValue : 0;
        } else {
          uncaptured = value?.uncapturedWhitespaceAmount;
        }
        return (
          <Box>
            <WalletShareClassic
              closed={closedOpportunitiesAmount}
              open={openOpportunitiesAmount}
              TAM={TAM}
              uncapturedWhitespace={uncaptured}
            />
          </Box>
        );
      },
    }],
    [TerritoryTableColumnsAccessors.OBLastActivitySDR, {
      Header: <Head>{TerritoryTableColumnTitles.OBLastActivitySDR}</Head>,
      accessor: TerritoryTableColumnsAccessors.OBLastActivitySDR,
      className: "collapse-column",
      style: { minWidth: "180px", width: "180px", wordBreak: "none" },
      regularBorder: true,
      Cell: ({ value, isEditable }: { value: string, isEditable: boolean }) => {
        const formattedDate = value ? dayjs(new Date(value)).format(standardUSDateFormat) : "--";

        return (
          <Box
            sx={{
              cursor: `${isEditable ? `url(${editIcon}) 16 16, auto` : "pointer"}`,
              display: "flex",
              justifyContent: "center",
              fontSize: "12px",
              wordBreak: "keep-all",
            }}
          >
            {formattedDate}
          </Box>
        );
      },
    }],
    [TerritoryTableColumnsAccessors.AELastActivity, {
      Header: <Head>{TerritoryTableColumnTitles.AELastActivity}</Head>,
      accessor: TerritoryTableColumnsAccessors.AELastActivity,
      className: "collapse-column",
      style: { minWidth: "150px", width: "150px", wordBreak: "none" },
      regularBorder: true,
      Cell: ({ value, isEditable }: { value: string, isEditable: boolean }) => {
        const formattedDate = value ? dayjs(new Date(value)).format(standardUSDateFormat) : "--";

        return (
          <Box
            sx={{
              cursor: `${isEditable ? `url(${editIcon}) 16 16, auto` : "pointer"}`,
              display: "flex",
              justifyContent: "center",
              fontSize: "12px",
              wordBreak: "keep-all",
            }}
          >
            {formattedDate}
          </Box>
        );
      },
    }],
    [TerritoryTableColumnsAccessors.RecordType, {
      Header: <Head>{TerritoryTableColumnTitles.RecordType}</Head>,
      accessor: TerritoryTableColumnsAccessors.RecordType,
      className: "collapse-column",
      style: { minWidth: "180px", width: "180px", wordBreak: "none" },
      regularBorder: true,
      Cell: ({ value, isEditable }:
        { value: { name: string; id: string }, isEditable: boolean }) => (
        <Box
          sx={{
            cursor: `${isEditable ? `url(${editIcon}) 16 16, auto` : "pointer"}`,
            display: "flex",
            justifyContent: "center",
            fontSize: "12px",
            wordBreak: "keep-all",
          }}
        >
          {value?.name || "--"}
        </Box>
      ),
    }],
    [TerritoryTableColumnsAccessors.emailProvider, {
      Header: <Head>{TerritoryTableColumnTitles.emailProvider}</Head>,
      accessor: TerritoryTableColumnsAccessors.emailProvider,
      className: "collapse-column",
      style: { minWidth: "150px", width: "150px", wordBreak: "none" },
      regularBorder: true,
      Cell: ({ value, isEditable }: { value: string, isEditable: boolean }) => (
        <Box
          sx={{
            cursor: `${isEditable ? `url(${editIcon}) 16 16, auto` : "pointer"}`,
            display: "flex",
            justifyContent: "center",
            fontSize: "12px",
            wordBreak: "keep-all",
          }}
        >
          {value || "--"}
        </Box>
      ),
    }],
    [TerritoryTableColumnsAccessors.CRM, {
      Header: <Head>{TerritoryTableColumnTitles.CRM}</Head>,
      accessor: TerritoryTableColumnsAccessors.CRM,
      className: "collapse-column",
      style: { minWidth: "150px", width: "150px", wordBreak: "none" },
      regularBorder: true,
      Cell: ({ value, isEditable }: { value: string, isEditable: boolean }) => (
        <Box
          sx={{
            cursor: `${isEditable ? `url(${editIcon}) 16 16, auto` : "pointer"}`,
            display: "flex",
            justifyContent: "center",
            fontSize: "12px",
            wordBreak: "keep-all",
          }}
        >
          {value || "--"}
        </Box>
      ),
    }],
    [TerritoryTableColumnsAccessors.totalClosedOpportunities, {
      Header: <Head sx={{
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
        textAlign: "center",
        width: "200px",
      }}
      >
        {TerritoryTableColumnTitles[TerritoryTableColumnsAccessors.totalClosedOpportunities]}
      </Head>,
      accessor: TerritoryTableColumnsAccessors.totalClosedOpportunities,
      disableSortBy: true,
      className: `collapse-column`,
      style: {
        width: "200px",
        padding: "10px",
      },
      Cell: ({ value, isEditable, row }: { value: string, isEditable: boolean, row: any }) => {
        const accountId = row?.original?.id;
        const renderValue = value ? parseInt(value).toLocaleString() : 0;
        return (
          <Box
            onClick={() => onAccountOpportunitiesFocus(accountId!, { status: "closed" })}
            sx={{
              cursor: `${isEditable ? `url(${editIcon}) 16 16, auto` : "pointer"}`,
              display: "flex",
              justifyContent: "center",
              fontSize: "12px",
              '&:hover': {
                color: "#004e87",
                textDecoration: "underline",
              },
            }}
          >
            {renderValue}
          </Box>
        );
      }
    }
    ],
    [TerritoryTableColumnsAccessors.totalOpenOpportunities, {
      Header: <Head sx={{
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
        textAlign: "center",
        width: "200px",
      }}
      >
        {TerritoryTableColumnTitles[TerritoryTableColumnsAccessors.totalOpenOpportunities]}
      </Head>,
      accessor: TerritoryTableColumnsAccessors.totalOpenOpportunities,
      disableSortBy: true,
      className: `collapse-column`,
      style: {
        width: "200px",
        padding: "10px",
      },
      Cell: ({ value, isEditable, row }: { value: string, isEditable: boolean, row: any }) => {
        const accountId = row?.original?.id;
        const renderValue = value ? parseInt(value).toLocaleString() : 0;
        return (
          <Box
            onClick={() => onAccountOpportunitiesFocus(accountId!, { status: "open" })}
            sx={{
              cursor: `${isEditable ? `url(${editIcon}) 16 16, auto` : "pointer"}`,
              display: "flex",
              justifyContent: "center",
              fontSize: "12px",
              '&:hover': {
                color: "#004e87",
                textDecoration: "underline",
              },
            }}
          >
            {renderValue}
          </Box>
        );
      }
    }
    ],
    [TerritoryTableColumnsAccessors.totalLosesToDate, {
      Header: <Head sx={{
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
        textAlign: "center",
        width: "100px",
      }}
      >
        {TerritoryTableColumnTitles[TerritoryTableColumnsAccessors.totalLosesToDate]}
      </Head>,
      accessor: TerritoryTableColumnsAccessors.totalLosesToDate,
      disableSortBy: true,
      className: `collapse-column`,
      style: {
        width: "150px",
        padding: "10px",
      },
      Cell: ({ value, isEditable, row }: { value: string, isEditable: boolean, row: any }) => {
        const accountId = row?.original?.id;
        const renderValue = value ? parseInt(value).toLocaleString() : 0;
        return (
          <Box
            onClick={() => onAccountOpportunitiesFocus(accountId!, {})}
            sx={{
              cursor: `${isEditable ? `url(${editIcon}) 16 16, auto` : "pointer"}`,
              display: "flex",
              justifyContent: "center",
              fontSize: "12px",
              '&:hover': {
                color: "#004e87",
                textDecoration: "underline",
              },
            }}
          >
            {renderValue}
          </Box>
        );
      }
    }
    ],
    [TerritoryTableColumnsAccessors.totalWinsToDate, {
      Header: <Head sx={{
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
        textAlign: "center",
        width: "100px",
      }}
      >
        {TerritoryTableColumnTitles[TerritoryTableColumnsAccessors.totalWinsToDate]}
      </Head>,
      accessor: TerritoryTableColumnsAccessors.totalWinsToDate,
      disableSortBy: true,
      className: `collapse-column`,
      style: {
        width: "150px",
        padding: "10px",
      },
      Cell: ({ value, isEditable, row }: { value: string, isEditable: boolean, row: any }) => {
        const accountId = row?.original?.id;
        const renderValue = value ? parseInt(value).toLocaleString() : 0;
        return (
          <Box
            onClick={() => onAccountOpportunitiesFocus(accountId!, {})}
            sx={{
              cursor: `${isEditable ? `url(${editIcon}) 16 16, auto` : "pointer"}`,
              display: "flex",
              justifyContent: "center",
              fontSize: "12px",
              '&:hover': {
                color: "#004e87",
                textDecoration: "underline",
              },
            }}
          >
            {renderValue}
          </Box>
        );
      }
    }
    ],
    [TerritoryTableColumnsAccessors.openOpportunitiesAmount, {
      Header: <Head sx={{
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
        textAlign: "center",
        width: "240px",
      }}
      >
        {TerritoryTableColumnTitles[TerritoryTableColumnsAccessors.openOpportunitiesAmount]}
      </Head>,
      accessor: TerritoryTableColumnsAccessors.openOpportunitiesAmount,
      disableSortBy: true,
      className: `collapse-column`,
      style: {
        width: "240px",
        padding: "10px",
      },
      Cell: ({ value, isEditable, row }: { value: string, isEditable: boolean, row: any }) => {
        const accountId = row?.original?.id;
        const renderValue = value ? parseInt(value).toLocaleString() : 0;
        return (
          <Box
            onClick={() => onAccountOpportunitiesFocus(accountId!, { status: 'open' })}
            sx={{
              cursor: `${isEditable ? `url(${editIcon}) 16 16, auto` : "pointer"}`,
              display: "flex",
              justifyContent: "center",
              fontSize: "12px",
              '&:hover': {
                color: "#004e87",
                textDecoration: "underline",
              },
            }}
          >
            {renderValue}
          </Box>
        );
      }
    }
    ],
    [TerritoryTableColumnsAccessors.closedOpportunitiesAmount, {
      Header: <Head sx={{
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
        textAlign: "center",
        width: "250px",
      }}
      >
        {TerritoryTableColumnTitles[TerritoryTableColumnsAccessors.closedOpportunitiesAmount]}
      </Head>,
      accessor: TerritoryTableColumnsAccessors.closedOpportunitiesAmount,
      disableSortBy: true,
      className: `collapse-column`,
      style: {
        width: "250px",
        padding: "10px",
      },
      Cell: ({ value, isEditable, row }: { value: string, isEditable: boolean, row: any }) => {
        const accountId = row?.original?.id;
        const renderValue = value ? parseInt(value).toLocaleString() : 0;
        return (
          <Box
            onClick={() => onAccountOpportunitiesFocus(accountId!, { status: 'closed' })}
            sx={{
              cursor: `${isEditable ? `url(${editIcon}) 16 16, auto` : "pointer"}`,
              display: "flex",
              justifyContent: "center",
              fontSize: "12px",
              '&:hover': {
                color: "#004e87",
                textDecoration: "underline",
              },
            }}
          >
            {renderValue}
          </Box>
        );
      }
    }
    ],
  ]);
  return mappings;
};


export const makeEditable = (
  columnMetData: ValueOf<DynFilterColumn>,
  column: TableColumn,
): TableColumn => {
  const { Cell } = column;
  if (Cell) {
    column.Cell = (function (_props: any) {
      const props = { ..._props, isEditable: true };
      return (
        <EditableColumn
          columnMetData={columnMetData}
          recordId={props?.row.original.id as string}
          filterKey={column.accessor}
          value={props?.value}
          {...props}
        >
          {Cell(props)}
        </EditableColumn>
      );
    });
  }
  return column;
};

export const formatUnkownValue = (value: any, accessor: string): string => {
  const isNumeric = /^\d+$/.test(value);
  if (value === null || value === undefined) {
    return "--";
  }
  if (isArrayOfStrings(value)) {
    return formatArrayOfStrings(value);
  }
  if (Array.isArray(value)) {
    return formatArrayOfStrings(value.map((v) => v.name));
  }
  if (isISODate(value)) {
    if (accessor.includes("last")) {
      const dateInfo = timeFromNow(value as string || "");
      const renderValue = dateInfo.date
        ? formatDateDifference(dateInfo.date, dateInfo.difference)
        : dayjs(new Date(value)).format(standardUSDateFormat);
      return renderValue;
    }

    return dayjs(new Date(value)).format(standardUSDateFormat);
  }
  if (isNumeric || (typeof value === "number" && !Number.isNaN(value))) {
    return numberFormats.millify(+value);
  }
  return value || "--";
};

export const renderUnknowValue = (value: any, isEditable: boolean, accessor: string) => {
  if (isArrayOfObjectsContainingName(value)) {
    const mappedValue = value.map((val) => ({
      accountName: val.name,
    }));
    // TODO: SimilarWins needs to be refactored, value doesnt need to be ISImlarWin, we just need name
    return <SimilarWins similarWins={mappedValue as ISimilarWin[]} label={spaceAndCapitalize(accessor)} />;
  }
  const formattedValue = formatUnkownValue(value, accessor);
  const renderValue = formattedValue ? truncateText(formattedValue, 25) : "--";
  const isTooltipVisible = formattedValue?.length > 25;
  const activeStyle = {
    fontWeight: "600",
    color: "#004e87",
    textDecoration: "underline",
  };
  return (
    <Tooltip placement="top" hide={!isTooltipVisible} title={formattedValue}>
      <Box
        sx={{
          cursor: `${isEditable ? `url(${editIcon}) 16 16, auto` : "pointer"}`,
          alignItems: "center",
          display: "flex",
          fontSize: "12px",
          justifyContent: "center",
          height: "54px",
          width: "100px",
          wordBreak: "keep-all",
          "&:hover": activeStyle,
        }}
      >
        {renderValue}
      </Box>
    </Tooltip>
  );
};

const nonDisplayableCols = ["source", "website"];
export const removeNonDisplayableColumns = (key: string): boolean => !nonDisplayableCols
  .includes(key);
