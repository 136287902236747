import { Stack } from "@mui/material";

import { IActivity } from "types/api/activities";

import { PersonLabel } from "./common";
import { ActivityContentInteractions, EmailField } from "../common";
import { formatForMinutesFromSeconds } from "../utils";

interface ICallContentProps {
  activity: IActivity;
  onActionTrigger?: (action: ActivityContentInteractions, value?: string, name?: string) => void;
}

export function CallContent({ activity, onActionTrigger }: ICallContentProps) {
  const calledPerson = {
    name: activity.secondParty?.personName,
    email: activity.secondParty?.email,
    title: activity.secondParty?.title,
  };

  const onFilterByPerson = (personName?: string, personId?: string) => {
    if (onActionTrigger && personName) {
      onActionTrigger(ActivityContentInteractions.FilterByPerson, personName, personId);
    }
  };

  return (
    <Stack direction="column" sx={{ width: "100%", wordWrap: "break-word" }}>
      <Stack direction="column" gap="5px" sx={{ width: "100%" }}>
        <EmailField
          label="With:"
          valueSx={{ textTransform: calledPerson.name ? "capitalize" : "none" }}
          value={(
            <PersonLabel
              onClick={onFilterByPerson}
              value={calledPerson.name}
              tipDetails={{
                email: calledPerson?.email,
                name: calledPerson?.name,
                jobTitle: calledPerson?.title,
              }}
            />
          )}
        />
        {activity.duration && (
          <EmailField
            label="Duration:"
            value={`${formatForMinutesFromSeconds(activity.duration)}`}
          />
        )}
      </Stack>
    </Stack>
  );
}

export default CallContent;
