// this file contain calendar util functions
type Email = {
  address: string;
  name?: string;
};
export interface EventDetails {
  subject: string;
  startDate: Date;
  endDate: Date;
  location: string;
  body: string;
  participants?: Email[];
}

const formatAndEncodeEventDetails = (details: EventDetails) => {
  const {
    subject, startDate, endDate, location, body,
  } = details;
  const startDateStr = startDate.toISOString().replace(/-|:|\.\d+/g, "");
  const endDateStr = endDate.toISOString().replace(/-|:|\.\d+/g, "");

  // Encode the subject, location, and body as URI components
  const encodedSubject = encodeURIComponent(subject);
  const encodedLocation = encodeURIComponent(location);
  const encodedBody = encodeURIComponent(body);
  return {
    startDate: startDateStr,
    endDate: endDateStr,
    subject: encodedSubject,
    location: encodedLocation,
    body: encodedBody,
  };
};
// google calendar link generator
export function generateGoogleCalendarLink(details: EventDetails): string {
  const {
    subject, startDate, endDate, participants, body,
  } = details;
  // Format dates as required by the Google Calendar API
  const startDateStr = startDate.toISOString().replace(/-|:|\.\d+/g, "");
  const endDateStr = endDate.toISOString().replace(/-|:|\.\d+/g, "");

  // Encode the subject and participants as URI components
  const encodedSubject = encodeURIComponent(subject);
  const encodedParticipants = participants?.map((p) => encodeURIComponent(p.address)).join(",");

  // Construct the Google Calendar link with the required query parameters
  const link = `https://calendar.google.com/calendar/render?action=TEMPLATE&text=${encodedSubject}&dates=${startDateStr}/${endDateStr}&add=${encodedParticipants}&details=${body ? encodeURIComponent(body) : ""}`;

  return link;
}

// Outlook calendar link generator
export function generateOutlookLink(details: EventDetails): string {
  // Format dates as required by the Outlook API
  const detailsEncoded = formatAndEncodeEventDetails(details);
  // Construct the Outlook link with the required query parameters
  const link = `https://outlook.live.com/calendar/0/deeplink/compose?subject=${detailsEncoded.subject}&startdt=${detailsEncoded.startDate}&enddt=${detailsEncoded.endDate}&location=${detailsEncoded.location}&body=${detailsEncoded.body}`;

  return link;
}

// Office 365 calendar link generator
export function generateOffice365Link(details: EventDetails): string {
  // Format dates as required by the Office 365 API
  const detailsEncoded = formatAndEncodeEventDetails(details);
  // Construct the Office 365 link with the required query parameters
  const link = `https://outlook.office.com/calendar/0/deeplink/compose?subject=${detailsEncoded.subject}&startdt=${detailsEncoded.startDate}&enddt=${detailsEncoded.endDate}&location=${detailsEncoded.location}&body=${detailsEncoded.body}`;
  return link;
}

export function generateICSFile(details: EventDetails): string {
  const { startDate: startTimeUTC, endDate: endTimeUTC } = formatAndEncodeEventDetails(details);
  const location = details.location ? `LOCATION:${details.location}\n` : "";
  const description = details.body ? `DESCRIPTION:${details.body}\n` : "";
  let attendees = ""; // details.participants ? `ATTENDEE;ROLE=REQ-PARTICIPANT;RSVP=TRUE:MAILTO:${details.participants.join(';MAILTO:')}\n` : '';
  const { participants = [] } = details;
  if (participants.length) {
    attendees = `${participants.map((p) => `ATTENDEE;CN=${p.name || p.address}:mailto:${p.address}`).join("\n")
    }\n`;
  }
  const icsContent = `BEGIN:VCALENDAR
VERSION:2.0
BEGIN:VEVENT
DTSTART:${startTimeUTC}
DTEND:${endTimeUTC}
SUMMARY:${details.subject}
${location}${description}${attendees}
END:VEVENT
END:VCALENDAR`;

  const encodedICSContent = encodeURIComponent(icsContent);
  const link = `data:text/calendar;charset=utf-8,${encodedICSContent}`;

  return link;
}
