import { Box, SxProps, Typography } from "@mui/material";
import { Link } from "react-router-dom";

function Footer({ width, sx }: { width: string; sx?: SxProps }) {
  const currentYear = new Date().getFullYear();
  return (
    <Box
      sx={{
        display: "flex",
        height: "60px",
        background: "#fff",
        padding: "0 24px",
        alignItems: "center",
        width: "100%",
        borderTop: "1px solid #E5E5E5",
        zIndex: 10,
        ...sx,
      }}
    >
      <Link to="/about">
        <Typography variant="body1" component="p" color="#2A7AB7">
          About
        </Typography>
      </Link>
      <Box sx={{ margin: "0 10px" }}>|</Box>
      <Link to="/legal">
        <Typography variant="body1" component="p" color="#2A7AB7">
          Legal
        </Typography>
      </Link>
      <Box sx={{ margin: "0 10px" }}>|</Box>
      <Typography variant="body1" component="p">
        SalesDash Platform v 1.0 (c)
        {" "}
        {currentYear}
        {" "}
        SalesDash, Inc.
      </Typography>
    </Box>
  );
}

export default Footer;
