import React, { FC, Fragment } from "react";

interface Props {
  children: React.ReactNode | React.ReactNode[];
  active: string | number;
  className?: string;
  fallback?: React.ReactNode;
}

// @todo: add a default value or a fallback component
const Switcher: FC<Props> & { Case: typeof SwitchContent } = (props: Props) => {
  const { active, fallback = null } = props;

  const children = React.Children.map(props.children, (child: React.ReactNode) => {
    if (React.isValidElement(child) && child?.props?.id === active) {
      // @ts-ignore
      return React.cloneElement(child, { active });
    }
    return null;
  }) || fallback;
  const hasValidChildren = children && React.Children.count(children) > 0;
  const renderFallback = !hasValidChildren && fallback;
  return (
    <>
      {" "}
      {children}
      {" "}
      {renderFallback}
    </>
  );
};

function SwitchContent({ id, children }: { id: string | number; children: React.ReactNode }) {
  return <>{children}</>;
}

Switcher.Case = SwitchContent;

export { SwitchContent };
export default Switcher;
