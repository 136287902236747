import { Box } from "@mui/material";

import React, { useEffect, useMemo } from "react";

import { CheckMarkIcon } from "assets/icons";
import useWhenClickOutside from "hooks/useWhenClockOutside";
import { getDomainFaviconFromEmail } from "utils/email";

import { useAccountSearch } from "../PeopleFinder/hooks";
import { CustomTextField } from "../shared";
import { SecCompaniesList } from "data/companiesSecFiling";

interface AutoCompleteCustomFieldProps {
  companyTicker: string;
  companies: SecCompaniesList;
  placeholder: string;
  onCompanySelect: (company: string) => void;
}

function SecCompaniesAutoComplete(props: AutoCompleteCustomFieldProps) {
  const { companyTicker, companies, placeholder, onCompanySelect } = props;
  const wrapperRef = React.useRef<HTMLDivElement>(null);
  const [showOptions, setShowOptions] = React.useState(false);
  const [searchKey, onSearchKeyUpdates] = React.useState("");

  useWhenClickOutside(wrapperRef, () => {
    setShowOptions(false);
  });

  const onSeachKeyChange = (key: string) => {
    onSearchKeyUpdates(key);
  };

  const filteredCompanies = useMemo(() => {
    return Object.values(companies).filter((company) => company.title?.toLowerCase()?.includes(searchKey?.toLowerCase())).slice(0, 10);
  }, [companies, searchKey]);

  const onAccountSelect = (ticker: string) => () => {
    const company = filteredCompanies.find((company) => company.ticker === ticker);
    if (!company) return;
    onCompanySelect(ticker);
    onSeachKeyChange(company.title);
    setShowOptions(false);
  };

  useEffect(() => {
    const foundCompany = Object.values(companies).find((company) => company.ticker === companyTicker)
    if (foundCompany) {
      onSearchKeyUpdates(foundCompany.title)
    }
  }, [companyTicker])

  return (
    <Box sx={{ position: "relative" }} ref={wrapperRef} onFocus={() => setShowOptions(true)}>
      <CustomTextField
        values={[]}
        onChange={onSeachKeyChange}
        value={searchKey}
        placeholder="Account Name"
        enableReset
        onUpdate={() => onSeachKeyChange("")}
        renderOptions={showOptions && (
          <Box
            sx={{
              position: "absolute",
              marginTop: "3px",
              top: "100%",
              left: 0,
              width: "80.5%",
              background: "white",
              boxShadow: "0 3px 10px rgba(0, 0, 0, 0.2)",
              borderRadius: "5px",
              zIndex: 1000,
            }}
          >
            {filteredCompanies.map((company, index) => {
              const isSelected = companyTicker === company.ticker;
              return (
                <Box
                  key={index}
                  sx={{
                    padding: "10px",
                    borderBottom: "1px solid #cccccc5d",
                    cursor: "pointer",
                    userSelect: "none",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "12px",
                    "&:hover": {
                      background: "#f9f9f9",
                    },
                  }}
                  onClick={onAccountSelect(company.ticker)}
                >
                  <span style={{ flex: 1 }}>
                    {company.title}
                  </span>
                  {isSelected && <CheckMarkIcon style={{ fontSize: "12px", color: "#3498db", marginRight: "5px" }} />}
                </Box>
              );
            })}
          </Box>
        )}
      />
    </Box>
  );
}

export default SecCompaniesAutoComplete;
