import { useRef, useState } from "react";
import { toast } from "react-toastify";
import aiWorkersService from "services/aiworkers";
import { AccountSimilarWins } from "types/api";

export const useSimilarWinsBot = () => {
  const [result, setResult] = useState<AccountSimilarWins[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [isSavingAccounts, setIsSavingAccounts] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [selectedAccounts, setSelectedAccounts] = useState<string[]>([]);
  const abortSignal = useRef(new AbortController());

  const getSimilarWins = async ({ accounts }: { accounts: string[] }) => {
    try {
      abortSignal.current.abort();
      setLoading(true);
      setErrorMessage(null);
      abortSignal.current = new AbortController();
      const { results } = await aiWorkersService.findSimilarWins({ accounts }, { signal: abortSignal.current.signal });

      if (!results?.length) {
        return setErrorMessage("No results found");
      }
      setResult(results);
    } catch (e: any) {
      setErrorMessage(e.response?.data?.message || "An error occurred while fetching data");
    } finally {
      setLoading(false);
    }
  };
  const resetErrorMessage = () => {
    setErrorMessage(null);
  };


  const onCancelRequest = () => {
    abortSignal.current.abort();
    setLoading(false);
    setErrorMessage(null);
  }


  const onSaveAccountsToTerritory = async () => {
    try {
      setIsSavingAccounts(true);
      setErrorMessage(null);
      await aiWorkersService.saveAccountsToTerritory({ accounts: selectedAccounts });
      toast.success("Accounts saved to territory successfully");
      setIsSavingAccounts(false);
      setSelectedAccounts([]);
    } catch (e: any) {
      setErrorMessage(e.response?.data?.message || "An error occurred while saving data");
      setIsSavingAccounts(false);
    }
  }

  const onResetResults = () => {
    setResult([]);
    setErrorMessage(null);
    setSelectedAccounts([]);
  }

  return {
    result,
    loading,
    getSimilarWins,
    errorMessage,
    resetErrorMessage,
    onCancelRequest,
    selectedAccounts,
    isSavingAccounts,
    onUpdateSelected: setSelectedAccounts,
    onSaveAccountsToTerritory,
    onResetResults
  };
};
