import { IExtendedEmployee } from "types/api";

const checkIfHasHasHiraricyChecked = (employee: IExtendedEmployee): boolean => {
  const { checked } = employee;

  if (checked) return checked;
  if (employee?.subordinates?.length) {
    return Boolean(
      employee.subordinates.find((subordinate: IExtendedEmployee) => checkIfHasHasHiraricyChecked(subordinate)),
    );
  }
  return false;
};

const checkIsFullChecked = (employee: IExtendedEmployee): boolean => {
  const { subordinates, checked } = employee;
  const hasSubordinates = Boolean(subordinates.length);

  if (hasSubordinates) {
    return subordinates
      .map((subordinate: IExtendedEmployee) => checkIsFullChecked(subordinate))
      .every(Boolean);
  }
  return checked;
};

const getCheckStatuses = (employee: IExtendedEmployee) => ({
  fullCheck: checkIsFullChecked(employee),
  isIndeterminate: checkIfHasHasHiraricyChecked(employee),
});

export default getCheckStatuses;
