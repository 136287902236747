import {
  Button, CircularProgress, Tooltip, Typography,
} from "@mui/material";

import { Box } from "@mui/system";
import { useSearchQuery } from "hooks";
import React from "react";
import { AiWorker, WorkerPreCachedParams } from "types/api";

import AccountAutoCompleteField from "../shared/AccountAutoCompleteField";
import { useWorkerPrePopulateParams } from "../shared/hooks";

interface PowerRankFormProps {
  onFind: (accounts: string[], jobs: string[]) => void;
  onResetErrorMessage: () => void;
  isLoading?: boolean;
  onCancelRequest: () => void;
  onResetForm: () => void;
}

function PowerRankForm(props: PowerRankFormProps) {
  const { onFind, onResetErrorMessage, isLoading, onCancelRequest } = props;
  const [accounts, setAccounts] = React.useState<string[]>([]);
  const canRankAccounts = accounts.length > 0 && !isLoading;

  const onUpdateAccounts = (values: string[]) => {
    setAccounts(values);
    onResetErrorMessage();
  };
  const [{ id: preStoredParamsId }] = useSearchQuery<{ id: string }>();
  useWorkerPrePopulateParams((params: WorkerPreCachedParams) => {
    if (params.worker !== AiWorker.ACCOUNT_RANK) return;
    const { accountNames } = params.parameters;
    if (Array.isArray(accountNames) && accountNames.length) {
      setAccounts(accountNames)
    }
    onFind(accountNames, []);
  }, preStoredParamsId)

  const onRankAccounts = () => {
    if (canRankAccounts) {
      onFind(accounts, []);
    }
  };

  const onResetForm = () => {
    setAccounts([]);
    props.onResetForm?.();
  }


  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
      <Typography sx={{ fontSize: "12px", color: "#333333" }}>
        Enter an account or multiple accounts to get them ranked.
      </Typography>
      <div style={{ display: "flex", gap: "10px", alignItems: "start" }}>
        <AccountAutoCompleteField values={accounts} placeholder="Account Name" onUpdate={onUpdateAccounts} />
        <Tooltip title={!canRankAccounts ? "Please add at least one account and one job title" : ""} arrow>
          <div>
            <Button sx={{ height: "42px", gap: "5px" }} variant="contained" color="primary" disabled={!canRankAccounts} onClick={onRankAccounts}>
              Search
              {isLoading && <CircularProgress size={12} color="inherit" sx={{ display: isLoading ? "block" : "none" }} />}
            </Button>
          </div>
        </Tooltip>
        {isLoading ? (
          <Button sx={{ height: "42px" }} variant="text" onClick={onCancelRequest}>Cancel</Button>
        ) : Boolean(accounts.length) && (
          <Button sx={{ height: "42px" }} variant="text" onClick={onResetForm}>Reset</Button>
        )}
      </div>
    </Box>
  );
}

export default PowerRankForm;
