import { SelectOption } from "@mui/base";
import { Box, Button, Typography } from "@mui/material";
import {
  FC,
  MouseEvent, useEffect, useMemo, useState,
} from "react";

import { useFilters } from "react-table";

import { Head } from "components/AccountTables/Head";
import { Table } from "components/common/Tables";
import Tooltip from "components/Tooltip";
import { useSearchQuery } from "hooks";
import useToggle from "hooks/useToggle";
import AccountDetailsAPI from "services/accountDetails";
import useActivities from "services/shooks/activities";
import { PeoplePerAccountResponse } from "types/api/people";
import { Account } from "types/api";
import { timeAgo } from "utils/timeAgo";

import { makePeopleData } from "../accountDetails.utils";
import { TextArea, textIncludes } from "../Details.filters";
import FindAndSavePeopleModal from "./AddPeopleModal";

// TODO: this should be imported from the data-model to be in sync across all the repos
// TODO: look into how to install the data-model in the webapp and see if there any concern
enum ActivityCategory {
  Marketing = 0,
  Sales = 1,
  Wins = 2,
  CustomerSuccess = 3,
  SDR = 4,
  Channel = 5,
  Executive = 6,
  RevOps = 7,
  "Services & Support" = 8,
  System = 9,
}

const fetchPeoplePerAccount = async (payload: Record<string, any>, signal?: AbortSignal) => {
  const { accountId, status } = payload;
  try {
    const options = signal ? { signal } : undefined
    const people = await AccountDetailsAPI.people({ accountId, status }, options);
    return people;
  } catch (error) {
    console.error(error);
  }
};

enum AccountPeopleTabFilter {
  All = "all",
  Contact = "contact",
  Lead = "lead",
}

const peopleStatusOptions = [
  { label: "All", value: AccountPeopleTabFilter.All },
  { label: "Contact", value: AccountPeopleTabFilter.Contact },
  { label: "Lead", value: AccountPeopleTabFilter.Lead },
];

const getSelectOptionFromValue = (value: string, options: SelectOption<string>[]) => options.find((option) => option.value === value);
interface PeopleTabProps {
  account?: Account
};

const PeopleTab: FC<PeopleTabProps> = (props) => {
  const { account } = props;
  const [people, setPeople] = useState<PeoplePerAccountResponse | []>([]);
  const { actions: activitiesActions } = useActivities();
  const [isLoading, , asyncToggleLoading] = useToggle(true);
  const [{ prefilter: peopleStatus, accid: currentAccountId }, updateSearchQuery] = useSearchQuery<{
    prefilter?: string;
    accid?: string;
    ac_feed?: boolean;
  }>();
  const [showFindPeopleModal, setShowFindPeopleModal] = useState(false);

  const updatePeopleForAccount = async (
    accountId: string,
    filters: { status: string },
    signal?: AbortSignal,
  ) => {
    const people = await asyncToggleLoading(
      fetchPeoplePerAccount({ accountId, status: filters.status }, signal),
    );
    setPeople(people || []);
    return people;
  };
  useEffect(() => {
    const lastAbortController = new AbortController();
    const { signal } = lastAbortController;
    if (!currentAccountId) return;

    updatePeopleForAccount(currentAccountId, { status: peopleStatus || "all" }, signal);
    return () => {
      lastAbortController.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAccountId, peopleStatus]);

  const peopleData = useMemo(() => makePeopleData(people), [people]);

  const onLastSignalClicked = async (e: MouseEvent, personName: string) => {
    e.stopPropagation();
    if (currentAccountId) {
      activitiesActions.filterByPerson(personName);
      // we need the openTab too, might need other things.
      updateSearchQuery({ ac_feed: true });
    }
  };

  const onPeopleStatusChange = (status?: string) => {
    if (status) updateSearchQuery({ prefilter: status });
  };
  const productsColumns = [
    {
      Header: () => <Head>Name</Head>,
      Footer: "name",
      accessor: "name",
      className: "cell-left collapse-column",
      placeholder: "Search by name",
      // disableFilters: true,
      disableGroupBy: true,
      borderLeft: "1px solid #E5E5E5",
      Filter: TextArea,
      filter: textIncludes,
      style: {
        width: "230px",
        minWidth: "230px",
        maxWidth: "230px",
      },
      Cell: ({ row }: any) => <Box sx={{ width: "200px" }}>{row.original.name || "N/A"}</Box>,
    },
    {
      Header: () => <Head>Status</Head>,
      Footer: "status",
      accessor: "status",
      placeholder: "Search by status",
      className: "cell-left collapse-column",
      disableGroupBy: true,
      borderLeft: "1px solid #E5E5E5",
      filterType: "select",
      filterProps: {
        sx: {
          height: "30px",
          width: "100%",
        },
        options: peopleStatusOptions,
        // @ts-ignore
        value: getSelectOptionFromValue(peopleStatus || "", peopleStatusOptions),
        onChange: onPeopleStatusChange,
      },
      style: {
        width: "150px",
        minWidth: "150px",
        maxWidth: "150px",
      },
      Cell: ({ row }: any) => (
        <Box sx={{ textTransform: "capitalize", width: "120px" }}>
          {row.original.status || "N/A"}
        </Box>
      ),
    },
    {
      Header: () => <Head>Last Signal</Head>,
      Footer: "lastSignal",
      accessor: "lastSignal",
      borderLeft: "1px solid #E5E5E5",
      className: "cell-left collapse-column",
      disableGroupBy: true,
      disableFilters: true,
      style: {
        width: "150px",
        minWidth: "150px",
        maxWidth: "150px",
      },
      Cell: ({ row }: any) => {
        const lastSignal = row.original.lastSignal || "";
        const lastSignalDate = lastSignal.split("T")[0]; // TODO: this is wildly used across the code, needs a common utility for formatting dates
        const formattedLastSignal = lastSignalDate
          ? timeAgo.format(new Date(lastSignalDate), "round")
          : "No Signal";
        const personName = row.original.name || "";
        return (
          <Box
            onClick={(e) => onLastSignalClicked(e, personName)}
            sx={{
              display: "flex",
              alignItems: "center",
              textTransform: "capitalize",
              width: "120px",
              cursor: "pointer",
            }}
          >
            <Tooltip title={lastSignalDate} placement="top">
              <Box>{formattedLastSignal}</Box>
            </Tooltip>
          </Box>
        );
      },
    },
    {
      Header: () => <Head>Title</Head>,
      Footer: "title",
      accessor: "title",
      placeholder: "Search by title",
      className: "cell-left collapse-column",
      disableGroupBy: true,
      Filter: TextArea,
      borderLeft: "1px solid #E5E5E5",
      filter: textIncludes,
      style: {
        width: "230px",
        minWidth: "230px",
        maxWidth: "230px",
      },
      Cell: ({ row }: any) => (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            textTransform: "capitalize",
            width: "200px",
          }}
        >
          {row.original.title || "N/A"}
        </Box>
      ),
    },
    {
      Header: () => <Head>Engagement</Head>,
      Footer: "Engagement",
      accessor: "engagement",
      placeholder: "Search by engagement",
      className: "cell-left",
      disableGroupBy: true,
      borderLeft: "1px solid #E5E5E5",
      Filter: TextArea,
      filter: (rows: any, id: any, filterValue: any) => rows.filter((row: any) => {
        const rowValue = row.values[id];
        if (!Array.isArray(rowValue)) return false;
        const formattedEngagement = rowValue
          .map((category: ActivityCategory) => ActivityCategory[category])
          .filter(Boolean);

        const engagementString = formattedEngagement.length > 0 ? formattedEngagement.join(", ") : "N/A";
        return String(engagementString).toLowerCase().includes(filterValue.toLowerCase());
      }),
      Cell: ({ row }: any) => {
        const engagement = (row.original.engagement || []) as ActivityCategory[];
        const formattedEngagement = engagement
          .map((category: ActivityCategory) => ActivityCategory[category])
          .filter(Boolean);

        const engagementString = engagement.length > 0 ? formattedEngagement.join(", ") : "N/A";
        return (
          <Box
            sx={{
              height: "54px !important",
              display: "flex",
              alignItems: "center",
              textTransform: "capitalize",
              width: "300px",
            }}
          >
            {engagementString || "N/A"}
          </Box>
        );
      },
    },
  ];

  const onClosePeopleFinderModal = () => {
    setShowFindPeopleModal(false)
    // update the list of people
    updatePeopleForAccount(currentAccountId!, { status: peopleStatus || "all" });
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        border: "1px solid #eee",
        borderBottom: "2px solid #eee",
      }}
    >

      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'baseline', p: '10px' }}>
        <Typography>
          Find new contacts to add to this account
        </Typography>
        <Button variant="outlined" onClick={() => setShowFindPeopleModal(true)}>
          Find People
        </Button>
      </Box>
      <Box
        sx={{
          zIndex: 12,
          p: '10px',
          borderRadius: '15px',
          borderColor: 'red',
          m: '5px 0'
        }}
      >
        <FindAndSavePeopleModal
          isOpen={showFindPeopleModal}
          onClose={onClosePeopleFinderModal}
          account={account!}
        />
      </Box>
      <Box sx={{ zIndex: 1 }}>
        <Table
          sx={{ maxHeight: "450px" }}
          columns={productsColumns}
          data={peopleData}
          showFiltersRow //= {Boolean(peopleData.length)}
          customPlugins={[useFilters]}
          stickyHeader
          loading={isLoading}
          noDataMessage="There are no people associated with this account"
        />
      </Box>
    </Box>
  );
}

export default PeopleTab;
