import { TextField, Typography } from "@mui/material";
import { useState } from "react";

import { useDebounceEffect } from "hooks";
import useTerritoryFilters from "services/shooks/territoryFilters";

type Props = {
  valuePath: string;
  label: string;
  hasActiveFilters: boolean;
  value: number | undefined;
}

function NumberFilter({
  valuePath,
  label,
  hasActiveFilters,
  value: initialValue,
}: Props) {
  const { actions } = useTerritoryFilters();
  const { onFilterChange } = actions;

  const [value, setValue] = useState<number | string | undefined>(initialValue);
  const [errorMessage, setErrorDetails] = useState<string>("");

  const onReset = () => {
    setValue("");
    onFilterChange(valuePath, undefined);
    setErrorDetails("");
  };

  const onChange = (e: any): void => {
    const removeLeadZeroes = +(e.target.value as string).replace(/^0+/, "");
    setValue(+removeLeadZeroes);
  };

  const isValidValue = () => {
    let success = true;
    let message = "";

    if (value !== undefined && !Number.isInteger(value) && +value < 0) {
      success = false;
      message = "Only numbers are allowed";
    }
    return { success, message };
  };

  useDebounceEffect(
    () => {
      const validity = isValidValue();
      if (validity.success) {
        onFilterChange(valuePath, value);
        setErrorDetails("");
      } else {
        setErrorDetails(validity.message);
      }
    },
    600,
    [value],
  );

  return (
    <>
      <div
        style={{
          alignItems: "center",
          color: "gray",
          display: "flex",
          fontSize: "14px",
          justifyContent: "space-between",
          marginBottom: "5px",
        }}
      >
        {label}
        {hasActiveFilters && (
          <Typography
            onClick={onReset}
            fontWeight="500"
            fontSize="11px"
            color="#2A7AB7"
            sx={{ cursor: "pointer" }}
          >
            Reset
          </Typography>
        )}
      </div>

      <TextField
        sx={{ width: "100%" }}
        placeholder=""
        type="number"
        error={!!errorMessage}
        autoComplete="off"
        value={value}
        name={valuePath}
        onChange={onChange}
      />

      {errorMessage && (
        <div style={{ color: "#e74c3c", fontSize: 12, marginTop: 6 }}>{errorMessage}</div>
      )}
    </>
  );
}

export default NumberFilter;
