import { SelectOption } from "@mui/base";
import { Box } from "@mui/material";
import { useEffect, useState } from "react";

import { useFilters } from "react-table";

import { Head } from "components/AccountTables/Head";
import { Table } from "components/common/Tables";
import { ProductStatus } from "pages/WhitespaceAndTerritory/components/SharedTable/Products";

import { getCompetitorsData, makeProductsData } from "./accountDetails.utils";
import { TextArea, textIncludes } from "./Details.filters";

enum ProductStatusFiltersEnum {
  All = "all",
  FullySold = "Fully Sold",
  PartiallySold = "Partially Sold",
  NotInterested = "Not Interested",
  Interested = "Interested",
  Unresponsive = "Unresponsive",
  UnknownInterest = "Unknown Interest",
}

const productStatusOptions = [
  { label: "All", value: ProductStatusFiltersEnum.All },
  { label: "FullySold", value: ProductStatusFiltersEnum.FullySold },
  { label: "PartiallySold", value: ProductStatusFiltersEnum.PartiallySold },
  { label: "Not Interested", value: ProductStatusFiltersEnum.NotInterested },
  { label: "Interested", value: ProductStatusFiltersEnum.Interested },
  { label: "Unresponsive", value: ProductStatusFiltersEnum.Unresponsive },
  { label: "Unknown Interest", value: ProductStatusFiltersEnum.UnknownInterest },
];

// todo: type will be IAccount when the new table PR is merged.
function ProductDetailsTab({ account }: { account: any }) {
  const [selectedProductName, setSelectedProductName] = useState<string>(
    account?.products[0]?.productName,
  );
  const [competitorsData, setCompetitorsData] = useState<any>([]);
  const [filters, setFilters] = useState<any>({ productStatus: ProductStatusFiltersEnum.All });

  const getFilteredProducts = (products: any[]) => {
    const { productStatus } = filters;
    return products.filter((product) => {
      if (!productStatus || productStatus === ProductStatusFiltersEnum.All) return true;
      return productStatus === product.saleStatus;
    });
  };

  const productsColumnData = makeProductsData(account);
  const filteredProducts = getFilteredProducts(productsColumnData);

  const onProductStatusChange = (value: string) => {
    setFilters({ ...filters, productStatus: value });
  };

  const getSelectOptionFromValue = (value: string, options: SelectOption<string>[]) => options.find((option) => option.value === value);

  const updateProductName = (productName: string) => {
    setSelectedProductName(productName);
  };

  useEffect(() => {
    const productCompetitors = getCompetitorsData(account, selectedProductName);
    setCompetitorsData(productCompetitors);
  }, [selectedProductName, account]);

  // should be it own hook (the two below)
  const productsColumns = [
    {
      Header: () => <Head>Name</Head>,
      Footer: "productName",
      accessor: "productName",
      className: "cell-left",
      placeholder: "Search by product name",
      filter: textIncludes,
      hasBorderRight: true,
      Filter: TextArea,
      borderLeft: "1px solid #E5E5E5",
      disableGroupBy: true,
      Cell: ({ row }: any) => {
        const { productName } = row.original;
        return (
          <Box
            sx={{
              display: "flex",
              flexGrow: 1,
              alignItems: "center",
              padding: "0 10px",
              height: "40px",
            }}
            onClick={() => updateProductName(productName)}
          >
            {productName || "N/A"}
          </Box>
        );
      },
    },
    {
      Header: () => <Head>Sale Status</Head>,
      Footer: "saleStatus",
      accessor: "saleStatus",
      placeholder: "Search by sale status",
      className: "cell-center collapse-column",
      borderLeft: "1px solid #E5E5E5",
      disableGroupBy: true,
      filterType: "select",
      filterProps: {
        options: productStatusOptions,
        // @ts-ignore
        value: getSelectOptionFromValue(`${filters.productStatus}`, productStatusOptions),
        onChange: onProductStatusChange,
        sx: {
          height: "30px",
          width: "100%",
        },
      },
      style: {
        width: "147px",
      },
      Cell: ({ row }: any) => {
        const product = row.original;
        const status = product.saleStatus || "Unknown Interest";
        const productStatus = status === ("Not Now" || "Churned") ? "Not Interested" : status;
        const data = {
          accountId: row.original.id,
          productId: row.original.productId,
          productStatus,
        };
        return (
          <Box
            sx={{
              position: "relative",
              padding: "10px",
              margin: 0,
            }}
          >
            <ProductStatus data={data} />
          </Box>
        );
      },
    },
  ];

  const competitorColumns = [
    {
      Header: () => <Head>Competitor</Head>,
      Footer: "competitor",
      accessor: "competitor",
      className: "cell-left",
      borderLeft: "1px solid #E5E5E5",
      disableGroupBy: true,
      filter: textIncludes,
      Filter: TextArea,
      Cell: ({ row }: any) => (
        <Box sx={{ display: "flex", alignItems: "center", padding: "10px" }}>
          {row.original.name}
        </Box>
      ),
    },
    {
      Header: () => <Head>Renews</Head>,
      Footer: "renews",
      accessor: "renews",
      className: "cell-left",
      disableGroupBy: true,
      filter: textIncludes,
      Filter: TextArea,
      Cell: ({ row }: any) => (
        <Box sx={{ padding: "10px" }}>{row.original.renewalDate?.toString().split("T")[0]}</Box>
      ),
    },
  ];

  return (
    <Box sx={{ display: "flex", flexDirection: "row", gap: "15px" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          border: "1px solid #eee",
          borderBottom: "2px solid #eee",
        }}
      >
        <Table
          columns={productsColumns}
          data={filteredProducts}
          showFiltersRow
          customPlugins={[useFilters]}
          noDataMessage="No products yet"
          tbodySX={{ padding: "0px" }}
        />
      </Box>
      {/* @todo: This code is commented because it's not working now, the ticket >> https://salesdash.atlassian.net/browse/SAL-1714 */}
      {/* <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, border: '1px solid #eee', borderBottom: '2px solid #eee' }}>
        <Table
          columns={competitorColumns}
          data={competitorsData}
          showFiltersRow={true}
          customPlugins={[useFilters]}
          noDataMessage={`No competitors available for product ${selectedProductName}`}
          tbodySX={{ padding: '0px' }}
        />
      </Box> */}
    </Box>
  );
}

export default ProductDetailsTab;
