import { enqueueSnackbar } from "notistack";

import { spaceAndCapitalize } from "pages/WhitespaceAndTerritory/components/utils";
import { IAllFiltersDetails } from "services/shooks/territoryFilters";
import { GenericObject } from "services/whitespace";
import {
  AccountResp,
  DynFilterColumn,
  DynamicAccount,
  DynamicFilterT,
  ObjectTypesEnum,
  TerritoryAccountProductsFilterPayload,
  TerritoryAccountProductsFilterResponse,
  TerritoryFiltersMetaData,
} from "types/api";
import { TerritoryFilters } from "types/api/territoryFilters";
import { AxiosNode } from "utils/axios";
import { captureExceptionWithLogRocket } from "utils/userActivitiesTracker/logRocket";

import { requestCacheUtil } from "./util";

export type PagePayload = {
  limit: number;
  offset: number;
  userIds?: string[];
};

export type PaginatedPayload = PagePayload & TerritoryFilters;

interface IAccountMetaDataKey {
  name: string;
  label: string;
  type: string;
}

export interface IAccountMetaData {
  fields: IAccountMetaDataKey[];
  salesdashFields: IAccountMetaDataKey[];
}

export type EditColumnPayload = {
  container: string;
  recordId: string;
  updates: GenericObject;
};

const Territory = {
  filters: async (
    payload: PaginatedPayload,
    isCacheEnabled?: boolean,
    options?: Parameters<typeof AxiosNode.post>[2]
  ) => {
    try {
      // payload.userIds = payload.userIds?.length ? payload.userIds : undefined;
      const cachedOrLiveData = await requestCacheUtil(
        "post",
        "/territory/accounts",
        payload,
        { isCacheEnabled, options }
      );
      return cachedOrLiveData || { results: [], total: 0 };
    } catch (err) {
      console.log(err);
      return { error: err, results: [], total: 0 };
    }
  },
  dynamicTable: async (
    payload: PagePayload | DynamicFilterT,
    tableId: string,
    rank: number = 0,
    colInfo: DynFilterColumn = {},
    options?: Parameters<typeof AxiosNode.post>[2],
  ): Promise<AccountResp<DynamicAccount>> => {
    const fallbackFiltersPayload: DynamicFilterT = {
      limit: payload.limit,
      offset: payload.offset,
      Account: {},
      Person: {},
      Opportunity: {},
    };
    const invalidPagination: boolean =
      !("limit" in payload) || !("offset" in payload);
    const hasEveryObject: boolean = Object.keys(ObjectTypesEnum).every(
      (key) => key in payload
    );

    const hasSomeObject = Object.keys(ObjectTypesEnum).some((key) => key in payload);

    const invalidObjectTypes = false
    // hasEveryObject &&
    // Object.keys(ObjectTypesEnum).every(
    //   (key) => !Object.keys(payload[key as keyof typeof payload]).length
    // );

    /**
     * in inital request, we dont send the Object Types in payload, only limit and offset
     * in subsequent requests, we send the Object Types in payload
     * these checks (hasEveryObject, hasSomeObject, invalidObjectTypes) are to check if the subsequent requests are valid
     * we need to send all 3 objects, hence
     *  - hasEveryObject: all 3 objects are present
     *  - hasSomeObject: at least 1 object is present (sometimes payload is sent with one object only which is wrong)
     */
    const invalidPayload = false
    // invalidPagination ||
    // (hasSomeObject && !hasEveryObject && !invalidObjectTypes);

    if (invalidPayload) {
      return {
        error: new Error("Invalid Payload"),
        results: [],
        columns: {},
        filters: fallbackFiltersPayload,
        total: 0,
      };
    }

    const params: GenericObject = {
      tableId,
    };
    if (rank) {
      params.rank = rank;
    }

    // eslint-disable-next-line no-underscore-dangle
    const _payload = { ...payload };
    if (Object.keys(colInfo).length) {
      // @ts-expect-error ign
      _payload.columnInfo = undefined;
    }

    let response;
    try {
      response = await AxiosNode.post<AccountResp<DynamicAccount>>(
        "/territory/filters",
        _payload,
        {
          // considers cancelation as retries!!
          // retryEnabled: true,
          params: {
            ...params,
          },
          ...options
        }
      );
      return (
        response.data || {
          results: [],
          columns: {},
          filters: fallbackFiltersPayload,
          total: 0,
        }
      );
    } catch (err: any) {
      let errorMessage =
        (err as any).response?.data?.message || "Something went wrong";
      switch (response?.status) {
        case 404:
          errorMessage = "Table no longer exists";
          break;
        case 403:
          errorMessage = "Unauthorized to view this table";
          break;
        default:
          break;
      }
      err.code !== 'ERR_CANCELED' && enqueueSnackbar(errorMessage, { variant: "error" });
      return {
        error: err,
        results: [],
        columns: {},
        filters: fallbackFiltersPayload,
        total: 0,
      };
    }
  },
  editColumn: async (payload: EditColumnPayload) => {
    try {
      const { data } = await AxiosNode.post<any>("/user/records", payload);
      const key = Object.keys(payload.updates)[0];
      enqueueSnackbar(`${spaceAndCapitalize(key)} Updated Successfully`, {
        variant: "success",
      });
      return data || {};
    } catch (err) {
      const errorMessage: string = `Error Updating ${payload.updates[Object.keys(payload.updates)[0]]
        }`;
      captureExceptionWithLogRocket(new Error(errorMessage));
      return err;
    }
  },
  filtersMetaData: async () => {
    try {
      const { data } = await AxiosNode.get<{ filters: DynamicFilterT, columnData?: DynFilterColumn }>(
        "/views/metadata"
      );
      return data;
    } catch (err) {
      console.log(err);
      return {} as {
        filters: DynamicFilterT, columnData?: DynFilterColumn
      }
    }
  },
  accountsMetaData: async () => {
    try {
      const { data } = await AxiosNode.get<IAccountMetaData>(
        "/accounts/metadata"
      );
      return data;
    } catch (err) {
      console.log(err);
      return {} as IAccountMetaData;
    }
  },
  accountsProductsFilter: async (
    payload: TerritoryAccountProductsFilterPayload,
    options?: Parameters<typeof AxiosNode.post>[2]
  ) => {
    try {
      const searchQuery = new URLSearchParams(payload as any).toString();
      const { data } =
        await AxiosNode.get<TerritoryAccountProductsFilterResponse>(
          `/territory/products-filter?${searchQuery}`,
          options
        );
      return data;
    } catch (err) {
      console.log(err);
      return {} as TerritoryAccountProductsFilterResponse;
    }
  },
  archiveAccounts: async (payload: {
    accountIds: string[];
  }): Promise<{ success: boolean }> => {
    try {
      await AxiosNode.post<any>("/user/accounts/exclude", payload);
      enqueueSnackbar("Accounts Archived Successfully", { variant: "success" });
      return {
        success: true,
      };
    } catch (err) {
      enqueueSnackbar("Error Archiving Accounts", { variant: "error" });
      return {
        success: false,
      };
    }
  },
  deleteAccounts: async (payload: {
    accountIds: string[];
  }): Promise<{ success: boolean }> => {
    try {
      await AxiosNode.post<any>("/user/accounts/delete", payload);
      enqueueSnackbar("Accounts Deleted Successfully", { variant: "success" });
      return {
        success: true,
      };
    } catch (err) {
      enqueueSnackbar("Error Deleting Accounts", { variant: "error" });
      return {
        success: false,
      };
    }
  },
  addAccountsToSF: async (payload: {
    accountIds: string[];
  }): Promise<{ success: boolean }> => {
    try {
      await AxiosNode.post<any>("/user/accounts/insert-salesforce", payload);
      enqueueSnackbar("Accounts Added Successfully", { variant: "success" });
      return {
        success: true,
      };
    } catch (err) {
      enqueueSnackbar("Error Adding Accounts", { variant: "error" });
      return {
        success: false,
      };
    }
  },
};

export default Territory;
