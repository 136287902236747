import { Box, Skeleton, Stack } from "@mui/material";
import React from "react";

export const ActivityCardSkelton = React.forwardRef((props, ref) => (
  <Box
    ref={ref}
    sx={{
      display: "flex",
      minHeight: "170px",
      flexDirection: "column",
      border: "1px solid #cecece",
      borderRadius: "4px",
    }}
  >
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        p: "2px",
        borderBottom: "1px solid #cecece",
      }}
    >
      <Skeleton variant="rounded" height={20} width={100} />
      <Stack direction="row" gap="2px">
        <Skeleton variant="rounded" height={24} width={24} />
        <Skeleton variant="rounded" height={24} width={24} />
      </Stack>
    </Box>
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-end",
        p: "4px",
        borderBottom: "1px solid #cecece",
      }}
    >
      <Skeleton variant="rectangular" height={20} width={100} />
    </Box>

    <Stack flex={1} direction="row" gap="16px" alignItems="center" sx={{ p: "10px 20px" }}>
      <Skeleton variant="circular" width={36} height={36} />
      <Stack gap="3px" direction="column" spacing={0} flex={1}>
        <Skeleton variant="rectangular" height={12} width={100} />
        <Skeleton variant="rectangular" height={12} width={100} />
        <Skeleton variant="rectangular" height={12} width={100} />
      </Stack>
      <Skeleton variant="rectangular" height={20} width={20} />
    </Stack>
  </Box>
));
