import React from 'react'
import { PeopleFinder } from 'pages/AIWorkers/workers';
import { Account } from "types/api";
import { Box, Modal } from '@mui/material';
interface FindAndSavePeopleModalProps {
  isOpen: boolean;
  onClose: () => void;
  account?: Account
}
const FindAndSavePeopleModal = (props: FindAndSavePeopleModalProps) => {
  const { isOpen, onClose, account } = props;
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}
    >
      <Box
        sx={{
          padding: '20px',
          background: 'white',
          borderRadius: '15px'
        }}
      >
        <PeopleFinder
          focusedAccount={account}
          listSx={{
            maxHeight: '500px',
            overflow: 'auto',
            pr: '10px'
          }}
        />
      </Box>
    </Modal>
  )
}

export default FindAndSavePeopleModal;