import { Stack } from "@mui/material";

import { PipelineSignalEnums } from "types/api/territoryFilters";
import { overrideHexColorTransparency } from "utils/colors";

import { PipelineSignal, signalStyles } from "./PipelineSignal";

interface PipelineSignalBadge {
  signal: PipelineSignalEnums;
  activeSignals?: PipelineSignalEnums[];
  onSelect?: (signal: PipelineSignalEnums) => void;
  hasHoverEffect?: boolean;
  noBorder?: boolean;
}

export function PipelineSignalBadge({
  signal,
  activeSignals,
  onSelect,
  hasHoverEffect,
  noBorder,
}: PipelineSignalBadge) {
  const isActive = activeSignals?.includes(signal);

  const _onSelect = () => {
    onSelect?.(signal);
  };
  const activeStyle = signalStyles[signal] ?? {};
  return (
    <Stack
      onClick={_onSelect}
      sx={{
        p: "2px 6px",
        cursor: "pointer",
        borderRadius: "5px",
        border: noBorder ? "none" : "1px solid #f0f0f0",
        transition: "transform .3s ease",
        borderColor: isActive ? activeStyle.backgroundColor : "#f0f0f0",
        bgcolor: isActive
          ? overrideHexColorTransparency(activeStyle.backgroundColor, { a: 0.1 })
          : "transparent",
        "&:hover": {
          borderColor: hasHoverEffect ? activeStyle.backgroundColor : "#f0f0f0",
          transform: hasHoverEffect ? "scale(1.1)" : "scale(1)",
        },
      }}
    >
      <PipelineSignal signal={signal} />
    </Stack>
  );
}

export default PipelineSignalBadge;
