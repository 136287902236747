import { useEffect, useState } from "react";

const useIsOverflowing = (ref: React.RefObject<HTMLElement>, direction?: "width" | "height") => {
  const [isOverflowing, setIsOverflowing] = useState(false);

  const triggerUpdate = () => {
    if (!ref.current) return;
    let hasOverflow = ref.current.scrollWidth > ref.current.clientWidth;
    if (direction === "height") {
      hasOverflow = ref.current.scrollHeight > ref.current.clientHeight;
    }
    setIsOverflowing(hasOverflow);
  };

  useEffect(() => {
    let observer: ResizeObserver;
    if ("ResizeObserver" in window && ref.current) {
      observer = new ResizeObserver(triggerUpdate);
      observer.observe(ref.current);
    }
    triggerUpdate();

    return () => {
      if (observer) {
        observer.disconnect();
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref.current]);

  return isOverflowing;
};

export default useIsOverflowing;
