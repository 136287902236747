import { Component, ErrorInfo, ReactNode } from "react";

import { captureExceptionWithLogRocket } from "utils/userActivitiesTracker/logRocket";

import ErrorPage from "./ErrorPage";

interface Props {
  children?: ReactNode;
}

interface State {
  hasError: boolean;
  error?: null | Error;
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = { hasError: false };

  static getDerivedStateFromError(error: Error): State {
    if (error.name === 'ChunkLoadError') {
      window.location.reload();
      return { hasError: false }
    }
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error("Uncaught error:", error, errorInfo);
    captureExceptionWithLogRocket(error);
  }

  public render() {
    if (this.state.hasError) {
      return <ErrorPage error={this.state.error || new Error("Something went wrong")} />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
