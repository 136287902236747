import { Skeleton, SxProps } from "@mui/material";
import { ReactNode } from "react";

interface LoadingPlaceHolderProps {
  sx?: SxProps;
}
function LoadingPlaceHolder(props: LoadingPlaceHolderProps) {
  return <Skeleton variant="rectangular" height="18.8px" width="160px" {...props} />;
}

interface LoadingFallbackProps {
  children: ReactNode;
  isLoading?: boolean;
  skeltonSx?: SxProps;
}

function LoadingFallback(props: LoadingFallbackProps) {
  const { children, isLoading, skeltonSx } = props;

  return <>{isLoading ? <LoadingPlaceHolder sx={skeltonSx} /> : children}</>;
}

export default LoadingFallback;
