import Tooltip from "@mui/material/Tooltip";
import { SxProps } from "@mui/system";
import React from "react";

interface Props {
  children: React.ReactElement;
  title: string | React.ReactElement;
  placement?:
    | "bottom-end"
    | "bottom-start"
    | "bottom"
    | "left-end"
    | "left-start"
    | "left"
    | "right-end"
    | "right-start"
    | "right"
    | "top-end"
    | "top-start"
    | "top";
  sx?: SxProps;
  open?: boolean;
  hide?: boolean;
}

function SDTooltip({
  children,
  title,
  placement = "bottom",
  sx = {},
  open,
  hide = false,
}: Props) {
  if (hide) return children;
  return (
    <Tooltip
      open={open}
      title={title}
      placement={placement}
      componentsProps={{
        tooltip: {
          sx: {
            background: "#FFF",
            padding: "10px",
            margin: "0px !important",
            border: "0.859375px solid #E6E6E6",
            borderRadius: "3.4375px",
            fontFamily: "Public Sans",
            fontWeight: 400,
            fontSize: "12px",
            lineHeight: 1.166,
            color: " #434343",
            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.05)",
            "& .MuiTooltip-arrow": {
              color: "#FFF",
              "&:before": {
                border: "1px solid #E6E6E6",
              },
            },
            ...sx,
          },
        },
      }}
      arrow
    >
      {children}
    </Tooltip>
  );
}

export default SDTooltip;
