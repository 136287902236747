import { useEffect, useState } from "react";

import useSearchQuery from "hooks/useSearchQuery";
import opportunityAPI from "services/oppourtunitiesService";
import type {
  AccountOpportunities,
  AccountOpportunitiesFilters,
  OpportunitiesColumns,
  OpportunitiesFiltersMeta,
} from "types/account";

const mapOpportunitiesItemsToColumns = (items: AccountOpportunities[]): OpportunitiesColumns[] => items.map((opportunity) => ({
  id: opportunity.id,
  name: opportunity.name,
  value: opportunity.amount,
  stageName: opportunity.stageName,
  source: opportunity.leadSource,
  type: opportunity.type,
  closeDate: opportunity.closeDate,
  ownerName: opportunity?.ownerName,
}));

const defaultFilters = {
  limit: 1000,
  offset: 0,
} as AccountOpportunitiesFilters;

const useAccountOpportunities = () => {
  const [opportunities, setOpportunities] = useState<OpportunitiesColumns[]>([]);
  const [filters, setFilters] = useState<AccountOpportunitiesFilters>(defaultFilters);
  const [{ accid: accountId }] = useSearchQuery<{ accid: string }>();
  const [filtersMeta, setFiltersMeta] = useState<OpportunitiesFiltersMeta>(
    {} as OpportunitiesFiltersMeta,
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // filter functionality
  const getFilteredOpportunities = async (
    filters: AccountOpportunitiesFilters,
    signal: AbortSignal,
  ) => {
    // start the loader.
    setIsLoading(true);

    // returns a new object with only the filters that are not 'all'. all is the default value (absence of filters).
    const cleanedFilters = Object.keys(filters).reduce((acc: any, key: string) => {
      const _filters = filters as any;
      if (_filters[key] && _filters[key] !== "all") {
        acc[key] = _filters[key];
      }
      return acc;
    }, {}) as AccountOpportunitiesFilters;
    // add the filters + account id to the payload.
    const payload = { accountId, ...defaultFilters, ...cleanedFilters };
    try {
      // get data from the backend.
      const { data: opportunitiesResponse } = await opportunityAPI.filterOpportunities(
        payload,
        signal,
      );
      const opportunities = opportunitiesResponse.items;
      // map the data (equivalent to make data)
      const opportunitiesColumns = mapOpportunitiesItemsToColumns(opportunities);
      setOpportunities(opportunitiesColumns);
      setFiltersMeta(opportunitiesResponse?.filters || ({} as OpportunitiesFiltersMeta));
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  // @todo: we should abstract the use of debounce action here to a reusable hook
  useEffect(() => {
    const lastAbortController = new AbortController();
    const controllerSignal = lastAbortController.signal;

    const debounceFunc = setTimeout(() => {
      getFilteredOpportunities(filters, controllerSignal);
    }, 100);

    return () => {
      lastAbortController.abort();
      clearTimeout(debounceFunc);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, accountId]);

  const onFilterChange = (newFilters: Partial<AccountOpportunitiesFilters>) => {
    setFilters((filters) => ({ ...filters, ...newFilters }));
  };

  return {
    opportunities, onFilterChange, filters, filtersMeta, isLoading,
  };
};

export default useAccountOpportunities;
