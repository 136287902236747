import { PeoplePerAccountResponse } from "types/api/people";
import { AxiosNode } from "utils/axios";

export interface IAccountDetailspayload {
  accountId: string;
  name?: string;
  title?: string;
  status?: string;
  orderBy?: string;
  orderDirection?: "ASC" | "DESC";
  offset?: number;
  limit?: number;
  maxSignalDate?: string | Date;
  minSignalDate?: string | Date;
  engagement?: string | string[];
}

class AccountDetailsAPI {
  static people = async (payload: Record<string, any>, options?: { signal: AbortSignal }) => {
    // @todo: abstract this to a util.
    if (!payload.offset) payload.offset = 0;
    if (!payload.limit) payload.limit = 100;
    const queryFromPayload = Object.keys(payload).reduce((acc, key) => {
      if (payload[key]) {
        return `${acc}&${key}=${payload[key]}`;
      }
      return acc;
    }, "");

    const { data } = await AxiosNode.get<PeoplePerAccountResponse>(
      `/people?${queryFromPayload}`,
      options,
    );
    return data;
  };
}

export default AccountDetailsAPI;
