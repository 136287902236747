import { ReloadOutlined } from "@ant-design/icons";
import { Box, Button, Typography } from "@mui/material";

import { SDSLogoComponent } from "assets/icons";
import { Page } from "components/layout";

interface ErrorPageProps {
  error: Error;
}

function ErrorPage(props: ErrorPageProps) {
  const onHardReloadThePage = () => {
    window.location.reload();
  };

  return (
    <Page hasFooter={false} hasHeader={false} hasSidebar={false}>
      <Box sx={{ display: "flex", justifyContent: "center", margin: "30px 0" }}>
        <SDSLogoComponent />
      </Box>
      <Box
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Typography variant="h2" color="tomato">
            Oops!
          </Typography>
        </Box>
        <br />
        <Typography variant="h4">Something went wrong</Typography>
        <br />
        <Typography variant="body1" color="gray">
          We apologize for the inconvenience.
          <br />
          Our team has been notified about this and will resolve it as soon as possible.
        </Typography>
        <br />
        <br />
        <br />
        <Typography variant="h6" sx={{ fontStyle: "italic" }}>
          Thank you for your patience.
        </Typography>
        <br />
        <Button variant="contained" onClick={onHardReloadThePage}>
          <ReloadOutlined />
          <Typography sx={{ marginLeft: "8px" }}>Reload</Typography>
        </Button>
      </Box>
    </Page>
  );
}

export default ErrorPage;
