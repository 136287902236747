import { useEffect, useState } from "react";

import PeopleAPI from "services/peopleService";

export interface PeopleStats {
  contactsCount: number;
  recentLeadsCount: number;
  recentlyEngagedCount: number;
}

interface peopleStatsOptions {
  accountId: string;
}

const usePeopleStats = (options: peopleStatsOptions) => {
  const { accountId } = options;
  const [peopleStats, setPeopleStats] = useState<PeopleStats>({
    contactsCount: 0,
    recentlyEngagedCount: 0,
    recentLeadsCount: 0,
  } as PeopleStats);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  const fetchPeopleStats = async (opt: peopleStatsOptions) => {
    try {
      setError("");
      setIsLoading(true);
      const data = await PeopleAPI.fetchPeopleStats(opt);
      setPeopleStats(data);
      setIsLoading(false);
    } catch (err) {
      setError("Something went wrong");
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchPeopleStats({ accountId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountId]);

  return { peopleStats, isLoading, error };
};

export default usePeopleStats;
