export interface ActivityStreamResult {
  activities: ActivityType[];
  totalResultCount: number;
}
export interface ActivityType {
  id: string;
  accountId: string;
  action: number;
  category: number;
  dateCreated: string;
  dateOfActivity: string;
  link: string;
  objectId: string;
  objectType: number;
  title: string;
}

export enum IActivitySegment {
  SALES = "sales",
  CUSTOMER = "customer",
}
