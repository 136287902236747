import { Grid, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import UserFeatureAccessWrapper from "components/UserFeatureAccessWrapper";
import { botsListSet, WorkerNameEnum } from "data/bots";
import { FeatureGroupSet } from "data/userPermissionsMap";
import PowerRankResultTable from "./PowerRankResultTable";
import { usePowerRankWorker } from "./hooks";
import PowerRankForm from "./PowerRankForm";
import { ProgressionLoading } from "../shared";

function PowerRankWorker() {
  const powerRankWorker = botsListSet.find((bot) => bot.key === WorkerNameEnum.POWER_RANK);
  const {
    getSimilarWins,
    onCancelRequest,
    result,
    errorMessage,
    resetErrorMessage,
    loading: isLoading,
    onResetResults
  } = usePowerRankWorker();

  const onSubmit = (accounts: string[]) => {
    getSimilarWins({ accounts });
  };

  if (!powerRankWorker) {
    return (
      <Box>
        Bot not found
      </Box>
    );
  }

  return (
    <Box sx={{
      p: "20px", display: "flex", flexDirection: "column", flexGrow: 1,
    }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          padding: "20px",
          backgroundColor: "#ffffff",
          borderRadius: "10px",
          border: "1px solid #e0e0e0",
          marginBottom: "20px",
        }}
      >
        <Box sx={{
          display: "flex",
          flexDirection: "row",
          gap: "20px",
          alignItems: "center",
        }}
        >
          <div style={{
            borderBottom: "1px solid #e0e0e0",
            paddingBottom: "10px",
            width: "70px",
            height: "70px",
            borderRadius: "50%",
            backgroundColor: "#f5f5f5",
            justifyContent: "center",
            alignItems: "center",
          }}
          >
            <img src={powerRankWorker.image} alt="Similar wins Finder" style={{ width: "100%" }} />
          </div>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
            <Typography variant="h5">{powerRankWorker.title}</Typography>
            <Typography variant="body1">{powerRankWorker.description}</Typography>
          </Box>
        </Box>
        <PowerRankForm
          onFind={onSubmit}
          onResetErrorMessage={resetErrorMessage}
          isLoading={isLoading}
          onCancelRequest={onCancelRequest}
          onResetForm={onResetResults}
        />
      </Box>
      {isLoading ? (<ProgressionLoading searchEntityTitle="Similar accounts" />) : (
        errorMessage ? (
          <Box sx={{
            padding: "20px", border: "1px solid #e0e0e0", borderRadius: "10px", marginBottom: "20px", backgroundColor: "#fff",
          }}
          >
            <Typography variant="body1">{errorMessage}</Typography>
          </Box>
        )
          : Boolean(result.length) && (
            <Stack direction='column' gap='20px'>
              <PowerRankResultTable
                accounts={result}
              />
            </Stack>
          )
      )}
    </Box>
  );
}

function PowerRankWorkerWrapper() {
  return (
    <UserFeatureAccessWrapper groupName={FeatureGroupSet.POWER_RANK_WORKER}>
      <PowerRankWorker />
    </UserFeatureAccessWrapper>
  );
}
export default PowerRankWorkerWrapper;
