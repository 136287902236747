import { Box } from "@mui/material";

import { Head } from "components/AccountTables";
import { Switcher } from "components/common";
import { DateCell, SupportedFormatTypes, TextCell } from "components/common/Tables/common/Cells";
import Tooltip from "components/Tooltip";
import { ISalesforceFieldsMetaData } from "types/api";
import {
  convertColumnTypes,
  getDefaultColumnStyles,
  getExpandingColumnCheck,
} from "utils/tableViews/getDefaultColumnStyles";

interface IUsePeopleColumnsProps {
  visibleColumns?: ISalesforceFieldsMetaData;
}

enum SwitcherTypes {
  Date = "date",
  Number = "number",
  Text = "text",
}

interface TextWidthOptions {
  text: string;
  fontSize: number;
  fontFamily: string;
}

// @todo: move this to a util file. Create and instantiate the canvas once, use it in different places by changing the context and calculating widths.
export const measureTextWidth = ({ text, fontSize, fontFamily }: TextWidthOptions): number => {
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");

  if (context) {
    context.font = `${fontSize}px ${fontFamily}`;
    return context.measureText(text).width;
  }

  return 0;
};

// @todo:
// findAndMatchObjectFunction => if type === object, look for the name. Similar wins should map to its component...etc.
// this can be handled in the switcher thing default scenario.

const usePeopleColumns = ({ visibleColumns }: IUsePeopleColumnsProps) => {
  const columns = Object.values(visibleColumns || {}).filter((column) => column.visible);

  const peopleColumns = columns.map(
    (col: { name: string; label: string; type: string; visible: boolean }, index: number) => {
      // @todo: abstract this into a declarative function.
      const { columnStyles, cellStyles } = getDefaultColumnStyles(col.type);
      const convertedType = convertColumnTypes(col.type);
      const { className, style } = getExpandingColumnCheck({
        style: columnStyles,
        isLastColumn: index === columns.length - 1,
        columnType: col.type,
      });

      const textWidth = measureTextWidth({
        text: col.label,
        fontSize: 14,
        fontFamily: "Public Sans",
      });

      const cellWidth = style?.maxWidth?.substring(0, style.maxWidth.length - 1);
      const numericCellWidth = cellWidth ? parseInt(cellWidth, 10) : 0;

      return {
        Header: () => (
          <Tooltip title={col.label} placement="top" hide={numericCellWidth >= textWidth}>
            <Box>
              <Head sx={{ display: "block", maxWidth: style.maxWidth }} className="truncate">
                {col.label}
              </Head>
            </Box>
          </Tooltip>
        ),
        accessor: col.name,
        style: {
          ...style,
        },
        className,
        Cell: ({ value }: any) => (
          <Switcher
            active={convertedType}
            fallback={<TextCell value={value} sx={{ ...cellStyles }} />}
          >
            <Switcher.Case id={SwitcherTypes.Date}>
              <DateCell value={value} format={SupportedFormatTypes.Word} sx={{ ...cellStyles }} />
            </Switcher.Case>
            <Switcher.Case id={SwitcherTypes.Number}>
              <Box sx={{ ...cellStyles }}>{value}</Box>
            </Switcher.Case>
            <Switcher.Case id={SwitcherTypes.Text}>
              <TextCell value={value} sx={{ ...cellStyles }} />
            </Switcher.Case>
          </Switcher>
        ),
      };
    },
  );
  return peopleColumns;
};

export default usePeopleColumns;
