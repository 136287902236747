import { LinkedinOutlined } from "@ant-design/icons";
import {
  Box, Stack, Tooltip, Typography,
} from "@mui/material";

import { ReactNode, useEffect, useState } from "react";

import LoadingFallback from "components/common/Spinner/LoadingFallback";
import usePeopleContacts from "services/shooks/people";

import { EmailField } from "../common";
import { ActivityCardSize, activityCardWidth, onGoToExternalLink } from "../utils";

interface PersonLabelProps {
  value: string;
  onClick?: (name: string) => void;
  tipDetails?: PersonTooltipProps;
  valueSuffix?: string;
  placement?: "top" | "bottom" | "left" | "right";
  isCapitalized?: boolean;
  fallBackValue?: string | ReactNode;
}

interface ItruncatedListProps {
  placement?: "top" | "bottom" | "left" | "right";
  children: ReactNode[];
  maxLength: number;
  hiddenListTip?: string;
  joinChar?: string;
}

interface PersonTooltipProps {
  name?: string;
  email?: string;
  phoneNumber?: string;
  jobTitle?: string;
  linkedInProfile?: string;
  children?: ReactNode;
  isLoading?: boolean;
  open?: boolean;
  onHover?: () => Promise<void>;
  placement?: "top" | "bottom" | "left" | "right";
}

export function PersonToolTip({
  name,
  email,
  isLoading,
  onHover,
  phoneNumber,
  jobTitle,
  linkedInProfile,
  children,
  open,
  placement,
}: PersonTooltipProps) {
  const onPhoneNumberClick = () => {
    const cleanPhoneNumber = phoneNumber?.replace?.(" ", "");
    if (!cleanPhoneNumber) return;
    const tel = `tel:${cleanPhoneNumber}`;
    onGoToExternalLink(tel);
  };
  const shouldRenderTooltip = Boolean(name && email);
  const renderTooltipContent = shouldRenderTooltip ? (
    <Box>
      <Stack gap="8px">
        <Stack gap="4px" direction="row">
          <Typography fontSize="14px" fontWeight={700} lineHeight="14px" sx={{ color: "#1A1A1A" }}>
            {name}
          </Typography>
          {linkedInProfile && (
            <LinkedinOutlined
              style={{ color: "#2671B0", fontSize: "16px" }}
              onClick={() => onGoToExternalLink(linkedInProfile)}
            />
          )}
        </Stack>
        <Stack gap="2px">
          <LoadingFallback isLoading={isLoading && !email} skeltonSx={{ mb: "5px" }}>
            {email && (
              <EmailField
                label="Email:"
                value={email}
                valueSx={{ color: "#2A7AB7" }}
                onClick={() => onGoToExternalLink(`mailto:${email}`)}
              />
            )}
          </LoadingFallback>
          <LoadingFallback isLoading={isLoading && !phoneNumber} skeltonSx={{ mb: "5px" }}>
            {phoneNumber && (
              <EmailField
                label="Phone Number:"
                value={phoneNumber}
                valueSx={{ color: "#2A7AB7" }}
                onClick={onPhoneNumberClick}
              />
            )}
          </LoadingFallback>
          <LoadingFallback isLoading={isLoading && !jobTitle}>
            {jobTitle && (
              <EmailField
                label="Job title:"
                value={jobTitle}
                valueSx={{
                  fontWeight: "500",
                  maxWidth: "230px",
                  wordBreak: "break-word",
                  textTransform: "capitalize",
                }}
              />
            )}
          </LoadingFallback>
        </Stack>
      </Stack>
    </Box>
  ) : null;

  return (
    <Tooltip
      arrow
      title={renderTooltipContent}
      placement={placement || "top"}
      onOpen={onHover}
      enterDelay={500}
      componentsProps={{
        tooltip: {
          sx: {
            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
            bgcolor: "#fff",
            p: "10px",
            "& .MuiTooltip-arrow": {
              color: "#fff",
            },
          },
        },
      }}
    >
      <Box>{children}</Box>
    </Tooltip>
  );
}

export function PersonLabel({
  value,
  onClick,
  tipDetails,
  valueSuffix,
  placement,
  isCapitalized,
  fallBackValue,
}: PersonLabelProps) {
  const getPersonContactInfo = usePeopleContacts(({ actions }) => actions.get);
  const [isLoading, setIsLoading] = useState(false);
  const [personDetails, setPersonDetails] = useState<PersonTooltipProps>(tipDetails || {});

  const onTooltipClick = () => {
    onClick?.(value);
  };

  useEffect(() => {
    if (tipDetails) setPersonDetails(tipDetails);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(tipDetails)]);

  const onHover = async () => {
    setIsLoading(true);
    if (tipDetails?.email) {
      const details = await getPersonContactInfo({ email: tipDetails?.email });
      setPersonDetails({
        ...personDetails,
        ...details,
      });
    }
    setIsLoading(false);
  };

  return (
    <PersonToolTip
      placement={placement}
      name={value}
      {...personDetails}
      isLoading={isLoading}
      onHover={onHover}
    >
      <EmailField
        valueSx={{ textTransform: isCapitalized ? "none" : "capitalize" }}
        value={value}
        onClick={onTooltipClick}
        valueSuffix={valueSuffix}
        fallBackValue={fallBackValue}
      />
    </PersonToolTip>
  );
}

interface InlineListOfPersonsProps {
  persons: Person[];
  onPersonClick: (name?: string) => void;
  maxWidth?: number;
  maxLines?: number;
  cardSize: ActivityCardSize;
  // fixed list, optional
  numberOfItemInList?: number;
}

interface Person {
  name?: string;
  email?: string;
  phoneNumber?: string;
  linkedInProfile?: string;
  jobTitle?: string;
}
function getTextWidth(text: string) {
  const textCtx = document.createElement("canvas").getContext("2d");
  if (!textCtx) return 0;
  const measure = textCtx.measureText(text);
  return measure.width;
}

export function InlineListOfPersons({
  persons,
  onPersonClick,
  cardSize,
  numberOfItemInList,
  maxLines = 2,
  maxWidth = activityCardWidth[ActivityCardSize.SMALL],
}: InlineListOfPersonsProps) {
  const listWidth = activityCardWidth[cardSize] || maxWidth;
  let availableWidth = (listWidth * maxLines || 0) - 20;

  const shownPersons = numberOfItemInList
    ? persons.slice(0, numberOfItemInList)
    : (persons
      .map((person) => {
        const personLabel = person.name || person.email || "";
        const personLabelWidth = getTextWidth(personLabel) + 10;
        if (personLabelWidth < availableWidth) {
          availableWidth -= personLabelWidth;
          return person;
        }
        return null;
      })
      .filter(Boolean) as Person[]);

  return (
    <Stack direction="row" flexWrap="wrap" gap="4px" flex={1} display="-webkit-inline-box">
      <TruncatedList maxLength={shownPersons.length} joinChar=",">
        {persons.map((person, index) => {
          const isCapitalized = !person.name && !!person.email;
          const name = person.name || person.email || "";
          const isLastItem = index === persons.length - 1;
          const suffix = isLastItem ? "" : ",";

          return (
            <PersonLabel
              placement={index > shownPersons.length - 1 ? "right" : "top"}
              key={name + index}
              value={name}
              onClick={() => onPersonClick(name)}
              tipDetails={person}
              valueSuffix={suffix}
              isCapitalized={isCapitalized}
            />
          );
        })}
      </TruncatedList>
    </Stack>
  );
}

function RenderRemainingChildrenTip({ children }: { children: ReactNode[] }) {
  return (
    <Box display="flex" flexDirection="column">
      {children}
    </Box>
  );
}

function TruncatedList({
  children,
  maxLength,
  hiddenListTip,
  joinChar,
  placement,
}: ItruncatedListProps) {
  const renderedChildren = children.slice(0, maxLength);
  const remainingChildren = children.slice(maxLength);

  return (
    // @todo: investigate why it requires 2 clicks to close the tooltip. (probably has to do with the MUI using overlay in their tooltips/modals)
    <div style={{ display: "flex", flexWrap: "wrap", gap: "2px" }}>
      {renderedChildren}
      {Boolean(remainingChildren.length) && (
        <Tooltip
          title={<RenderRemainingChildrenTip>{remainingChildren}</RenderRemainingChildrenTip>}
          placement="bottom"
          componentsProps={{
            tooltip: {
              sx: {
                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
                bgcolor: "#fff",
                p: "10px",
                "& .MuiTooltip-arrow": {
                  color: "#fff",
                },
              },
            },
          }}
        >
          <div
            style={{
              marginLeft: "4px", fontWeight: "bold", color: "#004E87", cursor: "pointer",
            }}
          >
            +
            {remainingChildren.length}
          </div>
        </Tooltip>
      )}
    </div>
  );
}
