import {
  AccountBriefResponsePayload,
  AccountResp,
  IAccountsSignals,
  TeamAlignmentResponsePayload,
  TeamsAlignmentFilters,
  UpdateAccountResponse,
} from "types/api";
import { AxiosNode } from "utils/axios";

export type GenericObject = {
  [key: string]: any;
};

export type PaginatedPayload = {
  limit: number;
  offset: number;
  userIds?: string[];
  [key: string]: any;
  accountId?: string;
};

interface AccountProduct {
  productId: string;
  status: string;
}

interface IUpdateAccountPayload {
  id: string;
  targetAccount?: boolean;
  products?: AccountProduct[];
  priority?: string;
}

const whitespace = {
  accounts: async (payload: PaginatedPayload, options?: Parameters<typeof AxiosNode.post>[2]) => {
    try {
      // @hotfix: if userIds is empty, remove it from the payload. This should be handled on the backend.
      payload.userIds = payload.userIds?.length ? payload.userIds : undefined;
      const { data } = await AxiosNode.post<AccountResp>(
        "/whitespace/accounts",
        payload,
        options,
      );
      return data;
    } catch (err) {
      console.log(err);
      return { results: [], total: 0 } as unknown as AccountResp;
    }
  },
  accountsBriefList: async (payload: PaginatedPayload) => {
    try {
      const params = new URLSearchParams(payload).toString();

      const { data } = await AxiosNode.get<AccountBriefResponsePayload>(`/accounts-list?${params}`);
      return data;
    } catch (error) {
      return { items: [] }
    }
  },
  updateAccount: async (payload: IUpdateAccountPayload) => {
    const { data } = await AxiosNode.patch<UpdateAccountResponse>("/whitespace/accounts", payload);
    return data;
  },
  getAccountById: async (accountId: string) => {
    const { data } = await AxiosNode.post<AccountResp>("/whitespace/accounts", {
      accountIds: [accountId],
      limit: 1,
      offset: 0,
    });

    return data.results[0];
  },
  accountsSignal: async () => {
    try {
      // const { data } = await AxiosNode.get<IAccountsSignals>("/activity/metadata");
      return {};
    } catch (err) {
      console.log(err);
      return {} as IAccountsSignals;
    }
  },
};

const teamAlignment = {
  get: async (body: TeamsAlignmentFilters) => {
    const { data } = await AxiosNode.post<TeamAlignmentResponsePayload>("/team-alignment", body);
    return { data };
  },
};

const Whitespace = {
  whitespace,
  teamAlignment,
};
export default Whitespace;
