export const downloadFile = (filename: string, url: string) => {
  const link = document.createElement("a");
  link.href = url;
  link.download = filename;
  link.click();
};

export const downloadCsvFile = (filename: string, data: string) => {
  const blob = new Blob([data], { type: "text/csv" });
  const url = window.URL.createObjectURL(blob);
  downloadFile(filename, url);
};
