import {
  Button, CircularProgress, Tooltip, Typography,
} from "@mui/material";

import { Box } from "@mui/system";
import { useSearchQuery } from "hooks";
import React from "react";
import { AiWorker, WorkerPreCachedParams } from "types/api";

import AccountAutoCompleteField from "../shared/AccountAutoCompleteField";
import { useWorkerPrePopulateParams } from "../shared/hooks";

interface SimilarWinsFormProps {
  onFind: (accounts: string[], jobs: string[]) => void;
  onResetErrorMessage: () => void;
  isLoading?: boolean;
  onCancelRequest: () => void;
  onResetForm?: () => void;
}

function SimilarWinsForm(props: SimilarWinsFormProps) {
  const { onFind, onResetErrorMessage, isLoading, onCancelRequest } = props;
  const [accounts, setAccounts] = React.useState<string[]>([]);

  const onUpdateAccounts = (values: string[]) => {
    setAccounts(values);
    onResetErrorMessage();
  };

  const canLookupForSimilarWins = accounts.length > 0 && !isLoading;
  const onFindSimilarWins = () => {
    if (canLookupForSimilarWins) {
      onFind(accounts, []);
    }
  };

  const onResetForm = () => {
    setAccounts([]);
    props.onResetForm?.();
  }
  const [{ id: preStoredParamsId }] = useSearchQuery<{ id: string }>();
  useWorkerPrePopulateParams((params: WorkerPreCachedParams) => {
    if (params.worker !== AiWorker.SIMILAR_ACCOUNTS) return;
    const { accountNames } = params.parameters;
    if (Array.isArray(accountNames) && accountNames.length) {
      setAccounts(accountNames)
    }
    onFind(accountNames, []);
  }, preStoredParamsId)


  const shouldShowResetButton = !isLoading && Boolean(accounts.length);
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
      <Typography sx={{ fontSize: "12px", color: "#333333" }}>
        Search for similar wins by account name(s), add the accounts you want to search for.
      </Typography>
      <div style={{ display: "flex", gap: "10px", alignItems: "start" }}>
        <AccountAutoCompleteField values={accounts} placeholder="Account Name" onUpdate={onUpdateAccounts} />
        <Tooltip title={!canLookupForSimilarWins ? "Please add at least one account and one job title" : ""} arrow>
          <div>
            <Button sx={{ height: "42px", gap: "5px" }} variant="contained" color="primary" disabled={!canLookupForSimilarWins} onClick={onFindSimilarWins}>
              Search
              {isLoading && <CircularProgress size={12} color="inherit" sx={{ display: isLoading ? "block" : "none" }} />}
            </Button>
          </div>
        </Tooltip>
        {isLoading ? (
          <Button sx={{ height: "42px" }} variant="text" onClick={onCancelRequest}>Cancel</Button>
        ) : shouldShowResetButton && (
          <Button sx={{ height: "42px" }} variant="text" onClick={onResetForm}>Reset</Button>
        )}
      </div>
    </Box>
  );
}

export default SimilarWinsForm;
