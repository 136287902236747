import { Box } from "@mui/material";

import { useEffect, useState } from "react";

import { useSearchQuery } from "hooks";
import useTerritoryFilters from "services/shooks/territoryFilters";
import { Account } from "types/api";

import { FocusWidget } from "../../utils";

import { AccountDetails, Header } from ".";

const containerSharedStyles = {
  background: "#fff",
  borderRadius: "4px",
  border: "1px solid #E5E5E5",
  padding: "20px",
};

function StandAloneAccountDetails() {
  const [{ accid: accountId }, updateSearchQuery] = useSearchQuery<{
    fw?: FocusWidget;
    accid?: string;
  }>();
  const { actions } = useTerritoryFilters();
  const [currentAccount, setCurrentAccount] = useState<Account | undefined>(undefined);
  const onToggleTheSection = () => {
    updateSearchQuery({ fw: undefined, accid: undefined });
  };

  useEffect(() => {
    if (accountId) {
      const getAccount = async () => {
        const currentAccount = await actions.getAccountById(accountId);
        setCurrentAccount({ ...currentAccount });
        return currentAccount;
      };

      getAccount();
    }
  }, [accountId]);

  return (
    <Box sx={{
      display: "flex", flexDirection: "column", ...containerSharedStyles, flexGrow: 1,
    }}
    >
      {currentAccount && (
        <Header currentAccount={currentAccount} onToggleTheSection={onToggleTheSection} />
      )}
      <Box>
        <AccountDetails account={currentAccount!} />
      </Box>
    </Box>
  );
}

export default StandAloneAccountDetails;
