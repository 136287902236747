import { Typography } from "@mui/material";
import { Stack } from "@mui/system";

import { useRef, useState } from "react";

import useWhenClickOutside from "hooks/useWhenClockOutside";

interface FiltersControlProps {
  onResetFilters?: () => void;
}

function FilterControls(props: FiltersControlProps) {
  const saveFilterPopupRef = useRef<HTMLDivElement>(null);
  const { onResetFilters } = props;
  const [_, setIsSaveFilter] = useState<boolean>(false);
  useWhenClickOutside(saveFilterPopupRef, () => setIsSaveFilter(false));

  return (
    <Stack direction="row" gap="10px" alignItems="center">
      <Typography
        onClick={onResetFilters}
        sx={{ fontSize: "12px", cursor: "pointer", color: "#2A7AB7" }}
      >
        Reset Filters
      </Typography>
      {/* <TooltipContent
        isOpen={isSaveFilterOpen}
        placement="bottom-start"
        title={<SavedFiltersBox ref={saveFilterPopupRef} />}
      >
        <div>
          <SaveFilterIcon isLoading={isLoading} onClick={onShowCreateFilterForm} />
        </div>
      </TooltipContent> */}
    </Stack>
  );
}

export default FilterControls;
