import {
  Link, Stack, Tooltip, Typography,
} from "@mui/material";

import { Box } from "@mui/system";
import { format } from "date-fns";

import { CheckMarkIcon, DeclineMarkIcon } from "assets/icons";
import { dateWithTimeDefaultFormate } from "constants/dates";
import { isValidUrl } from "pages/WhitespaceAndTerritory/components/FocusPanel/components/AccountDetails/components/Overview/AccountWidget";
import { IActivity } from "types/api/activities";
import { stringUtils } from "utils/generics/string";

import { InlineListOfPersons, PersonLabel } from "./common";
import { ActivityContentInteractions, EmailField } from "../common";
import { ActivityCardSize } from "../utils";

interface IMeetingContentProps {
  activity: IActivity;
  onActionTrigger?: (action: ActivityContentInteractions, value?: string, name?: string) => void;
  cardSize: ActivityCardSize;
}
// @todo: move to utils
const getActivityParticipants = (activity: IActivity) => {
  const participants = activity?.recipient?.length
    ? activity?.recipient?.map((recipient) => ({
      name: recipient?.name,
      email: recipient?.address,
    }))
    : [
      {
        name: activity?.secondParty?.personName,
        email: activity?.secondParty?.email,
      },
    ];

  return participants;
};

export function MeetingContent({ activity, onActionTrigger, cardSize }: IMeetingContentProps) {
  const meetingOrganizer = {
    name: activity?.source?.personName,
    email: "",
  };

  const participants = getActivityParticipants(activity);

  const onFilterByPerson = (personName?: string, personId?: string) => {
    if (onActionTrigger && personName) {
      onActionTrigger(ActivityContentInteractions.FilterByPerson, personName, personId);
    }
  };
  const meetingStartingDate = activity.meetingStartTime
    && format(new Date(activity.meetingStartTime), `${dateWithTimeDefaultFormate}, zzz`);

  return (
    <Stack direction="column" sx={{ width: "100%", wordWrap: "break-word" }}>
      <Stack direction="column" sx={{ width: "100%" }}>
        <EmailField
          label="From:"
          valueSx={{ textTransform: meetingOrganizer.name ? "capitalize" : "none" }}
          value={(
            <PersonLabel
              onClick={onFilterByPerson}
              value={meetingOrganizer.name}
              tipDetails={{
                email: meetingOrganizer?.email,
                name: meetingOrganizer?.name,
              }}
            />
          )}
        />
        {meetingStartingDate && (
          <EmailField
            label="Meeting:"
            value={meetingStartingDate || "Not available"}
            isValueLowerCase={false}
          />
        )}
        <EmailField
          label="Subject:"
          value={(
            <span style={{ "--lines-max": 3 } as any} className="multi-line-truncate">
              {activity.subject}
            </span>
          )}
          valueSx={{ maxWidth: "70%", display: "block" }}
          isBolder
        />
        <EmailField
          label="With:"
          valueSx={{ textTransform: "capitalize" }}
          value={(
            <InlineListOfPersons
              cardSize={cardSize}
              persons={participants}
              onPersonClick={onFilterByPerson}
            />
          )}
        />
      </Stack>
    </Stack>
  );
}

export default MeetingContent;

export function MeetingExtraContent({ activity }: { activity: IActivity }) {
  const participants = getActivityParticipants(activity);
  return (
    <Box
      sx={{
        bgcolor: "#FAFAFA",
        p: "8px 12px",
        borderRadius: "4px",
        fontSize: "14px",
        color: "#004E87",
        lineHeight: "22px",
        cursor: "pointer",
        userSelect: "none",
      }}
    >
      <Stack gap="8px">
        {activity?.meetingLinks && isValidUrl(activity?.meetingLinks) && (
          <Link
            href={activity?.meetingLinks}
            target="_blank"
            underline="hover"
            sx={{ color: "#2A7AB7", maxWidth: "90%", fontSize: "12px" }}
            className="truncate"
          >
            {activity?.meetingLinks}
          </Link>
        )}
        <Stack gap="8px">
          <Typography
            lineHeight="14.1px"
            fontSize="12px"
            fontWeight="700"
            variant="caption"
            sx={{ color: "#333333" }}
          >
            Participants (
            {participants?.length || 0}
            )
          </Typography>
          <Stack gap="5px">
            {participants?.map((participant, index) => (
              <Participant
                key={index}
                name={participant.name}
                email={participant.email}
                attended={undefined}
                isOrganizor={stringUtils.compareIgnoreCase(
                  activity?.source?.personName,
                  participant.name,
                )}
              />
            ))}
          </Stack>
          <Stack gap="5px" />
        </Stack>
      </Stack>
    </Box>
  );
}

interface ParticipantProps {
  name: string;
  email: string;
  attended?: boolean;
  isOrganizor?: boolean;
}

function ParticipantStatus({ attended }: { attended?: boolean }) {
  const isRespened = typeof attended === "boolean";
  const statusTip = !isRespened ? "Not responded" : attended ? "Accepted" : "Declined";
  const color = !isRespened ? "#AAAAAA" : attended ? "#358700" : "#B22424";
  const isDeclined = isRespened && !attended;
  return (
    <Tooltip title={statusTip} placement="top">
      {/* <Box sx={{ color }}> */}
      {isDeclined ? <DeclineMarkIcon color={color} /> : <CheckMarkIcon color={color} />}
      {/* </Box> */}
    </Tooltip>
  );
}

function Participant({
  name, email, attended, isOrganizor,
}: ParticipantProps) {
  return (
    <Stack direction="row" alignItems="center" gap="8px">
      <ParticipantStatus attended={attended} />
      <PersonLabel value={name || email} tipDetails={{ email, name: name || email }} />
      {isOrganizor && (
        <Typography variant="caption" sx={{ color: "#333333" }}>
          (Organizor)
        </Typography>
      )}
    </Stack>
  );
}
