import { CloseCircleOutlined, HddOutlined } from "@ant-design/icons";
import { useAuth0 } from "@auth0/auth0-react";
import { Badge, Box, Tooltip } from "@mui/material";
import { uniqBy } from "lodash";
import { useEffect, useMemo, useState } from "react";

import IconButton from "components/@extended/IconButton";
import { ActivitiesResults } from "components/Activity.components/ActivitiesResults";
import { ActivityFiltersHeader } from "components/Activity.components/ActivityFiltersHeader";
import { EmailDisplayBox, ScrollableBox } from "components/Activity.components/common";
import MainCard from "components/Activity.components/MainCard";

import { SearchBoxFiltersValue } from "components/Activity.components/MultiSelectInput";
import { NestedActivityView } from "components/Activity.components/NestedActivityView";
import {
  ActivityCardSize,
  ActivityGroupsEnum,
  splitFiltersGroup,
} from "components/Activity.components/utils";
import { Switcher } from "components/common";
import { FeaturesEnum } from "config/featureFlagsConfig";
import { useSearchQuery } from "hooks";
import useElementBoundingClientRect from "hooks/useElementSizeDetails";
import useFeaturesFlag from "hooks/useFeatureFlag";
import useToggle from "hooks/useToggle";
import { ActivitySegment } from "services/activities/search";
import useActivities from "services/shooks/activities";

import useUserSettings from "services/shooks/settings";
import useTerritoryAccounts from "services/shooks/territory";
import useTerritoryFilters from "services/shooks/territoryFilters";
import useLastSignalCount from "services/shooks/useNewActivitiesSignals";
import { ActivityTypes, IActivity } from "types/api/activities";

export enum ActivitySearchWidgetViewModes {
  RootView = "RootView",
  ActivityFocusMode = "ActivityFocusMode",
  ActivityGroupView = "ActivityGroupView",
}

function ActivityFeed() {
  const [{ ac_feed: isActivityFeedOpen }, updateSearchQuery] = useSearchQuery<{
    ac_feed: boolean;
  }>();
  const { actions: activitiesActions, state: activitiesState, isLoading } = useActivities();
  const [filters, setFilters] = useState<SearchBoxFiltersValue>({});
  const [focusedActivity, setFocusedActivity] = useState<IActivity | null>(null);
  const [viewMode, setViewMode] = useState<ActivitySearchWidgetViewModes>(
    ActivitySearchWidgetViewModes.RootView,
  );
  const [showActivityFilters, toggleActivitiesFilters] = useToggle(true);
  const [accountsOptions, setAccountsOptions] = useState<
    { value: string; name: string; group: ActivityGroupsEnum }[]
  >([]);
  const [unreadNotificationCount, updateLastActiveDate] = useLastSignalCount(
    ({ state, actions }) => [state.lastSignalsCount, actions.updateLastSeenDate],
  );
  const dismissedActivityIds = useUserSettings(
    ({ state }) => state?.activities?.dismissedActivities || [],
  ) as string[];
  const [isLoadingMore, , asyncIsLoadingMoreToggle] = useToggle(false);
  const {
    selectedAccounts, accounts, totalSelectedAccounts, isSelectAll,
  } = useTerritoryFilters(
    ({ state }) => {
      const { selected = {}, isSelectAll } = state.selectionState || {};
      const selectedAccounts = Object.entries(selected)
        .filter(([, value]) => value)
        .map(([key]) => key);
      return {
        selectedAccounts,
        accounts: state.accounts || [],
        totalSelectedAccounts: isSelectAll
          ? state.pagination?.total - selectedAccounts.length
          : selectedAccounts.length,
        isSelectAll,
      };
    },
  );
  const { activities, filters: activitiesRootFilters } = activitiesState;
  const {
    isFilterOnSelectedAccounts,
    focusedAccount,
    query: activitySearchPlainQuery,
  } = activitiesRootFilters;
  const hasMoreResults = activitiesState.totalResultCount > activitiesState.activities.length;
  const onToggleFilterOnSelectedAccount = () => {
    activitiesActions.onToggleFilterOnSelectedAccounts();
  };
  const { user } = useAuth0<User>();
  const {
    state: { selectedUserIds },
  } = useTerritoryAccounts();
  const hasSelectedUsers = selectedUserIds?.filter((id) => id !== user?.SFUserId)?.length > 0;
  const { ref: activitiesContainerRef, rect: activitiesContainerDimensions } = useElementBoundingClientRect([showActivityFilters]);
  const listContainerHeight = useMemo(
    () => (activitiesContainerDimensions?.height || 100) - 60,
    [activitiesContainerDimensions?.height],
  );

  const isDebugModeOn = useFeaturesFlag(FeaturesEnum.DebugMode);
  const [isMetricsActivityCardShown, setIsMetricsActivityCardShow] = useToggle();

  const onToggleMetricsActivityCardShow = () => {
    if (isDebugModeOn) {
      setIsMetricsActivityCardShow(!isMetricsActivityCardShown);
    } else {
      setIsMetricsActivityCardShow(false);
    }
  };

  useEffect(() => {
    if (activitySearchPlainQuery) {
      const options = [
        {
          name: activitySearchPlainQuery,
          value: activitySearchPlainQuery,
          group: ActivityGroupsEnum.CustomByQueryText,
        },
      ] as unknown as SearchBoxFiltersValue["options"];
      setFilters({ options });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activitySearchPlainQuery]);

  useEffect(() => {
    const queryFilter = activitiesState.filters.query
      ? [
        {
          name: activitiesState.filters.query,
          value: activitiesState.filters.query,
          group: ActivityGroupsEnum.CustomByQueryText,
        },
      ]
      : [];
    // @todo: this can be abstracted to reusable function reducing the code duplication.
    if (isFilterOnSelectedAccounts) {
      const selectedAccountsList = accounts?.filter((account) => selectedAccounts.includes(account.id)) || [];
      const accountsOptions = selectedAccountsList
        .map((account) => ({ value: account.id, name: account.name }))
        .map((option) => ({ ...option, group: ActivityGroupsEnum.SelectedAccounts }));
      const currentOptions = filters?.options?.filter((option) => option.group !== ActivityGroupsEnum.SelectedAccounts)
        || [];
      const optionsCurrentPage = [
        ...currentOptions,
        ...accountsOptions,
        ...queryFilter,
      ] as typeof currentOptions;

      setFilters(({ options = [], ...filters }) => {
        const uniqList = uniqBy([...options, ...optionsCurrentPage], ({ value }) => value).filter(
          ({ value }) => selectedAccounts.includes(String(value)),
        );
        return { ...filters, options: uniqList };
      });
    } else {
      const noneSelectedAccountsOptions = filters?.options?.filter(
        (option) => ![ActivityGroupsEnum.SelectedAccounts, ActivityGroupsEnum.ActionSegment].includes(
          option.group,
        ) || [],
      );

      let options = noneSelectedAccountsOptions
        ? [...noneSelectedAccountsOptions, ...queryFilter]
        : ([...queryFilter] as any[]);
      if (focusedAccount?.id && focusedAccount?.name) {
        const singleAccountFocusOption = {
          value: focusedAccount.id,
          icon: null,
          name: focusedAccount.name,
          group: ActivityGroupsEnum.SelectedAccounts,
        };
        options = [singleAccountFocusOption] as any[];
        if (focusedAccount?.signalSegment) {
          const segmentOption = {
            value: focusedAccount?.signalSegment,
            group: ActivityGroupsEnum.ActionSegment,
            name: `${String(focusedAccount?.signalSegment).toLowerCase()} Activity`,
          } as any;
          options = [...options, segmentOption];
        }
      }
      setFilters({ ...filters, options });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    JSON.stringify(selectedAccounts),
    focusedAccount?.id,
    focusedAccount?.signalSegment,
    isFilterOnSelectedAccounts,
  ]);

  const onFilterByAccount = (accountId: string, accountName?: string) => {
    if (!(accountName && accountId)) return;

    const option = {
      value: accountId,
      icon: null,
      name: accountName,
      group: ActivityGroupsEnum.SelectedAccounts,
    } as any;
    onFilterChange({ options: [option] });
  };

  const onFilterByPerson = (personName: string) => {
    activitiesActions.filterByPerson(personName);
  };

  useEffect(() => {
    if (Array.isArray(dismissedActivityIds) && dismissedActivityIds.length) {
      const newActivities = activities.filter(
        (activity) => !dismissedActivityIds?.includes(activity.id),
      );
      activitiesActions.updateState({ activities: newActivities });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dismissedActivityIds]);

  useEffect(() => {
    const accountsOptions = accounts
      ?.map((account) => ({ value: account.id, name: account.name }))
      .map((option) => ({
        ...option,
        group: ActivityGroupsEnum.SelectedAccounts,
      }));
    setAccountsOptions(accountsOptions || []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(accounts)]);

  const resetActivityPlainQueryState = () => {
    const hasSingleAccountFocusInOptions = filters?.options?.find(
      (option) => focusedAccount?.id && String(option.value) === focusedAccount?.id,
    );
    const shouldResetFocusAccount = !hasSingleAccountFocusInOptions && Boolean(focusedAccount?.id && focusedAccount?.name);

    const hasPainQueryInOptions = filters?.options?.find(
      (option) => option.group === ActivityGroupsEnum.CustomByQueryText,
    );
    const shouldResetPlainQuery = !hasPainQueryInOptions && activitySearchPlainQuery;

    if (shouldResetFocusAccount) {
      activitiesActions.filterForAccount(undefined);
    }
    if (shouldResetPlainQuery) {
      activitiesActions.filterByPerson("");
    }
  };

  useEffect(() => {
    const lastAbortController = new AbortController();
    const {
      accountIds, actionFilters, activitySegment, plainQuerySearch,
    } = splitFiltersGroup(filters);
    onActivitiesSearch({
      searchQuery: plainQuerySearch,
      accountIds,
      actionFilters,
      initiatedBy: activitySegment,
      signal: lastAbortController.signal,
      isSelectAll,
    });

    return () => {
      resetActivityPlainQueryState();
      lastAbortController.abort();
    };
    // filter only valid groups for updates trigger
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(filters?.options), selectedUserIds, isSelectAll]);

  const onActivityFocus = (activity: IActivity) => {
    const isSupportedForFocus = activity?.action === ActivityTypes.Email;
    if (!isSupportedForFocus) return;
    setFocusedActivity(activity);
    setViewMode(ActivitySearchWidgetViewModes.ActivityFocusMode);
  };

  // reset filters when activity feed is closed
  // if the filter by account is checked, keep the accounts group, clear everything else.
  useEffect(
    () =>
    // and when we toggle it back, the options will be already wiped out
      () => setFilters({}),
    [],
  );

  const onLoadMoreResults = () => {
    const { limit = 100, offset = 0, activities } = activitiesState;
    const { accountIds, actionFilters, activitySegment } = splitFiltersGroup(filters);
    return asyncIsLoadingMoreToggle(
      activitiesActions.loadMoreResultActivities({
        limit,
        offset: activities?.length || offset,
        accountIds: (accountIds?.length || 0) >= 1 ? accountIds : undefined,
        actionFilters: (actionFilters?.length || 0) >= 1 ? actionFilters : [],
        userIds: hasSelectedUsers ? selectedUserIds : undefined,
        initiatedBy: activitySegment,
        dismissedActivities: {
          activityIds: dismissedActivityIds,
          accountIds: [],
        },
      }),
    );
  };

  const onActivitiesSearch = async ({
    searchQuery,
    initiatedBy,
    accountIds,
    actionFilters,
    signal,
    isSelectAll,
  }: {
    initiatedBy?: string;
    searchQuery?: string;
    accountIds?: string[];
    actionFilters?: string[];
    signal?: AbortSignal;
    isSelectAll?: boolean;
  } = {}) => activitiesActions.search(
    {
      offset: 0,
      limit: 100,
      accountIds: (accountIds?.length || 0) < 1 || isSelectAll ? undefined : accountIds,
      userIds: hasSelectedUsers ? selectedUserIds : undefined,
      actionFilters: (actionFilters?.length || 0) >= 1 ? actionFilters : [],
      searchQuery: searchQuery || "*",
      initiatedBy: initiatedBy as ActivitySegment,
      dismissedActivities: {
        activityIds: dismissedActivityIds,
        accountIds: isSelectAll ? accountIds || [] : [],
      },
    },
    {
      signal,
    },
  );

  const handleToggle = () => {
    updateSearchQuery({ ac_feed: !isActivityFeedOpen });
  };

  const onFilterChange = (filtersUpdates: SearchBoxFiltersValue) => {
    setFilters({ ...filters, ...filtersUpdates });
  };

  const onSwitchBackToRootView = () => {
    setViewMode(ActivitySearchWidgetViewModes.RootView);
    setFocusedActivity(null);
  };

  const isActivityFocusMode = viewMode === ActivitySearchWidgetViewModes.ActivityFocusMode;

  const onActivityFeedContainerClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    updateLastActiveDate();
  };

  return (
    <MainCard
      onClick={onActivityFeedContainerClick}
      title="Activity Feed"
      sx={{
        border: "none",
        borderRadius: 0,
        width: "320px",
        minWidth: "320px",
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#FAFAFA",
        "& .MuiCardHeader-root": {
          color: "#F5F5F5",
          bgcolor: "#2A7AB7",
          "& .MuiTypography-root": {
            fontSize: "1rem",
          },
        },
      }}
      content={false}
      secondary={(
        <IconButton
          shape="rounded"
          size="small"
          onClick={handleToggle}
          sx={{ color: "background.paper" }}
        >
          <CloseCircleOutlined style={{ fontSize: "1.15rem" }} />
        </IconButton>
      )}
    >
      {!isActivityFocusMode && (
        <ActivityFiltersHeader
          signalsCount={unreadNotificationCount}
          onFilterOnAccountSelectionToggle={onToggleFilterOnSelectedAccount}
          isFilterOnAccountSelection={isFilterOnSelectedAccounts!}
          onToggleFilters={toggleActivitiesFilters}
          isFiltersShown={showActivityFilters}
          isGroupView={false}
          onFilterChange={onFilterChange}
          selectedAccountsCount={totalSelectedAccounts}
          filters={filters}
          accountsOptions={accountsOptions}
          onToggleMetricsCard={onToggleMetricsActivityCardShow}
          isMetricsActivityCardShown={isMetricsActivityCardShown}
        />
      )}
      {/* @note: this hey for rerendering to avoid the flex:1 delima with capturing the max size not updating based of the current state */}
      <Box
        key={`${showActivityFilters}${listContainerHeight}`}
        sx={{ flex: 1, overflow: "hidden" }}
        ref={activitiesContainerRef}
      >
        <Switcher active={viewMode}>
          <Switcher.Case id={ActivitySearchWidgetViewModes.RootView}>
            <ActivitiesResults
              activities={activities}
              onActivityFocus={onActivityFocus}
              isLoading={isLoading}
              onFilterByAccount={onFilterByAccount}
              onFilterByPerson={onFilterByPerson}
              onLoadMore={onLoadMoreResults}
              isLoadingMore={isLoadingMore}
              hasMoreResults={hasMoreResults}
              isFiltersShown={showActivityFilters}
              cardSize={ActivityCardSize.SMALL}
              listContainerHeight={listContainerHeight}
              showMetricsCard={isMetricsActivityCardShown}
            />
          </Switcher.Case>
          <Switcher.Case id={ActivitySearchWidgetViewModes.ActivityFocusMode}>
            <NestedActivityView onGoBack={onSwitchBackToRootView}>
              <ScrollableBox show height="75vh" sx={{ m: 0, p: 0 }}>
                <EmailDisplayBox activity={focusedActivity!} />
              </ScrollableBox>
            </NestedActivityView>
          </Switcher.Case>
          <Switcher.Case id={ActivitySearchWidgetViewModes.ActivityGroupView}>
            <Box sx={{ p: "10px 20px" }}>
              <EmailDisplayBox activity={activities?.[0]} />
            </Box>
          </Switcher.Case>
        </Switcher>
      </Box>
    </MainCard>
  );
}

function ActivitySideBar() {
  const [{ ac_feed: isActivityFeedOpen }, updateSearchQuery] = useSearchQuery<{
    ac_feed: boolean;
  }>();
  const hasNewUnreadActivities = useLastSignalCount(({ state }) => Boolean(state.lastSignalsCount));
  const onToggle = () => {
    updateSearchQuery({ ac_feed: true });
  };

  return isActivityFeedOpen ? (
    <ActivityFeed />
  ) : (
    <Box
      sx={{
        minWidth: "60px",
        background: "#fff",
        padding: "20px",
        borderBottom: "1px solid #eee",
        cursor: "pointer",
      }}
      onClick={onToggle}
    >
      <Box>
        <Tooltip title="Open Activity Feed" placement="right">
          <Badge
            badgeContent={hasNewUnreadActivities}
            color="primary"
            sx={{
              "& .MuiBadge-badge": {
                bgcolor: hasNewUnreadActivities ? "#2A7AB7" : "transparent",
                minWidth: "unset",
                padding: "unset",
                height: "6px",
                width: "6px",
                top: "4px",
              },
            }}
          >
            <HddOutlined style={{ color: "#757575", fontSize: "20px", margin: "5px 0 5px 0" }} />
          </Badge>
        </Tooltip>
      </Box>
    </Box>
  );
}

export default ActivitySideBar;
