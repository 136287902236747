import type { Color, HEX } from "types/colors";

type ThreeTuple = [number, number, number];
type FourTuple = [number, number, number, number];

export const hex2dec = (hex: Color) => hex
  ?.replace("#", "")
  ?.match(/.{2}/g)
  ?.map((n) => parseInt(n, 16));

export const rgb2hex = (r: number, g: number, b: number): HEX => {
  const values = [r, g, b]
    .map((c) => Math.min(Math.round(c)))
    .map((c) => c.toString(16).padStart(2, "0"))
    .join("");
  return `#${values}`;
};

export const rgb2cmyk = (r: number, g: number, b: number) => {
  let c = 1 - r / 255;
  let m = 1 - g / 255;
  let y = 1 - b / 255;
  const k = Math.min(c, m, y);
  c = (c - k) / (1 - k);
  m = (m - k) / (1 - k);
  y = (y - k) / (1 - k);
  return [c, m, y, k];
};

export const cmyk2rgb = (
  c: number,
  m: number,
  y: number,
  k: number,
): ThreeTuple => {
  let r = c * (1 - k) + k;
  let g = m * (1 - k) + k;
  let b = y * (1 - k) + k;
  r = (1 - r) * 255 + 0.5;
  g = (1 - g) * 255 + 0.5;
  b = (1 - b) * 255 + 0.5;
  return [r, g, b];
};

export const mix_cmyks = (...cmyks: any) => {
  const c = cmyks
    .map((cmyk: any) => cmyk[0])
    .reduce((a: number, b: number) => a + b, 0) / cmyks.length;
  const m = cmyks
    .map((cmyk: any) => cmyk[1])
    .reduce((a: number, b: number) => a + b, 0) / cmyks.length;
  const y = cmyks
    .map((cmyk: any) => cmyk[2])
    .reduce((a: number, b: number) => a + b, 0) / cmyks.length;
  const k = cmyks
    .map((cmyk: any) => cmyk[3])
    .reduce((a: number, b: number) => a + b, 0) / cmyks.length;
  return [c, m, y, k];
};

export const mix_hex_colors = (...hexes: Color[]): Color => {
  const rgbs = hexes.map((hex) => hex2dec(hex));
  const cmyks = rgbs.map((rgb) => rgb2cmyk(...(rgb as ThreeTuple)));
  const mixture_cmyk = mix_cmyks(...cmyks);
  const mixture_rgb = cmyk2rgb(...(mixture_cmyk as FourTuple));
  const mixture_hex = rgb2hex(...mixture_rgb);
  return mixture_hex;
};

export function overrideHexColorTransparency(
  hexColor: string,
  overrides: { r?: number; g?: number; b?: number; a?: number },
) {
  const hex = hexColor?.replace("#", "");
  const bigint = parseInt(hex, 16);
  const r = overrides.r || (bigint >> 16) & 255;
  const g = overrides.g || (bigint >> 8) & 255;
  const b = overrides.b || bigint & 255;
  const rgpaList = [r, g, b, overrides.a].join(",");

  return `rgba(${rgpaList})`;
}

// Function to calculate the Euclidean distance between two colors
// To make sure no two colors are too similar
const colorDistance = (
  color1: [number, number, number],
  color2: [number, number, number],
): number => {
  const [r1, g1, b1] = color1;
  const [r2, g2, b2] = color2;
  return Math.sqrt(
    (r1 - r2) ** 2 + (g1 - g2) ** 2 + (b1 - b2) ** 2,
  );
};

export const getRandomColor = (): [number, number, number] => {
  const r = Math.floor(150 + Math.random() * 90); // 0-255, higher # = lighter color
  const g = Math.floor(150 + Math.random() * 90);
  const b = Math.floor(150 + Math.random() * 90);
  return [r, g, b];
};

export const generateRandomColors = (
  length: number,
  minDistance: number = 100,
): string[] => {
  const colors: [number, number, number][] = [];

  while (colors.length < length) {
    const newColor = getRandomColor();
    let isFarEnough = true;

    // eslint-disable-next-line no-restricted-syntax
    for (const color of colors) {
      if (colorDistance(newColor, color) < minDistance) {
        isFarEnough = false;
        break;
      }
    }

    if (isFarEnough) {
      colors.push(newColor);
    }
  }

  return colors.map(([r, g, b]) => `rgba(${r}, ${g}, ${b}, 0.7)`);
};
