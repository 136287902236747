import React from "react";

import { FeatureGroupSet } from "data/userPermissionsMap";
import useAccessManager from "hooks/useAccessManager";

interface UserFeatureAccessWrapperProps {
  groupName: FeatureGroupSet;
  children: JSX.Element;
  accessDeniedComponent?: JSX.Element;
}

function UserFeatureAccessWrapper({ groupName, children, accessDeniedComponent }: React.PropsWithChildren<UserFeatureAccessWrapperProps>) {
  const { checkIfUserHasAccess } = useAccessManager();
  const hasAccess = checkIfUserHasAccess(groupName);

  if (hasAccess) {
    return children;
  }

  return accessDeniedComponent || null;
}

export default UserFeatureAccessWrapper;
