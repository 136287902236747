import { numberFormats } from "utils/numbers";

interface IWalletShareWidgetProps {
  TAM: number;
  open: number;
  closed: number;
  uncapturedWhitespace: number;
  accountId?: string;
}

// TAM cannot be 0 here.
function WalletShareWidget({
  TAM, open, closed, uncapturedWhitespace,
}: IWalletShareWidgetProps) {
  const openPercent: number = (open / TAM) * 100;
  const closedPercent: number = (closed / TAM) * 100;
  const uncapturedPercent: number = 100 - openPercent - closedPercent;
  const uncapturedRevenueValue: string = `$${numberFormats.millify(uncapturedWhitespace)}`;
  const displayValues = {
    open: `$${numberFormats.millify(open)}`,
    closed: `$${numberFormats.millify(closed)}`,
    TAM: `$${numberFormats.millify(TAM)}`,
  };

  const hasValidValues = TAM || open || closed;
  if (!hasValidValues) return <div style={{ display: "flex", justifyContent: "center" }}>--</div>;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        position: "relative",
        // margin: '25px 0 0 0',
        padding: "16px 0 0 0",
        fontSize: "10px",
        zIndex: 1,
        overflow: "hidden",
        maxWidth: "100%",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-start",
          justifyContent: "space-between",
          position: "relative",
          margin: 0,
          padding: 0,
          width: "100%",
        }}
      >
        <div style={{ display: "flex", justifyContent: "flex-start", gap: "2px" }}>
          {Boolean(closed) && (
            <div style={{ margin: 0, padding: 0, color: "#1A1A1A" }}>{displayValues.closed}</div>
          )}
          {Boolean(open) && (
            <div style={{ margin: 0, padding: 0, color: "#1A1A1A" }}>{displayValues.open}</div>
          )}
        </div>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          {Boolean(uncapturedPercent) && (
            <div style={{ margin: 0, padding: 0, color: "#1A1A1A" }}>
              {uncapturedRevenueValue}
            </div>
          )}
        </div>
      </div>
      <div
        className="hello-world"
        style={{
          position: "relative",
          width: "100%",
          height: "16px",
          borderRadius: "2px",
          overflow: "hidden",
        }}
      >
        <div
          style={{
            width: `${closedPercent}%`,
            height: "100%",
            backgroundColor: "#00223C",
            position: "absolute",
            top: 0,
            left: 0,
            zIndex: 4,
          }}
        />
        <div
          style={{
            width: `${openPercent}%`,
            height: "100%",
            backgroundColor: "#fff",
            position: "absolute",
            top: 0,
            left: `${closedPercent}%`,
            zIndex: 3,
            border: openPercent ? "1px solid #61ABF2" : "none",
          }}
        />
        <div
          style={{
            width: `${uncapturedPercent}%`,
            backgroundColor: "#E3E9ED",
            height: "100%",
            position: "absolute",
            top: 0,
            left: `${openPercent + closedPercent}%`,
            zIndex: 2,
          }}
        />
      </div>
    </div>
  );
}

export default WalletShareWidget;
