import { Account, TerritoryFiltersMetaData } from "types/api";
import {
  DynamicFilterT, ObjectTypesEnum, TerritoryTableColumnTitles, TerritoryTableColumnsAccessors,
} from "types/api/territory";
import { AccountStatus, TerritoryFilters, TerritoryFiltersEnums } from "types/api/territoryFilters";
import { ISimilarWin } from "types/territory";
import { numberFormats } from "utils/numbers";

type FilterMeta = {
  filterGroup: TerritoryFiltersEnums;
  label: string;
};

export type Column = {
  name: string;
  accessor: string;
}

const specialWords: Map<string, string> = new Map([
  ["inCRM", "In CRM"],
]);

export const spaceAndCapitalize = (str: string) => {
  // Check if the string is all caps
  if (/^[A-Z]+$/.test(str)) {
    return str; // Return the string unchanged if it's all uppercase
  }

  // Otherwise, apply transformations
  return (
    specialWords.get(str) ||
    str
      .replace(/[^a-zA-Z0-9]/g, " ")  // Replace non-alphanumeric characters with spaces
      .replace(/([a-z])([A-Z])/g, "$1 $2")  // Add space before capital letters in camelCase
      .replace(/^./, (match) => match.toUpperCase())  // Capitalize the first letter
      .trim()  // Remove any leading or trailing spaces
  );
};

export const isArrayOfObjectsContainingName = (value: any): value is { name: string }[] => Array.isArray(value) && value.every((item) => typeof item === "object" && item.name);

export const isArrayOfStrings = (value: any): value is string[] => Array.isArray(value) && value.every((item) => typeof item === "string");

export const formatArrayOfStrings = (value: string[]) => value.map((val) => JSON.stringify(val)).join(", ").replaceAll("\"", "");

export const isISODate = (str: string): boolean => {
  const regex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d{3})?(Z|[+-]\d{2}(:\d{2})?|[+-]\d{4})$/;
  return regex.test(str);
};

export const formatDateToYYYYMMDD = (
  date: Date,
): string => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
};

export const omitLimitAndOffset = (obj: DynamicFilterT) => Object.entries(obj)
  .filter(([key, _]) => key !== "limit" && key !== "offset");

export const removeInvalidObjectTypes = (obj: DynamicFilterT): DynamicFilterT => {
  Object.keys(obj).forEach((key) => {
    if (!Object.values(ObjectTypesEnum).includes(key as ObjectTypesEnum)) {
      delete obj[key as keyof DynamicFilterT];
    }
  });
  return obj;
};

const filterCounterMap = {
  textQuery: (filters: TerritoryFilters) => {
    const { searchQuery } = filters;
    return {
      count: (searchQuery?.length ?? 0) > 0 ? 1 : 0,
      filterMeta: {
        filterGroup: TerritoryFiltersEnums.searchQuery,
        label: "Search text is enabled",
      },
    };
  },
  // @note: in the UI we call this filter Account Fit
  pipelineSignals: (filters: TerritoryFilters) => {
    const { pipelineSignals } = filters;
    return {
      count: pipelineSignals?.length ?? 0,
      filterMeta: {
        filterGroup: TerritoryFiltersEnums.pipelineSignals,
        label: "filter by selected account fit signals",
      },
    };
  },
  accountStatus: (filters: TerritoryFilters) => {
    const { accountStatus = {} } = filters;
    const isExactMatchEnabled = !Object.values(accountStatus).some(
      (value) => value === undefined || value === null,
    );
    const hasEnabledStatusFilters = Object.values(accountStatus).some(
      (value) => value === 1 || value === 0,
    );
    return {
      count: hasEnabledStatusFilters ? 1 : 0,
      filterMeta: {
        filterGroup: TerritoryFiltersEnums.searchQuery,
        label: isExactMatchEnabled
          ? "Exact accounts status is enabled"
          : "filter by matching subset of the accounts status",
      },
    };
  },
  accountOwner: (filters: TerritoryFilters) => {
    const { accountOwners } = filters;
    return {
      count: (accountOwners?.length ?? 0) > 0 ? 1 : 0,
      filterMeta: {
        filterGroup: TerritoryFiltersEnums.accountOwners,
        label: `filter by selected account owners (${accountOwners?.length ?? 0} selected) `,
      },
    };
  },
  firmographicFilters: (filters: TerritoryFilters) => {
    const { firmographicFilters } = filters;
    let count = 0;
    const {
      employees, industry, headquarters, annualRevenue,
    } = firmographicFilters || {};
    if ((annualRevenue?.min ?? 0) !== 0) {
      count += 1;
    }
    if ((employees?.max ?? 0) !== 0) {
      count += 1;
    }
    if ((industry?.length ?? 0) > 0) {
      count += 1;
    }
    if ((headquarters?.length ?? 0) > 0) {
      count += 1;
    }

    return {
      count,
      filterMeta: {
        filterGroup: TerritoryFiltersEnums.firmographicFilters,
        label: `${count} filters enabled in the Firmographics`,
      },
    };
  },
  pipelineAttributeFilters: (filters: TerritoryFilters) => {
    const { pipelineAttributeFilters } = filters;
    const { availableWhitespace, ...restPipelineAttributeFilters } = pipelineAttributeFilters || {};
    // @ts-ignore @todo fix this
    const otherFilters = Object.values(restPipelineAttributeFilters).filter(
      (filter) => filter !== "all",
    );
    let count = otherFilters.length;
    if ((availableWhitespace?.min ?? 0) > 0) {
      count += 1;
    }
    return {
      count,
      filterMeta: {
        filterGroup: TerritoryFiltersEnums.pipelineAttributeFilters,
        label: `${count} filters enabled in the Pipeline attributes`,
      },
    };
  },
};

// @TODO:: >>>> we don't need this function anymore
export const getEnabledFiltersCount = (filters?: TerritoryFilters) => {
  if (!filters) return { totalCount: 0, filtersMeta: [] };
  const enabledFilters = Object.values(filterCounterMap).reduce(
    (enabledFilters, counterFunction) => {
      const { count, filterMeta } = typeof counterFunction === "function"
        ? counterFunction(filters)
        : { count: 0, filterMeta: { label: "", filterGroup: "" as TerritoryFiltersEnums } };
      if (count) {
        enabledFilters.totalCount += count ?? 0;
        filterMeta?.filterGroup && enabledFilters.filtersMeta.push(filterMeta);
      }
      return enabledFilters;
    },
    { totalCount: 0, filtersMeta: [] as FilterMeta[] },
  ) as { totalCount: number; filtersMeta: FilterMeta[] };

  return enabledFilters;
};

export const calculateRowsCapacity = (height: number) => {
  const tableRowHeight = 68;
  const padding = height > 750 ? 3 : 2;
  return Math.floor(height / tableRowHeight) - padding;
};

export const getColumnsWithNames = () => {
  const columnsWithNames = Object.values(TerritoryTableColumnsAccessors)
    .map((accessor) => ({
      name: TerritoryTableColumnTitles[accessor],
      accessor,
    }))
    .filter(Boolean) as { name: string; accessor: string }[];
  return columnsWithNames;
};

export const getAllColumns = (account: Account, isTerritoryScreen?: boolean): Column[] => {
  const allCollumns = getColumnsWithNames();
  if (!isTerritoryScreen) {
    account?.products?.forEach((product) => {
      allCollumns.push({
        name: product.productName,
        accessor: product.productName as typeof TerritoryTableColumnsAccessors.account,
      });
    });
  }
  return allCollumns;
};

export const getDynamicAllColumns = (accounts: any[]) => {
  const set = new Set<string>();
  for (const account of accounts) {
    Object.keys(account).forEach((key) => set.add(key));
  }
  return Array.from(set).map((key) => ({ name: spaceAndCapitalize(key), accessor: key }));
};

export const getProductSelectors = (account: Account, isTerritoryScreen?: boolean) => {
  // set the default shown columns
  const productSelectors: any = [];
  if (isTerritoryScreen) return productSelectors;
  account?.products?.forEach((product) => {
    productSelectors.push({
      name: product.productName,
      accessor: product.productName as typeof TerritoryTableColumnsAccessors.account,
    });
  });
  return productSelectors;
};

export const addProductSelectors = (account: Account, columnsMap: { [key: string]: boolean }) => {
  account?.products?.forEach((product) => {
    columnsMap[product.productName] = true;
  });
  return columnsMap;
};

export const getSimilarWinsText = (label: string, similarWins?: ISimilarWin[]): string => {
  if (!similarWins || !similarWins.length) return "";
  return similarWins.length > 1 ? `${similarWins.length} ${label}` : similarWins[0].accountName;
};

export const findDividerColumns = (visibleColumns: any[]) => {
  const currentWalletShareDivider = visibleColumns.find((column) => {
    const currentDivider = column.Header === "Annual Recurring Revenue"
      ? "Annual Recurring Revenue"
      : column.Header === "Open Opportunities"
        ? "Open Opportunities"
        : column.Header === "Closed Opportunities"
          ? "Closed Opportunities"
          : column.Header === "TAM"
            ? "TAM"
            : null;
    return currentDivider;
  });

  return currentWalletShareDivider;
};

export const sortByDate = (rowA: any, rowB: any, id: string, desc: boolean) => sortByValue(Date.parse(rowA.values[id]), Date.parse(rowB.values[id]), desc);

export const sortByValue = (rowAValue: any, rowBValue: any, desc: boolean) => {
  const aGreaterThanB = rowAValue - rowBValue;

  if (desc) {
    return aGreaterThanB > 0 ? -1 : 1;
  }

  return aGreaterThanB < 0 ? 1 : -1;
};

export enum FocusWidget {
  TerritoryDetails = "territoryDetails",
  AccountDetails = "accountDetails",
}

export const isValidTabFilter = (validColumns: string[], filteredColumn: string) => {
  const isValidColumn = validColumns.includes(filteredColumn);
  return isValidColumn;
};

interface IGetAccountStatusEnumValues {
  trueEnumType: { [key: string]: string };
  falseEnumType: { [key: string]: string };
  keys: string[];
  accountStatus: AccountStatus;
}

export const getAccountStatusEnumValues = ({
  trueEnumType,
  falseEnumType,
  keys,
  accountStatus,
}: IGetAccountStatusEnumValues) => {
  const tooltipValues: { [key: string]: string } = {};
  keys.forEach((key: string) => {
    const isTrue = accountStatus[key as keyof AccountStatus];
    tooltipValues[key] = isTrue ? trueEnumType[key] : falseEnumType[key];
  });
  return tooltipValues;
};

export const getHeadquarterAddress = (headquarter: string) => {
  const arrayfromHQString = headquarter?.split(", ") || [];
  let country: string | null = "";
  let state: string | null = "";
  let city: string | null = "";
  if (arrayfromHQString.length === 1) {
    country = arrayfromHQString[0];
    return { country };
  }
  if (arrayfromHQString.length === 2) {
    [state, country] = arrayfromHQString;
    return { state, country };
  }
  if (arrayfromHQString.length === 3) {
    [city, state, country] = arrayfromHQString;
  }
  return { city, state, country };
};

export function normalizeAddress(locations?: TerritoryFiltersMetaData["headquarters"]) {
  const headquartersList: string[] = [];
  locations?.forEach((location) => {
    const { city, state, country } = location;
    // push only city and state
    const countryOnly = country ? `${country}` : "";
    const stateOnly = state ? `${state}, ` : "";
    const cityOnly = city ? `${city}, ` : "";

    headquartersList.push(`${countryOnly}`);
    headquartersList.push(`${stateOnly}${countryOnly}`);
    headquartersList.push(`${cityOnly}${stateOnly}${countryOnly}`);
  });

  // remove duplicate country/state+country combination
  const uniqueValues = Array.from(new Set(headquartersList));
  return uniqueValues;
}
export const getFilterRangeTooltipText = (minValue: number, maxValue: number) => {
  const formattedMin = numberFormats.millify(minValue);
  const formattedMax = numberFormats.millify(maxValue);
  return `${formattedMin} - ${formattedMax}`;
};

export function deepEqual<T = object>(obj1: T, obj2: T): boolean {
  // Check if both are the same reference
  if (obj1 === obj2) return true;

  // Check if both are objects
  if (typeof obj1 !== 'object' || typeof obj2 !== 'object' || obj1 === null || obj2 === null) return false;

  // Check if both have the same number of properties
  const keys1 = Object.keys(obj1 as T);
  const keys2 = Object.keys(obj2 as T);
  if (keys1.length !== keys2.length) return false;

  // Check each property
  for (let key of keys1) {
    // If any property differs, return false
    if (!keys2.includes(key) || !deepEqual((obj1 as any)[key], (obj2 as any)[key])) return false;
  }

  // All properties match
  return true;
}