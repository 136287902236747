/** todo: abstract the single employee selection here as well */
import type { IExtendedEmployee } from "types/api";

// sets all employee to checked or unchecked
export const updateAllEmployeeStatus = (
  EmployeeHierarchy: IExtendedEmployee[],
  checkStatus: boolean,
) => {
  const updatedList: IExtendedEmployee[] = EmployeeHierarchy.map((employee) => {
    const updatedEmployee = employee;
    employee.checked = checkStatus;
    return {
      ...updatedEmployee,
      subordinates: updateAllEmployeeStatus(employee.subordinates, checkStatus),
    };
  });
  return updatedList;
};
