import { Stack } from "@mui/material";

import { IActivity } from "types/api/activities";

import { InlineListOfPersons, PersonLabel } from "./common";
import {
  ActivityContentInteractions,
  EmailBodyDisplayBox,
  EmailField,
  emailActivityDetailsExtractor,
} from "../common";
import { ActivityCardSize } from "../utils";

interface IEmailContentProps {
  activity: IActivity;
  onActionTrigger?: (action: ActivityContentInteractions, value?: string, name?: string) => void;
  cardSize: ActivityCardSize;
}

export function EmailContent({ activity, onActionTrigger, cardSize }: IEmailContentProps) {
  const { recipient: recipients, sender } = activity;
  const { fromEmail, subject, hasValidSenderName } = emailActivityDetailsExtractor(activity);

  const onFilterByPerson = (personName?: string, personId?: string) => {
    if (onActionTrigger && personName) {
      onActionTrigger(ActivityContentInteractions.FilterByPerson, personName, personId);
    }
  };
  const persons = recipients.map((recipient) => ({ name: recipient.name, email: recipient.address }));

  return (
    <Stack direction="column" sx={{ width: "100%", wordWrap: "break-word" }}>
      <Stack direction="column" gap="5px" sx={{ width: "100%" }}>
        <EmailField
          label="From:"
          // truncate
          valueSx={{
            maxWidth: "70%",
            display: "block",
            textTransform: hasValidSenderName ? "capitalize" : "none",
          }}
          value={(
            <PersonLabel
              onClick={onFilterByPerson}
              value={fromEmail}
              tipDetails={{
                email: sender?.address,
                name: sender?.name,
              }}
            />
          )}
        />
        <EmailField
          label="To:"
          value={(
            <InlineListOfPersons
              cardSize={cardSize}
              persons={persons}
              onPersonClick={onFilterByPerson}
              numberOfItemInList={ActivityCardSize.LARGE !== cardSize ? 1 : 0}
            />
          )}
        />
      </Stack>
      <EmailField
        label="Subject:"
        value={(
          <span style={{ "--lines-max": 3 } as any} className="multi-line-truncate">
            {subject}
          </span>
        )}
        valueSx={{ maxWidth: "70%", display: "block" }}
        isBolder
      />
    </Stack>
  );
}

export default EmailContent;

export function EmailExtraContent({ activity }: { activity: IActivity }) {
  if (!activity.body || !activity.body?.trim?.()) {
    return null;
  }
  return (
    <EmailBodyDisplayBox
      body={activity.body}
      sx={{ bgcolor: "#FAFAFA", p: "8px 12px", borderRadius: "4px" }}
    />
  );
}
