import { Employee } from "types/api";

import { IExtendedEmployee } from "../components/EmployeeHierarchy";

type UpdatedEmployee = IExtendedEmployee & { originalSubordinatesCount?: number };

// applies recursion to get the original subordinates count.
const getOriginalSubordinateCount = (employee: Employee, subordinateLength: number = 0): number => {
  if (!employee.subordinates.length) return 0;
  const subordinates = employee.subordinates.map((subordinate) => getOriginalSubordinateCount(subordinate, employee.subordinates.length));
  const subordianteCount = subordinates.length + subordinates.reduce((acc, curr) => acc + curr, 0);
  // if manager is not duplicated, it means they have no accounts
  if (employee.name !== employee.subordinates[0].name) return subordianteCount - 1;
  return subordianteCount;
};

const updateTreeScope = (
  employee: UpdatedEmployee[],
  scope: { [key: string]: any },
  parent?: UpdatedEmployee,
) => {
  if (!Array.isArray(employee)) return [];
  const updatedList: UpdatedEmployee[] = employee.map((employee) => {
    let updatedEmployee = employee;
    // include manager if one of the subordinates is selected (otherwise the filter will remove the manager);
    if (employee.checked && parent) parent.checked = true;

    if (scope[employee.id]) {
      updatedEmployee = { ...employee, ...scope[employee.id] };
    }
    updatedEmployee.originalSubordinatesCount = getOriginalSubordinateCount(employee);
    return {
      ...updatedEmployee,
      subordinates: updateTreeScope(employee.subordinates, scope, updatedEmployee),
    };
  });
  const updatedScope = updatedList
    .filter((employee) => employee.checked)
    .filter(
      (employee) => employee.subordinates.length > 0 || employee.originalSubordinatesCount === 0,
    );
  return updatedScope;
};

export default updateTreeScope;
