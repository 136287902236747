import { Button, Checkbox, CircularProgress, Grid, MenuItem, Select, Typography } from "@mui/material";
import { Box, Stack, SxProps } from "@mui/system";

import UserFeatureAccessWrapper from "components/UserFeatureAccessWrapper";
import { botsListSet } from "data/bots";
import { FeatureGroupSet } from "data/userPermissionsMap";
import { PersonResult } from "services/aiworkers";

import FinderForm from "./FinderForm";
import { ContactTypeEnum, groupPeopleByAccount, usePersonalFinder } from "./hooks";
import PersonCard from "./PersonCard";
import { ProgressionLoading } from "../shared";
import AccountPeopleCard from "./AccountPeopleCard";
import { useMemo } from "react";
import { groupBy } from "lodash";
import { Account } from "types/api";


interface PersonalFinderProps {
  focusedAccount?: Account
  listSx?: SxProps
};

function PersonalFinder(props: PersonalFinderProps) {
  const { listSx = {}, focusedAccount } = props
  const personalFinderBot = botsListSet.find((bot) => bot.key === "personfinder");
  const {
    find: findPeople,
    result,
    onCancelRequest,
    errorMessage,
    resetErrorMessage,
    loading: isLoading,
    onResetResults,
    selectedPeople,
    onUpdateSelected,
    onSavePeopleToAccounts,
    isSavingPeople,
    contactType,
    onContactSavedAsChange,
  } = usePersonalFinder({ focusedAccount });

  const isAllPeopleSelected = result.every((person) => selectedPeople.includes(person.id));
  const onSelectAllPeople = () => {
    if (isAllPeopleSelected) {
      onUpdateSelected([]);
    } else {
      const allPeopleIds = result.filter(person => !person.isAlreadySaved && person.isInUserScope).map((person) => person.id)
      onUpdateSelected(allPeopleIds);
    }
  }

  const onFind = (accounts: string[], jobs: string[]) => {
    findPeople({ accounts, jobs });
  };

  const resultsNumber = result?.length;

  const groupedPeople = useMemo(() => {
    const groups = groupBy(result, 'accountId')
    return Object.keys(groups).map(key => {
      const account = groups[key];
      return {
        id: account?.[0].accountId,
        name: account?.[0].accountName,
        people: account
      }
    })
  }, [JSON.stringify(result)])



  if (!personalFinderBot) {
    return (
      <Box>
        Bot not found
      </Box>
    );
  }

  return (
    <Box sx={{
      p: "20px", display: "flex", flexDirection: "column", flexGrow: 1,
    }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          padding: "20px",
          backgroundColor: "#ffffff",
          borderRadius: "10px",
          border: "1px solid #e0e0e0",
          marginBottom: "20px",
        }}
      >
        <Box sx={{
          display: "flex", flexDirection: "row", gap: "20px", alignItems: "center",
        }}
        >
          <div style={{
            borderBottom: "1px solid #e0e0e0", paddingBottom: "10px", width: "70px", height: "70px", borderRadius: "50%", backgroundColor: "#f5f5f5", justifyContent: "center", alignItems: "center",
          }}
          >
            <img src={personalFinderBot.image} alt="Personal Finder" style={{ width: "100%" }} />
          </div>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
            <Typography variant="h5">{personalFinderBot.title}</Typography>
            <Typography variant="body1">{personalFinderBot.description}</Typography>
          </Box>
        </Box>
        <FinderForm
          onFind={onFind}
          onResetErrorMessage={resetErrorMessage}
          isLoading={isLoading}
          onCancelRequest={onCancelRequest}
          onResetForm={onResetResults}
          focusedAccount={focusedAccount}
        />
      </Box>
      {isLoading ? (
        <ProgressionLoading searchEntityTitle="People & titles" />
      ) : (
        errorMessage ? (
          <Box sx={{
            padding: "20px", border: "1px solid #e0e0e0", borderRadius: "10px", marginBottom: "20px", backgroundColor: "#fff",
          }}
          >
            <Typography variant="body1">{errorMessage}</Typography>
          </Box>
        )
          : (
            <>
              {Boolean(resultsNumber) && (<Box sx={{
                padding: "20px",
                border: "1px solid #e0e0e0",
                borderRadius: "10px",
                marginBottom: "20px",
                backgroundColor: "#fff",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
              >
                <Stack direction='row' gap='5px' alignItems={'center'} justifyContent={'center'}>
                  <Checkbox checked={isAllPeopleSelected} onChange={onSelectAllPeople} />
                  <Typography variant="body1">
                    Found {resultsNumber} people, {`${selectedPeople.length ? `${selectedPeople.length} selected to be saved` : 'select to save'} to your accounts, as ${contactType}s.`}
                  </Typography>
                </Stack>
                <Stack direction='row' alignItems='center' gap='10px'>
                  <Typography>
                    Save as
                  </Typography>
                  <Select value={contactType} sx={{ height: '36px', width: '110px' }} onChange={(event) => onContactSavedAsChange(event?.target?.value as ContactTypeEnum)}>
                    <MenuItem value={ContactTypeEnum.Lead}>{ContactTypeEnum.Lead}s</MenuItem>
                    <MenuItem value={ContactTypeEnum.Contact}>{ContactTypeEnum.Contact}s</MenuItem>
                  </Select>

                  <Button onClick={onSavePeopleToAccounts} disabled={!selectedPeople.length || isLoading || isSavingPeople} variant="outlined" sx={{ gap: '5px' }}>
                    <span>Save to territory</span>
                    {isSavingPeople && <CircularProgress size={12} color="inherit" />}
                  </Button>
                </Stack>
              </Box>)}
              <Stack direction='column' gap='20px' sx={listSx}>
                {groupedPeople.map((accountPeople: any) => (
                  <AccountPeopleCard
                    key={accountPeople.id}
                    account={accountPeople}
                    selectedPeople={selectedPeople}
                    onSelectPerson={onUpdateSelected}
                  />
                ))}
              </Stack>
            </>
          )
      )}
    </Box>
  );
}

function PersonalFinderWrapper(props: PersonalFinderProps) {
  return (
    <UserFeatureAccessWrapper groupName={FeatureGroupSet.PERSON_FINDER_WORKER}>
      <PersonalFinder {...props} />
    </UserFeatureAccessWrapper>
  );
}

export default PersonalFinderWrapper;
