import dayJS from "dayjs";
import duration from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime";

dayJS.extend(relativeTime);
dayJS.extend(duration);

export const timeFromNow = (date: string) => {
  if (!date) return {};
  const now = new Date().getTime();
  const then = new Date(date).getTime();
  const difference = then - now;
  return {
    difference: dayJS.duration(difference).asDays(),
    date: dayJS(date).fromNow(true),
  };
};

export const formatDateDifference = (timePeriod: string, difference: number) => {
  if (!timePeriod || !difference) return "";
  if (difference > 0) return `in ${timePeriod}`;
  return `${timePeriod} ago`;
};

export const formatRenews = (timePeriod: string, difference: number) => {
  if (!timePeriod || !difference) return "";
  if (difference > 0) return `< ${timePeriod}`;
  return `> ${timePeriod}`;
};
