import { EmailActivityDirection, IActivity } from "types/api/activities";
import { numberFormats } from "utils/numbers";

import { ActivityContentInteractions, emailActivityDetailsExtractor } from "./common";

export interface IActivityStack {
  label: string;
  interaction?: {
    type?: ActivityContentInteractions;
    value?: string;
    name?: string;
  };
}

const textNonceCleanup = (text: string, patterns: string[]) => {
  const nonceRegex = new RegExp(patterns.join("|"), "g");
  return text ? String(text).replace(nonceRegex, "") : "";
};

function contentStackBuilder() {
  const stack = [] as IActivityStack[];

  return {
    push: (label: string, interaction?: string | IActivityStack["interaction"]) => {
      const item = { label } as IActivityStack;
      if (interaction) {
        const isString = typeof interaction === "string";
        item.interaction = {
          type: isString ? ActivityContentInteractions.link : interaction.type,
          value: isString ? interaction : interaction.value,
          name: isString ? undefined : interaction.name,
        } as IActivityStack["interaction"];
      }
      stack.push(item);
    },
    get: () => stack,
    getCleaned: (patterns: string[]) => stack.map((item) => ({ ...item, label: textNonceCleanup(item.label, patterns) })),
  };
}

const withAccountFilterInteraction = (accountId?: string, accountName?: string) => ({
  type: ActivityContentInteractions.FilterByAccount,
  value: accountId,
  name: accountName,
});
const withPersonFilterInteraction = (personId?: string) => ({
  type: ActivityContentInteractions.FilterByPerson,
  value: personId,
});

export function emailActivityContentFormatter(activity: IActivity) {
  const activityStack = contentStackBuilder();
  const isOutbound = activity.direction === EmailActivityDirection.Outbound;
  const isInbound = activity.direction === EmailActivityDirection.Inbound;

  const {
    fromEmail, firstEmailLabel, subject, othersRecipientsLabel,
  } = emailActivityDetailsExtractor(activity);

  activityStack.push(fromEmail || "Unknown Sender", withPersonFilterInteraction(fromEmail));
  if (isOutbound) {
    activityStack.push("from");
    activityStack.push(
      activity.secondParty?.accountName,
      withAccountFilterInteraction(
        activity.secondParty.accountId,
        activity.secondParty?.accountName,
      ),
    );
    activityStack.push("replied to");
    activityStack.push(
      firstEmailLabel || "Unknown Recipient",
      withPersonFilterInteraction(firstEmailLabel),
    );
  }

  if (isInbound) {
    activityStack.push("emailed");
    activityStack.push(
      othersRecipientsLabel?.length
        ? `${firstEmailLabel} +${othersRecipientsLabel?.length}`
        : `${firstEmailLabel || "Unknown Recipient"}`,
      withPersonFilterInteraction(firstEmailLabel),
    );
    activityStack.push("from");
    activityStack.push(
      activity.secondParty?.accountName,
      withAccountFilterInteraction(
        activity.secondParty.accountId,
        activity.secondParty?.accountName,
      ),
    );
  }

  activityStack.push(`${subject || "(No Subject)"}.`);
  const cleanupPatterns = [">>", "Email >>", "Email: <<", "\""];
  return activityStack.getCleaned(cleanupPatterns);
}

export function leadActivityContentFormatter(activity: IActivity) {
  const activityStack = contentStackBuilder();
  activityStack.push(
    activity.secondParty?.personName || "Lead",
    withPersonFilterInteraction(activity?.secondParty?.personName),
  );
  activityStack.push("from");
  activityStack.push(
    `${activity.secondParty?.accountName}.`,
    withAccountFilterInteraction(activity.secondParty.accountId, activity.secondParty?.accountName),
  );
  if (activity?.leadSource) {
    activityStack.push("Lead source is");
    activityStack.push(`${activity?.leadSource || "Unknown Source"}.`);
  }
  return activityStack.get();
}

export function meetingActivityContentFormatter(activity: IActivity) {
  const activityStack = contentStackBuilder();
  activityStack.push("Meeting with");
  activityStack.push(
    `${activity.secondParty?.personName || "Unknown Recipient"},`,
    withPersonFilterInteraction(activity.secondParty?.personName),
  );
  activityStack.push("from");
  activityStack.push(
    activity.secondParty?.accountName,
    withAccountFilterInteraction(activity.secondParty.accountId, activity.secondParty?.accountName),
  );
  activityStack.push("regarding");
  activityStack.push(`${activity.subject || "(No Subject)"}.`);
  const cleanupPatterns = [">>", "Zoom:", "\""];
  return activityStack.getCleaned(cleanupPatterns);
}

export function callActivityContentFormatter(activity: IActivity) {
  const activityStack = contentStackBuilder();
  activityStack.push(
    activity.source?.personName || "Unknown Sender",
    withPersonFilterInteraction(activity.source?.personName),
  );
  activityStack.push("called");
  activityStack.push(
    activity.secondParty?.personName || "Unknown Recipient",
    withPersonFilterInteraction(activity.secondParty.personName),
  );
  activityStack.push("from");
  activityStack.push(
    activity.secondParty?.accountName,
    withAccountFilterInteraction(activity.secondParty.accountId, activity.secondParty?.accountName),
  );
  activityStack.push("regarding");
  activityStack.push(`${activity.subject || "(No Subject)"}.`);
  const cleanupPatterns = [">>", "Zoom:", "\""];
  return activityStack.getCleaned(cleanupPatterns);
}

export function winWireActivityContentFormatter(activity: IActivity) {
  const activityStack = contentStackBuilder();
  activityStack.push(
    activity.source?.personName || "Unknown Sender",
    withPersonFilterInteraction(activity.source?.personName),
  );
  activityStack.push("just closed a deal with");
  activityStack.push(
    activity.secondParty?.accountName,
    withAccountFilterInteraction(activity.secondParty.accountId, activity.secondParty?.accountName),
  );
  if (activity.amount) {
    activityStack.push("for");
    activityStack.push(`$${numberFormats.millify(activity.amount)}.` || "Unknown Amount");
  }
  const cleanupPatterns = [">>", "Zoom:", "\""];
  return activityStack.getCleaned(cleanupPatterns);
}

export function intentActivityContentFormatter(activity: IActivity) {
  const activityStack = contentStackBuilder();
  activityStack.push(
    activity.secondParty?.accountName,
    withAccountFilterInteraction(activity.secondParty.accountId, activity.secondParty?.accountName),
  );
  activityStack.push("Intent Score");
  const {
    oldBuyingStage, newBuyingStage, oldIntentScore, newIntentScore,
  } = activity;
  const hasValidScore = oldIntentScore && newIntentScore;
  if (hasValidScore) {
    const changeDirection = hasValidScore
      ? newIntentScore > oldIntentScore
        ? "increased"
        : "decreased"
      : "";
    const textIcon = changeDirection === "increased" ? "↑" : "↓";
    activityStack.push(changeDirection);
    activityStack.push(`to ${newIntentScore} ${textIcon},`);
  }
  const hasIntentChanged = newBuyingStage && oldBuyingStage && newBuyingStage !== oldBuyingStage;
  const intentStage = newBuyingStage || oldBuyingStage;

  if (hasIntentChanged) {
    activityStack.push(`Intent buying stage changed from ${oldBuyingStage} to ${newBuyingStage}.`);
  } else if (intentStage) {
    activityStack.push(`Current intent stage ${intentStage}.`);
  } else {
    activityStack.push("Intent Score has changed.");
  }
  return activityStack.get();
}

export function webActivityContentFormatter(activity: IActivity) {
  const activityStack = contentStackBuilder();
  const personName = activity.secondParty?.personName || "Unknown";
  activityStack.push(personName, withPersonFilterInteraction(personName));
  activityStack.push(`${activity.subject || ""}.`);
  return activityStack.get();
}

export function thirdPartyDownloadActivityContentFormatter(activity: IActivity) {
  const activityStack = contentStackBuilder();
  const personName = activity.secondParty?.personName || "Unknown";
  activityStack.push(personName, withPersonFilterInteraction(personName));
  if (activity.subject) {
    activityStack.push("downloaded");
    activityStack.push(`${activity.subject || ""}.`);
  }
  return activityStack.get();
}

export function eventActivityContentFormatter(activity: IActivity) {
  const activityStack = contentStackBuilder();
  const personName = activity.secondParty?.personName || "Unknown";
  activityStack.push(personName, withPersonFilterInteraction(personName));
  activityStack.push(`${activity.subject || ""}.`);
  return activityStack.get();
}

export function marketingEmailActivityContentFormatter(activity: IActivity) {
  const activityStack = contentStackBuilder();
  const personName = activity.secondParty?.personName || "Unknown";
  activityStack.push(personName, withPersonFilterInteraction(personName));
  activityStack.push(`${activity.subject || ""}.`);
  return activityStack.get();
}
