import type { User } from "@auth0/auth0-spa-js";
import setupLogRocketReact from "logrocket-react";

import config from "config/envConfig";
import appFeatures from "config/featureFlagsConfig";
import { delay } from "utils/general";

const { logRocketId } = config;

const loadLogRocket = async () => import("logrocket").then((module) => module.default);
// @todo: need to resolve the type of the following
let LogRocket: any;

export const initiateLogRocket = async (cb?: CallableFunction) => {
  // if not enabled, return
  if (!config.isDevEnvironment || appFeatures.LogRocketEventErrorTracking) {
    // if not initiated and loaded on previous calls, it will
    if (!LogRocket) {
      LogRocket = await loadLogRocket();
      LogRocket.init(logRocketId);
      setupLogRocketReact(LogRocket);
    }
    cb?.(LogRocket)
  }
};

export const onIdentifyUserLoginEvent = async (user: Partial<User>): Promise<any> => {
  const {
    sub: userId, name, email, ...restUserFields
  } = user;
  if (!email) return;
  // if not initiated and loaded on previous calls, it will
  if (!LogRocket) {
    await initiateLogRocket();
    // waiting to load the library and then retrigger the function again with the preserved user passed
    await delay(400);
    return onIdentifyUserLoginEvent(user);
  }

  LogRocket.identify(email, {
    name: name!,
    email,
    userId: userId!,
    ...restUserFields,
  });
};

export const captureExceptionWithLogRocket = (error: Error) => {
  initiateLogRocket((LogRocket: any) => {
    if (error.name !== 'CanceledError') {
      LogRocket.captureException?.(error);
      console.error(error)
    }
  })
};
