import { Stack } from "@mui/material";
import { useMemo } from "react";

import { IconButton } from "./common";
import { getAvailablePages } from "./utils";

interface SpreadedPagesNumbersProps {
  currentPage: number;
  totalPages: number;
  onPageChange: (page: number) => void;
}

const SpreadedPagesNumbers: React.FC<SpreadedPagesNumbersProps> = ({
  currentPage,
  totalPages,
  onPageChange,
}) => {
  const availablePages = useMemo(
    () => getAvailablePages(currentPage, totalPages),
    [currentPage, totalPages],
  );

  return (
    <Stack direction="row" gap="6px">
      {availablePages.map((page: number | string, index: number) => (
        <IconButton
          key={index}
          isActive={page === currentPage}
          disabled={page === "..."}
          sx={{ width: "auto", maxWidth: "50px", p: "6px 12px" }}
          onClick={() => typeof page === "number" && onPageChange(page)}
        >
          {page}
        </IconButton>
      ))}
    </Stack>
  );
};

export default SpreadedPagesNumbers;
