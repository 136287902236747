import { SelectOption } from "@mui/base";
import {
  MenuItem, Select as MuiSelect, SelectChangeEvent, SxProps,
} from "@mui/material";

interface SelectProps {
  options: SelectOption<string>[];
  value: SelectOption<string>;
  onChange: (e: SelectChangeEvent) => void;
  name?: string;
  labelId?: string;
  sx?: SxProps;
}

function Select({
  options, value, onChange, name, labelId, sx,
}: SelectProps) {
  return (
    <MuiSelect
      name={name}
      onChange={onChange}
      labelId={labelId}
      sx={sx}
      value={value?.value || options?.[0]?.value}
    >
      {options?.map((option: SelectOption<string>, index: number) => (
        <MenuItem key={`#${index}_${option.value}`} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </MuiSelect>
  );
}

export default Select;
