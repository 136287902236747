import {
  ArrowBackOutlined, BoltOutlined, IntegrationInstructionsOutlined, ManageAccounts, ManageSearchOutlined,
} from "@mui/icons-material";
import {
  Box, List, ListItem, Tooltip,
} from "@mui/material";
import { useCallback, useMemo } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import {
  AppStoreIconComponent, ChatBotIcon, HomeIconComponent, SalesDashLogoMiniComponent, SettingsIcon,
} from "assets/icons";

import UserFeatureAccessWrapper from "components/UserFeatureAccessWrapper";
import { FeatureGroupSet } from "data/userPermissionsMap";

const listItemStyle = {
  background: "#00223C",
  height: "44px",
  cursor: "pointer",
  margin: "8px 0",
  borderRight: "2px solid transparent",
  transition: "background 0.3s ease-in-out",
  color: "#93AFBF",
  "&:hover": {
    color: "#FFF",
    background: "#3C647E",
    borderColor: "currentColor",
  },
};

const leftDrawerStyle = {
  backgroundColor: "#00223C",
  width: 60,
  flexShrink: 0,
  flex: 1,
  color: "#AAAAAA",
  justifyContent: "center",
  "& .MuiDrawer-paper": {
    overflow: "hidden",
    padding: "13px 0px",
    backgroundColor: "#00223C",
    alignItems: "center",
    // width: 60,
    boxSizing: "border-box",
  },
};

// eslint-disable-next-line no-shadow
enum AppPages {
  home = "/",
  whitespace = "/whitespace",
  territory = "/territory",
  analytics = "/analytics",
  contacts = "/contacts",
  revic = "/revic",
  settings = "/settings",
  aiWorkers = "/aiworkers",
}

enum SettingsPageSubRoutes {
  integrations = "/settings/integrations",
  users = "/settings/users",
  aiWorkers = "/settings/workers",
}

const PageLinks = {
  home: AppPages.home,
  whitespace: AppPages.whitespace,
  analytics: AppPages.analytics,
  territory: AppPages.territory,
  contacts: AppPages.contacts,
  revic: AppPages.revic,
  helpLink: "mailto:help@salesdash.ai",
  settings: AppPages.settings,
  aiWorkers: AppPages.aiWorkers,
};

const SettingsPageLinks = {
  integrations: SettingsPageSubRoutes.integrations,
  usersManagement: SettingsPageSubRoutes.users,
  aiWorkers: SettingsPageSubRoutes.aiWorkers,
};

export function SideBar() {
  const navigate = useNavigate();
  const { pathname: currentPathName } = useLocation();
  const isOnboarding = currentPathName.includes("onboarding");

  const onGoTo = (path: string, external?: boolean) => () => {
    if (!external) return navigate(path);
    return window.open(path, "_blank");
  };

  const getActiveStyle = useCallback((path: AppPages | SettingsPageSubRoutes) => {
    const isMatch = currentPathName === path;
    return isMatch ? { color: "#FFF", background: "#3C647E", borderColor: "currentColor" } : {};
  }, [currentPathName]);

  const isSettingsPage = useMemo(() => currentPathName.includes(AppPages.settings), [currentPathName]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      sx={{ ...leftDrawerStyle, justifyContent: "flex-start" }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          justifyContent: "flex-end",
          height: "70px",
        }}
      >
        <Link to="/">
          <SalesDashLogoMiniComponent />
        </Link>
      </Box>
      {!isOnboarding && (
        <Box flex={1} display="flex" flexDirection="column" justifyContent="space-between">
          {!isSettingsPage ? (
            <List sx={{ mt: "16px", flex: 1 }}>
              <Tooltip title="Home" placement="right">
                <UserFeatureAccessWrapper groupName={FeatureGroupSet.VIEW_HOME_PAGE}>
                  <ListItem
                    sx={{ ...listItemStyle, ...getActiveStyle(AppPages.home) }}
                    onClick={onGoTo(PageLinks.home)}
                  >
                    <HomeIconComponent />
                  </ListItem>
                </UserFeatureAccessWrapper>
              </Tooltip>
              <UserFeatureAccessWrapper groupName={FeatureGroupSet.REVIC_CHAT}>
                <Tooltip title="Chat with revic" placement="right">
                  <ListItem
                    sx={{ ...listItemStyle, ...getActiveStyle(AppPages.revic) }}
                    onClick={onGoTo(PageLinks.revic)}
                  >
                    <ChatBotIcon style={{ height: "23px" }} />
                  </ListItem>
                </Tooltip>
              </UserFeatureAccessWrapper>

              <UserFeatureAccessWrapper groupName={FeatureGroupSet.VIEW_AI_WORKERS_PAGE}>
                <Tooltip title="AI Workers" placement="right">
                  <ListItem
                    sx={{ ...listItemStyle, ...getActiveStyle(AppPages.aiWorkers) }}
                    onClick={onGoTo(PageLinks.aiWorkers)}
                  >
                    <BoltOutlined />
                  </ListItem>
                </Tooltip>
              </UserFeatureAccessWrapper>
              <UserFeatureAccessWrapper groupName={FeatureGroupSet.TERRITORY_PAGE}>
                <Tooltip title="Territory" placement="right">
                  <ListItem
                    sx={{ ...listItemStyle, ...getActiveStyle(AppPages.territory) }}
                    onClick={onGoTo(PageLinks.territory)}
                  >
                    <AppStoreIconComponent />
                  </ListItem>
                </Tooltip>
              </UserFeatureAccessWrapper>
            </List>
          ) : (
            <List sx={{ mt: "16px", flex: 1 }}>
              <UserFeatureAccessWrapper groupName={FeatureGroupSet.MANAGE_USERS}>
                <Tooltip title="Users management" placement="right">
                  <ListItem
                    sx={{ ...listItemStyle, ...getActiveStyle(SettingsPageLinks.usersManagement) }}
                    onClick={onGoTo(SettingsPageLinks.usersManagement)}
                  >
                    <ManageAccounts />
                  </ListItem>
                </Tooltip>
              </UserFeatureAccessWrapper>
              <UserFeatureAccessWrapper groupName={FeatureGroupSet.CONNECT_INTEGRATIONS}>
                <Tooltip title="Connectors" placement="right">
                  <ListItem
                    sx={{ ...listItemStyle, ...getActiveStyle(SettingsPageLinks.integrations) }}
                    onClick={onGoTo(SettingsPageLinks.integrations)}
                  >
                    <IntegrationInstructionsOutlined />
                  </ListItem>
                </Tooltip>
              </UserFeatureAccessWrapper>
              <UserFeatureAccessWrapper groupName={FeatureGroupSet.VIEW_AI_WORKERS_CONFIGURATION_PAGE}>
                <Tooltip title="AI workers" placement="right">
                  <ListItem
                    sx={{ ...listItemStyle, ...getActiveStyle(SettingsPageLinks.aiWorkers) }}
                    onClick={onGoTo(SettingsPageLinks.aiWorkers)}
                  >
                    <ManageSearchOutlined />
                  </ListItem>
                </Tooltip>
              </UserFeatureAccessWrapper>
            </List>
          )}
          <List>
            <UserFeatureAccessWrapper groupName={FeatureGroupSet.VIEW_ADMIN_SETTINGS}>
              <Tooltip title={isSettingsPage ? "Back to Territory" : "Settings"} placement="right">
                <ListItem
                  sx={{ ...listItemStyle, ...getActiveStyle(AppPages.settings) }}
                  onClick={isSettingsPage ? onGoTo(PageLinks.territory) : onGoTo(SettingsPageLinks.usersManagement)}
                >
                  {
                    isSettingsPage ? (
                      <ArrowBackOutlined />
                    ) : (
                      <SettingsIcon />
                    )
                  }
                </ListItem>
              </Tooltip>
            </UserFeatureAccessWrapper>
          </List>
        </Box>
      )}
    </Box>
  );
}

export default SideBar;
