import { useCallback } from "react";
import { OrderDirectionEnum, WhitespaceAccountOrderByField } from "types/account";
import { TerritoryTableColumnsAccessors } from "types/api";
import { TerritoryFilters } from "types/api/territoryFilters";

export const supportedColumnsSortingFieldsMap = {
  [TerritoryTableColumnsAccessors.account]: WhitespaceAccountOrderByField.name,
  [TerritoryTableColumnsAccessors.whitespace]: WhitespaceAccountOrderByField.uncapturedWhitespaceAmount,
  [TerritoryTableColumnsAccessors.ownerName]: WhitespaceAccountOrderByField.ownerName,
  [TerritoryTableColumnsAccessors.lastCustomerSignal]: WhitespaceAccountOrderByField.lastCustomerSignal,
  [TerritoryTableColumnsAccessors.lastSalesActivity]: WhitespaceAccountOrderByField.lastSalesActivity,
  [TerritoryTableColumnsAccessors.nextRenewalDate]: WhitespaceAccountOrderByField.nextRenewalDate,
  [TerritoryTableColumnsAccessors.employees]: WhitespaceAccountOrderByField.employeeCount,
  [TerritoryTableColumnsAccessors.annualRevenue]: WhitespaceAccountOrderByField.annualRevenue,
  [TerritoryTableColumnsAccessors.industry]: WhitespaceAccountOrderByField.industry,
};

export const columnsThatSupportsSorting = Object.keys(supportedColumnsSortingFieldsMap);
type SortingState = {
  orderBy?: TerritoryFilters["orderBy"];
  orderDirection?: TerritoryFilters["orderDirection"];
};

interface IAccountColumnSortBy {
  onSortColumn: (columnId: string, direction?: OrderDirectionEnum) => Promise<void>;
  sortingState: SortingState;
  isLoading?: boolean;
}

const useAccountColumnsSortBy = ({
  onSortColumn,
  sortingState,
  isLoading: isLoadingAccounts,
}: IAccountColumnSortBy) => {

  const onColumnSort = async (column: string, orderDirection: OrderDirectionEnum) => {
    onSortColumn(column, orderDirection);
  };

  const sortingPlugin = useCallback((accountColumn: any) => {

    accountColumn.onSortingChange = async (columnAccessor: TerritoryTableColumnsAccessors, sortDirection: OrderDirectionEnum) => {
      return onColumnSort(columnAccessor, sortDirection);
    };

    accountColumn.hasCustomSorting = true
    // @ts-ignore
    const isColumnSorted = accountColumn === sortingState?.orderBy;
    const sortDirection = sortingState?.orderDirection === OrderDirectionEnum.DESC;

    accountColumn.sortingState = {
      isLoading: isLoadingAccounts && isColumnSorted,
      isSorted: isColumnSorted,
      isSortedDesc: isColumnSorted && sortDirection,
    };
    return accountColumn;
  }, [isLoadingAccounts, JSON.stringify(sortingState)]
  )


  return {
    onColumnSort,
    sortingPlugin,
  };
};

export default useAccountColumnsSortBy;
