import envConfig from "config/envConfig";
import { CopilotResponse } from "types/api";
import { AxiosNode } from "utils/axios";

// gpt/user-query

export type copilotQuestionPayload = {
  query: string;
  messageTs?: string;
  clarifier?: { selectedOption: string };
};

export enum RevicFeedbackReaction {
  NEGATIVE = "negative",
  POSITIVE = "positive",
}

const copilotAPI = {
  getCopilotResponse: async (
    payload: copilotQuestionPayload,
    options?: Parameters<typeof AxiosNode.post>[2],
  ): Promise<CopilotResponse> => {
    try {
      // payload.userIds = payload.userIds?.length ? payload.userIds : undefined;
      const { data } = await AxiosNode.post<CopilotResponse>("/gpt/user-query", payload, options);
      return data;
    } catch (err) {
      console.log(err);
      // @todo: figure out the correct return type from the backend and reflect it here.
      return {} as CopilotResponse;
    }
  },
  feedbackSubmit: async (
    payload: { messageId: string, reaction: RevicFeedbackReaction },
    options?: Parameters<typeof AxiosNode.post>[2],
  ): Promise<void> => {
    try {
      await AxiosNode.post<void>("/gpt/log-user-slack-reaction", {
        reaction: payload.reaction,
        messageId: payload.messageId,
      }, {
        ...options,
        headers: {
          api_key: envConfig.RevicAPIKey,
        },
      });
    } catch (err) {
      console.log(err);
    }
  },
};

export default copilotAPI;
