import { Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

import { PipelineSignalBadge } from "components/AccountTables";
import useTerritoryAccounts from "services/shooks/territory";
import useTerritoryFilters from "services/shooks/territoryFilters";
import { PipelineSignalEnums } from "types/api/territoryFilters";

import { delay } from "utils/general";
import { numberFormats } from "utils/numbers";

import { SkeletonMask, WidgetBox } from "./common";

function AccountQuality() {
  const { actions, isLoading } = useTerritoryFilters();
  const { selectedUserIds } = useTerritoryAccounts(({ state }) => state);
  const navigate = useNavigate();
  const [lowCount, setLowCount] = useState(0);
  const [mediumCount, setMediumCount] = useState(0);
  const [strongCount, setStrongCount] = useState(0);

  const onSignalChange = async (signal: PipelineSignalEnums) => {
    navigate("/territory?filters=true", {
      state: { filters: { rank: signal } },
    });
  };

  const getUpdates = async () => {
    const [lowCount, mediumCount, strongCount] = await actions.getAccountQualityStats({
      userIds: selectedUserIds,
    });
    setLowCount(lowCount);
    setMediumCount(mediumCount);
    setStrongCount(strongCount);
  };

  useEffect(() => {
    // initialize the counts of each account Fit category.
    getUpdates();
  }, [JSON.stringify(selectedUserIds)]);

  return (
    <WidgetBox title="Account Quality">
      <SkeletonMask loading={isLoading}>
        <Stack
          onClick={() => onSignalChange(PipelineSignalEnums.Strong)}
          alignItems="center"
          justifyContent="space-between"
          direction="row"
          sx={{
            fontSize: "18px",
            padding: "3px 8px",
            transition: "background .3s ease",
            cursor: "pointer",
            "&:hover": {
              background: "#E5EFF5",
              borderRadius: "5px",
              transition: "background .3s ease",
            },
            gap: "10px",
          }}
        >
          <PipelineSignalBadge noBorder signal={PipelineSignalEnums.Strong} />
          <Typography
            fontSize="18px"
            variant="caption"
            component="div"
            color="#000"
          >
            {numberFormats.millify(strongCount)}
          </Typography>
        </Stack>
        <Stack
          onClick={() => onSignalChange(PipelineSignalEnums.Medium)}
          alignItems="center"
          justifyContent="space-between"
          direction="row"
          sx={{
            fontSize: "18px",
            padding: "3px 8px",
            cursor: "pointer",
            "&:hover": {
              background: "#E5EFF5",
              borderRadius: "5px",
            },
            gap: "10px",
          }}
        >
          <PipelineSignalBadge noBorder signal={PipelineSignalEnums.Medium} />
          <Typography
            fontSize="18px"
            variant="caption"
            component="div"
            color="#000"
          >
            {numberFormats.millify(mediumCount)}
          </Typography>
        </Stack>
        <Stack
          onClick={() => onSignalChange(PipelineSignalEnums.Low)}
          alignItems="center"
          justifyContent="space-between"
          direction="row"
          sx={{
            fontSize: "18px",
            padding: "3px 8px",
            cursor: "pointer",
            "&:hover": {
              background: "#E5EFF5",
              borderRadius: "5px",
            },
            gap: "10px",
          }}
        >
          <PipelineSignalBadge noBorder signal={PipelineSignalEnums.Low} />
          <Typography
            fontSize="18px"
            variant="caption"
            component="div"
            color="#000"
          >
            {numberFormats.millify(lowCount)}
          </Typography>
        </Stack>
      </SkeletonMask>
    </WidgetBox>
  );
}

export default AccountQuality;
