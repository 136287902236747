import { Box, Stack } from "@mui/material";

import { BarChart } from "pages/Home/components/Widgets/components";
import { IActivity, IActivitySource } from "types/api/activities";

import { extractActivityMetrics } from "./utils";
import { ActivityCard } from "../ActivityCard";
import { EmailField } from "../common";

interface IDebuggingActivityCard {
  activities: IActivity[];
}
interface FieldMetricProps {
  fieldKeyName?: string;
  propValue: { score?: { total: number; valid: number }; value: any[] };
}
function FieldMetric({ fieldKeyName, propValue }: FieldMetricProps) {
  const { score, value, ...rest } = propValue;
  if (!score && !value && Object.keys(rest).length) {
    return (
      <EmailField
        // label={<Box sx={{ fontWeight: 'bold' }}>{fieldKeyName}</Box>}
        value={(
          <Box sx={{
            pl: "20px", display: "flex", flexDirection: "column", gap: "5px",
          }}
          >
            {Object.entries(rest).map(([key, value]) => (
              <FieldMetric
                key={key}
                fieldKeyName={key}
                propValue={value as FieldMetricProps["propValue"]}
              />
            ))}
          </Box>
        )}
      />
    );
  }

  const validValuesPercent = ((score?.valid || 0) / (score?.total || 0)) * 100;
  const items = [
    //  @note: subtracting lightgreen from green to get the value of the yellow bar to only show the difference
    {
      value: score?.valid || 0,
      color: "#358700",
      label: validValuesPercent
        ? `${validValuesPercent.toFixed()}% (${Math.round(score?.valid || 0) || 0} out of ${Math.round(score?.total || 0)}) `
        : `${validValuesPercent}`,
    },
  ];
  const sampleItems = value?.slice?.(0, 5);
  return (
    <EmailField
      valueSx={{ width: "320px" }}
      label={<Box sx={{ width: "120px" }}>{fieldKeyName}</Box>}
      tip={(
        <Stack direction="column">
          <span>
            {fieldKeyName}
            {" "}
            is available
            {validValuesPercent?.toFixed?.()}
            %
          </span>
          {sampleItems && (
            <Stack direction="column">
              {sampleItems.map((sampleItem) => (
                <span key={sampleItem}>{sampleItem}</span>
              ))}
            </Stack>
          )}
        </Stack>
      )}
      value={(
        <Box sx={{ minWidth: "200px", flex: 1 }}>
          <BarChart items={items} />
        </Box>
      )}
    />
  );
}

function DebuggingActivityCardBox(props: IDebuggingActivityCard) {
  // eslint-disable-next-line
  const { recipient, ...activitiesMetrics } = extractActivityMetrics(props.activities);
  // const renderTypeIcon = activitiesMetrics?.secondParty.email?.score?.valid
  return (
    <ActivityCard
      title="Activities validity percentage (ac.debugger.v1)"
      createdAt=""
      source={
        {
          tool: "s",
        } as IActivitySource
      }
      activity={{} as unknown as IActivity}
      type={1}
      expandableInfoRender={(
        <FieldMetric
          fieldKeyName="Activities"
          propValue={activitiesMetrics as unknown as FieldMetricProps["propValue"]}
        />
      )}
      expandableInfoHeight={500}
    >
      Expand the activity to see the details
    </ActivityCard>
  );
}

export default DebuggingActivityCardBox;
