import { useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { create } from "zustand";

interface RedirectState {
  initialUrl: string | null;
  setInitialUrl: (url: string) => void;
  clearInitialUrl: () => void;
}

const useRedirectStore = create<RedirectState>((set) => ({
  initialUrl: sessionStorage.getItem("initialUrl"),
  setInitialUrl: (url) => {
    sessionStorage.setItem("initialUrl", url);
    set({ initialUrl: url });
  },
  clearInitialUrl: () => {
    sessionStorage.removeItem("initialUrl");
    set({ initialUrl: null });
  },
}));

const useMonitorAndRedirect = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { initialUrl, setInitialUrl, clearInitialUrl } = useRedirectStore();
  const isRedirecting = useRef(false);

  useEffect(() => {
    const { pathname, search } = location;

    if (!initialUrl) {
      const url = `${pathname}${search}`;
      setInitialUrl(url);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const reroute = () => {
    if (initialUrl && !isRedirecting.current) {
      isRedirecting.current = true;
      navigate(initialUrl);
      clearInitialUrl();
    }
  };

  return { reroute };
};

export default useMonitorAndRedirect;
