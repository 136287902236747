import { Stack } from "@mui/material";

import { IActivity } from "types/api/activities";

import { InlineListOfPersons } from "./common";
import { ActivityContentInteractions, EmailField } from "../common";
import { ActivityCardSize } from "../utils";

interface IMilestoneContentProp {
  activity: IActivity;
  onActionTrigger?: (action: ActivityContentInteractions, value?: string, name?: string) => void;
  cardSize: ActivityCardSize;
}
// @todo: move to utils or something
const getActivityParticipants = (activity: IActivity) => {
  const participants = [
    {
      name: activity?.secondParty?.personName,
      email: activity?.secondParty?.email,
    },
  ];
  return participants;
};

export function MilestoneContent({
  activity,
  onActionTrigger,
  cardSize,
}: IMilestoneContentProp) {
  const participants = getActivityParticipants(activity);

  const onFilterByPerson = (personName?: string, personId?: string) => {
    if (onActionTrigger && personName) {
      onActionTrigger(ActivityContentInteractions.FilterByPerson, personName, personId);
    }
  };
  return (
    <Stack direction="column" sx={{ width: "100%", wordWrap: "break-word" }}>
      <Stack direction="column" sx={{ width: "100%" }}>
        <EmailField
          label="Contact:"
          valueSx={{ textTransform: "capitalize" }}
          value={(
            // keeping this despite having one contact only for consistency with other activities + the custom tooltip
            <InlineListOfPersons
              cardSize={cardSize}
              persons={participants}
              onPersonClick={onFilterByPerson}
            />
          )}
        />
        <EmailField
          label="Subject:"
          value={(
            <span style={{ "--lines-max": 3 } as any} className="multi-line-truncate">
              {activity.subject}
            </span>
          )}
          valueSx={{ maxWidth: "70%", display: "block" }}
          isBolder
        />
      </Stack>
    </Stack>
  );
}

export default MilestoneContent;
