import {
  TableBody as MuiTableBody, Skeleton, SxProps, TableCell, TableRow,
} from "@mui/material";
import { ReactNode, useMemo } from "react";
import type { HeaderGroup } from "react-table";

import { tableDefaultFilterComponents } from "./table.filters";

interface TBodyProps {
  rows: any;
  prepareRow: any;
  loading: boolean;
  tableBodyProps: any;
  onRowClick?: (e: MouseEvent, row: any) => void;
  headerGroups: HeaderGroup[];
  showFiltersRow?: boolean;
  noDataMessage?: string;
  sx?: any;
  cellSX?: SxProps;
  memoDeps?: any;
  loadingSkeletonOptions?: {
    rowsCount?: number;
    height?: number | string;
  };
}

// this is not working as intended.
const MemoizedRow = ({ dataDep, children }: { children: ReactNode; dataDep: string }) => useMemo(
  () => <>{children}</>,
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [dataDep],
);

function getColumnsNamesFromHeaderGroups(headerGroups: HeaderGroup[]) {
  const columnsNames: string[] = [];
  headerGroups.forEach((headerGroup) => {
    headerGroup.headers.forEach((column: any) => {
      columnsNames.push(column.id);
    });
  });
  return columnsNames;
}

function TableBody(props: TBodyProps) {
  const {
    tableBodyProps,
    rows,
    loadingSkeletonOptions = {},
    prepareRow,
    loading,
    cellSX = {},
  } = props;
  const {
    onRowClick, noDataMessage, headerGroups, showFiltersRow, memoDeps, sx,
  } = props;
  const { rowsCount: skeltonRowsCount = 5, height: skeltonRowHeight = "100%" } = loadingSkeletonOptions;
  const visibleColumns = getColumnsNamesFromHeaderGroups(headerGroups);
  return (
    <MuiTableBody {...tableBodyProps} sx={{ height: "33px", background: "#fff" }}>
      {showFiltersRow
        && headerGroups?.map((group: any, index: number) => {
          const headerGroupProps = group.getHeaderGroupProps();
          return (
            <TableRow
              key={`table-column-filter-${index}`}
              {...headerGroupProps}
              sx={{ padding: "10px" }}
            >
              {group.headers?.map((column: any) => {
                const columnHeaderProps = column.getHeaderProps([
                  { className: column.className },
                ]);
                const DefaultFilter = tableDefaultFilterComponents.get(column.filterType);
                const renderFilter = column.Filter ? (
                  column.render("Filter")
                ) : DefaultFilter ? (
                  <DefaultFilter {...column.filterProps} />
                ) : null;
                const hasBorderRight = typeof column.hasBorderRight === "function"
                  ? column.hasBorderRight(visibleColumns)
                  : column.hasBorderRight;
                // @todo: sx should be customizable in the future.
                return (
                  <TableCell
                    key={column.accessor}
                    {...columnHeaderProps}
                    sx={{
                      borderRight: hasBorderRight
                        ? "2px solid #757575"
                        : "1px solid #E6E6E6 !important",
                      borderLeft: column.hasBorderLeft
                        ? "2px solid #757575"
                        : "1px solid #E6E6E6",
                      marginRight: hasBorderRight ? "20px" : "none",
                      color: "#aaa",
                      padding: "16px",
                      fontSize: "12px",
                      ...cellSX,
                    }}
                  >
                    {renderFilter}
                  </TableCell>
                );
              })}
            </TableRow>
          );
        })}
      {!loading
        && rows?.map((row: any, index: number) => {
          prepareRow(row);
          const rowProps = row.getRowProps();

          return (
            <MemoizedRow
              key={`${index}_${row.original?.id}`}
              dataDep={JSON.stringify({ row: row.original, visibleColumns, ...memoDeps })}
            >
              <TableRow
                onClick={(e: MouseEvent) => {
                  if (onRowClick) onRowClick(row.original, e);
                }}
                {...rowProps}
                // edit table cell style/props
                sx={{
                  bgcolor: "inherit",
                  padding: 0,
                  "& .MuiTableCell-root": {
                    padding: sx?.padding || "0 10px",
                    // todo: this should be coming from props.
                    background: `${row.original?.selected ? "#EEF9FF" : "inherit"}`,
                    // width: "1%", // this change breaks the table, you can pass the width from the column definition.
                  },
                  "& .collapse-column": {
                    width: "0.0000000001%",
                  },
                }}
              >
                {row.cells?.map((cell: any, i: number) => {
                  let bgcolor = "inherit";
                  if (cell.isGrouped) bgcolor = "success.lighter";
                  if (cell.isAggregated) bgcolor = "warning.lighter";
                  if (cell.isPlaceholder) bgcolor = "error.lighter";
                  if (cell.isPlaceholder) bgcolor = "error.lighter";

                  const cellProps = cell.getCellProps([{ className: cell.column.className }]);
                  const hasBorderRight = typeof cell.column.hasBorderRight === "function"
                    ? cell.column.hasBorderRight(visibleColumns)
                    : cell.column.hasBorderRight;
                  return (
                    <TableCell
                      {...cellProps}
                      onClick={(e: MouseEvent) => cell.column.onClick?.(e, row.original)}
                      sx={{
                        bgcolor,
                        borderLeft: cell.column.borderLeft || "none",
                        boxShadow: hasBorderRight ? "rgba(0, 0, 0, 0.01) 0px 10px 5px 0px" : "none",
                        ...cellSX,
                      }}
                    >
                      {cell.render("Cell")}
                    </TableCell>
                  );
                })}
              </TableRow>
            </MemoizedRow>
          );
        })}
      {!loading && rows?.length === 0 && (
        <TableRow>
          <TableCell
            sx={{
              p: "20px 0",
              fontSize: "16px",
              fontWeight: "500",
              ...cellSX,
            }}
            colSpan={visibleColumns.length}
            align="center"
          >
            {noDataMessage || "No results found"}
          </TableCell>
        </TableRow>
      )}
      {loading
        && Array.from(Array(skeltonRowsCount)).map((_, index) => (
          <TableRow key={`table-row-skeleton-${index}`} sx={{ padding: "10px" }}>
            {visibleColumns.map((columnName: any) => (
              <TableCell key={columnName} sx={{ height: skeltonRowHeight, p: "10px", ...cellSX }}>
                <Skeleton variant="text" width="100%" height="100%" />
              </TableCell>
            ))}
          </TableRow>
        ))}
    </MuiTableBody>
  );
}

export default TableBody;
