import { TabPanel } from "@mui/lab";
import TabContext from "@mui/lab/TabContext";
import { Box, Tab, Tabs } from "@mui/material";
import React, { MouseEvent, useEffect } from "react";

import { useSearchQuery } from "hooks";
import useUserSettings from "services/shooks/settings";
import { Account } from "types/api";
import { getSalesForceLink } from "utils/generics";

import {
  Opportunities, Overview, People, Products, SimilarWins,
} from "./components";

export enum AccountTabsEnum {
  Overview = "overview",
  Opportunities = "opportunities",
  SimilarWins = "similarWins",
  Products = "products",
  People = "people",
}
interface Props {
  account: Account;
}

function AccountDetailsTabs({ account }: Props) {
  const [{ open_tab: activeTab }, updateSearchQuery] = useSearchQuery<{
    open_tab: AccountTabsEnum;
  }>();
  const { instanceURL } = useUserSettings(({ state }) => state);
  const onSalesForceLinkClick = (e: MouseEvent, id: string) => {
    e.stopPropagation();
    const objectId = id;
    const salesforceLink = getSalesForceLink({ instanceURL, objectId });
    window.open(salesforceLink, "_blank");
  };

  const onTabChange = (event: React.SyntheticEvent, newValue: AccountTabsEnum) => {
    // remember any open tab in the url.
    // const index = Object.keys(AccountTabsEnum).map((key) => key[0].toLowerCase() + key.slice(1)); // => ['overview', 'opportunities', 'similarWins', 'products', 'people']
    // const openTabValue = index[Number(newValue)];
    // we
    updateSearchQuery({ open_tab: newValue });
  };

  useEffect(() => {
    if (!activeTab) {
      updateSearchQuery({ open_tab: AccountTabsEnum.Overview });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  // useEffect(() => {
  //   if (openTab) {
  //     // change the value of open tab when clicking on the people/opportunities widgets
  //     const openTabValue = openTab.toLowerCase() === 'people' ? '4' : openTab === 'opportunities' ? '1' : activeTab;
  //     setActiveTab(openTabValue as AccountTabsEnum);
  //   }
  // }, [openTab]);

  return (
    <Box sx={{
      flex: 1, display: "flex", flexDirection: "column", overflow: "auto", zIndex: 2
    }}
    >
      <TabContext value={activeTab}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs value={activeTab} onChange={onTabChange} aria-label="accounts details page">
            <Tab label="Overview" value={AccountTabsEnum.Overview} />
            <Tab label="Opportunities" value={AccountTabsEnum.Opportunities} />
            <Tab label="Similar Wins" value={AccountTabsEnum.SimilarWins} />
            {/* <Tab label="Products" value={AccountTabsEnum.Products} /> */}
            <Tab label="People" value={AccountTabsEnum.People} />
          </Tabs>
        </Box>
        <TabPanel value={AccountTabsEnum.Overview} sx={{ pr: 0, pl: 0 }}>
          <Overview account={account} />
        </TabPanel>
        <TabPanel value={AccountTabsEnum.Opportunities} sx={{ padding: 0, paddingTop: "20px" }}>
          <Opportunities onSalesforceLinkClick={onSalesForceLinkClick} />
        </TabPanel>
        <TabPanel sx={{ pr: 0, pl: 0 }} value={AccountTabsEnum.SimilarWins}>
          <SimilarWins account={account} onSalesforceLinkClick={onSalesForceLinkClick} />
        </TabPanel>
        {/* <TabPanel sx={{ pr: 0, pl: 0 }} value={AccountTabsEnum.Products}>
          <Products account={account} />
        </TabPanel> */}
        <TabPanel sx={{ padding: 0, paddingTop: "20px" }} value={AccountTabsEnum.People}>
          <People account={account} />
        </TabPanel>
      </TabContext>
    </Box>
  );
}

export default AccountDetailsTabs;
