/**
 * when a manager is duplicated as a subordinate, we should add 'Only' to the name
 */
import type { IExtendedEmployee } from "types/api";

const renameDuplicatedManager = (employee: IExtendedEmployee): IExtendedEmployee => {
  if (!employee.subordinates.length) return employee;
  const firstSubordinate = employee.subordinates[0];
  const duplicateManager = firstSubordinate.name === employee.name;

  if (duplicateManager) employee.subordinates[0].name = `${employee.subordinates[0].name} Only`;
  return employee;
};

export default renameDuplicatedManager;
