import { Box, SxProps } from "@mui/material";

import { CSSProperties, useEffect, useState } from "react";

import Spinner from "components/common/Spinner";

interface LoadingComponentProps {
  searchEntityTitle?: string;
  steps?: { title: string; interval: number }[];
  parentSx?: SxProps;
  messageSx?: CSSProperties;
  hasSpinner?: boolean;
}
function LoadingComponent({
  searchEntityTitle,
  steps,
  parentSx,
  messageSx,
}: LoadingComponentProps) {
  const [progress, setProgress] = useState(0);

  const progressionSteps = (Array.isArray(steps) && steps.length > 1) ? steps : [{
    title: `Searching for ${searchEntityTitle || "selected"}...`,
    interval: 3000,
  }, {
    title: "Analyzing data...",
    interval: 4000,
  }, {
    title: "Preparing & fetching results...",
    interval: 12000,
  }];

  useEffect(() => {
    const timeout = setTimeout(() => {
      setProgress((prev) => {
        if (prev === progressionSteps.length - 1) {
          return 0;
        }
        return prev + 1;
      });
    }, progressionSteps[progress].interval);
    return () => clearTimeout(timeout);
  }, [progress]);

  return (
    <Box sx={parentSx}>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "5px" }}>
        <span style={messageSx}>
          {progressionSteps?.[progress]?.title}
          {progress === progressionSteps.length - 1 ? "..." : ""}
        </span>
      </Box>
    </Box>
  );
}

LoadingComponent.defaultProps = {
  searchEntityTitle: "",
  parentSx: {
    padding: "20px", border: "1px solid #e0e0e0", borderRadius: "10px", marginBottom: "20px", backgroundColor: "#fff",
  },
  messageSx: { fontSize: "12px", color: "#333333" },
};

export default LoadingComponent;
