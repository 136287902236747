export enum WhitespaceColumnAccessors {
  selection = "id",
  account = "name",
  priority = "priority",
  pipelineSignal = "pipelineSignal",
  lastSalesActivity = "lastSalesActivity",
  lastCustomerSignal = "lastCustomerSignal",
  renews = "nextRenewalDate",
  whitespace = "whitespace",
}

export const WhitespaceColumnTitles = {
  [WhitespaceColumnAccessors.selection]: "Selection",
  [WhitespaceColumnAccessors.account]: "Account Name",
  [WhitespaceColumnAccessors.priority]: "Priority",
  [WhitespaceColumnAccessors.pipelineSignal]: "Pipeline Signal",
  [WhitespaceColumnAccessors.lastSalesActivity]: "Last Sales Activity",
  [WhitespaceColumnAccessors.lastCustomerSignal]: "Last Customer Signal",
  [WhitespaceColumnAccessors.renews]: "Renews",
  [WhitespaceColumnAccessors.whitespace]: "Whitespace",
};
