import { getUserConversionRateDetails } from "./conversionRate";
import { getEmployeeHierarchy } from "./employees";
import { default as filters, default as settings } from "./settings";

const User = {
  filters,
  settings,
  getEmployeeHierarchy,
  getUserConversionRateDetails,
};

export default User;
