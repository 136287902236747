import { useEffect, useState } from "react";

export function useTabActiveStatus() {
  const [isBrowserTabActive, setIsBrowserTabActive] = useState(true);
  const onVisiabilityChange = () => {
    setIsBrowserTabActive(!document.hidden);
  };

  useEffect(() => {
    if (!window) return;
    window.addEventListener("visibilitychange", onVisiabilityChange);
    window.addEventListener("mozvisibilitychange", onVisiabilityChange);
    window.addEventListener("webkitvisibilitychange", onVisiabilityChange);
    window.addEventListener("msvisibilitychange", onVisiabilityChange);

    return () => {
      window.removeEventListener("visibilitychange", onVisiabilityChange);
      window.removeEventListener("mozvisibilitychange", onVisiabilityChange);
      window.removeEventListener("webkitvisibilitychange", onVisiabilityChange);
      window.removeEventListener("msvisibilitychange", onVisiabilityChange);
    };
  }, []);
  return isBrowserTabActive;
}
