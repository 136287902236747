export const defaultStyles = {
  cellStyles: {
    width: "150px",
    minWidth: "150px",
    maxWidth: "150px",
  },
  columnStyles: {
    width: "172px",
    minWidth: "172px",
    maxWidth: "172px",
  },
};

export const string = {
  columnStyles: {
    width: "322px",
    minWidth: "322px",
    maxWidth: "322px",
    textTransform: "capitalize",
  },
  cellStyles: {
    textTransform: "capitalize",
    width: "300px",
    minWidth: "300px",
    maxWidth: "300px",
  },
};

export const phone = {
  columnStyles: {
    width: "152px",
    minWidth: "152px",
    maxWidth: "152px",
  },
  cellStyles: {
    width: "130px",
    minWidth: "130px",
    maxWidth: "130px",
  },
};

export const id = {
  columnStyles: {
    width: "202px",
    minWidth: "202px",
    maxWidth: "202px",
  },
  cellStyles: {
    width: "180px",
    minWidth: "180px",
    maxWidth: "180px",
  },
};

export const email = {
  columnStyles: {
    ...string.columnStyles,
    textTransform: "lowercase",
  },
  cellStyles: {
    ...string.cellStyles,
    textTransform: "lowercase",
  },
};

export const mobile = phone;

export const number = {
  ...defaultStyles,
};

export const boolean = {
  ...defaultStyles,
};

export const date = {
  ...defaultStyles,
};
