import { CloseCircleFilled } from "@ant-design/icons";
import { InputAdornment } from "@mui/material";
import { Stack } from "@mui/system";
import {
  KeyboardEvent, SyntheticEvent, useEffect, useState,
} from "react";

import { useDebounceEffect } from "hooks";
import useEmployeeHierarchy from "services/shooks/EmployeeHierarchy";
import useTerritoryFilters from "services/shooks/territoryFilters";
import { IExtendedEmployee } from "types/api";

import { isSubOrEqualString } from "utils/comparison";

import { FiltersGroupBox, SearchInput } from "./common";
import HierarchyEmployeeList from "../EmployeeHierarchy/components/EmployeeHierarchy";
import {
  findMatchesInTree,
  updateAllEmployeeStatus,
  updateEmployeeInTree,
} from "../EmployeeHierarchy/utils";

interface AccountOwner {
  isFilterBoxOpen?: boolean;
  valuePath?: string;
  values?: string[];
  onToggleFilterBox: (isOpen?: boolean) => void;
}

function AccountOwner({
  isFilterBoxOpen, onToggleFilterBox, valuePath = "", values = [],
}: AccountOwner) {
  const initialEmployeeHierarchy = useEmployeeHierarchy(
    (state) => updateAllEmployeeStatus(state.state.hierarchy?.[0]?.subordinates || [], false, values),
  );

  const [employeeHierarchy, setEmployeeHierarchy] = useState<IExtendedEmployee[]>(
    initialEmployeeHierarchy,
  );

  const { actions, state } = useTerritoryFilters();
  const [searchKey, setSearchKey] = useState<string>();
  const { onFilterChange, setAllFiltersDetails } = actions;
  const filterName = valuePath || "accountOwners";

  const onSearch = (event: SyntheticEvent) => {
    const { value } = event.target as HTMLInputElement;
    setSearchKey(value);
  };

  const updateEmployeeCheckStatus = (employee: IExtendedEmployee, checkState?: boolean) => {
    const newEmployeeList = updateEmployeeInTree(
      employee.id,
      (currentEmployee: IExtendedEmployee) => {
        currentEmployee.checked = checkState ?? !employee.checked;
        if (currentEmployee.subordinates.length) {
          const updatedSubordinates = currentEmployee.subordinates.map(
            (subordinate: IExtendedEmployee) => {
              updateEmployeeCheckStatus(subordinate, currentEmployee.checked);
              return subordinate;
            },
          );
          currentEmployee.subordinates = updatedSubordinates;
        }
        return currentEmployee;
      },
      employeeHierarchy,
    );

    return newEmployeeList;
  };

  const onSelectEmployee = (employee: IExtendedEmployee, checkStatus?: boolean) => {
    const newEmployeeList = updateEmployeeCheckStatus(employee, checkStatus);
    setEmployeeHierarchy(newEmployeeList);
  };

  const getSelectedUserIds = (hierarchy: IExtendedEmployee[]) => {
    const selectedEmployees = findMatchesInTree(hierarchy, (employee) => employee.checked);
    const selectedEmployeeIds = selectedEmployees.map((employee) => employee.id);
    const uniqueSelectedEmployeeIds = [...new Set(selectedEmployeeIds)];
    return uniqueSelectedEmployeeIds;
  };

  const onResetSearchKey = () => setSearchKey("");

  const onSelectSearchMatches = (event: KeyboardEvent<HTMLElement>) => {
    if (event.key !== "Enter" || !searchKey) return;
    const matchedIds = findMatchesInTree(employeeHierarchy, (employee) => isSubOrEqualString(employee.name, searchKey));
    // @todo: enable multiple emplyee update instead of this workaround
    const [newEmployeeList] = matchedIds
      .map((emp) => updateEmployeeCheckStatus(emp, !emp.checked))
      .reverse();
    setEmployeeHierarchy(newEmployeeList);
  };

  const activeFilterCount = state.filters?.accountOwners?.length ? 1 : 0;

  useDebounceEffect(() => {
    const selectedEmployeeIds = getSelectedUserIds(employeeHierarchy);
    // @todo: make a general utility function to remove the parent id.
    // 1- Figure out the use cases and if we can use it directly in the zustand state.
    // 2- If not, refactor the zustand state to no longer require the developer to have knowledge of what problems the code has.
    const sanitizedIds = selectedEmployeeIds.filter((id) => !id.endsWith("|parent"));

    onFilterChange(filterName, sanitizedIds);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, 600, [JSON.stringify(employeeHierarchy)]);

  useEffect(() => {
    const hasAccountOwners = Boolean(state.filters?.accountOwners?.length);
    if (!hasAccountOwners) setEmployeeHierarchy(initialEmployeeHierarchy);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(state.filters?.accountOwners)]);

  useEffect(() => {
    setAllFiltersDetails({ name: filterName, value: activeFilterCount });
  }, [activeFilterCount]);

  return (
    <FiltersGroupBox
      title="Account Owner"
      activeFilterCount={activeFilterCount}
      isOpen={isFilterBoxOpen}
      onToggle={onToggleFilterBox}
    >
      <SearchInput
        placeholder="Search..."
        onChange={onSearch}
        value={searchKey}
        onKeyDown={onSelectSearchMatches}
        endAdornment={
          searchKey && (
            <InputAdornment
              position="end"
              sx={{
                cursor: "pointer",
                "&:hover": { opacity: "0.8", transform: "scale(1.2)" },
              }}
            >
              <CloseCircleFilled style={{ fontSize: "13px" }} onClick={onResetSearchKey} />
            </InputAdornment>
          )
        }
      />
      <Stack gap={0} direction="column" sx={{ overflow: "hidden" }}>
        {employeeHierarchy
          ?.filter((employee) => employee.name)
          .map((employee) => (
            <HierarchyEmployeeList
              key={`${employee.id}${searchKey}`}
              filterKey={(searchKey?.length ?? 0) > 2 ? searchKey : ""}
              employee={employee}
              onSelectEmployee={onSelectEmployee}
            />
          ))}
      </Stack>
    </FiltersGroupBox>
  );
}

export default AccountOwner;
