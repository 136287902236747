import { captureExceptionWithLogRocket } from 'utils/userActivitiesTracker/logRocket'
export interface SecCompany {
  cik_str: number
  ticker: string
  title: string
}

export interface SecCompaniesList {
  [key: string]: SecCompany
}

let companiesList: SecCompaniesList

export const getSecCompaniesFilingList = async () => {
  if (!companiesList) {
    try {

      const list = await fetch('https://www.sec.gov/files/company_tickers.json')
      companiesList = await list.json()
    } catch (error) {
      captureExceptionWithLogRocket(error as Error)
      console.error('Error fetching companies list', error)
      return import('./public_companies_ticker.json')
    }
  }

  return companiesList
}

export default getSecCompaniesFilingList