import {
  IconButton as BaseIconButton, Button, Stack, TextField,
} from "@mui/material";
import { KeyboardEvent, SyntheticEvent, useState } from "react";

const activeSx = { bgcolor: "#004E87", color: "#fff", borderColor: "currentColor" };
const disabledSx = { color: "#333333" };

export function IconButton({
  disabled,
  onClick,
  children,
  isActive,
  sx,
}: {
  isActive?: boolean;
  disabled?: boolean;
  onClick?: () => void;
  children: React.ReactNode;
  sx?: any;
}) {
  const customSx = { ...sx, ...(disabled ? disabledSx : {}), ...(isActive ? activeSx : {}) };
  return (
    <BaseIconButton
      disabled={disabled}
      onClick={onClick}
      sx={{
        ...sx, bgcolor: "#fff", border: "1px solid #E6E6E6", ...customSx,
      }}
    >
      {children}
    </BaseIconButton>
  );
}

interface PaginationMiniFormProps {
  currentPage: number;
  onPageChange: (page: number) => void;
}

export function PaginationMiniForm({ currentPage, onPageChange }: PaginationMiniFormProps) {
  const [page, setPage] = useState<number>();

  const onSubmitPageChange = () => {
    if (page) onPageChange(page);
  };

  const onChange = (event: SyntheticEvent) => {
    const { value } = event.target as HTMLInputElement;
    setPage(Number(value));
  };
  const onGeneralKeyHandler = (event: KeyboardEvent<HTMLElement>) => {
    if (event.key === "Enter") {
      onSubmitPageChange?.();
    }
  };

  return (
    <Stack gap="4px" direction="row" onKeyDown={onGeneralKeyHandler}>
      <TextField
        name="page"
        key={currentPage}
        placeholder="Page"
        onChange={onChange}
        type="number"
        inputProps={{
          sx: {
            "&::placeholder": {
              color: "currentColor",
              opacity: "1",
            },
          },
        }}
        sx={{
          "& .MuiInputBase-root": {
            height: "35px",
          },
          bgcolor: "#fff",
          width: "82px",
          borderColor: "#C8C8C8",
          color: "#4D4D4D",
        }}
      />
      <Button
        type="submit"
        onClick={onSubmitPageChange}
        variant="outlined"
        sx={{
          height: "34px",
          bgcolor: "#fff",
          color: "#004E87",
          borderColor: "#004E87",
          p: "5px 16px",
          fontSize: "14px",
          lineHeight: "22px",
        }}
      >
        Apply
      </Button>
    </Stack>
  );
}
