import { create } from "zustand";

import peopleContactAPI from "services/peopleService";

import { StateRecord } from "./main";

export interface PersonContactInfo {
  id: string | undefined;
  emailAddress: string | undefined;
  name: string | undefined;
  linkedInProfile: string | undefined;
  jobTitle: string | undefined;
  phoneNumber: string | undefined;
}

interface PeopleContactState {
  peopleContactsInfo: {
    [key: string]: PersonContactInfo;
  };
}

interface PeopleState extends StateRecord {
  isLoading?: boolean;
  state: PeopleContactState;
  actions: {
    get: (
      query: Parameters<typeof peopleContactAPI.getPersonContactInfo>[0]
    ) => Promise<PersonContactInfo>;
  };
}

const initialPeopleContacts = {
  peopleContactsInfo: {},
} as PeopleContactState;

const usePeopleContacts = create<PeopleState>((set, getState) => ({
  isLoading: false,
  state: initialPeopleContacts,
  actions: {
    async get(query) {
      const { state } = getState();
      set({ isLoading: true });
      const { peopleContactsInfo } = state;
      const personKey = query.email || query.personId || "";
      let personContactInfo = peopleContactsInfo[personKey];
      if (!personContactInfo) {
        try {
          personContactInfo = await peopleContactAPI.getPersonContactInfo(query);
          const updatedPeopleContactsMap = {
            ...peopleContactsInfo,
            [personKey]: personContactInfo,
          };
          set({ isLoading: false, state: { peopleContactsInfo: updatedPeopleContactsMap } });
        } catch (err) {
          set({ isLoading: false });
        }
      } else {
        set({ isLoading: false });
      }
      return personContactInfo;
    },
  },
}));

export default usePeopleContacts;
