
import { BlockOutlined } from "@ant-design/icons";
import { Checkbox, Tooltip, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import { SavedIcon } from "assets/icons";
import { Table } from "components/common/Tables";
import { HeaderCell } from "pages/Settings/UsersManagement/common";
import { useCallback, useMemo } from "react";
import { PersonResult } from "services/aiworkers";


interface AccountBusinessCardProps {
  account: {
    people: PersonResult[];
    name: string;
    id: string;
  },
  onSelectPerson: (callback: string[] | ((peopleIds: string[]) => string[])) => void;
  selectedPeople: string[]
}
const linkedInIcon = "https://www.linkedin.com/favicon.ico";

function AccountPeopleCard(props: AccountBusinessCardProps) {
  const { account, onSelectPerson, selectedPeople } = props;
  const isAllAccountsPeopleSelected = useMemo(() => account.people?.every(person => selectedPeople?.includes(person.id)), [account.people, selectedPeople]);

  const onSelect = (personId: string) => () => {
    if (personId === 'all') {
      const allAccountPeopleIds = account.people.filter(person => !person.isAlreadySaved && person.isInUserScope).map(person => person.id);
      onSelectPerson((selected) => isAllAccountsPeopleSelected ? selected.filter((personId) => !allAccountPeopleIds.includes(personId)) : [...selected, ...allAccountPeopleIds]);
      return;
    }
    onSelectPerson((selectedPeople) => {
      const isAlreadyExist = selectedPeople.find(person => person === personId)
      return isAlreadyExist ? selectedPeople.filter((person) => person !== personId) : [...selectedPeople, personId]
    });
  }

  const columns = [
    {
      Header: () => (
        <HeaderCell width="250px">
          <Checkbox onChange={onSelect('all')} checked={isAllAccountsPeopleSelected} />
          Contact Name
        </HeaderCell>
      ),
      accessor: "name",
      style: { width: '250px' },
      Cell: (cellProps: any) => {
        const personAvatar = cellProps?.cell?.row?.original?.photo;
        const isAlreadySaved = cellProps?.cell?.row?.original?.isAlreadySaved;
        const isInUserScope = cellProps?.cell?.row?.original?.isInUserScope;
        const canSelectAndSave = isInUserScope && !isAlreadySaved
        return (
          <Box sx={{ color: "#262626", display: 'flex', alignItems: 'center', gap: '5px', width: '250px', py: '5px' }}>
            {canSelectAndSave ? (
              <Checkbox onChange={onSelect(cellProps?.cell?.row?.original?.id)} checked={cellProps?.cell?.row?.original.isSelected} />
            ) : (
              <>
                {!isInUserScope ? (
                  <Tooltip placement="right" title={`The account ${account.name} is outside your territory.`}>
                    <Box sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      cursor: 'not-allowed',
                      alignItems: 'center',
                      borderRadius: '3px',
                      p: '2px',
                      m: '0 7px 0 10px',
                      color: '#616161',
                      '&:hover': {
                        color: '#1890ff',
                        background: '#6161612b'
                      }
                    }}>
                      <BlockOutlined />
                    </Box>
                  </Tooltip>
                ) : (
                  isAlreadySaved ? (
                    <Tooltip placement="right" title='Contact already saved!'>
                      <Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        cursor: 'pointer',
                        alignItems: 'center',
                        borderRadius: '3px',
                        p: '2px',
                        m: '0 7px 0 10px',
                        color: '#616161',
                        '&:hover': {
                          color: '#1890ff',
                          background: '#6161612b'
                        }
                      }}>
                        <SavedIcon style={{ height: '15px', width: '15px' }} />
                      </Box>
                    </Tooltip>
                  ) : null
                )}
              </>
            )}
            {personAvatar && (
              <img
                src={personAvatar}
                alt="contact avatar"
                style={{ width: "20px", height: "20px", borderRadius: "50%", border: '1px solid #616161' }}
              />
            )}
            <Typography>{cellProps?.cell?.row?.original.name}</Typography>
          </Box>
        );
      },
    },
    {
      Header: () => (
        <HeaderCell >
          Linkedin profile
        </HeaderCell>
      ),
      accessor: "linkedInProfile",
      style: { width: '200px' },
      Cell: (cellProps: any) => {
        const linkedInProfile = cellProps?.cell?.row?.original.linkedInProfile

        return (
          <Box sx={{ color: "#262626", display: 'flex', alignItems: 'center', gap: '5px', textTransform: 'capitalize' }}>
            {Boolean(linkedInProfile) ? (
              <>
                <img
                  src={linkedInIcon}
                  alt="LinkedIn"
                  style={{ width: "16px", height: "16px" }}
                />
                <a href={linkedInProfile} target="_blank" rel="noreferrer" style={{ fontSize: "12px", textDecoration: "none", color: "#3498db" }}>
                  LinkedIn Profile
                </a>
              </>
            ) : '--'}
          </Box>
        );
      },
    },
    {
      Header: () => (
        <HeaderCell >
          Email
        </HeaderCell>
      ),
      accessor: "email",
      Cell: (cellProps: any) => {
        return (
          <Box sx={{ color: "#262626", display: 'flex', }}>
            <Typography>{cellProps?.cell?.row?.original?.email || '--'}</Typography>
          </Box>
        );
      }
    },
    {
      Header: () => (
        <HeaderCell >
          title
        </HeaderCell>
      ),
      accessor: "title",
      style: { minWidth: '300px', width: '400px' },
      Cell: (cellProps: any) => {
        const title = cellProps?.cell?.row?.original.title

        return (
          <Box sx={{ color: "#262626", display: 'flex', alignItems: 'center', gap: '5px', textTransform: 'capitalize' }}>
            <Typography>{title}</Typography>
          </Box>
        );
      },
    },
  ]


  return (
    <Box sx={{
      display: "flex",
      flex: 1,
      flexDirection: "column",
      gap: "10px",
      padding: "20px",
      backgroundColor: "#ffffff",
      borderRadius: "10px",
      border: "1px solid #e0e0e0",
      width: "100%",
    }}
    >
      <Box>
        <Stack gap="10px" direction="row" alignItems="center">
          <Typography variant="h5">{account.name}</Typography>
        </Stack>
      </Box>
      <Stack flex={1} direction="row" gap="10px" width={'100%'} sx={{ borderRadius: '10px', overflow: 'hidden' }}>
        <Table
          columns={columns}
          data={account.people.map((person) => ({ ...person, isSelected: selectedPeople?.includes(person.id) })) as any}
          noDataMessage="No people found"
          stickyHeader={false}
          memoDeps={[...(selectedPeople || []), isAllAccountsPeopleSelected, account.name]}
          cellSX={{
            padding: "15px 0",
            border: 'none !important',
          }}
        />
      </Stack>
    </Box>
  );
}

export default AccountPeopleCard;
