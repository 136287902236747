import { Box, Stack } from "@mui/material";

import { IActivity } from "types/api/activities";
import { downloadFile } from "utils/file";

import { PersonLabel } from "./common";
import { ActivityContentInteractions, AddToCalendarAvailableOptions, EmailField } from "../common";
import { CalendarService, addToCalendarFromActivity, onGoToExternalLink } from "../utils";

interface IEventContentProps {
  activity: IActivity;
  onActionTrigger?: (action: ActivityContentInteractions, value?: string, name?: string) => void;
}

export function EventContent({ activity, onActionTrigger }: IEventContentProps) {
  const person = {
    name: activity.secondParty?.personName,
    email: activity.secondParty?.email,
    title: activity.secondParty?.title,
  };

  const onFilterByPerson = (personName?: string, personId?: string) => {
    if (onActionTrigger && personName) {
      onActionTrigger(ActivityContentInteractions.FilterByPerson, personName, personId);
    }
  };

  return (
    <Stack direction="column" sx={{ width: "100%", wordWrap: "break-word" }}>
      <Stack direction="column" sx={{ width: "100%" }}>
        <EmailField
          label="Who:"
          valueSx={{ textTransform: person?.name ? "capitalize" : "none" }}
          value={(
            <PersonLabel
              onClick={onFilterByPerson}
              value={person.name}
              tipDetails={{
                email: person?.email,
                name: person?.name,
                jobTitle: person?.title,
              }}
            />
          )}
        />
        <EmailField
          label="What:"
          value={(
            <span style={{ "--lines-max": 3 } as any} className="multi-line-truncate">
              {activity.subject}
            </span>
          )}
          valueSx={{ maxWidth: "80%", display: "block" }}
        />
      </Stack>
    </Stack>
  );
}

export default EventContent;

export function EventExtraContent({ activity }: { activity: IActivity }) {
  const addEventLinkAction = () => {
    onGoToExternalLink("https://calendar.google.com/calendar/");
  };

  const onAddToCalendar = (targetCalendar: CalendarService) => () => {
    const link = addToCalendarFromActivity(activity, targetCalendar);
    if (!link) return;
    if (targetCalendar === CalendarService.DownloadIcs) {
      return downloadFile(`${activity.subject}.ics`, link);
    }
    onGoToExternalLink(link);
  };

  return (
    <Box
      onClick={addEventLinkAction}
      sx={{
        bgcolor: "#FAFAFA",
        p: "8px 12px",
        borderRadius: "4px",
        fontSize: "14px",
        color: "#004E87",
        lineHeight: "22px",
        cursor: "pointer",
        userSelect: "none",
      }}
    >
      <Stack direction="row" gap="5px" alignItems="center">
        <span>Add to Calendar:</span>
        <AddToCalendarAvailableOptions onAdd={onAddToCalendar} />
      </Stack>
    </Box>
  );
}
