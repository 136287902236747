import { ArrowDropUpRounded } from "@mui/icons-material";
import {
  Box, Skeleton, Stack, SxProps, Typography,
} from "@mui/material";
import React, { ReactNode } from "react";

interface WidgetBoxProps {
  children: React.ReactNode;
  title: string | React.ReactNode;
}

export function WidgetBox(props: WidgetBoxProps) {
  return (
    <Box
      sx={{
        display: "flex",
        width: "260px",
        flexDirection: "column",
        borderRadius: "8px",
        overflow: "hidden",
        border: "1px solid transparent",
        transition: "box-shadow 0.3s ease-in-out, border 0.3s ease-in-out",
        boxShadow: "0px 0px 20px rgba(11, 60, 107, 0.05)",
        "&:hover": {
          boxShadow: "0px 0px 20px rgba(11, 60, 107, 0.1)",
          border: "1px solid #C0D7E9",
        },
      }}
    >
      <Box
        sx={{
          color: "#1A1A1A",
          fontSize: "16px",
          textTransform: "capitalize",
          background: "#E5EFF5",
          p: "8px 16px",
        }}
      >
        {props.title}
      </Box>
      <Box sx={{ p: "12px 16px 8px 16px" }}>{props.children}</Box>
    </Box>
  );
}

interface WidgetLinkProps {
  children: React.ReactNode;
  onClick: () => void;
  icon?: React.ReactNode;
  sx?: SxProps;
}

export function WidgetLink(props: WidgetLinkProps) {
  const { children, onClick, icon } = props;

  return (
    <Stack
      gap="8px"
      alignItems="center"
      direction="row"
      sx={{
        cursor: "pointer",
        width: "fit-content",
        color: "#004E87",
        lineHeight: "22px",
        fontSize: "14px",
        ...props.sx,
      }}
      onClick={onClick}
    >
      {icon}
      {children}
    </Stack>
  );
}

interface BarChartProps {
  items: { name?: string; value: number; tip?: string; color?: string; label?: string }[];
  maxVal?: number;
}

export function BarChart(props: BarChartProps) {
  const { items, maxVal = 100 } = props;
  // const itemsSorted = items.sort((a, b) => b.value - a.value)
  return (
    <Box
      sx={{
        position: "relative",
        display: "flex",
        justifyContent: "flex-start",
        height: "18px",
        bgcolor: "#F5F5F5",
        width: "100%",
        overflow: "hidden",
      }}
    >
      {items.map((item, index) => {
        const percent = (item.value / maxVal) * 100;
        const safePercent = percent > 100 ? 100 : percent;

        return (
          <Box
            key={`${index}_${item.name}`}
            sx={{
              // position: 'absolute',
              // display: 'flex',
              // alignItems: 'center',
              // justifyContent: 'space-between',
              // left: `${prevItem ? (prevItem.value / max) * 100 : 0}%`,
              width: `${safePercent}%`,
              // top: 0,
              height: "100%",
              background: item.color ?? "inherit",
              display: "flex",
              justifyContent: "center",
              fontSize: "12px",
            }}
            children={item.label}
          />
        );
      })}
    </Box>
  );
}
interface PipelineBarChartProps {
  total?: number;
  closedOpportunities?: number;
  openOpportunities?: number;
}

export function PipelineBarChart(props: PipelineBarChartProps) {
  const { closedOpportunities = 0, openOpportunities = 0 } = props;
  const items = [
    //  @note: subtracting lightgreen from green to get the value of the yellow bar to only show the difference
    { value: closedOpportunities, color: "#358700" },
    { value: openOpportunities, color: "#94DE64" },
  ];

  return <BarChart items={items} />;
}

interface ConversionRateBarChartProps {
  referenceRate: number;
  comparisonRate: number;
}

export function ConversionRateBarChart(props: ConversionRateBarChartProps) {
  let { referenceRate, comparisonRate } = props;
  referenceRate = referenceRate > 100 ? 100 : referenceRate;
  comparisonRate = comparisonRate > 100 ? 100 : comparisonRate;
  const isSameConversion = referenceRate === comparisonRate;
  const comparedRateColor = comparisonRate >= referenceRate ? "#94DE64" : "#FF0000";
  return (
    <Box
      sx={{
        position: "relative",
        height: "18px",
        bgcolor: "#F5F5F5",
        width: "100%",
        mb: isSameConversion ? "20px" : "65px",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          height: "100%",
          display: "flex",
          justifyContent: "flex-end",
          top: 0,
          left: `${comparisonRate}%`,
          bottom: 0,
        }}
      >
        <Box
          sx={{
            width: "8px",
            bgcolor: comparedRateColor,
            transform: "translateX(-50%)",
          }}
        />
      </Box>
      {!isSameConversion && (
        <Box
          sx={{
            position: "absolute",
            height: "100%",
            color: "#2A7AB7",
            borderRight: "1.7px dashed currentColor",
            display: "flex",
            justifyContent: "flex-end",
            top: 0,
            left: `${referenceRate}%`,
            bottom: 0,
          }}
        >
          <Box
            sx={{
              position: "absolute",
              height: "100%",
              right: 0,
              top: "120%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              transform: "translateX(50%)",
            }}
          >
            <ArrowDropUpRounded style={{ color: "#2A7AB7" }} />
            <Stack
              direction="column"
              alignItems="center"
              sx={{
                transform:
                  referenceRate >= 6
                    ? referenceRate <= 95
                      ? "unset"
                      : "translateX(-20%)"
                    : "translateX(20%)",
              }}
            >
              <Typography sx={{ fontWeight: "500", color: "#1A1A1A", fontSize: "12px" }}>
                {Number(referenceRate).toFixed(1)}
                %
              </Typography>
              <Typography sx={{ fontWeight: "500", color: "#1A1A1A", fontSize: "12px" }}>
                (Average)
              </Typography>
            </Stack>
          </Box>
        </Box>
      )}
    </Box>
  );
}

export function SkeletonMask(props: { children: ReactNode; loading?: boolean, opacity?: number, blurDegree?: number }) {
  const { opacity = '', blurDegree = 8, loading: isLoading } = props;
  return (
    <Box sx={{ position: "relative", opacity: isLoading ? opacity : '', filter: isLoading ? `blur(${blurDegree}px)` : "none" }}>
      {props.children}
      {isLoading && (
        <Skeleton
          variant="rectangular"
          width="100%"
          height="100%"
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            zIndex: 1,
            ...(isLoading ? { display: "block" } : { display: "none" }),
          }}
        />
      )}
    </Box>
  );
}
