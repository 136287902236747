import { useAuth0 } from "@auth0/auth0-react";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import NotificationsIcon from "@mui/icons-material/Notifications";

import NotificationsActiveIcon from "@mui/icons-material/NotificationsActiveOutlined";
import { Badge, ClickAwayListener } from "@mui/material";

import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import { MouseEvent, ReactNode, useEffect, useState } from "react";

import Avatar from "components/@extended/Avatar";
import { EMPLOYEE_HIERARCHY, TERRITORY_SELECTED_USER_IDS } from "constants/territory";
import { useSearchQuery } from "hooks";
import useLastSignalCount from "services/shooks/useNewActivitiesSignals";
import { Storage } from "utils/storage";
import defaultAvatar from 'assets/images/avatar_sd.jpg'
import { userStorage } from "utils/auth";
interface INavbarProps {
  renderCustomElement?: () => ReactNode;
  isNotificationBellShown?: boolean;
  pageName?: string;
}

export default function Navbar({
  renderCustomElement,
  isNotificationBellShown = true,
  pageName = "Territory",
}: INavbarProps) {
  const { user, logout } = useAuth0();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleMenu = (event: MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const [{ ac_feed: isActivityFeedOpen }, updateSearchQuery] = useSearchQuery<{ ac_feed: boolean; }>();
  const unreadNotificationCount = useLastSignalCount(({ state }) => state.lastSignalsCount);
  const [userAvatar, setUserAvatar] = useState<string | undefined>(user?.picture);

  const onToggleActivityFeedDrawer = () => {
    updateSearchQuery({ ac_feed: !isActivityFeedOpen });
  };
  // this should be integrated to the auth context and shared across the app
  const handleLogout = () => {
    // userStorage.removeKeyValue("accessToken");
    handleClose();
    // @hotfix: the proper way is to use the userId from the IdToken, and stored the values in the localStorage using the userId as the key
    // userStorage.remove(EMPLOYEE_HIERARCHY);
    // userStorage.remove(TERRITORY_SELECTED_USER_IDS);
    userStorage.drop()
    logout({ returnTo: window.location.origin });
  };

  useEffect(() => {
    if (user?.picture) {
      setUserAvatar(user?.picture)
    }
  }, [user?.picture])

  const onError = (e: any) => {
    setUserAvatar(defaultAvatar);
  };


  return (
    <Box
      sx={{
        display: "flex",
        borderBottom: "1px solid #E0E0E0",
        backgroundColor: "#fff",
        color: "#000",
        padding: "10px",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      {/* left side of the navbar */}
      <Box sx={{ display: "flex", ml: "15px" }}>
        {/* <IconButton size="large" color="inherit" aria-label="menu" sx={{ mr: 2 }}>
          <Tooltip title="Feature will be released soon" placement="top-end">
            <MenuUnfoldOutlined style={{ color: '#AAA' }} />
          </Tooltip>
        </IconButton> */}
        {/* support extending the navbar. @todo: this needs to be thought out more thoroughly.
        It is probably best to create multiple versions to avoid unnecessary complexity. */}
        {renderCustomElement ? (
          renderCustomElement()
        ) : (
          <Typography variant="h3" component="div">
            {user?.given_name}
            &apos;s&nbsp;
            {pageName}
          </Typography>
        )}
      </Box>

      {/* display the pipeline widget only for leaf nodes (no subordinates in the hierarchy) */}
      {/* {(isInitialized && !isManager) ? <PipelineWidget /> : null} */}

      {/* right side of the navbar */}
      <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
        <Box display="flex" gap="40px" margin="0 40px 0">
          {isNotificationBellShown && (
            <Badge
              onClick={onToggleActivityFeedDrawer}
              badgeContent={unreadNotificationCount}
              color="error"
              sx={{ color: "#4d4d4d", cursor: "pointer" }}
            >
              {isActivityFeedOpen ? (
                <NotificationsActiveIcon fontSize="large" />
              ) : (
                <NotificationsIcon fontSize="large" />
              )}
            </Badge>
          )}
        </Box>
        <ClickAwayListener onClickAway={handleClose}>
          <>
            <Avatar size="sm">
              <img
                width={40}
                height={40}
                alt="user avatar"
                src={userAvatar}
                onError={onError}
              />
            </Avatar>
            <div
              style={{ display: "flex", alignItems: "center", paddingLeft: "10px" }}
              onClick={handleMenu}
            >
              <Typography
                variant="h6"
                component="div"
                sx={{
                  cursor: "pointer", display: "flex", alignItems: "center", flexGrow: 1,
                }}
              >
                {user?.given_name}
                {" "}
                {user?.family_name}
                {" "}
                <ArrowDropDownIcon />
              </Typography>
            </div>
          </>
        </ClickAwayListener>
        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          // keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={handleLogout}>Log out</MenuItem>
        </Menu>
      </Box>
    </Box>
  );
}
