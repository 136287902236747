import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";

import * as React from "react";

import { useDebounceEffect } from "hooks";
import useTerritoryFilters from "services/shooks/territoryFilters";

type Props = {
  valuePath: string;
  label: string;
  options: string[];
  value: string;
}

export default function SimpleSelect({
  valuePath, label, options, value,
}: Props) {
  const valueLabelMappings = new Map([
    ["true", "True"],
    ["false", "False"],
    ["null", "Unknown"],
  ]);
  const [selected, setSelected] = React.useState(value);

  const {
    actions: { onFilterChange },
  } = useTerritoryFilters();

  const handleChange = (event: SelectChangeEvent) => {
    const selectedKey = event.target.value;
    setSelected(selectedKey);
  };

  React.useEffect(() => {
    setSelected(value);
  }, [value]);

  useDebounceEffect(
    () => {
      onFilterChange(valuePath, selected);
    },
    600,
    [selected],
  );

  const onReset = () => {
    setSelected("");
    onFilterChange(valuePath, undefined);
  };

  return (
    <Box sx={{ minWidth: 120 }}>
      <div style={{
        alignItems: "center",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        marginBottom: "10px",
      }}
      >

        <div style={{
          color: "gray", fontSize: "14px", marginBottom: "5px",
        }}
        >
          {label}
        </div>

        <Typography
          onClick={onReset}
          fontWeight="500"
          fontSize="11px"
          color="#2A7AB7"
          sx={{ cursor: "pointer" }}
        >
          Reset
        </Typography>
      </div>
      <FormControl fullWidth>
        <Select
          key={selected}
          displayEmpty
          value={selected}
          defaultValue=""
          renderValue={(val) => {
            if (!val || val === "") {
              return <div style={{ color: "gray" }}>Select</div>;
            }
            if (!valueLabelMappings.has(val)) {
              return val;
            }
            return valueLabelMappings.get(val) ?? val;
          }}
          onChange={handleChange}
        >
          {options.map((option) => (
            <MenuItem key={option} value={option}>
              {valueLabelMappings.get(option) ?? option}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}
