import { Box, Typography } from "@mui/material";

import { Account } from "types/api";
import { numberFormats } from "utils/numbers";
import { timeAgo } from "utils/timeAgo";

import Widget from "./Widget";

export const isValidUrl = (urlString: string) => {
  try {
    return Boolean(new URL(urlString));
  } catch (e) {
    return false;
  }
};

function AccountWidget({ selectedAccount }: { selectedAccount: Account }) {
  const accountId = selectedAccount.id;
  const isTarget = selectedAccount.targetAccount;
  const { rank } = selectedAccount;

  const annualRevenue = typeof selectedAccount.annualRevenue === "number"
    ? `$${numberFormats.millify(selectedAccount.annualRevenue)}`
    : "--";
  const employeeCount = selectedAccount.employeeCount ?? "--";
  const industry = selectedAccount.industry || "--";
  const website = selectedAccount.website?.toLowerCase() === "none" ? "" : selectedAccount.website || "";
  const websiteUrl = website.includes("http") ? website : `http://${website}`;

  const nextRenewalDate = selectedAccount.nextRenewalDate || "";
  const renewal = nextRenewalDate.split("T")[0];
  const renewalAmount = renewal ? timeAgo.format(new Date(renewal), "round") : "N/A"; // quick fix for the date format breaking on new Date("") which is an invalid date

  const TAMamount = selectedAccount.TAMamount || 0;
  const openOpportunitiesAmount = selectedAccount.openOpportunitiesAmount || 0;
  const closedOpportunitiesAmount = selectedAccount.closedOpportunitiesAmount || 0;
  const uncapturedAmount = TAMamount - (openOpportunitiesAmount + closedOpportunitiesAmount);
  const walletShare = [
    {
      title: "Closed",
      amount: closedOpportunitiesAmount || 0,
      bg: "#757575",
      color: "#FFFFFF",
    },
    {
      title: "Open",
      amount: openOpportunitiesAmount || 0,
      bg: "#AAAAAA",
      color: "#FFFFFF",
    },
    {
      title: "Uncaptured",
      amount: uncapturedAmount > 0 ? uncapturedAmount : 0,
      bg: "#FFFFFF",
      color: "#333333",
    },
  ];

  return (
    <Widget>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "30px",
        }}
      >
        {walletShare.map(({
          title, amount, bg, color,
        }) => (
          <Box
            key={title}
            sx={{
              border: "1px solid #E6E6E6",
              background: bg,
              padding: "10px",
              width: "30%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              gap: "10px",
            }}
          >
            <Typography variant="body1" sx={{ color }}>
              {title}
            </Typography>
            <Typography variant="body1" sx={{ color, fontSize: "1rem", fontWeight: 700 }}>
              $
              {numberFormats.millify(amount)}
            </Typography>
          </Box>
        ))}
      </Box>
      <Box>
        {/* <Typography variant="body1" sx={{ color: "#333333", fontWeight: 700 }}>
          Rank #{rank}
        </Typography> */}
        {renewalAmount.includes("in") && (
          <Typography variant="body1" sx={{ color: "#DB393A", fontWeight: 700, marginTop: "10px" }}>
            Next renewal
            {" "}
            {renewalAmount}
            {" "}
            (
            {renewal}
            )
          </Typography>
        )}
      </Box>
      <Box sx={{ marginTop: "30px" }}>
        {[
          {
            title: "Revenue",
            value: (
              <Typography variant="body1" sx={{ color: "#333333" }}>
                {annualRevenue}
              </Typography>
            ),
          },
          {
            title: "Employees",
            value: (
              <Typography variant="body1" sx={{ color: "#333333" }}>
                {employeeCount}
              </Typography>
            ),
          },
          {
            title: "Industry",
            value: (
              <Typography
                variant="body1"
                sx={{
                  color: "#333333",
                  paddingLeft: "20px",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {industry}
              </Typography>
            ),
          },
          {
            title: "Website",
            value: isValidUrl(websiteUrl) ? (
              <a
                href={websiteUrl}
                target="_blank"
                rel="noreferrer"
                style={{
                  textDecoration: "none",
                  color: "#2A7AB7",
                }}
              >
                {website}
              </a>
            ) : (
              <Typography variant="body1" sx={{ color: "#333333" }}>
                --
              </Typography>
            ),
          },
        ].map(({ title, value }) => (
          value && (
            <Box
              key={title}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "10px",
              }}
            >
              <Typography variant="body1" sx={{ color: "#757575" }}>
                {title}
              </Typography>
              {value}
            </Box>
          )
        ))}
      </Box>
    </Widget>
  );
}

export default AccountWidget;
