import millify from "millify";
import { MillifyOptions } from "millify/dist/options";

export const numberFormats = {
  // @todo: should extend to support currencies as options prefixed & suffixed
  millify: (value: number, options?: Partial<MillifyOptions>): string => {
    if (!value) return "0";
    return millify(value, options);
  },
  currency: (value: number, currency?: string): string => {
    const _currency = currency ? `${currency} ` : "$";
    if (!value) return "0";
    const _value = numberFormats.millify(value);
    return `${_currency}${_value}`;
  }
};
