import { Button, Checkbox, CircularProgress, Grid, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import UserFeatureAccessWrapper from "components/UserFeatureAccessWrapper";
import { botsListSet, WorkerNameEnum } from "data/bots";
import { FeatureGroupSet } from "data/userPermissionsMap";
import AccountSimilarWinsCard from "./AccountSimilarWinsCard";
import { useSimilarWinsBot } from "./hooks";
import SimilarWinsForm from "./SimilarWinsForm";
import { ProgressionLoading } from "../shared";
import { AccountSimilarWins } from "types/api";

function SimilarWinsWorker({ widget = WorkerNameEnum.SIMILAR_ACCOUNTS }: { widget?: WorkerNameEnum }) {
  const similarWinsWorker = botsListSet.find((bot) => bot.key === WorkerNameEnum.SIMILAR_ACCOUNTS);
  const isSimilarAccountsWidget = widget === WorkerNameEnum.SIMILAR_ACCOUNTS;
  const {
    getSimilarWins,
    onCancelRequest,
    result,
    errorMessage,
    resetErrorMessage,
    loading: isLoading,
    selectedAccounts,
    onUpdateSelected,
    isSavingAccounts,
    onSaveAccountsToTerritory,
    onResetResults
  } = useSimilarWinsBot();
  const onSubmit = (accounts: string[]) => {
    getSimilarWins({ accounts });
  };

  if (!similarWinsWorker) {
    return (
      <Box>
        Bot not found
      </Box>
    );
  }

  const onSelectAccounts = (parentName: string, selected: string) => {
    if (selected === 'all') {
      const allAccounts = result.find((account) => account.name === parentName)?.accounts.map((account) => account.name) || [];
      const allSelected = allAccounts.every((account) => selectedAccounts.includes(account));
      if (allSelected) {
        onUpdateSelected((selectedAccounts) => selectedAccounts.filter((account) => !allAccounts.includes(account)));
      } else {
        onUpdateSelected(allAccounts);
      }
    } else {
      const index = selectedAccounts.indexOf(selected);
      if (index > -1) {
        onUpdateSelected((selectedAccounts) => selectedAccounts.filter((account) => account !== selected));
      } else {
        onUpdateSelected((selectedAccounts) => [...selectedAccounts, selected]);
      }
    }
  }

  const resultsNumber = result.map((account) => account.accounts.length).reduce((acc, curr) => acc + curr, 0);
  const isAllResultSelected = result.every((account) => account.accounts.every((acc) => selectedAccounts.includes(acc.name)));
  const onSelectAllAccounts = () => {
    if (isAllResultSelected) {
      const allAccounts = result.map((account) => account.accounts.map((acc) => acc.name)).flat();
      onUpdateSelected((selectedAccounts) => selectedAccounts.filter((account) => !allAccounts.includes(account)));
    } else {
      const allAccounts = result.map((account) => account.accounts.map((acc) => acc.name)).flat();
      onUpdateSelected(allAccounts);
    }
  }

  return (
    <Box sx={{
      p: "20px", display: "flex", flexDirection: "column", flexGrow: 1,
    }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          padding: "20px",
          backgroundColor: "#ffffff",
          borderRadius: "10px",
          border: "1px solid #e0e0e0",
          marginBottom: "20px",
        }}
      >
        <Box sx={{
          display: "flex", flexDirection: "row", gap: "20px", alignItems: "center",
        }}
        >
          <div style={{
            borderBottom: "1px solid #e0e0e0", paddingBottom: "10px", width: "70px", height: "70px", borderRadius: "50%", backgroundColor: "#f5f5f5", justifyContent: "center", alignItems: "center",
          }}
          >
            <img src={similarWinsWorker.image} alt="Similar wins Finder" style={{ width: "100%" }} />
          </div>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
            <Typography variant="h5">{similarWinsWorker.title}</Typography>
            <Typography variant="body1">{similarWinsWorker.description}</Typography>
          </Box>
        </Box>
        <SimilarWinsForm
          onFind={onSubmit}
          onResetErrorMessage={resetErrorMessage}
          isLoading={isLoading}
          onCancelRequest={onCancelRequest}
          onResetForm={onResetResults}
        />
      </Box>
      {isLoading ? (
        <ProgressionLoading searchEntityTitle="Similar accounts" />
      ) : (
        errorMessage ? (
          <Box sx={{
            padding: "20px", border: "1px solid #e0e0e0", borderRadius: "10px", marginBottom: "20px", backgroundColor: "#fff",
          }}
          >
            <Typography variant="body1">{errorMessage}</Typography>
          </Box>
        )
          : (
            <>
              {Boolean(result.length) && (
                <Box sx={{
                  padding: "20px",
                  border: "1px solid #e0e0e0",
                  borderRadius: "10px",
                  marginBottom: "20px",
                  backgroundColor: "#fff",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
                >

                  <Stack direction='row' gap='5px' alignItems={'center'} justifyContent={'center'}>
                    {isSimilarAccountsWidget && (<Checkbox checked={isAllResultSelected} onChange={onSelectAllAccounts} />)}
                    <Typography variant="body1">
                      Found {resultsNumber} similar accounts {isSimilarAccountsWidget && (`, select accounts to save to territory.`)}
                    </Typography>
                  </Stack>
                  {isSimilarAccountsWidget && (<Stack>
                    <Button onClick={onSaveAccountsToTerritory} disabled={!selectedAccounts.length || isLoading || isSavingAccounts} variant="outlined" sx={{ gap: '5px' }}>
                      <span>Save to territory</span>
                      {isSavingAccounts && <CircularProgress size={12} color="inherit" />}
                    </Button>
                  </Stack>)}
                </Box>)}
              <Stack direction='column' gap='20px'>
                {result.map((account: AccountSimilarWins) => (
                  <AccountSimilarWinsCard
                    key={account.name}
                    name={account.name}
                    accounts={account.accounts?.filter((acc) => account.name !== acc.name)}
                    onSelectAccounts={onSelectAccounts}
                    selectedAccounts={selectedAccounts}
                    isSimilarAccountsWidget={isSimilarAccountsWidget}
                  />
                ))}
              </Stack>
            </>
          )
      )
      }
    </Box >
  );
}

function SimilarWinsWorkerWrapper({ widget = WorkerNameEnum.SIMILAR_ACCOUNTS }: { widget?: WorkerNameEnum }) {
  const isSimilarAccountsWidget = widget === WorkerNameEnum.SIMILAR_ACCOUNTS;
  const accessGroup = isSimilarAccountsWidget ? FeatureGroupSet.SIMILAR_ACCOUNTS_WORKER : FeatureGroupSet.SIMILAR_WINS_WORKER;
  return (
    <UserFeatureAccessWrapper groupName={accessGroup}>
      <SimilarWinsWorker widget={widget} />
    </UserFeatureAccessWrapper>
  );
}
export default SimilarWinsWorkerWrapper;
