import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import App from "./App";
import "assets/css/third-party/react-table.css";

function RenderedApp() {
  return (
    <BrowserRouter>
      <App />
    </BrowserRouter>
  );
}

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(<RenderedApp />);
