import { Box, Button, Typography } from "@mui/material";

import Tooltip from "components/Tooltip";
import { useSearchQuery } from "hooks";

import Error from "./Error";
import Loader from "./Loader";
import usePeopleStats from "./usePeopleStats";
import Widget from "./Widget";
import { AccountTabsEnum } from "../..";

enum AccountPeopleTabFilter {
  statusAll = "all",
  statusContact = "contact",
  statusLead = "lead",
}

function PeopleWidget({ accountId }: { accountId: string }) {
  const { error, isLoading, peopleStats } = usePeopleStats({ accountId });
  const [, updateSearchQuery] = useSearchQuery<{
    open_tab: string;
    prefilter: string;
    filtered_column: string;
  }>();

  const onButtonClick = (targetTab: AccountTabsEnum, filter: AccountPeopleTabFilter) => {
    updateSearchQuery({
      open_tab: targetTab,
      prefilter: filter,
      filtered_column: "status",
    });
  };

  const onPeopleFilter = (filter: AccountPeopleTabFilter) => () => {
    onButtonClick(AccountTabsEnum.People, filter);
  };

  const formattedPeopleStats = {
    contactsCount: {
      count: peopleStats.contactsCount,
      tooltipTitle: "",
    },
    recentlyEngagedCount: {
      count: peopleStats.recentlyEngagedCount,
      tooltipTitle: "within the last 90 days",
    },
    recentLeadsCount: {
      count: peopleStats.recentLeadsCount,
      tooltipTitle: "within the last 90 days",
    },
  };

  return (
    <Widget
      title={(
        <Box>
          <Typography variant="body1" sx={{ fontSize: "1rem", fontWeight: 600 }}>
            People
          </Typography>
        </Box>
      )}
    >
      {isLoading ? (
        <Loader />
      ) : error ? (
        <Error error={error} />
      ) : (
        <Box>
          {[
            {
              key: "contactsCount",
              title: "Contacts",
              onClick: onPeopleFilter(AccountPeopleTabFilter.statusContact),
            },
            {
              key: "recentlyEngagedCount",
              title: "Recently engaged",
              onClick: onPeopleFilter(AccountPeopleTabFilter.statusAll),
            },
            {
              key: "recentLeadsCount",
              title: "Recent leads",
              onClick: onPeopleFilter(AccountPeopleTabFilter.statusLead),
            },
          ].map(({ key, title, onClick }) => {
            const stat = key as "contactsCount" | "recentlyEngagedCount" | "recentLeadsCount";

            const { tooltipTitle } = formattedPeopleStats[stat];

            const TitleButton = (
              <Typography component="div">
                <Button
                  variant="text"
                  sx={{
                    padding: 0,
                    justifyContent: "flex-start",
                    textTransform: "none",
                    color: "#2A7AB7",
                  }}
                  onClick={onClick}
                >
                  {title}
                </Button>
              </Typography>
            );

            return (
              <Box
                key={key}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "10px",
                }}
              >
                {tooltipTitle ? <Tooltip title={tooltipTitle}>{TitleButton}</Tooltip> : TitleButton}
                <Typography variant="body1" sx={{ color: "#333333" }}>
                  {formattedPeopleStats[stat].count}
                </Typography>
              </Box>
            );
          })}
        </Box>
      )}
    </Widget>
  );
}

export default PeopleWidget;
