
import { Check, LinkOutlined } from "@mui/icons-material";
import { Checkbox, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import { onGoToExternalLink } from "components/Activity.components/utils";
import { Table } from "components/common/Tables";
import { HeaderCell } from "pages/Settings/UsersManagement/common";
import { useMemo } from "react";
import { AccountSimilarWins } from "types/api";
import { numberFormats } from "utils/numbers";
import { getFaviconByDomain } from "utils/urlParsers";

interface AccountBusinessCardProps {
  name: string
  accounts: AccountSimilarWins['accounts']
  onSelectAccounts: (parentName: string, accounts: string) => void
  selectedAccounts?: string[]
  isSimilarAccountsWidget?: boolean
}
// 'Carousel Industries', 'Qentelli', 'TierPoint'
function AccountCard({ name: accountName, accounts: similarAccounts, selectedAccounts, isSimilarAccountsWidget, onSelectAccounts }: AccountBusinessCardProps) {

  const isAllAccountsSelected = useMemo(() => similarAccounts?.every(account => selectedAccounts?.includes(account.name)), [selectedAccounts, similarAccounts]);

  const onSelect = (selectedName: string) => () => {
    if (!isAllAccountsSelected) return;
    onSelectAccounts(accountName, selectedName);
    return;
  }

  const columns = [
    {
      Header: () => (
        <HeaderCell width="200px">
          {isSimilarAccountsWidget && <Checkbox onChange={onSelect('all')} checked={isAllAccountsSelected} />}
          Account Name
        </HeaderCell>
      ),
      accessor: "name",
      Cell: (cellProps: any) => {
        const hasImage = cellProps?.cell?.row?.original?.website;
        const accountCompanyLogo = getFaviconByDomain(cellProps?.cell?.row?.original.website);
        return (
          <Box sx={{ color: "#262626", display: 'flex', alignItems: 'center', gap: '5px' }}>
            {isSimilarAccountsWidget && <Checkbox onChange={onSelect(cellProps?.cell?.row?.original?.name)} checked={cellProps?.cell?.row?.original.isSelected} />}
            {hasImage && <img src={accountCompanyLogo} alt="company logo" style={{ width: "20px", height: "20px", borderRadius: "50%" }} />}
            <Typography>{cellProps?.cell?.row?.original.name}</Typography>
          </Box>
        );
      },
    },
    {
      Header: () => (
        <HeaderCell width="200px">
          Website
        </HeaderCell>
      ),
      accessor: "website",
      Cell: (cellProps: any) => {
        const withProtocolSuffix = cellProps?.cell?.row?.original.website.includes('http') ? cellProps?.cell?.row?.original.website : `https://${cellProps?.cell?.row?.original.website}`;
        const onClick = () => {
          onGoToExternalLink(withProtocolSuffix);
        }
        return (
          <Box sx={{ color: "#262626", display: 'flex', alignItems: 'center', gap: '5px' }}>
            <Typography>{withProtocolSuffix}</Typography>
            <LinkOutlined sx={{ fontSize: '16px', cursor: 'pointer', '&:hover': { color: '#1890ff' } }} onClick={onClick} />
          </Box>
        );
      },
    },
    {
      Header: () => (
        <HeaderCell width="130px">
          Annual Revenue
        </HeaderCell>
      ),
      accessor: "industry",
      style: { textAlign: "center" },
      Cell: (cellProps: any) => {
        return (
          <Box sx={{ color: "#262626", display: 'flex', justifyContent: 'center' }}>
            <Typography>{numberFormats.currency(cellProps?.cell?.row?.original.annualRevenue)}</Typography>
          </Box>
        );
      }
    },
    {
      Header: () => (
        <HeaderCell width="100px">
          Employees
        </HeaderCell>
      ),
      accessor: "employees",
      style: { textAlign: "center", border: 'none' },
      Cell: (cellProps: any) => {
        return (
          <Box sx={{ color: "#262626", display: 'flex', justifyContent: 'center', py: '7px' }}>
            <Typography>{numberFormats.millify(cellProps?.cell?.row?.original.numberOfEmployees)}</Typography>
          </Box>
        );
      }
    }
  ]


  return (
    <Box sx={{
      display: "flex",
      flex: 1,
      flexDirection: "column",
      gap: "10px",
      padding: "20px",
      backgroundColor: "#ffffff",
      borderRadius: "10px",
      border: "1px solid #e0e0e0",
      width: "100%",
    }}
    >
      <Box>
        <Stack gap="10px" direction="row" alignItems="center">
          <Typography variant="h5">{accountName}</Typography>
        </Stack>
      </Box>
      <Stack flex={1} direction="row" gap="10px" width={'100%'} sx={{ borderRadius: '10px', overflow: 'hidden' }}>
        <Table
          columns={columns}
          data={similarAccounts.map((account) => ({ ...account, isSelected: selectedAccounts?.includes(account.name) })) as any}
          noDataMessage="No similar accounts found"
          stickyHeader={false}
          memoDeps={[...(selectedAccounts || [])]}
          cellSX={{
            padding: "15px 0",
            border: 'none !important',
          }}
        />
      </Stack>
    </Box>
  );
}

export default AccountCard;
