import { Stack } from "@mui/material";

import { IActivity } from "types/api/activities";

import { PersonLabel } from "./common";
import { ActivityContentInteractions, EmailField } from "../common";
import { onGoToExternalLink } from "../utils";

interface IWebContentProps {
  activity: IActivity;
  onActionTrigger?: (action: ActivityContentInteractions, value?: string, name?: string) => void;
}

export function WebContent({ activity, onActionTrigger }: IWebContentProps) {
  const onFilterByPerson = (personName?: string, personId?: string) => {
    if (onActionTrigger && personName) {
      onActionTrigger(ActivityContentInteractions.FilterByPerson, personName, personId);
    }
  };

  const onFilterByAccount = (accountName?: string, accountId?: string) => {
    if (onActionTrigger && accountName) {
      onActionTrigger(ActivityContentInteractions.FilterByAccount, accountId, accountName);
    }
  };

  const onVisitedLinkClicked = () => {
    onGoToExternalLink(activity?.visitedLink);
  };

  const person = {
    name: activity.secondParty?.personName,
    email: activity.secondParty?.email,
    title: activity.secondParty?.title,
    id: activity.secondParty?.whoId,
  };

  const account = {
    name: activity.secondParty?.accountName,
    id: activity.secondParty?.accountId,
  };

  const fallbackActivityIssuer = account.name ? `Someone from ${account.name}.` : "";
  const isHighlighted = Boolean(activity?.visitedLink);

  return (
    <Stack direction="column" sx={{ width: "100%", wordWrap: "break-word" }}>
      <Stack direction="column" sx={{ width: "100%" }}>
        <EmailField
          label="Who:"
          valueSx={{ textTransform: person.name ? "capitalize" : "none" }}
          value={(
            <PersonLabel
              onClick={() => (person.name
                ? onFilterByPerson(person.name, person.id)
                : onFilterByAccount(account.name, account.id))}
              value={person.name || fallbackActivityIssuer}
              tipDetails={
                person.name
                  ? {
                    email: person?.email,
                    name: person?.name,
                    // @note: missing data to show these
                    // phoneNumber: '+92342342342',
                    // linkedInProfile: 'http://link.in',
                    jobTitle: person?.title,
                  }
                  : undefined
              }
            />
          )}
        />
        <EmailField
          label="Visited:"
          value={<span onClick={onVisitedLinkClicked}>{activity.subject}</span>}
          valueSx={{ width: "75%", display: "block" }}
          isHighlighted={isHighlighted}
        />
      </Stack>
    </Stack>
  );
}

export default WebContent;
