import { create } from "zustand";

import { ActivityAPI } from "services";
import { ActivitySearchResponse, IActivity } from "types/api/activities";

import { StateRecord } from "./main";
import { cleanUpUserIdsSuffix } from "./utils";

interface Activities extends Omit<ActivitySearchResponse, "activities"> {
  limit: number;
  offset: number;
  activities: IActivity[];
  isLoadingMore?: boolean;
  filters: {
    focusedAccount?: {
      id: string;
      name: string;
      signalSegment?: string;
    };
    isFilterOnSelectedAccounts?: boolean;
    query?: string;
  };
}

interface ActivitiesState extends StateRecord {
  isLoading?: boolean;
  state: Activities;
  actions: {
    search: (...args: Parameters<typeof ActivityAPI.search>) => Promise<Partial<Activities>>;
    updateState: (newStateUpdates: Partial<Activities>) => void;
    filterForAccount: (accountId: Activities["filters"]["focusedAccount"]) => Promise<void>;
    filterByPerson: (person: string) => Promise<void>;
    onToggleFilterOnSelectedAccounts: () => void;
    loadMoreResultActivities: (
      ...args: Parameters<typeof ActivityAPI.search>
    ) => Promise<Partial<Activities>>;
  };
}

const initialActivitiesState = {
  activities: [],
  filters: {},
  totalResultCount: 0,
} as Partial<Activities>;

const useActivities = create<ActivitiesState>((setState, getState) => ({
  isLoading: false,
  state: initialActivitiesState as Activities,
  actions: {
    async search(params, requestConfig) {
      setState({ isLoading: true });
      const userIds = cleanUpUserIdsSuffix(params.userIds);
      const { error, activities, totalResultCount } = await ActivityAPI.search(
        { ...params, userIds },
        requestConfig,
      );
      if (error?.code === "ERR_CANCELED") {
        return { activities, totalResultCount };
      }
      const { state } = getState();
      setState({
        isLoading: false,
        state: {
          ...params,
          ...state,
          activities,
          totalResultCount,
        },
      });
      return { activities, totalResultCount };
    },
    updateState: (newStateUpdates: Partial<Activities>) => {
      const { state } = getState();
      setState({ state: { ...state, ...newStateUpdates } });
    },
    onToggleFilterOnSelectedAccounts() {
      const { actions, state } = getState();
      actions.updateState({
        filters: {
          isFilterOnSelectedAccounts: !state?.filters?.isFilterOnSelectedAccounts,
        },
      });
    },
    async loadMoreResultActivities(params, requestConfig) {
      const { actions, state } = getState();
      actions.updateState({ isLoadingMore: true });
      const { error, activities, totalResultCount } = await ActivityAPI.search(params, requestConfig);
      if (error?.code === "ERR_CANCELED") {
        return { activities, totalResultCount };
      }
      const newActivities = [...state.activities, ...activities];
      actions.updateState({ isLoadingMore: true, activities: newActivities, totalResultCount });
      return { activities, totalResultCount };
    },
    async filterForAccount(focusedAccount) {
      const { actions } = getState();
      actions.updateState({ filters: { focusedAccount, isFilterOnSelectedAccounts: false } });
    },
    async filterByPerson(personName: string) {
      const { actions, state } = getState();
      actions.updateState({ filters: { ...state.filters, query: personName } });
    },
  },
}));

export default useActivities;
