import type { AccountOpportunities, AccountOpportunitiesFilters } from "types/account";
import { AxiosNode } from "utils/axios";
import { paramsFromStringQuery } from "utils/urlParsers";

// these types should be moved to the types folder
interface OpportunitiesResponseBody {
  items: AccountOpportunities[];
  itemsCount: number;
  filters?: {
    opportunitySources: string[];
    opportunityTypes: string[];
    value: {
      min: number;
      max: number;
    };
  };
}

export interface StatsRecord {
  count: number;
  amount: number;
}

export interface AccountsOpportunityStatsGroup {
  count: number;
  openOpportunities: StatsRecord;
  winsToDate: StatsRecord;
  lossesToDate: StatsRecord;
}
export interface AccountsOpportunityStatsResponseBody {
  closedOpportunities: number;
  openOpportunities: number;
  uncapturedOpportunities: number;
  targetAccounts: AccountsOpportunityStatsGroup;
  otherAccounts: AccountsOpportunityStatsGroup;
}

class OpportunityAPI {
  static fetchOpportunitiesStats = async (
    opt: { accounts: string[]; selectedUserIds?: string[] } = { accounts: [], selectedUserIds: [] },
  ) => {
    const { data } = await AxiosNode.post<AccountsOpportunityStatsResponseBody>(
      "/opportunities/stats",
      { userIds: opt.selectedUserIds, accounts: opt.accounts },
    );
    return data;
  };

  static filterOpportunities = async (
    filters: AccountOpportunitiesFilters,
    signal: AbortSignal,
  ) => {
    const filtersParams = paramsFromStringQuery(filters);
    const { data } = await AxiosNode.get<OpportunitiesResponseBody>(
      `/opportunities?${filtersParams}`,
      { signal },
    );

    return { data };
  };
}

export default OpportunityAPI;
