import { Chip, SxProps } from "@mui/material";
import { ReactElement } from "react";

interface IChipProps {
  name: string;
  truncateLimit?: number;
  sx?: SxProps;
  icon?: ReactElement;
}

// @todo: add tooltip here.
export function TableChip(props: IChipProps) {
  const { name, sx, icon, } = props;
  if (!name) return null;

  const truncateLimit = props.truncateLimit || name?.length
  const truncatedName = (name && name?.length > truncateLimit) ? `${name.slice(0, truncateLimit - 3)}...` : (name || 'N/A');
  return (
    <Chip
      icon={icon}
      className="truncate"
      label={truncatedName}
      sx={{ color: "#1A1A1A", backgroundColor: "#E1EDFA", ...sx }}
    />
  );
}
