import { create } from "zustand";

import UserAPI from "services/user";

import { StateRecord } from "./main";

export interface ConversionRateState {
  conversionRate: number;
  orgConversionRate?: number;
}

interface IConversionRateState extends StateRecord {
  isLoading?: boolean;
  state: ConversionRateState;
  errors?: any;
  actions: {
    get: (
      payload: Parameters<typeof UserAPI.getUserConversionRateDetails>[0]
    ) => Promise<ConversionRateState>;
  };
}

const initialConversionRateState = {
  conversionRate: 0,
  orgConversionRate: 0,
} as ConversionRateState;

const useConversionRateState = create<IConversionRateState>((set, getState) => ({
  isLoading: false,
  state: initialConversionRateState,
  actions: {
    get: async (payload: Parameters<typeof UserAPI.getUserConversionRateDetails>[0]) => {
      set({ isLoading: true });
      try {
        const conversionRateData = await UserAPI.getUserConversionRateDetails(payload);
        // const response = await UserAPI.getUserConversionRateDetails(payload);
        set({ isLoading: false, state: conversionRateData });
        return conversionRateData;
      } catch (error) {
        set({ isLoading: false, errors: error });
        return initialConversionRateState;
      }
    },
  },
}));

export default useConversionRateState;
