import { Box, Typography } from "@mui/material";

import { backgroundColors, textColors } from "components/DropdownMenu";
import Tooltip from "components/Tooltip";
import { Account } from "types/api";

import Widget from "./Widget";

const moveToTheEnd = (arr: string[], element: string) => {
  const copiedArray = [...arr];

  copiedArray.forEach((e, i) => {
    if (e.toLowerCase() === element.toLowerCase()) {
      copiedArray.splice(i, 1);
      copiedArray.push(e);
    }
  });

  return copiedArray;
};

const formatStatus = (status?: string | null) => (status ? status.toLocaleLowerCase().trim() : "unknown interest");

const getProductsStatus = (products: Account["products"]) => [...new Set(products?.map((p) => formatStatus(p?.status)).filter(Boolean))].sort((a, b) => a.localeCompare(b));

const getProductsStatusesCountMap = (products: Account["products"]) => products.reduce((acc: { [key: string]: number }, v) => {
  const formattedStatus = formatStatus(v?.status);
  if (acc[formattedStatus]) {
    acc[formattedStatus] += 1;
  } else {
    acc[formattedStatus] = 1;
  }

  return acc;
}, {});
const typedBackgroundColors = backgroundColors as { [key: string]: string };
const typedTextColors = textColors as { [key: string]: string };
const formattedBackgroundColors = Object.entries(typedBackgroundColors).reduce(
  (acc: { [key: string]: string }, v) => {
    const key = v[0].toLocaleLowerCase();
    acc[key] = v[1];

    return acc;
  },
  {},
);
const formattedTypedTextColors = Object.entries(typedTextColors).reduce(
  (acc: { [key: string]: string }, v) => {
    const key = v[0].toLocaleLowerCase();
    acc[key] = v[1];

    return acc;
  },
  {},
);

function ProductSalesWidget({ products }: { products: Account["products"] }) {
  const totalProducts = products?.length;
  const productsStatuses = moveToTheEnd(getProductsStatus(products), "unknown interest"); // unknown should be the last element in the statuses bar
  const productsStatusesCountMap = getProductsStatusesCountMap(products);

  return (
    <Widget
      title={(
        <Box>
          <Typography variant="body1" sx={{ fontSize: "1rem", fontWeight: 600 }}>
            Product Sales Status
          </Typography>
        </Box>
      )}
    >
      {totalProducts > 0 ? (
        <Box>
          <Box sx={{ display: "flex", gap: "10px", flexWrap: "wrap" }}>
            {productsStatuses.map((status) => (
              <Box
                key={status}
                sx={{
                  padding: "1px 8px",
                  border: "1px solid #E6E6E6",
                  background: formattedBackgroundColors[status] || "#FFFFFF",
                  color: formattedTypedTextColors[status] || "#000",
                }}
              >
                <Typography variant="caption">{status}</Typography>
              </Box>
            ))}
          </Box>
          <Box sx={{ marginTop: "10px" }}>
            <Box sx={{ height: 20, display: "flex", flexDirection: "row" }}>
              {productsStatuses.map((status) => (
                <Tooltip key={status} title={`${productsStatusesCountMap[status]}`}>
                  <Box
                    sx={{
                      height: 30,
                      width: `${(productsStatusesCountMap[status] / totalProducts) * 100}%`,
                      background: formattedBackgroundColors[status] || "#FFFFFF",
                      border: "1px solid #C8C8C8",
                    }}
                  />
                </Tooltip>
              ))}
            </Box>
          </Box>
        </Box>
      ) : (
        <Box>
          <Typography variant="body1" sx={{ fontSize: "1rem", fontWeight: 600 }}>
            No products for this account
          </Typography>
        </Box>
      )}
    </Widget>
  );
}

export default ProductSalesWidget;
