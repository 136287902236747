import { Box } from "@mui/material";
import cx from "classnames";
import React, { useEffect } from "react";

import styles from "./PulsEffect.module.css";

interface IPulsEffectProps {
  isRunning?: boolean;
  children: React.ReactNode;
  animateIndependencyChange?: boolean;
  changeDeps?: any;
}

export function PulsEffectBox({
  isRunning,
  children,
  animateIndependencyChange = true,
  changeDeps,
  ...props
}: IPulsEffectProps & Parameters<typeof Box>[0]) {
  const pulsElementRef = React.useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (animateIndependencyChange) {
      pulsElementRef.current?.classList.add(styles.animateOnce);
      setTimeout(() => {
        pulsElementRef.current?.classList.remove(styles.animateOnce);
      }, 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [animateIndependencyChange, JSON.stringify(changeDeps)]);

  return (
    <Box
      ref={pulsElementRef}
      className={cx(styles.pulsEffect, props.className, { [styles.running]: isRunning })}
      {...props}
    >
      {children}
    </Box>
  );
}

export default PulsEffectBox;
