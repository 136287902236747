import { create } from "zustand";

import { TerritoryAPI } from "services";
import { DynamicFilterT, DynFilterColumn } from "types/api";

import { StateRecord } from "./main";
import { TERRITORY_COLUMN_MAP } from "constants/territory";
import { addSelectionColumnToTerritoryColumns, } from "./utils";
import { userStorage } from "utils/auth";


interface TerritoryMetaDataStore {
  filters: DynamicFilterT,
  columns: DynFilterColumn
}
interface IFiltersMetaData extends StateRecord {
  isLoading?: boolean;
  state: TerritoryMetaDataStore;
  errors?: any;
  actions: {
    initialize: () => Promise<DynamicFilterT>;
    updateVisibleColumns: (columns: DynFilterColumn) => DynFilterColumn
  };
}



function mergeStoredWithFreshMeta(fresh: DynamicFilterT, stored: DynamicFilterT) {
  if (!fresh) return {};
  const entries = Object.entries(fresh).map(([key, value]) => {
    const storedValue = stored?.[key as keyof DynamicFilterT];
    if (!storedValue) return [
      key,
      {
        ...(value as object),
        isViewed: true
      }
    ];
    return [key, {
      ...(value as object),
      ...(storedValue as object),
    }]
  })
  const object = Object.fromEntries(entries);
  return object
};

const useFiltersMetaData = create<IFiltersMetaData>((setState, getState) => ({
  isLoading: false,
  state: {
    filters: {},
    columns: userStorage.get(TERRITORY_COLUMN_MAP)
  } as TerritoryMetaDataStore,
  actions: {
    initialize: async () => {
      const result = await TerritoryAPI.filtersMetaData();
      const stored = userStorage.get(TERRITORY_COLUMN_MAP)
      const merged = mergeStoredWithFreshMeta(result?.columnData?.Account as any, stored);
      const shapingColumns = addSelectionColumnToTerritoryColumns(merged)
      setState({
        state: {
          columns: shapingColumns || {},
          filters: result.filters || {}
        }
      });
      shapingColumns && userStorage.set(`${TERRITORY_COLUMN_MAP}`, shapingColumns);
      return result.filters;
    },
    updateVisibleColumns: (columns) => {
      userStorage.set(`${TERRITORY_COLUMN_MAP}`, columns);
      setState(({ state }) => ({
        state: { ...state, columns: columns },
      }));
      return columns
    }
  },
}));

export default useFiltersMetaData;
