import {
  Box, Button, Divider, Menu, MenuItem,
} from "@mui/material";
import { MouseEvent, useRef, useState } from "react";

import Tooltip from "components/Tooltip";
import { ISimilarWin } from "types/territory";

import { SimilarWinsChip } from "./SimilarWinsChip";
import { getSimilarWinsText } from "../../utils";
import { TableChip } from "../Chip";

export const temp = {};

export function SimilarWins({ similarWins, label }: { similarWins: ISimilarWin[], label: string }) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const hoverTimeout = useRef<NodeJS.Timeout | null>(null);
  const open = Boolean(anchorEl);
  const similarWinsText = getSimilarWinsText(label, similarWins);
  const hideTooltip = Boolean(!similarWinsText || similarWinsText.length < 12);
  const hasMenu = similarWins?.length > 1;

  const handleMouseEnter = (event: MouseEvent<HTMLButtonElement>) => {
    if (hoverTimeout.current) {
      clearTimeout(hoverTimeout.current);
      hoverTimeout.current = null;
    }
    setAnchorEl(event.currentTarget);
  };

  const handleMouseLeave = () => {
    hoverTimeout.current = setTimeout(() => {
      setAnchorEl(null);
    }, 1000 * 1);
  };

  const handleMenuMouseEnter = () => {
    if (hoverTimeout.current) {
      clearTimeout(hoverTimeout.current);
      hoverTimeout.current = null;
    }
  };

  const handleMenuMouseLeave = () => {
    hoverTimeout.current = setTimeout(() => {
      setAnchorEl(null);
    }, 200);
  };

  return (
    <div>
      <Tooltip placement="top" hide={hideTooltip} title={<span>{similarWinsText}</span>}>
        <Button
          // variant="light"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          sx={{
            color: "#1A1A1A",
            justifyContent: "center",
            padding: "0",
            cursor: hasMenu ? "pointer" : "auto",
            "&:hover": {
              backgroundColor: "transparent",
              color: "#1A1A1A",
            },
          }}
        >
          {similarWins?.length ? (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <SimilarWinsChip shouldRenderIcon={hasMenu} accountName={similarWinsText || "--"} />
            </Box>
          ) : (
            "--"
          )}
        </Button>
      </Tooltip>

      {hasMenu && (
        <Menu
          sx={{
            boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
            "& .MuiMenu-paper": {
              boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
            },
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleMouseLeave}
          MenuListProps={{
            onMouseEnter: handleMenuMouseEnter,
            onMouseLeave: handleMenuMouseLeave,
          }}
          transformOrigin={{ vertical: 0, horizontal: 10 }}
        >
          {similarWins?.filter((win) => win && win?.accountName).map((win: ISimilarWin, i: number, list: ISimilarWin[]) => {
            const borderBottom = i < list.length - 1 ? "1px #eee solid" : "none";
            const margin = i < list.length - 1 ? "10px 0" : "10px 0 5px 0";
            return (
              <MenuItem
                sx={{
                  padding: "0px",
                  justifyContent: "flex-start",
                  minWidth: "130px",
                  cursor: "auto",
                  "&:hover": { backgroundColor: "transparent" },
                }}
                key={win.accountName}
              >
                <Tooltip
                  placement="top"
                  hide={win.accountName?.length < 40}
                  title={(
                    <span>
                      {win.accountName}
                    </span>
                  )}
                >
                  <Box
                    sx={{
                      padding: "0 10px",
                      width: "100%",
                      display: "flex",
                      justifyContent: "flex-start",
                      borderBottom,
                    }}
                  >
                    <TableChip
                      truncateLimit={40}
                      sx={{
                        borderRadius: "24px",
                        minWidth: "110px",
                        margin,
                        fontSize: "12px",
                        height: "24px",
                        textAlign: "center",
                      }}
                      name={win.accountName}
                    />
                    {/* <Divider /> */}
                  </Box>
                </Tooltip>
                <Divider />
              </MenuItem>
            );
          })}
        </Menu>
      )}
    </div>
  );
}
