import { OrderDirectionEnum } from "types/account";
import { Account } from "./accounts";
import { RangeFilter } from "./territoryFilters";

export interface TerritoryState {
  id: string;
  instanceId: string;
  userId: string;
  FiscalYear: 0;
  subordinates: any;
  managerId: string;
  accounts: TerritoryAccount[];
}

export interface TerritoryAccount {
  accountName: string;
  accountId: string;
  ownerUid: string;
  rank: number;
  TAMamount: number;
  products: TerritoryAccountProduct[];
  lastSignalDate: string;
  intentSignal: false;
  marketingSignal: false;
  status: any;
  nextRenewalDate: any;
  openOpportunitiesAmount: number;
  similarWins: ISimilarWin[];
  Renewals: [];
  employeeCount: number;
  annualRevenue: number;
  annualRecurringRevenue: number;
  industry: string;
  salesforceLink: string;
  targetAccount: boolean;
}

export interface ISimilarWin {
  accountId: string;
  accountName: string;
  opportunityAmount: number;
  opportunityCloseDate: Date | string;
  opportunityId: string;
  opportunityName: string;
  sellerName: string;
}

export interface TerritoryAccountProduct {
  productId: string;
  productName: string;
  productWhitespaceAmount: number;
  inputtedProductWhitespaceAmount: any;
  calculatedProductWhitespaceAmount: number;
  accountId: any;
  uid: any;
  productFamily: string;
  status: string;
  productRevenue: number;
  inputtedProductRevenue: any;
  calculatedProductRevenue: any;
  installedCompetitor: any;
}

export interface TeamsAlignmentFilters {
  accountIds: string[];
  userId: string;
  startDate?: string;
  endDate?: string;
}

export interface TeamsAlignment {
  userId: string;
  instanceId: string;
  startDate?: string;
  endDate?: string;
  accountsIds: string[];
  planAccountsIds?: string[];
  openAccountsIds?: string[];
  salesActivity: string[];
  marketingActivity: string[];
  customerSuccessActivity: string[];
  SDRSuccessActivity: string[];
}

export interface UpdateAccountResponse {
  message: string;
  resource: Account;
}

export enum TerritoryTableColumnsAccessors {
  selection = "id",
  account = "name",
  pipelineSignal = "pipelineSignal",
  rankValue = "rankValue",
  priority = "priority",
  accountStatus = "accountStatus",
  lastSalesActivity = "lastSalesActivity",
  lastCustomerSignal = "lastCustomerSignal",
  employees = "employees",
  industry = "industry",
  annualRevenue = "annualRevenue",
  similarWins = "similarWins",
  nextRenewalDate = "nextRenewalDate",
  annualRecurringRevenue = "annualRecurringRevenue",
  ownerName = "ownerName",
  whitespace = "whitespace",
  sdrPersonName = "sdrPersonName",
  OBLastActivitySDR = "OBLastActivitySDR",
  AELastActivity = "AELastActivity",
  RecordType = "RecordType",
  emailProvider = "emailProvider",
  CRM = "CRM",
  country = "location",
  city = "city",
  state = "state",
  openOpportunitiesAmount = "openOpportunitiesAmount",
  closedOpportunitiesAmount = "closedOpportunitiesAmount",
  totalClosedOpportunities = "totalClosedOpportunities",
  totalOpenOpportunities = "totalOpenOpportunities",
  totalLosesToDate = "totalLosesToDate",
  totalWinsToDate = "totalWinsToDate",
  alexaRanking = 'alexaRanking',
  renewals = 'renewals',
  lastSignalDate = 'lastSignalDate',
  technologies = 'technologies'
}


export const TerritoryTableDefaultColumns = {
  [TerritoryTableColumnsAccessors.selection]: TerritoryTableColumnsAccessors.selection,
  [TerritoryTableColumnsAccessors.account]: TerritoryTableColumnsAccessors.account,
  [TerritoryTableColumnsAccessors.ownerName]: TerritoryTableColumnsAccessors.ownerName,
  [TerritoryTableColumnsAccessors.priority]: TerritoryTableColumnsAccessors.priority,
  [TerritoryTableColumnsAccessors.accountStatus]: TerritoryTableColumnsAccessors.accountStatus,
  [TerritoryTableColumnsAccessors.similarWins]: TerritoryTableColumnsAccessors.similarWins,
  [TerritoryTableColumnsAccessors.nextRenewalDate]: TerritoryTableColumnsAccessors.nextRenewalDate,
  [TerritoryTableColumnsAccessors.industry]: TerritoryTableColumnsAccessors.industry,
  [TerritoryTableColumnsAccessors.annualRevenue]: TerritoryTableColumnsAccessors.annualRevenue,
  [TerritoryTableColumnsAccessors.employees]: TerritoryTableColumnsAccessors.employees,
  [TerritoryTableColumnsAccessors.city]: TerritoryTableColumnsAccessors.city,
  [TerritoryTableColumnsAccessors.state]: TerritoryTableColumnsAccessors.state,
  [TerritoryTableColumnsAccessors.country]: TerritoryTableColumnsAccessors.country,
  [TerritoryTableColumnsAccessors.lastCustomerSignal]: TerritoryTableColumnsAccessors.lastCustomerSignal,
  [TerritoryTableColumnsAccessors.lastSalesActivity]: TerritoryTableColumnsAccessors.lastSalesActivity,
  [TerritoryTableColumnsAccessors.totalOpenOpportunities]: TerritoryTableColumnsAccessors.totalOpenOpportunities,
  [TerritoryTableColumnsAccessors.openOpportunitiesAmount]: TerritoryTableColumnsAccessors.openOpportunitiesAmount,
  [TerritoryTableColumnsAccessors.totalClosedOpportunities]: TerritoryTableColumnsAccessors.totalClosedOpportunities,
  [TerritoryTableColumnsAccessors.closedOpportunitiesAmount]: TerritoryTableColumnsAccessors.closedOpportunitiesAmount,
  [TerritoryTableColumnsAccessors.totalWinsToDate]: TerritoryTableColumnsAccessors.totalWinsToDate,
  [TerritoryTableColumnsAccessors.totalLosesToDate]: TerritoryTableColumnsAccessors.totalLosesToDate,
};

export const TerritoryTableColumnsAccessorsOrder = {
  [TerritoryTableColumnsAccessors.selection]: 1,
  [TerritoryTableColumnsAccessors.account]: 2,
  [TerritoryTableColumnsAccessors.ownerName]: 3,
  [TerritoryTableColumnsAccessors.priority]: 4,
  [TerritoryTableColumnsAccessors.accountStatus]: 5,
  [TerritoryTableColumnsAccessors.pipelineSignal]: 6,
  [TerritoryTableColumnsAccessors.rankValue]: 6.1,
  [TerritoryTableColumnsAccessors.similarWins]: 7,
  [TerritoryTableColumnsAccessors.nextRenewalDate]: 8,
  [TerritoryTableColumnsAccessors.industry]: 9,
  [TerritoryTableColumnsAccessors.annualRevenue]: 10,
  [TerritoryTableColumnsAccessors.employees]: 11,
  [TerritoryTableColumnsAccessors.city]: 12,
  [TerritoryTableColumnsAccessors.state]: 13,
  [TerritoryTableColumnsAccessors.country]: 14,
  [TerritoryTableColumnsAccessors.lastCustomerSignal]: 15,
  [TerritoryTableColumnsAccessors.lastSalesActivity]: 16,
  [TerritoryTableColumnsAccessors.lastSignalDate]: 17,
  [TerritoryTableColumnsAccessors.totalOpenOpportunities]: 18,
  [TerritoryTableColumnsAccessors.openOpportunitiesAmount]: 19,
  [TerritoryTableColumnsAccessors.totalClosedOpportunities]: 20,
  [TerritoryTableColumnsAccessors.closedOpportunitiesAmount]: 21,
  [TerritoryTableColumnsAccessors.totalWinsToDate]: 22,
  [TerritoryTableColumnsAccessors.totalLosesToDate]: 23,
}

export const WhitespaceTableDefaultColumns = {
  [TerritoryTableColumnsAccessors.selection]: TerritoryTableColumnsAccessors.selection,
  [TerritoryTableColumnsAccessors.account]: TerritoryTableColumnsAccessors.account,
  [TerritoryTableColumnsAccessors.pipelineSignal]: TerritoryTableColumnsAccessors.pipelineSignal,
  [TerritoryTableColumnsAccessors.priority]: TerritoryTableColumnsAccessors.priority,
  [TerritoryTableColumnsAccessors.nextRenewalDate]: TerritoryTableColumnsAccessors.nextRenewalDate,
  [TerritoryTableColumnsAccessors.whitespace]: TerritoryTableColumnsAccessors.whitespace,
};

export const TerritoryTableColumnTitles = {
  [TerritoryTableColumnsAccessors.selection]: "Selection",
  [TerritoryTableColumnsAccessors.account]: "Account Name",
  [TerritoryTableColumnsAccessors.pipelineSignal]: "Suitability",
  [TerritoryTableColumnsAccessors.rankValue]: "Revic Rank",
  [TerritoryTableColumnsAccessors.priority]: "Priority",
  [TerritoryTableColumnsAccessors.accountStatus]: "Status",
  [TerritoryTableColumnsAccessors.lastSalesActivity]: "Last Sales Attempt",
  [TerritoryTableColumnsAccessors.lastCustomerSignal]: "Last recorded customer signal",
  [TerritoryTableColumnsAccessors.employees]: "Employees",
  [TerritoryTableColumnsAccessors.industry]: "Industry",
  [TerritoryTableColumnsAccessors.annualRevenue]: "Revenue",
  [TerritoryTableColumnsAccessors.similarWins]: "Similar Wins",
  [TerritoryTableColumnsAccessors.annualRecurringRevenue]: "Annual Recurring Revenue",
  [TerritoryTableColumnsAccessors.whitespace]: "Whitespace",
  [TerritoryTableColumnsAccessors.nextRenewalDate]: "Renewal Date",
  [TerritoryTableColumnsAccessors.ownerName]: "Account Owner",
  [TerritoryTableColumnsAccessors.sdrPersonName]: "SDR Person",
  [TerritoryTableColumnsAccessors.OBLastActivitySDR]: "OB-SDR Last Activity",
  [TerritoryTableColumnsAccessors.AELastActivity]: "AE Last Activity",
  [TerritoryTableColumnsAccessors.RecordType]: "Account Record Type",
  [TerritoryTableColumnsAccessors.emailProvider]: "Email Provider",
  [TerritoryTableColumnsAccessors.CRM]: "CRM",
  [TerritoryTableColumnsAccessors.country]: "Country",
  [TerritoryTableColumnsAccessors.city]: "City",
  [TerritoryTableColumnsAccessors.state]: "State",
  [TerritoryTableColumnsAccessors.openOpportunitiesAmount]: "Total Open Opportunities Amount",
  [TerritoryTableColumnsAccessors.closedOpportunitiesAmount]: "Total Closed Opportunities Amount",
  [TerritoryTableColumnsAccessors.totalClosedOpportunities]: "Total Closed Opportunities",
  [TerritoryTableColumnsAccessors.totalOpenOpportunities]: "Total Open Opportunities",
  [TerritoryTableColumnsAccessors.totalLosesToDate]: "Total Loses",
  [TerritoryTableColumnsAccessors.totalWinsToDate]: "Total Wins",
  [TerritoryTableColumnsAccessors.alexaRanking]: "Alexa Ranking",
  [TerritoryTableColumnsAccessors.renewals]: "Renewals",
  [TerritoryTableColumnsAccessors.lastSignalDate]: "Last Signal Date",
  [TerritoryTableColumnsAccessors.technologies]: "Technologies",
};

export type HeadQuerter = {
  city?: string;
  country?: string;
  state?: string;
  street?: string;
};

type NumberOfRecords = {
  technographicsCount: number;
};

export interface TerritoryFiltersMetaData {
  industries: string[];
  headquarters?: HeadQuerter[];
  annualRevenueMinMax?: RangeFilter;
  whitespaceMinMax?: RangeFilter;
  NumberOfRecords?: NumberOfRecords;
}

export interface TerritoryAccountProductsFilterResponse {
  groupedProducts: VendorProduct[];
}

export interface VendorProduct {
  vendor: string;
  products: string[];
  accountIds: string[];
}

export interface TerritoryAccountProductsFilterPayload {
  searchQuery: string;
  offset?: string;
  limit?: string;
}

// @todo: this is a temporary solution. Once we can reliably use the metadata endpoint to dynamically generate columns, we can remove this.
export const TerritoryTableColumnWidths = {
  [TerritoryTableColumnsAccessors.selection]: 35,
  [TerritoryTableColumnsAccessors.account]: 275,
  [TerritoryTableColumnsAccessors.pipelineSignal]: 150,
  [TerritoryTableColumnsAccessors.priority]: 66,
  [TerritoryTableColumnsAccessors.accountStatus]: 177,
  [TerritoryTableColumnsAccessors.lastSalesActivity]: 170,
  [TerritoryTableColumnsAccessors.lastCustomerSignal]: 250,
  [TerritoryTableColumnsAccessors.employees]: 89,
  [TerritoryTableColumnsAccessors.industry]: 200,
  [TerritoryTableColumnsAccessors.annualRevenue]: 70,
  [TerritoryTableColumnsAccessors.similarWins]: 110,
  [TerritoryTableColumnsAccessors.annualRecurringRevenue]: 189,
  [TerritoryTableColumnsAccessors.whitespace]: 110,
  [TerritoryTableColumnsAccessors.nextRenewalDate]: 170,
  [TerritoryTableColumnsAccessors.ownerName]: 130,
  [TerritoryTableColumnsAccessors.OBLastActivitySDR]: 180,
  [TerritoryTableColumnsAccessors.AELastActivity]: 150,
  [TerritoryTableColumnsAccessors.RecordType]: 180,
  [TerritoryTableColumnsAccessors.emailProvider]: 150,
  [TerritoryTableColumnsAccessors.CRM]: 150,
};

export enum FilterTypesEnum {
  String = "String",
  Range = "Range",
  Choice = "Choice",
  Enum = "Enum",
  DateRange = "DateRange",
  Hierarchy = "Hierarchy",
  Number = "Number",
}

export enum MinMaxFilterTypes {
  Number = "Number",
  Date = "Date",
}

export type StringFilterT = {
  type: FilterTypesEnum;
  value?: string[];
  sort?: OrderDirectionEnum;
  options: string[];
}

export type RangeFilterT = {
  type: FilterTypesEnum;
  options: RangeFilter;
  value?: RangeFilter;
  sort?: OrderDirectionEnum;
}

export type ChoiceFilterT = {
  type: FilterTypesEnum;
  options: string[];
  value?: string;
  sort?: OrderDirectionEnum;
}

export type EnumFilterT = {
  type: FilterTypesEnum;
  value?: number[];
  sort?: OrderDirectionEnum;
  options: string[];
}

export type FiltersV = StringFilterT | RangeFilterT | ChoiceFilterT | EnumFilterT;

export type FiltersKV = {
  [key: string]: FiltersV;
}

export type DynamicFilterT = {
  limit: number;
  offset: number;
  Account: FiltersKV;
  Person: FiltersKV;
  Opportunity: FiltersKV;
}

export enum ObjectTypesEnum {
  Account = "Account",
  Person = "Person",
  Opportunity = "Opportunity",
}
