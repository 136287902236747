import {
  FilterTwoTone,
  MinusSquareOutlined,
  PlusCircleTwoTone,
  PlusSquareOutlined,
} from "@ant-design/icons";
import {
  DeleteOutline,
  Search,
} from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  Collapse,
  Divider,
  OutlinedInput,
  Select,
  Stack,
  SxProps,
  Tooltip,
  Typography,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import _ from "lodash";
import React, {
  FormEvent,
  ReactNode,
  SyntheticEvent,
  forwardRef,
  useEffect,
  useMemo,
  useState,
} from "react";

import useToggle from "hooks/useToggle";
import useUserFilters from "services/shooks/filtersPresets";
import useTerritoryFilters from "services/shooks/territoryFilters";

import { revereseCompatibleTerritoryFilters } from "services/shooks/utils";
import { SavedUserFilter } from "services/user/filters";
import { isString } from "utils/varTypes";

import { AccountStatusEnums } from "./AccountStatus";

interface FiltersGroupBoxHeadProps {
  isCollapsed?: boolean;
  children?: React.ReactNode;
  title?: string | React.ReactNode;
  onToggle?: () => void;
  count?: number;
}

function FiltersGroupBoxHead(props: FiltersGroupBoxHeadProps) {
  const {
    isCollapsed, title, onToggle, count,
  } = props;
  const IconWithStatus = !isCollapsed ? MinusSquareOutlined : PlusSquareOutlined;

  return (
    <Stack
      gap="8px"
      direction="row"
      p="10px"
      bgcolor="#E1EDFA"
      borderRadius="8px"
      onClick={onToggle}
      alignItems="center"
      sx={{
        cursor: "pointer",
        transition: "border-radius .3s ease-in-out",
        borderBottomLeftRadius: isCollapsed ? "8px" : "0",
        borderBottomRightRadius: isCollapsed ? "8px" : "0",
      }}
    >
      <IconWithStatus style={{ fontSize: "20px", color: "#2A7AB7" }} color="#2A7AB7" />
      {isString(title) ? (
        <Typography
          fontSize="14px"
          lineHeight="22px"
          sx={{ color: "#333333" }}
          className="truncate"
        >
          {title}
        </Typography>
      ) : (
        title
      )}
      {Boolean(count) && (
        <Typography fontSize="12px" alignItems="center" display="flex" sx={{ color: "#757575" }}>
          (
          {count}
          )
        </Typography>
      )}
    </Stack>
  );
}

interface FiltersGroupBoxProps {
  children: React.ReactNode;
  title: FiltersGroupBoxHeadProps["title"];
  activeFilterCount?: number;
  isOpen?: boolean;
  onToggle: (isOpen?: boolean) => void;
}

export function FiltersGroupBox(props: FiltersGroupBoxProps & Parameters<typeof Stack>[0]) {
  const {
    children, title, activeFilterCount, onToggle, isOpen, ...restProps
  } = props;

  const onToggleCollapsed = () => onToggle(!isOpen);

  return (
    <Stack direction="column" {...restProps}>
      <FiltersGroupBoxHead
        title={title}
        isCollapsed={!isOpen}
        onToggle={onToggleCollapsed}
        count={activeFilterCount}
      />
      <Collapse in={isOpen}>
        <Stack direction="column" p="10px" gap="10px">
          {children}
        </Stack>
      </Collapse>
    </Stack>
  );
}

interface FilterInputGroupProps {
  children: React.ReactNode;
  label?: string | React.ReactNode;
}

export function FilterInputGroup(props: FilterInputGroupProps) {
  const { children, label } = props;
  return (
    <Stack direction="column" gap="8px">
      <Typography fontSize="14px" lineHeight="22px" sx={{ color: "#757575" }}>
        {label}
      </Typography>
      {children}
    </Stack>
  );
}

export function SelectInput(props: Parameters<typeof Select>[0] & { sx?: SxProps }) {
  return (
    <Select
      variant="outlined"
      {...props}
      sx={{
        width: "100%",
        height: "40px",
        borderRadius: "4px",
        border: "1px solid #E6E6E6",
        "& .MuiOutlinedInput-input": {
          padding: "10px 14px",
          fontSize: "12px",
          lineHeight: "20px",
          color: "#2A7AB7",
        },
        "& .MuiOutlinedInput-notchedOutline": {
          border: "none",
        },
        "& .MuiSelect-select": {
          padding: "10px 14px",
          fontSize: "12px",
          lineHeight: "20px",
          color: "#2A7AB7",
        },
      }}
    >
      {props.children}
    </Select>
  );
}

interface SearchFieldProps {
  sx?: SxProps;
  onClear?: () => void;
  onSelect?: (value: string) => void;
  isLoading?: boolean;
  onChange?: (event: SyntheticEvent) => void;
  options?: any[];
  searchTermThreshold?: number;
  autoHighlight?: boolean;
}

export function SearchField(props: SearchFieldProps & Parameters<typeof TextField>[0]) {
  const {
    options = [],
    isLoading,
    inputProps,
    onSelect,
    onClear,
    searchTermThreshold = 3,
    autoHighlight,
  } = props;
  const [inputValue, setInputValue] = useState("");

  const _onChange = (event: SyntheticEvent, option: any) => {
    // props.onChange?.(event)
    if (option === null) return;
    onSelect?.(option.value);
    setInputValue?.(option.value);
  };

  function NoOptionsText({ isLoading, ...props }: any) {
    return <span>{isLoading ? "Loading..." : "type to search..."}</span>;
  }

  useEffect(() => {
    const clearButton: HTMLElement = document?.querySelector(
      "button[aria-label='Clear']",
    ) as HTMLElement;

    if (clearButton?.click) {
      clearButton?.click?.();
      setInputValue("");
    }
  }, [inputValue]);

  return (
    <Autocomplete
      autoHighlight={autoHighlight}
      options={options}
      multiple={false}
      onChange={_onChange}
      sx={{ height: "40px", width: "100%" }}
      noOptionsText={<NoOptionsText isLoading={isLoading} />}
      filterOptions={(options, state) => {
        if (state.inputValue.length >= searchTermThreshold) {
          return options.filter((item: any) => String(item.label).toLowerCase().includes(state.inputValue.toLowerCase()));
        } return [];
      }}
      // endAdornment={<Search style={{ fontSize: '17px', color: '#262626' }} />}
      renderInput={(params) => (
        // @ts-ignore
        <TextField {...params} {...inputProps} />
      )}
    />
  );
}

type SearchInputProps = Parameters<typeof OutlinedInput>[0] & SearchFieldProps;
export const SearchInput = forwardRef((props: SearchInputProps, ref) => {
  // eslint-disable-next-line
  const { sx, onReset, ...restProps } = props;
  return (
    <OutlinedInput
      sx={{
        borderRadius: "4px",
        fontSize: "14px",
        height: "32px",
        borderBottom: "none",
        "&::placeholder": {
          color: "#AAAAAA",
        },
      }}
      startAdornment={<Search style={{ fontSize: "17px", color: "#262626" }} />}
      {...restProps}
    />
  );
});

interface AccountStatusIconProps {
  isActive?: number;
  icon: ReactNode;
  disabledIcon?: ReactNode;
  sx?: SxProps;
  onToggle: (name: AccountStatusEnums, isActive?: number) => void;
  name: AccountStatusEnums;
}

const getAccountStatusTooltip = (isActive?: number, name?: AccountStatusEnums) => {
  if (isActive === undefined) return "Click to filter by status";
  const statusNameCaplitized = (name && name?.charAt(0).toUpperCase() + name?.slice(1)) || "";
  // Accounts with x, click for accounts without x
  const activeTooltip = `Accounts with ${statusNameCaplitized}.\n Click for accounts without`;
  // Accounts without x, click for accounts with x
  const inactiveTooltip = `Accounts without ${statusNameCaplitized}`;
  return isActive ? activeTooltip : inactiveTooltip;
};

export function AccountStatusIcon({
  icon,
  name,
  disabledIcon,
  isActive,
  onToggle,
}: AccountStatusIconProps) {
  const isDisabled = typeof isActive === "undefined";

  const onClick = () => {
    const nextToggleState = isActive === undefined ? 1 : isActive === 0 ? undefined : 0;
    onToggle?.(name, nextToggleState);
  };

  const tooltipLabel = getAccountStatusTooltip(isActive, name);
  return (
    <Tooltip
      title={<div style={{ textAlign: "center", whiteSpace: "pre-line" }}>{tooltipLabel}</div>}
      enterDelay={500}
      placement="top"
    >
      <Box
        onClick={onClick}
        sx={{
          color: isActive ? "#358700" : "#C8C8C8",
          transition: "color 0.3s ease, transform 0.3s ease",
          cursor: "pointer",
          height: "38px",
          width: "38px",
          display: "flex",
          alignItems: "center",
          position: "relative",
          justifyContent: "center",
          "&:hover": {
            transform: "scale(1.2)",
          },
          "::after": {
            content: "\"\"",
            position: "absolute",
            width: "100%",
            height: "100%",
            borderRadius: "50%",
            backgroundColor: "#358700",
            opacity: isActive ? 0.2 : 0,
          },
        }}
      >
        {isDisabled ? disabledIcon : icon}
      </Box>
    </Tooltip>
  );
}

export function SaveFilterIcon({ onClick, isLoading }: { onClick?: any; isLoading?: boolean }) {
  return (
    <Stack
      onClick={onClick}
      alignItems="center"
      sx={{
        cursor: "pointer",
        position: "relative",
        color: "#2A7AB7",
        transition: "transofrm .3s easy-in-out",
        "&:hover": {
          transform: "scale(1.2)",
        },
      }}
    >
      <FilterTwoTone style={{ fontSize: "17px", color: "currentColor" }} />
      <Stack
        sx={{
          width: "12px",
          position: "absolute",
          top: "-5px",
          right: "-5px",
          alignItems: "flex-start",
          justifyContent: "flex-end",
        }}
      >
        {isLoading ? (
          <CircularProgress size={12} sx={{ marginRight: "5px" }} />
        ) : (
          <PlusCircleTwoTone style={{ fontSize: "12px", color: "currentColor" }} />
        )}
      </Stack>
    </Stack>
  );
}

interface SaveFilterFormProps {
  onSaveSubmit?: (event: FormEvent<HTMLFormElement>) => void;
  onClose?: () => void;
  isLoading?: boolean;
}

export function SaveFilterForm(props: SaveFilterFormProps) {
  const { onSaveSubmit, isLoading: isSubmitting, onClose } = props;
  return (
    <form onSubmit={onSaveSubmit}>
      <Stack gap="15px" minWidth="240px">
        <Stack>
          <Typography fontSize="16px">Save new filter preset:</Typography>
          <Typography fontSize="12px">Set a name for the current filters to be saved.</Typography>
        </Stack>
        <Stack direction="row" justifyContent="space-between" gap="10px">
          <TextField
            label="Filter Name"
            name="filterName"
            autoFocus
            size="small"
            autoComplete="off"
          />
          <Button
            sx={{ height: "32.25px" }}
            disabled={isSubmitting}
            type="submit"
            variant="contained"
          >
            <Stack direction="row" alignItems="center" gap="5px">
              {isSubmitting && <CircularProgress size={16} sx={{ marginRight: "5px" }} />}
              <span>Save</span>
            </Stack>
          </Button>
        </Stack>
      </Stack>
    </form>
  );
}

interface FilterItemProps {
  filter: SavedUserFilter;
  onDelete: (filterId: string) => Promise<void>;
  onSelect: (filter: SavedUserFilter) => void;
}

function FilterItem(props: FilterItemProps) {
  const { filter, onDelete, onSelect } = props;
  const [isDeleting, , asyncSetIsDeleting] = useToggle();

  const onDeleteFilter = (event: SyntheticEvent) => {
    event.stopPropagation();
    asyncSetIsDeleting(onDelete?.(filter?.id));
  };

  const onSelectFilter = () => {
    onSelect?.(filter);
  };

  return (
    <Stack
      py="5px"
      pr="8px"
      direction="row"
      justifyContent="space-between"
      gap="20px"
      alignItems="center"
      sx={{
        transition: "background .3s ease",
        pl: "10px",
        color: "#333333",
        "--icon-opacity": 0,
        gap: "10px",
        borderLeft: "2px solid transparent",
        borderTopRightRadius: "4px",
        borderBottomRightRadius: "4px",
        "&:hover": {
          backgroundColor: "#3498db45",
          borderLeft: "2px solid #3498db",
          "--icon-opacity": 1,
        },
      }}
    >
      <Typography
        sx={{
          flex: 1,
          display: "flex",
          textTransform: "capitalize",
          cursor: "pointer",
          transition: "color .3s ease",
          alignItems: "center",
          fontSize: "14px",
          color: "#333333",
          gap: "10px",
          "&:hover": {
            fontWeight: "600",
          },
        }}
      >
        <Tooltip
          title={`Select to update filters with (${filter.name}) filter group.`}
          placement="top"
        >
          <div onClick={onSelectFilter} style={{ flex: 1, maxWidth: "180px" }} className="truncate">
            {filter.name}
          </div>
        </Tooltip>
      </Typography>
      {isDeleting ? (
        <CircularProgress size={16} sx={{ marginRight: "5px", color: "#DB3A" }} />
      ) : (
        <Tooltip title={`Delete (${filter.name}) Filter`} placement="top">
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              transition: "color .3s ease,transform .3s ease",
              cursor: "pointer",
              color: "#333333",
              "&:hover": {
                color: "#DB393A",
                transform: "scale(1.2)",
              },
            }}
          >
            <DeleteOutline
              onClick={onDeleteFilter}
              style={{
                color: "currentColor",
                opacity: "var(--icon-opacity)",
                fontSize: "18px",
              }}
            />
          </Box>
        </Tooltip>
      )}
    </Stack>
  );
}

export const SavedFiltersBox = forwardRef<HTMLDivElement>((props, ref) => {
  const [isSaveFilterOpen, setIsSaveFilter] = useState<boolean>(false);
  const { actions: filtersActions, state } = useUserFilters();
  const { state: territoryFilterState, actions: territoryFiltersActions } = useTerritoryFilters();
  const [isSubmitting, , setAsyncToogleSubmitting] = useToggle();

  const onShowCreateFilterForm = () => {
    setIsSaveFilter(true);
  };

  const onCloseCreateFilterForm = () => {
    setIsSaveFilter(false);
  };

  const onSaveNewFilter = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const { filterName: filterNameInput } = event.target as HTMLFormElement;
    const newFilter = {
      filter: territoryFilterState.filters!,
      name: filterNameInput.value,
    };
    await setAsyncToogleSubmitting(filtersActions.saveNewFilter(newFilter));
    onCloseCreateFilterForm();
    (event.target as HTMLFormElement).reset();
  };

  const onFilterDelete = async (filterId: string) => {
    await filtersActions.delete(filterId);
  };

  const onFilterSelect = (userFilter: SavedUserFilter) => {
    const cleanFilters = revereseCompatibleTerritoryFilters(userFilter.filter);
    territoryFiltersActions.updateFilters(cleanFilters);
  };

  const hasSavedFilters = state?.filters?.length > 0;
  // disabed temporarily
  const isCurrentChangesMatchAFilter = useMemo(() => {
    const savedFilters = state?.filters;
    if (!savedFilters) return false;
    const currentFilter = savedFilters.find((filter) => _.isEqual(filter, territoryFilterState.filters));
    return Boolean(currentFilter);
  }, [JSON.stringify({ based: territoryFilterState.filters, saved: state?.filters ?? [] })]);

  return (
    <Stack ref={ref} direction="column" p="10px 5px">
      <Stack justifyContent="space-between" gap="10px">
        <SaveFilterForm
          onClose={onCloseCreateFilterForm}
          onSaveSubmit={onSaveNewFilter}
          isLoading={isSubmitting}
        />
        {hasSavedFilters && (
          <>
            <Divider orientation="horizontal" sx={{ borderColor: "#E6E6E6" }} />
            <Typography fontSize="16px">Saved filters presets:</Typography>
            <Stack direction="column" sx={{ maxHeight: "200px", overflow: "auto" }}>
              {state.filters.map((filter) => (
                <FilterItem
                  key={filter.id}
                  filter={filter}
                  onDelete={onFilterDelete}
                  onSelect={onFilterSelect}
                />
              ))}
            </Stack>
          </>
        )}
      </Stack>
    </Stack>
  );
});
