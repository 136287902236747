import { CloseCircleOutlined } from "@ant-design/icons";
import OriginalTextField, { BaseTextFieldProps } from "@mui/material/TextField";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";

interface TextFieldProps extends BaseTextFieldProps {
  value?: string;
  onChange?: (value?: string) => void;
  placeholder?: string;
  name?: string;
  sx?: any;
  size?: "small" | "medium";
  enableReset?: boolean;
  disable?: boolean
}

function TextField(props: TextFieldProps) {
  const { value, onChange, placeholder, name, sx, size, enableReset, disable } = props;
  const [_value, setValue] = useState(value);
  const _onChange = (e: any) => {
    const value = e.target?.value;
    setValue?.(() => {
      onChange?.(value);
      return value;
    });
  };

  useEffect(() => {
    setValue(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const onReset = () => {
    setValue("");
    onChange?.("");
  }
  // memoizing this creates a bug where the value is not updated. Needs more time to implement a better fix while preserving the memo.
  return (
    <Box sx={{ position: 'relative', '&:hover': { '--show-close-icon': 1 } }}>
      <OriginalTextField
        sx={{ ...sx }}
        name={name}
        size={size}
        variant="outlined"
        value={_value}
        onChange={_onChange}
        placeholder={placeholder}
        disabled={disable}
      />
      {(enableReset && Boolean(value)) && (
        <Box sx={{ cursor: 'pointer', position: 'absolute', top: 0, bottom: 0, color: '#616161', right: '10px', display: 'flex', alignItems: 'center', opacity: 'var(--show-close-icon, 0.3)', '&:hover': { color: '#096dd9' } }}>
          <CloseCircleOutlined style={{ color: 'currentColor' }} onClick={onReset} />
        </Box>
      )}
    </Box>
  );
}

export default TextField;
