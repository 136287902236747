import { IExtendedEmployee } from "types/api";
import { stringUtils } from "utils/generics/string";

interface IHierarchyPerson {
  value: string;
  isManager: boolean;
  parent?: string;
}

interface IStats {
  hierarchyCount: number;
  checkedCount: number;
  checkedManagers: number;
  peopleDetails: IHierarchyPerson[];
}

/**
 *
 * @param hierarchy
 * @param stats info about the hierarchy. How many people in it, how many of them are checked (sellers), how many managers, and who are the checked people (sellers/managers)
 * @param parent the parent of the current hierarchy. Used to determine who are thetop level managers of the current hierarchy as their parent will be null
 * @returns
 */

const getHierarchyStats = (
  hierarchy: IExtendedEmployee[],
  stats: IStats = {
    hierarchyCount: 0,
    checkedCount: 0,
    checkedManagers: 0,
    peopleDetails: [],
  },
  parent?: string,
): IStats => {
  hierarchy.forEach((employee: IExtendedEmployee) => {
    const endsWithOnly = employee.name.toLowerCase().endsWith("only");
    // make sure the `only` is for duplication and not part of the original name.
    const sanitizedName = endsWithOnly ? employee.name.slice(0, -4) : employee.name;
    const isDuplicate = stringUtils.compareIgnoreCase(sanitizedName, parent);
    const isManager = Boolean(employee.subordinates.length);
    stats.hierarchyCount += 1;
    if (employee.checked) stats.checkedCount += 1;
    if (employee.checked && !isDuplicate) stats.peopleDetails.push({ value: employee.name, isManager, parent });
    if (isManager && employee.checked) stats.checkedManagers += 1;
    if (employee.subordinates.length) {
      stats = getHierarchyStats(employee.subordinates, stats, parent);
    }
  });
  return stats;
};

const deriveScopeStringConditionsFromStats = (stats: IStats) => {
  const checkedFirstLevelManagers = stats.peopleDetails.filter(
    (person: IHierarchyPerson) => !person.parent,
  );
  const checkedFirstLevelManagerCount = checkedFirstLevelManagers.length;
  const checkedSellers = stats.peopleDetails.filter(
    (person: IHierarchyPerson) => !person.isManager,
  );
  const firstSellerName = checkedSellers[0]?.value;
  const firstManagerName = checkedFirstLevelManagers[0]?.value;
  const sellerAccountsCount = stats.checkedCount - stats.checkedManagers;
  const isAllTerritories = stats.hierarchyCount === stats.checkedCount;

  return {
    checkedFirstLevelManagerCount,
    firstSellerName,
    firstManagerName,
    sellerAccountsCount,
    isAllTerritories,
  };
};

const createScopeStringFromStats = (stats: IStats) => {
  const {
    checkedFirstLevelManagerCount,
    firstSellerName,
    firstManagerName,
    sellerAccountsCount,
    isAllTerritories,
  } = deriveScopeStringConditionsFromStats(stats);

  // missing case: manager name's reports (sellercount) vs manager name (sellercount).
  if (isAllTerritories) return "Scope: All Territories";
  if (!sellerAccountsCount) return "Employee Hierarchy";
  if (sellerAccountsCount === 1) return `Scope: ${firstSellerName}`;
  if (checkedFirstLevelManagerCount === 1) return `Scope: ${firstManagerName}'s reports (${sellerAccountsCount} sellers)`;
  if (checkedFirstLevelManagerCount > 1) return `Scope: ${sellerAccountsCount} sellers`;
};

export const getScopeString = (hierarchy: IExtendedEmployee[]) => {
  if (!hierarchy) return "";
  const stats = getHierarchyStats(hierarchy);
  const string = createScopeStringFromStats(stats);
  return string;
};
