import { Box, SxProps } from "@mui/material";

import Tooltip from "components/Tooltip";
import { measureTextWidth } from "pages/Contacts/components/PeopleTable/usePeopleColumns";

interface ITextCellProps {
  value: string;
  onClick?: () => void;
  truncateLimit?: number;
  sx?: SxProps;
  clickable?: boolean;
}

const getDefaultStyle = (clickable?: boolean) => ({
  cursor: clickable ? "pointer" : "default",
  height: "54px",
  display: "flex",
  alignItems: "center",
  fontSize: "14px",
  width: "275px",
  "&:hover": clickable
    ? {
      fontWeight: "600",
      color: "#004e87",
      textDecoration: "underline",
    }
    : {},
});

export function TextCell({
  value, onClick, sx, clickable,
}: ITextCellProps) {
  const textWidth = measureTextWidth({
    text: value,
    fontSize: 14,
    fontFamily: "Public Sans",
  });

  // @ts-ignore proper fix requires quite some work (https://github.com/mui/material-ui/issues/21650)
  const cellWidth = sx?.maxWidth;
  const numericCellWidth = cellWidth ? parseInt(cellWidth, 10) : 0;
  // @todo: this guards against null values and undefined. This guard should be implemented in a cleaner way and outside this component.
  //  Also, truncateString function should handle these cases itself and return an empty string for nulls and undefined.
  const defaultStyle = getDefaultStyle(clickable);
  return (
    <Tooltip title={value} placement="top" hide={numericCellWidth >= textWidth}>
      <Box
        onClick={onClick}
        className="truncate"
        sx={{
          ...defaultStyle,
          ...sx,
        }}
      >
        {value || "--"}
      </Box>
    </Tooltip>
  );
}
