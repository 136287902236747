import { Box } from "@mui/material";

function Widget({ title, children }: { title?: React.ReactNode; children: React.ReactNode }) {
  return (
    <Box
      sx={{
        border: "1px solid #E6E6E6",
        borderRadius: "4px",
        height: "100%",
      }}
    >
      <Box
        sx={{
          background: "#FAFAFA",
          borderBottom: "1px solid #E6E6E6",
          padding: "16px 0px 16px 20px",
          height: "54px",
        }}
      >
        {title}
      </Box>
      <Box sx={{ padding: "20px" }}>{children}</Box>
    </Box>
  );
}

export default Widget;
