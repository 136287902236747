import { create } from "zustand";

import { ActivityAPI } from "services";
import { StateRecord } from "./main";
import { userStorage } from "utils/auth";

interface ActivitiesLastSignals {
  lastSignalsCount: number;
  lastSeenDate?: string;
}

interface ActivitiesState extends StateRecord {
  isLoading?: boolean;
  state: ActivitiesLastSignals;
  actions: {
    updateLastSeenDate: (lastSeenDate?: string) => void;
    updateLastSignalsCount: (lastSignalsCount: number) => void;
    initialize: () => void;
    updateState: (newStateUpdates: Partial<ActivitiesLastSignals>) => void;
  };
}

export const LAST_SEEN_DATE_KEY = "lastSeenDate";

const initialLastActivitiesSignal = {
  lastSignalsCount: 0,
  lastSeenDate: userStorage.get(LAST_SEEN_DATE_KEY)?.lastSeenDate,
} as ActivitiesLastSignals;

const useLastSignalCount = create<ActivitiesState>((setState, getState) => ({
  isLoading: false,
  state: initialLastActivitiesSignal,
  actions: {
    async initialize() {
      const { actions } = getState();
      const { lastSeenDate } = getState().state;
      if (!lastSeenDate) return;
      // @todo: the limit should be set to 0, but the API is not working properly
      const { totalResultCount } = await ActivityAPI.search({
        limit: 1,
        activitiesAfterDate: new Date(lastSeenDate),
      });
      actions.updateLastSignalsCount(totalResultCount);
    },
    updateState: (newStateUpdates: Partial<ActivitiesLastSignals>) => {
      const { state } = getState();
      setState({ state: { ...state, ...newStateUpdates } });
    },
    updateLastSeenDate: (lastSeenDate?: string) => {
      const _lastSeenDate = lastSeenDate || new Date().toISOString();
      const { actions } = getState();
      userStorage.set(LAST_SEEN_DATE_KEY, { lastSeenDate: _lastSeenDate });
      actions.updateState({ lastSeenDate: _lastSeenDate });
    },
    updateLastSignalsCount: (lastSignalsCount: number) => {
      const { actions } = getState();
      actions.updateState({ lastSignalsCount });
    },
  },
}));

export default useLastSignalCount;
