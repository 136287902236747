import { Box, CircularProgress, TextField } from "@mui/material";

import { enqueueSnackbar } from "notistack";
import { KeyboardEvent, useRef, useState } from "react";

import { editIcon } from "assets/images/brands";

import useTerritoryFilters from "services/shooks/territoryFilters";
import { DynFilterColumn, ValueOf } from "types/api";
import useToggle from "hooks/useToggle";

type Props = {
  columnMetData: ValueOf<DynFilterColumn>,
  recordId: string;
  filterKey: string;
  value: any;
  children: React.ReactNode;
}

function EditableColumn({
  columnMetData,
  recordId,
  filterKey,
  children,
  value,
}: Props) {
  const {
    actions: { editColumn },
  } = useTerritoryFilters();

  const [isEditMode, setIsEditMode] = useState(false);
  const [showEditStyles, setShowEditStyles] = useState(false);
  const [editValue, setEditValue] = useState<any>(value);
  const [isLoading, , asyncLoadingToggler] = useToggle();
  const textFieldRef = useRef<HTMLInputElement>(null);

  const isValidChange = (): {
    success: boolean;
    message: string;
  } => {
    const numericRegex = /^[0-9]*$/;

    const returnObj = {
      success: true,
      message: "",
    };

    switch (columnMetData.type) {
      case "Number":
        returnObj.success = numericRegex.test(editValue as string);
        returnObj.message = "Only numbers are allowed";
        break;
      case "String":
        returnObj.success = (editValue as string).length > 0;
        returnObj.message = "Empty fields are not allowed";
        break;
      default:
        break;
    }
    return returnObj;
  };

  const enableEditMode = () => {
    setIsEditMode(true);
    setShowEditStyles(true);
  };

  const onUpdate = async () => {
    await asyncLoadingToggler(updateField())
  }
  const updateField = async (): Promise<void> => {
    const isValid = isValidChange();
    if (!isValid.success) {
      enqueueSnackbar(isValid.message, {
        variant: "error",
      });
      setEditValue(value);
      setShowEditStyles(false);
      return;
    }

    setShowEditStyles(false);
    if (isValid && editValue !== value) {
      await editColumn({
        container: columnMetData.parentObject || 'Account',
        recordId,
        updates: {
          [filterKey]: editValue,
        },
      });
    }
    setIsEditMode(false);
  };

  const blurInput = () => {
    if (textFieldRef.current) {
      textFieldRef.current.blur();
    }
  };

  const onKeyDown = async (e: KeyboardEvent<HTMLInputElement>): Promise<void> => {
    if (e.key === "Escape") {
      blurInput();
      setShowEditStyles(false);
      setEditValue(value);
    }
    if (e.key === "Enter") {
      blurInput();
      await onUpdate();
    }
  };

  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <CircularProgress size="16px" sx={{ color: "currentcolor" }} />
      </Box>
    )
  }
  return (
    isEditMode ? (
      <TextField
        inputRef={textFieldRef}
        placeholder=""
        type="text"
        // error={!!errorMessage}
        autoComplete="off"
        autoFocus
        value={editValue}
        onClick={(e) => {
          e.stopPropagation();
          setShowEditStyles(true);
        }}
        onChange={(e) => setEditValue(e.target.value)}
        onBlur={onUpdate}
        onKeyDown={onKeyDown}
        sx={{
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              border: `${showEditStyles ? "1px solid #00223C" : "none"}`,
            },
            "&:hover fieldset": {
              border: `${showEditStyles ? "1px solid #00223C" : "none"}`,
            },
            "&.Mui-focused fieldset": {
              border: `${showEditStyles ? "1px solid #00223C" : "none"}`,
            },
            "&.Mui-focused": {
              boxShadow: "none",
            },
          },
          "& .MuiInputBase-input": {
            cursor: `${showEditStyles ? "cursor" : `url(${editIcon}) 16 16, auto !important`}`,
            textAlign: "center",
          },
        }}
      />
    ) : (
      <button
        onClick={enableEditMode}
        type="button"
      >
        {children}
      </button>
    )
  );
}

export default EditableColumn;
