export enum ConnectionName {
  Salesforce = "Salesforce",
  HubSpot = "HubSpot",
  Apollo = "Apollo",
  Google = "Google",
  // Not Supported
  Outreach = "Outreach",
  Slack = "Slack",
  Demandbase = "Demandbase",
  GongEngage = "Gong Engage",
  Marketo = "Marketo",
  Salesloft = "Salesloft",
  SixSense = "6Sense",
  ZoomInfo = "ZoomInfo"
}