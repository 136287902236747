
import { Check, LinkOutlined } from "@mui/icons-material";
import { Checkbox, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import { onGoToExternalLink } from "components/Activity.components/utils";
import { Table } from "components/common/Tables";
import { HeaderCell } from "pages/Settings/UsersManagement/common";
import { useMemo } from "react";
import { AccountSimilarWins, BusinessPriority, BusinessRisk, CompanySecData } from "types/api";
import { numberFormats } from "utils/numbers";
import { getFaviconByDomain } from "utils/urlParsers";

interface AccountBusinessCardProps {
  risks?: BusinessRisk[]
}


const PointBox = ({ title, value }: { title: string, value: string }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "5px",
        pl: "20px",
        borderLeft: "5px solid #E0E0E0",
      }}>
      <Typography>{title}</Typography>
      <Typography variant="caption" sx={{ color: "#666666" }}>{value || 'N/A'}</Typography>
    </Box>
  )
}

function RisksWidget({ risks }: AccountBusinessCardProps) {

  if (!(Array.isArray(risks) && risks.length)) return (
    <Box sx={{ padding: "20px", border: "1px solid #e0e0e0", borderRadius: "10px", backgroundColor: "#fff", }}>
      No data available
    </Box>
  )

  return (
    <Box sx={{
      padding: "20px", border: "1px solid #e0e0e0", borderRadius: "10px", backgroundColor: "#fff",
      gap: "10px", flexDirection: "column", display: "flex",
      minWidth: "300px",
      flex: 1
    }}>
      <Typography variant="h4">Business Risks</Typography>
      <Stack direction="column" spacing={2} >
        {risks.map((priority, index) => (
          <PointBox
            key={index} title={priority.title} value={priority.body} />
        ))}
      </Stack>
    </Box>
  )
}

export default RisksWidget;
