import { Box } from "@mui/material";
import { ReactNode } from "react";

interface IActivitySignalProps {
  value: ReactNode;
  onClick: () => void;
  hasValidSignal: boolean;
}

export function ActivitySignals({ onClick, value, hasValidSignal }: IActivitySignalProps) {
  return (
    <Box
      onClick={onClick}
      sx={{
        display: "flex",
        justifyContent: "center",
        minWidth: "170px",
        fontSize: "12px",
        cursor: hasValidSignal ? "pointer" : "default",
      }}
    >
      {value}
    </Box>
  );
}
