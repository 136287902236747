import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  Box, Button, Checkbox, Menu, MenuItem,
} from "@mui/material";
import {
  MouseEvent, useMemo, useState,
} from "react";

import useTerritoryFilters from "services/shooks/territoryFilters";

enum SELECTION_OPTION {
  CURRENT_PAGE,
  SELECT_ALL,
  SELECT_NONE,
}

const selectionOptions = [
  { value: SELECTION_OPTION.CURRENT_PAGE, label: "Select the current page" },
  { value: SELECTION_OPTION.SELECT_ALL, label: "Select all accounts" },
  { value: SELECTION_OPTION.SELECT_NONE, label: "Select None" },
];

// @todo: state should be lifted elsewhere.
export function CheckboxWithDropdown() {
  const { actions, state } = useTerritoryFilters(({ actions, state }) => ({ actions, state }));
  const { selectionState = {}, accounts: currentAccounts = [] } = state;
  const { selected = {}, isSelectAll = false } = selectionState;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const slectedAccountsInTheCurrentPageLength = currentAccounts.filter(({ id }) => selected[id])?.length || 0;
  const currentAccountsLength = state?.accounts?.length || 0;
  const selectedAccountsLength = Object.values(selected).filter(Boolean)?.length;
  const isAllCurrentPageAccountsSelected = isSelectAll
    ? !slectedAccountsInTheCurrentPageLength
    : Boolean(selectedAccountsLength)
      && slectedAccountsInTheCurrentPageLength === currentAccountsLength;
  const listOfCurrentAccountIds = currentAccounts.map(({ id }) => id) || [];

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const updateAccountsSelection = (value: SELECTION_OPTION) => {
    switch (value) {
      case SELECTION_OPTION.SELECT_ALL:
        actions.onToggleAccountSelection(true);
        break;
      case SELECTION_OPTION.CURRENT_PAGE:
        actions.onToggleAccountSelection(listOfCurrentAccountIds);
        break;
      case SELECTION_OPTION.SELECT_NONE:
        actions.onToggleAccountSelection([]);
        break;
    }
  };

  const onChange = () => {
    if (isSelectAll) {
      updateAccountsSelection(SELECTION_OPTION.SELECT_NONE);
    } else if (isAllCurrentPageAccountsSelected) {
      actions.onRemoveAccountSelection(listOfCurrentAccountIds);
    } else {
      updateAccountsSelection(SELECTION_OPTION.CURRENT_PAGE);
    }
  };

  const onMenuChange = (value: SELECTION_OPTION) => {
    handleClose();
    updateAccountsSelection(value);
  };

  const { indeterminate, checked } = useMemo(
    () => ({
      indeterminate:
        !isAllCurrentPageAccountsSelected && Boolean(slectedAccountsInTheCurrentPageLength),
      checked: isAllCurrentPageAccountsSelected,
    }),
    [isAllCurrentPageAccountsSelected, slectedAccountsInTheCurrentPageLength],
  );

  return (
    <Box
      key={`${selected}`}
      sx={{
        width: "5px", display: "flex", alignItems: "center", justifyContent: "center",
      }}
    >
      <Button
        id="fade-button"
        aria-controls={open ? "fade-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        variant="light"
        sx={{
          margin: "0px 0 0 40px !important",
          width: "35px !important",
          justifyContent: "flex-end",
          alignItems: "center",
          color: "#1A1A1A",
          border: "none",
        }} // mui button are inline flex for some reason
      >
        <Checkbox
          onChange={onChange}
          sx={{ padding: 0 }}
          onClick={(e) => e.stopPropagation()}
          indeterminate={indeterminate}
          checked={checked}
        />
        <ArrowDropDownIcon
          viewBox="13 -2 16 30"
          width="6"
          height="4"
          sx={{ transform: "scale(0.9)" }}
        />
      </Button>
      <Menu
        id="fade-menu"
        MenuListProps={{ "aria-labelledby": "fade-button" }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{
          "&.MuiMenu-list": {
            padding: "0px",
          },
        }}
      >
        {selectionOptions.map((option) => (
          <MenuItem
            key={option.value}
            sx={{ width: "210px", borderBottom: "1px solid #eee", height: "30px" }}
            onClick={() => onMenuChange(option.value)}
          >
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
}
