export const Storage = {
  get(key: string) {
    const store = localStorage.getItem(key);
    if (!store) return null;

    try {
      return JSON.parse(store);
    } catch (err) {
      return store
    }
  },
  set: (key: string, value: object | string) => {
    if (typeof value === "string") localStorage.setItem(key, value);

    if (typeof value === "object") localStorage.setItem(key, JSON.stringify(value));
  },
  getAllKeys: () => Object.keys(localStorage),
  remove: (key: string) => {
    localStorage.removeItem(key);
  },
};
