import { OnboardingStatus } from "types/auth";
import { Storage } from "./storage";

export const getOnboardingStatus = (user?: User) => {
  const status = user?.profile?.preferences?.onboarding?.status;
  const isComplete = status === OnboardingStatus.COMPLETE;
  const isIncomplete = status === OnboardingStatus.INCOMPLETE;
  const isSkipped = status === OnboardingStatus.SKIPPED;

  return {
    isComplete,
    isIncomplete,
    isSkipped,
  };
};


export const userStorageClass = () => {
  const userPrefix: string = '$SalesDash_'

  return {
    set: (key: string, value: any) => {
      const fullKeyName = `${userPrefix}_${key}`
      Storage.set(key, value)
      const access = Storage.get(key)
    },
    get: (key: string) => {
      const fullKeyName = `${userPrefix}_${key}`
      return Storage.get(key)
    },
    removeKeyValue: (key: string) => {
      return Storage.remove(key)
    },
    remove: (key: string) => {
      return Storage.remove(key)
    },
    allKeys: () => Storage.getAllKeys(),
    drop: () => {
      localStorage.clear()
    }
  }
}

export const userStorage = userStorageClass()


