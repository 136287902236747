import { create } from "zustand";

import { EMPLOYEE_HIERARCHY } from "constants/territory";
import UserAPI from "services/user";
import type { IExtendedEmployee } from "types/api";
import { renameDuplicatedManager, updateAllEmployeeStatus } from "utils/employeeHierarchy";

import { StateRecord } from "./main";
import { userStorage } from "utils/auth";

export interface ISelectionDictionary {
  [key: string]: ISelectionPayload;
}

export interface IScopePayload {
  name: string;
  count: number;
}

const updateDuplicateId = (employee: IExtendedEmployee) => {
  if (!employee.subordinates.length) return employee;
  employee = renameDuplicatedManager(employee);
  const shouldAddParent = employee.subordinates.length && !employee.id.endsWith("|parent");
  employee.id = shouldAddParent ? `${employee.id}|parent` : employee.id;
  employee.subordinates.forEach(updateDuplicateId);
  return employee;
};

export interface ISelectionPayload {
  checked: boolean;
  indeterminate?: boolean;
  parentId?: string;
  id: string;
  name: string;
}

export interface IHierarchyState extends StateRecord {
  hierarchy: IExtendedEmployee[];
  isManager: boolean;
}

export interface IEmployeeHierarchyState extends StateRecord {
  isLoading?: boolean;
  state: IHierarchyState;
  isInitialized: boolean;
  actions: {
    get: (
      payload: Parameters<typeof UserAPI.getEmployeeHierarchy>[0]
    ) => Promise<IExtendedEmployee[]>;
    initialize: ({ userId }: { userId: string }) => Promise<IExtendedEmployee[]>;
    updateEmployeeHierarchy: (payload: IExtendedEmployee[]) => void;
    getManagerStatus: (hierarchy: IExtendedEmployee[]) => void;
  };
}

const initialSettingState = {
  hierarchy: (userStorage.get(EMPLOYEE_HIERARCHY) as IExtendedEmployee[]) || [],
  isManager: false,
} as IHierarchyState;

//
const useEmployeeTree = create<IEmployeeHierarchyState>((setState, getState) => ({
  isLoading: false,
  isInitialized: false,
  state: initialSettingState,
  actions: {
    async get({ userId }: { userId: string }) {
      setState({ isLoading: true });
      const hierarchy = await UserAPI.getEmployeeHierarchy({ userId });
      const updatedHierarchy = userStorage.get(EMPLOYEE_HIERARCHY)
        || updateAllEmployeeStatus(hierarchy as IExtendedEmployee[], true);
      // const selection = {} as ISelectionDictionary;
      const state = { hierarchy: updatedHierarchy } as IHierarchyState;
      setState({ isLoading: false, state });
      return updatedHierarchy;
    },
    async initialize({ userId }: { userId: string }) {
      const { actions } = getState();
      setState({ isLoading: true });
      const hierarchy = await UserAPI.getEmployeeHierarchy({ userId });
      const isHierarchyCached = Boolean(userStorage.get(EMPLOYEE_HIERARCHY)?.length);
      const updatedHierarchy = isHierarchyCached
        ? userStorage.get(EMPLOYEE_HIERARCHY)
        : updateAllEmployeeStatus(hierarchy as IExtendedEmployee[], true);
      const deDuplicated = updatedHierarchy.map(updateDuplicateId);
      const newHierarchy = [...deDuplicated];
      actions.getManagerStatus(newHierarchy);
      setState({ isLoading: false, isInitialized: true });
      actions.updateEmployeeHierarchy(newHierarchy);
      return updatedHierarchy;
    },
    updateEmployeeHierarchy(payload: IExtendedEmployee[]) {
      const { state } = getState();
      setState({ state: { ...state, hierarchy: payload } as IHierarchyState });
      userStorage.set(EMPLOYEE_HIERARCHY, payload);
    },
    getManagerStatus(hierarchy: IExtendedEmployee[] = []) {
      const { state } = getState();
      const isManager = Boolean(hierarchy?.[0]?.subordinates?.length);
      setState({ state: { ...state, isManager } as IHierarchyState });
    },
  },
}));

export default useEmployeeTree;
