import { Box } from "@mui/material";
import { useEffect } from "react";

import useConversionRateState from "services/shooks/conversionRate";
import useTerritoryAccounts from "services/shooks/territory";

// import usePipelineState from "services/shooks/pipeline"
import { ConversionRateBarChart, SkeletonMask, WidgetBox } from "./common";

function WhiteSpaceWidget() {
  const {
    state: conversionRateState,
    actions: { get: getConversionRateDetails } = {},
    isLoading,
  } = useConversionRateState();
  const {
    state: { selectedUserIds },
  } = useTerritoryAccounts();

  useEffect(() => {
    getConversionRateDetails?.({ userIds: selectedUserIds });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(selectedUserIds)]);

  const referenceRate = (conversionRateState.orgConversionRate ?? 0.15) * 100;
  const comparisonRate = (conversionRateState.conversionRate ?? 0) * 100 || referenceRate;

  return (
    <WidgetBox title="Conversion Rate">
      <Box sx={{ display: "flex", flexDirection: "column", gap: "12px" }}>
        <Box sx={{
          display: "flex", flexDirection: "column", flex: 1, gap: "4px",
        }}
        >
          <Box
            sx={{
              color: "#1A1A1A",
              fontSize: "12px",
              textTransform: "capitalize",
              lineHeight: "20px",
            }}
          >
            Opportunity → Win
          </Box>
          <SkeletonMask loading={isLoading}>
            <Box
              sx={{
                color: "#1A1A1A",
                fontWeight: "bold",
                fontSize: "30px",
                flex: 1,
                textTransform: "capitalize",
                lineHeight: "38px",
              }}
            >
              {Number(comparisonRate).toFixed(1)}
              %
            </Box>
          </SkeletonMask>
        </Box>
        <ConversionRateBarChart referenceRate={referenceRate} comparisonRate={comparisonRate} />
      </Box>
    </WidgetBox>
  );
}

export default WhiteSpaceWidget;
