export enum FeaturesEnum {
  LogRocketEventErrorTracking = "LogRocketEventErrorTracking",
  fillVennChartWithDummyData = "fillVennChartWithDummyData",
  DebugMode = "DebugMode",
  CopilotPoC = "CopilotPoC",
}

const flags = {
  // @todo: this is temporary flag to be removed when we have data
  [FeaturesEnum.fillVennChartWithDummyData]:
    window?.location?.search?.includes("chart-with-dummy-data"),
  [FeaturesEnum.LogRocketEventErrorTracking]: localStorage.getItem("session-tracking") === "true",
  [FeaturesEnum.DebugMode]: window?.location?.search?.includes("debug"),
};

export default flags;
