import { Box } from "@mui/material";

import { MouseEvent } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import { useFilters } from "react-table";

import { salesforceBrand } from "assets/images/brands";
import { Head } from "components/AccountTables/Head";
import { Table } from "components/common/Tables";
import ScrollX from "components/ScrollX";
import { numberFormats } from "utils/numbers";

import { makeSimilarWinsData } from "./accountDetails.utils";
import { TextArea, textIncludes } from "./Details.filters";
import DragPreview from "components/DragPreview";

function AccountDetailsTabs({
  account,
  onSalesforceLinkClick,
}: {
  account: any;
  onSalesforceLinkClick: (e: MouseEvent, id: string) => void;
}) {
  const data = makeSimilarWinsData(account);

  const similarWinsCols = [
    {
      Header: () => <Head>Customer</Head>,
      Footer: "customer",
      accessor: "customer",
      className: "cell-left collapse-column",
      placeholder: "Search customer",
      disableGroupBy: true,
      filter: textIncludes,
      borderLeft: "1px solid #E5E5E5",
      Filter: TextArea,
      hasBorderRight: true,
      disableFilters: true,
      width: 50,
      style: {
        width: "330px",
        minWidth: "330px",
        maxwidth: "330px",
      },
      Cell: ({ row }: any) => {
        const id = row.original.win.opportunityId;
        const name = row.original.win.accountName || "N/A";
        return (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "300px",
            }}
          >
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              {name}
            </Box>
            <Box
              sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
              onClick={(e) => onSalesforceLinkClick(e, id)}
            >
              <img
                src={salesforceBrand}
                alt="link to salesforce"
                style={{ width: "35px", height: "25px" }}
              />
            </Box>
          </Box>
        );
      },
    },
    {
      Header: () => <Head>Win Date</Head>,
      Footer: "winDate",
      accessor: "winDate",
      className: "cell-left collapse-column",
      disableGroupBy: true,
      disableFilters: true,
      borderLeft: "1px solid #E5E5E5",
      // filter: filterByDays,
      // Filter: ({ column }: any) => <SliderFilter column={column} title={"filter by win date (days)"} />,
      width: 50,
      style: {
        width: "230px",
        minWidth: "230px",
        maxwidth: "230px",
      },
      Cell: ({ row }: any) => {
        // @todo: there's no need to have the win prop. just the values directly in row.original.
        const date = new Date(row.original.win.opportunityCloseDate).toString();
        return (
          <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "200px",
              }}
            >
              {date.split(" ").slice(1, 4).join(" ") || "N/A"}
            </Box>
          </Box>
        );
      },
    },
    {
      Header: () => <Head>Amount</Head>,
      Footer: "amount",
      accessor: "amount",
      className: "cell-left collapse-column",
      disableFilters: true,
      disableGroupBy: true,
      borderLeft: "1px solid #E5E5E5",
      style: {
        width: "230px",
        minWidth: "230px",
        maxwidth: "230px",
      },
      width: 50,
      Cell: ({ row }: any) => (
        <Box sx={{ width: "200px" }}>
          $
          {numberFormats.millify(row.original.win.opportunityAmount) || "N/A"}
        </Box>
      ),
    },
    {
      Header: () => <Head>Seller</Head>,
      Footer: "seller",
      accessor: "seller",
      className: "cell-left",
      disableFilters: true,
      disableGroupBy: true,
      filter: textIncludes,
      Filter: TextArea,
      borderLeft: "1px solid #E5E5E5",
      placeholder: "Search seller",
      width: 50,
      Cell: ({ row }: any) => {
        const doesSellerExist = Boolean(row.original.win.sellerName);
        let sellerName = "N/A";
        // reverse the first/last name
        if (doesSellerExist && row.original.win.sellerName.includes(" ")) {
          sellerName = row.original.win.sellerName.split(" ").join(", ");
        }
        return (
          <Box
            sx={{
              height: "44px !important",
              display: "flex",
              alignItems: "center",
              fontSize: "14px",
              fontWeight: 500,
              width: "200px",
            }}
          >
            {sellerName}
          </Box>
        );
      },
    },
  ];

  return (
    <Box
      sx={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
        border: "1px solid #eee",
        borderBottom: "2px solid #eee",
        width: "100%",
      }}
    >
      <ScrollX>
        <DndProvider backend={HTML5Backend}>
          <Table
            columns={similarWinsCols}
            data={data}
            showFiltersRow={Boolean(data.length)}
            customPlugins={[useFilters]}
          />
          <DragPreview />
        </DndProvider>
      </ScrollX>
    </Box>
  );
}

export default AccountDetailsTabs;
