import { Stack } from "@mui/material";

import { IActivity } from "types/api/activities";
import { numberFormats } from "utils/numbers";

import { PersonLabel } from "./common";
import { ActivityContentInteractions, EmailField } from "../common";

interface IWinWireContentProps {
  activity: IActivity;
  onActionTrigger?: (action: ActivityContentInteractions, value?: string, name?: string) => void;
}

export function WinWireContent({ activity, onActionTrigger }: IWinWireContentProps) {
  const person = {
    name: activity.source?.personName,
    email: "",
    title: "",
  };
  const onFilterByPerson = (personName?: string, personId?: string) => {
    if (onActionTrigger && personName) {
      onActionTrigger(ActivityContentInteractions.FilterByPerson, personName, personId);
    }
  };

  return (
    <Stack direction="column" sx={{ width: "100%", wordWrap: "break-word" }}>
      <Stack direction="column" sx={{ width: "100%" }}>
        <EmailField
          label="Who:"
          valueSx={{ textTransform: "capitalize" }}
          value={(
            <PersonLabel
              onClick={onFilterByPerson}
              value={person.name}
              tipDetails={{
                email: person?.email,
                name: person?.name,
                // @note: missing data to show these
                // phoneNumber: '+92342342342',
                // linkedInProfile: 'http://link.in',
                jobTitle: person?.title,
              }}
            />
          )}
        />
        <EmailField
          label="Value:"
          value={(
            <span style={{ fontWeight: 700 }}>
              $
              {" "}
              {numberFormats.millify(activity?.amount || 0)}
            </span>
          )}
        />
      </Stack>
    </Stack>
  );
}

export default WinWireContent;
