import { Employee } from "types/api";
import { AxiosNode } from "utils/axios";

interface IGetEmployees {
  userId: string;
}

export const getEmployeeHierarchy = async ({ userId }: IGetEmployees) => {
  try {
    const { data } = await AxiosNode.get<Promise<Employee[]>>(`user/${userId}/employee-tree`);
    return data;
  } catch (error) {
    return []
  }
};
