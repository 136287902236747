import { LeftOutlined } from "@ant-design/icons";
import { Box, Divider, Stack } from "@mui/material";

import { MiniButton } from "./common";

interface INestedActivityView {
  onGoBack: () => void;
}

export function NestedActivityView({
  children,
  onGoBack,
  ...props
}: INestedActivityView & Parameters<typeof Box>[0]) {
  return (
    <Box {...props}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ p: "17px 20px" }}
      >
        <MiniButton onClick={onGoBack}>
          <LeftOutlined style={{ fontSize: "12px" }} />
          <span>Back</span>
        </MiniButton>
      </Stack>
      <Divider />
      <Stack sx={{ p: "10px 20px" }} direction="column">
        {children}
      </Stack>
    </Box>
  );
}
