import { CloseOutlined } from "@mui/icons-material";
import {
  Box, IconButton, Slider, Tooltip,
} from "@mui/material";
import { SyntheticEvent } from "react";

interface SliderFilterProps {
  tip?: string;
  value?: number;
  onChange?: (value?: number) => void;
  marks?: { value: number; label: string }[];
  min?: number;
  max?: number;
  step?: number | null;
  valueLabelFormat?: (value: number) => string;
  padding?: string | number;
}

function SliderFilter(props: SliderFilterProps) {
  const {
    marks = [], value, valueLabelFormat, step = null, onChange, min = 0, max = 1e2,
  } = props;

  const _onChange = (event: Event | SyntheticEvent, newValue: number | number[]) => {
    onChange?.(newValue as number);
  };

  const onResetValues = () => {
    onChange?.(min ?? undefined);
  };

  return (
    <Tooltip sx={{ margin: "0 20px" }} title="Feature will be released soon">
      <Box sx={{ margin: "0 auto", display: "flex", justifyContent: "center" }}>
        <Slider
          sx={{ maxWidth: 100 }}
          value={value ?? min}
          defaultValue={min}
          min={min}
          max={max}
          disabled
          step={step}
          marks={marks}
          onChangeCommitted={_onChange}
          valueLabelDisplay="auto"
          valueLabelFormat={valueLabelFormat}
          aria-labelledby="non-linear-slider"
        />
        <IconButton sx={{ marginLeft: "10px" }} size="small" color="error" onClick={onResetValues}>
          <CloseOutlined />
        </IconButton>
      </Box>
    </Tooltip>
  );
}

export default SliderFilter;
