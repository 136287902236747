import { Box, Stack, SxProps } from "@mui/system";

import { ReactNode } from "react";

import {
  ActivityIcon, AwarenessIcon, EngagementIcon, OpportunityIcon,
} from "assets/icons";
import Tooltip from "components/Tooltip";
import {
  AccountStatus,
  AccountStatusOffConditions,
  AccountStatusOnConditions,
} from "types/api/territoryFilters";

import { getAccountStatusEnumValues } from "../utils";

function AccountStatusIcon({
  icon,
  sx,
  isActive = false,
}: {
  icon: ReactNode;
  sx?: SxProps;
  isActive?: boolean;
}) {
  const color = isActive ? "#358700" : "#C8C8C8";
  return <Box sx={{ color, ...sx, height: "30px" }}>{icon}</Box>;
}

interface IAccountStatusProps {
  accountStatus: AccountStatus;
}

export default function AccountStatusBox({ accountStatus: _accountStatus }: IAccountStatusProps) {
  const accountStatus = _accountStatus || {
    awareness: false,
    activity: false,
    engagement: false,
    opportunities: false,
  };

  const accountStatusKeys = Object.keys(accountStatus);
  const tooltipValues = getAccountStatusEnumValues({
    trueEnumType: AccountStatusOnConditions,
    falseEnumType: AccountStatusOffConditions,
    keys: accountStatusKeys,
    accountStatus,
  });

  return (
    <Stack justifyContent="space-between" alignItems="center" direction="row" gap="16px">
      <Tooltip title={tooltipValues.awareness} placement="top">
        <span>
          <AccountStatusIcon
            isActive={Boolean(accountStatus.awareness)}
            icon={<AwarenessIcon width="30" height="30" />}
          />
        </span>
      </Tooltip>
      <Tooltip title={tooltipValues.activity} placement="top">
        <span>
          <AccountStatusIcon
            isActive={Boolean(accountStatus.activity)}
            icon={<ActivityIcon width="30" height="30" />}
          />
        </span>
      </Tooltip>
      <Tooltip title={tooltipValues.engagement} placement="top">
        <span>
          <AccountStatusIcon
            isActive={Boolean(accountStatus.engagement)}
            icon={<EngagementIcon width="30" height="30" />}
          />
        </span>
      </Tooltip>
      <Tooltip title={tooltipValues.opportunities} placement="top">
        <span>
          <AccountStatusIcon
            isActive={Boolean(accountStatus.opportunities)}
            icon={<OpportunityIcon width="30" height="30" />}
          />
        </span>
      </Tooltip>
    </Stack>
  );
}
