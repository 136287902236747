import { Box, Button, Typography } from "@mui/material";

import { useSearchQuery } from "hooks";

import Widget from "./Widget";
import { AccountTabsEnum } from "../..";
import { OpportunityColumnsEnum } from "../Opportunities";

export interface SelectedAccount {
  totalOpenOpportunities: number | null;
  totalWinsToDate: number | null;
  totalLosesToDate: number | null;
}
interface IOpportunitiesWidget {
  totalOpenOpportunities: number | null;
  totalWinsToDate: number | null;
  totalLosesToDate: number | null;
}

enum AccountOpportunityTabFilter {
  stageOpen = "open",
  stageWin = "win",
  stageLose = "lose",
}

function OpportunitiesWidget({
  totalOpenOpportunities: openOpportunities = 0,
  totalWinsToDate: winsToDate = 0,
  totalLosesToDate: lossesToDate = 0,
}: IOpportunitiesWidget) {
  const opportunitiesStats = { openOpportunities, winsToDate, lossesToDate };
  // @todo:  rename prefilter to active filter. This should be the pattern for all tabs.
  const [, updateSearchQuery] = useSearchQuery<{
    open_tab: string;
    prefilter: string;
    filtered_column: string;
  }>();

  const onButtonClick = (targetTab: AccountTabsEnum, filter: AccountOpportunityTabFilter) => {
    updateSearchQuery({
      open_tab: targetTab,
      prefilter: filter,
      filtered_column: OpportunityColumnsEnum.stage,
    });
  };
  const onOpportunityFilter = (filter: AccountOpportunityTabFilter) => () => {
    onButtonClick(AccountTabsEnum.Opportunities, filter);
  };
  return (
    <Widget
      title={(
        <Box>
          <Typography variant="body1" sx={{ fontSize: "1rem", fontWeight: 600 }}>
            Opportunities
          </Typography>
        </Box>
      )}
    >
      <Box>
        {[
          {
            key: "openOpportunities",
            title: "Open",
            onClick: onOpportunityFilter(AccountOpportunityTabFilter.stageOpen),
          },
          {
            key: "winsToDate",
            title: "Wins to date",
            onClick: onOpportunityFilter(AccountOpportunityTabFilter.stageWin),
          },
          {
            key: "lossesToDate",
            title: "Losses to date",
            onClick: onOpportunityFilter(AccountOpportunityTabFilter.stageLose),
          },
        ].map(({ key, title, onClick }) => {
          const stat = key as "openOpportunities" | "winsToDate" | "lossesToDate";

          return (
            <Box
              key={key}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "10px",
              }}
            >
              <Typography component="div">
                <Button
                  variant="text"
                  sx={{
                    padding: 0,
                    justifyContent: "flex-start",
                    textTransform: "none",
                    color: "#2A7AB7",
                  }}
                  onClick={onClick}
                >
                  {title}
                </Button>
              </Typography>
              <Typography variant="body1" sx={{ color: "#333333" }}>
                {opportunitiesStats[stat]}
              </Typography>
            </Box>
          );
        })}
      </Box>
    </Widget>
  );
}

export default OpportunitiesWidget;
