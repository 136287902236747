import { Box, Stack, Typography } from "@mui/material";

import { IActivity } from "types/api/activities";

import { ActivityContentInteractions } from "../common";

interface IIntentScoreContentProps {
  activity: IActivity;
  onActionTrigger?: (action: ActivityContentInteractions, value?: string, name?: string) => void;
}

export function IntentScoreContent({ activity, onActionTrigger }: IIntentScoreContentProps) {
  const {
    oldBuyingStage, newBuyingStage, oldIntentScore, newIntentScore,
  } = activity;
  const hasValidScore = !!(oldIntentScore && newIntentScore);
  let directionLabel = "";
  let directionIcon = "";

  if (hasValidScore) {
    directionLabel = hasValidScore
      ? newIntentScore > oldIntentScore
        ? "increased"
        : "decreased"
      : "";
    directionIcon = directionLabel === "increased" ? "↑" : "↓";
  }

  const hasIntentChanged = newBuyingStage && oldBuyingStage && newBuyingStage !== oldBuyingStage;
  const intentStage = newBuyingStage || oldBuyingStage;

  return (
    <Stack direction="column" sx={{ width: "100%", wordWrap: "break-word" }}>
      <Stack direction="column" gap="5px" sx={{ width: "100%" }}>
        {hasValidScore && (
          <Typography variant="body2" sx={{ fontWeight: 400 }}>
            Intent Score
            {" "}
            <b>
              {directionLabel}
              {directionIcon}
            </b>
            {" "}
            to
            {" "}
            <b>{newIntentScore}</b>
          </Typography>
        )}
        <Typography variant="body2" sx={{ fontWeight: 400 }}>
          {hasIntentChanged ? (
            <Typography variant="body2" sx={{ fontWeight: 400 }}>
              Intent buying stage changed from
              {" "}
              <b>{oldBuyingStage}</b>
              {" "}
              to
              {" "}
              <b>{newBuyingStage}</b>
              .
            </Typography>
          ) : intentStage ? (
            <Typography variant="body2" sx={{ fontWeight: 400 }}>
              Current intent stage
              {" "}
              <b>{intentStage}</b>
              .
            </Typography>
          ) : (
            <Typography variant="body2" sx={{ fontWeight: 400 }}>
              Intent Score has changed.
            </Typography>
          )}
        </Typography>
      </Stack>
    </Stack>
  );
}

export default IntentScoreContent;

export function IntentScoreExtraContent({ activity }: { activity: IActivity }) {
  const { oldBuyingStage, oldIntentScore = 0, newIntentScore = 0 } = activity;
  const hasValidScore = oldIntentScore && newIntentScore;
  const directionLabel = hasValidScore
    ? newIntentScore > oldIntentScore
      ? "increase"
      : "decrease"
    : "";
  const increaseOrDecreasePercent = Math.round(
    ((newIntentScore - oldIntentScore) / oldIntentScore) * 100,
  );
  const scoreDiff = newIntentScore - oldIntentScore;

  return (
    <Box
      // onClick={addEventLinkAction}
      sx={{
        bgcolor: "#FAFAFA",
        p: "8px 12px",
        borderRadius: "4px",
        fontSize: "12px",
        color: "#333333",
        lineHeight: "22px",
        cursor: "pointer",
        userSelect: "none",
      }}
    >
      <ul style={{ margin: 0, padding: 0, paddingLeft: "16px" }}>
        {oldIntentScore && (
          <li>
            Previous score:
            {oldIntentScore}
          </li>
        )}
        {oldBuyingStage && (
          <li>
            Previous Stage:
            {oldBuyingStage}
          </li>
        )}
        <li>
          <b>
            {increaseOrDecreasePercent}
            %
          </b>
          {" "}
          {directionLabel}
          {" "}
          of
          {" "}
          <b>
            {scoreDiff}
            {" "}
            pts
          </b>
        </li>
      </ul>
    </Box>
  );
}
