import {
  Avatar, Button, Card, CardContent, Typography,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import React from "react";
import { useNavigate } from "react-router-dom";

import { robotImageIcon } from "assets/icons";
import botBackgroundImageSrc from "assets/images/bot_background.jpg";
import UserFeatureAccessWrapper from "components/UserFeatureAccessWrapper";
import { FeatureGroupSet } from "data/userPermissionsMap";

interface Bot {
  title: string;
  key: string;
  description: string;
  enabled: boolean;
  image: string;
  accessGroupName: FeatureGroupSet;
}

const AIWorkerCard: React.FC<{ bot: Bot }> = ({ bot }) => {
  const navig = useNavigate();
  const onUseBot = () => {
    navig(`/aiworkers/${bot.key}`);
  };

  return (
    <UserFeatureAccessWrapper groupName={bot.accessGroupName}>
      <Card style={{
        width: "280px",
        margin: "10px",
        height: "340px",
        borderRadius: "10px",
        display: "flex",
        position: "relative",
        boxShadow: "0 3px 10px rgba(0, 0, 0, 0.2)",
      }}
      >
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "27%",
            borderRadius: "10px 10px 0 0",
            background: `url(${botBackgroundImageSrc})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            filter: "blur(.3px)",
            borderBottom: "1px solid #3498db6f",
          }}
        />
        <CardContent sx={{
          display: "flex", flex: 1, flexDirection: "column", gap: "10px",
        }}
        >
          <Avatar
            src={robotImageIcon}
            style={{
              backgroundColor: "white",
              padding: "14px",
              width: 100,
              height: 100,
              borderRadius: "50%",
              border: "2px solid #3498db6f",
              margin: "0 auto",
              marginTop: "20px",
            }}
          />
          <Stack spacing={2} flex={1} justifyContent="center" alignItems="center" display="flex" flexDirection="column">
            <Box sx={{
              display: "flex", flex: 1, justifyContent: "center", alignItems: "center", flexDirection: "column",
            }}
            >
              <Typography
                style={{
                  fontSize: 18,
                  fontWeight: "bold",
                }}
                gutterBottom
              >
                {bot.title}
              </Typography>
              <Typography variant="body1" color="textSecondary" component="p" textAlign="center" style={{ marginBottom: 10 }}>
                {bot.description}
              </Typography>
            </Box>
            <Button onClick={onUseBot} variant="outlined" color="primary" style={{ margin: "5px" }} disabled={!bot.enabled}>
              {bot.enabled ? "Use Worker" : "Being trained..."}
            </Button>
          </Stack>
        </CardContent>
      </Card>
    </UserFeatureAccessWrapper>
  );
};

export default AIWorkerCard;
