import {
  ChevronLeft, ChevronRight, SkipNext, SkipPrevious,
} from "@mui/icons-material";
import { Divider, Stack } from "@mui/material";

import { IconButton, PaginationMiniForm } from "./common";
import SpreadedPagesNumbers from "./SpreadedPagesPagination";

interface PaginationProps {
  page: number;
  total: number;
  pageSize: number;
  onPageChange: (page: number) => void;
}

function Pagination({
  page: currentPage, total, pageSize, ...props
}: PaginationProps) {
  const totalPages = Math.ceil(total / pageSize);

  const onPageChange = (page: number) => {
    if (page > totalPages || page < 1) return;
    props.onPageChange?.(page);
  };

  const handlePageChange = (page: number) => () => {
    onPageChange(page);
  };

  return (
    <Stack direction="row" gap="6px" fontSize="14px" lineHeight="22px">
      <IconButton
        disabled={currentPage === 1}
        onClick={handlePageChange(1)}
        sx={{ bgcolor: "#fff" }}
      >
        <SkipPrevious />
      </IconButton>
      <IconButton disabled={currentPage <= 1} onClick={handlePageChange(currentPage - 1)}>
        <ChevronLeft />
      </IconButton>
      {Boolean(currentPage && totalPages) && (
        <SpreadedPagesNumbers
          currentPage={currentPage}
          onPageChange={onPageChange}
          totalPages={totalPages}
        />
      )}
      <IconButton disabled={currentPage >= totalPages} onClick={handlePageChange(currentPage + 1)}>
        <ChevronRight />
      </IconButton>
      <IconButton disabled={currentPage === totalPages} onClick={handlePageChange(totalPages)}>
        <SkipNext />
      </IconButton>
      <Divider orientation="vertical" sx={{ borderColor: "#C8C8C8", mx: "16px" }} />
      <PaginationMiniForm currentPage={currentPage} onPageChange={onPageChange} />
    </Stack>
  );
}

export default Pagination;
