import type { IExtendedEmployee } from "../components/EmployeeHierarchy";

export const updateEmployeeInTree = (employeeId: string, data: any, list: IExtendedEmployee[]) => {
  if (!Array.isArray(list)) return [];
  const updatedList: IExtendedEmployee[] = list.map((employee) => {
    let updatedEmployee = employee;

    if (employee.id === employeeId) {
      updatedEmployee = typeof data === "function" ? data(employee) : { ...employee, ...data };
    }
    return {
      ...updatedEmployee,
      subordinates: updateEmployeeInTree(employeeId, data, employee.subordinates),
    };
  });
  return updatedList;
};

export default updateEmployeeInTree;
