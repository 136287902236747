import { Box } from "@mui/material";

import Navbar from "components/layout/Navbar";
import useElementBoundingClientRect from "hooks/useElementSizeDetails";

import Footer from "./Footer";
import SideBar from "./SideBar";

interface Props {
  children: React.ReactNode;
  hasHeader?: boolean;
  hasFooter?: boolean;
  hasSidebar?: boolean;
  renderCustomHeader?: () => React.ReactNode;
  renderCustomFooter?: () => React.ReactNode;
  renderCustomSidebar?: () => React.ReactNode;
  contentContainerRef?: any;
}

function Page(props: Props) {
  const { ref: headerRef, rect: headerElementDetails } = useElementBoundingClientRect();

  const {
    hasHeader = true,
    hasFooter = true,
    hasSidebar = true,
    renderCustomHeader,
    renderCustomFooter,
    renderCustomSidebar,
    children,
    contentContainerRef,
  } = props;

  const renderSidebar = renderCustomSidebar?.()
    || (hasSidebar && (
      <Box
        sx={{
          position: "fixed",
          display: "flex",
          flexDirection: "column",
          top: 0,
          bottom: 0,
          zIndex: 100,
        }}
      >
        <SideBar />
      </Box>
    ));

  const renderHeader = hasHeader ? (
    renderCustomHeader ? (
      <Box
        ref={headerRef}
        sx={{
          position: "fixed",
          top: 0,
          left: renderSidebar ? 60 : 0,
          right: 0,
          zIndex: 100,
        }}
      >
        {renderCustomHeader?.()}
      </Box>
    ) : (
      <Box
        ref={headerRef}
        sx={{
          position: "fixed",
          top: 0,
          left: renderSidebar ? 60 : 0,
          right: 0,
          zIndex: 100,
        }}
      >
        {/* @todo: we should move this from here to be added based on the screen to make it more flexible */}
        <Navbar />
      </Box>
    )
  ) : null;

  const renderFooter = renderCustomFooter?.() || (hasFooter && <Footer width="calc(100vw - 380px)" />);

  return (
    <Box
      sx={{
        display: "flex",
        minHeight: "100vh",
        maxWidth: "100vw",
        background: "#fff",
        flexGrow: 1,
      }}
    >
      {renderSidebar}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flex: "1",
          overflow: "auto",
        }}
      >
        {renderHeader}
        <Box
          ref={contentContainerRef}
          sx={{
            display: "flex",
            flexDirection: "column",
            flex: "1",
            ml: renderSidebar ? "60px" : 0,
            maxWidth: "100%",
            left: renderSidebar ? 60 : 0,
            // maxHeight: 'calc(94vh - 66px)',
            // @todo: this fix for the activity feed drawer occupying the right side of the page, should be fixed in the future
            // paddingRight: hasSidebar ? '60px' : "0px",
            paddingTop: renderHeader ? `${headerElementDetails?.height}px` : 0,
          }}
        >
          {children}
        </Box>
        {renderFooter}
      </Box>
    </Box>
  );
}

export default Page;
