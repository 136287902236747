import React, { useEffect, useState } from 'react';
import aiWorkersService from 'services/aiworkers';

export const useWorkerPrePopulateParams = (cb: CallableFunction, id: string, deps?: any[]) => {
  const [params, setParams] = useState<any>();

  const getWorkerParams = async (id: string) => {
    const res = await aiWorkersService.workerCachedParams(id);
    setParams(res)
    cb?.(res)
  }

  useEffect(() => {
    id && getWorkerParams(id)
  }, [id, ...(Array.isArray(deps) ? deps : [])])
  return params
}

