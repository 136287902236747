// @todo: split this file into separate components in a directory named header.
import { Box, Typography } from "@mui/material";

import cx from "classnames";
import { useMemo } from "react";

import { format } from "timeago.js";

import { CloseIcon } from "assets/icons";
import { Account } from "types/api";

interface IHeaderProps {
  onToggleTheSection: () => void;
  currentAccount?: Account;
}

const sectionHeaderStyles = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  color: "#333333",
  fontSize: "20px",
  fontWeight: 600,
  lineHeight: "28px",
  marginBottom: "16px",
};
interface ITerritoryTitle {
  totalAccountSum: number;
  selectedAccounts: { [key: string]: boolean };
  isAccountFocused: boolean;
  onBackToTerritory: () => void;
}
function TerritoryTitle(props: ITerritoryTitle) {
  const { isAccountFocused, selectedAccounts, onBackToTerritory } = props;
  const hasSelectedAccounts = Object.values(selectedAccounts).find(Boolean);
  const numberOfSelectedAccounts = Object.values(selectedAccounts).filter(Boolean).length;
  const title = !hasSelectedAccounts ? "Territory" : `${numberOfSelectedAccounts} Selected Accounts`;

  const _onClick = () => {
    if (!isAccountFocused) return;
    onBackToTerritory?.();
  };

  return (
    <Box
      onClick={_onClick}
      sx={{ display: "flex", alignItems: "center" }}
      className={cx({ activeLink: isAccountFocused })}
    >
      {title}
    </Box>
  );
}

function AccountTitle({ account }: { account?: Account }) {
  if (!account) return null;
  const { name: accountName, nextRenewalDate } = account;
  const isRenewalInLessThan30Days = nextRenewalDate
    && new Date(nextRenewalDate).getTime() - new Date().getTime() < 30 * 24 * 60 * 60 * 1000;
  const isValidRenewalDate = nextRenewalDate && new Date(nextRenewalDate).getTime() > 0;

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      {accountName}
      <Typography
        sx={{
          ml: 2,
          pl: 2,
          borderLeft: "1.5px solid",
          fontSize: "18px",
          display: "flex",
          alignItems: "center",
        }}
      >
        {/* we might decide to restore this */}
        {/* <span>
          Rank # {rank} {isValidRenewalDate ? <>&nbsp;-&nbsp;</> : null}
        </span> */}
        {isValidRenewalDate && (
          <span
            style={{ color: isRenewalInLessThan30Days ? "#DB393A" : "inherit", fontWeight: "600" }}
          >
            Next Renewal
            {" "}
            {format(nextRenewalDate)}
          </span>
        )}
      </Typography>
    </Box>
  );
}
function Header(props: IHeaderProps) {
  const { currentAccount } = props;
  const renderAccountTitle = useMemo(
    () => (
      <AccountTitle account={currentAccount} />
      // eslint-disable-next-line react-hooks/exhaustive-deps
    ),
    [currentAccount?.id],
  );

  return (
    <Box sx={sectionHeaderStyles}>
      <Box sx={{ display: "flex", alignItems: "center" }}>{renderAccountTitle}</Box>
      <CloseIcon
        height={20}
        width={20}
        style={{ cursor: "pointer" }}
        onClick={props.onToggleTheSection}
      />
    </Box>
  );
}

export default Header;
