/* eslint-disable react/display-name */
import LinkIcon from "@mui/icons-material/Link";

import "./columnsOverrides.css";

import { Link } from "react-router-dom";

import { SalesforceGrayIcon, SalesforceIcon } from "assets/icons";

import { IActivitySegment } from "types/ActivityStream";
import {
  Account, AccountSourceT,
} from "types/api";
import { getSalesForceLink } from "utils/generics";

export type Params = {
  instanceURL: string,
  onAccountFocus: (accountId?: string) => void,
  onAccountOpportunitiesFocus: (accountId: string, filters: any) => void,
  onPriorityChange: (id: string, priority: string) => Promise<Account>,
  onFilterByAccountLastSignal: (details: {
    id: string;
    name: string;
    segment: IActivitySegment;
    hasValidSignal?: boolean;
  }) => () => void,
  onRowSelection: (e: React.MouseEvent<Element, MouseEvent>, row?: any) => void
}

export type TableColumn = {
  Header: JSX.Element,
  accessor: string,
  className: string,
  style: any,
  regularBorder?: boolean,
  hasBorderRight?: boolean,
  disableSortBy?: boolean,
  Cell?: (props: any) => JSX.Element
}

export type CellProps = {
  value: any;
  row: any;
  isEditable: boolean | undefined;
  data: Account[];
}

export const getWebsiteUrl = (url: string): string => {
  if (!url) return "";
  if (!url.startsWith("http://") && !url.startsWith("https://")) {
    return `https://${url}`;
  }
  if (url.startsWith("http://")) {
    return url.replace("http://", "https://");
  }
  return url;
};

export const getWebsiteIcon = (website: string | undefined): JSX.Element => (website ? (
  <Link
    to={getWebsiteUrl(website)}
    target="_blank"
    rel="noreferrer"
    style={{ margin: "0px 7px" }}
  >
    <LinkIcon sx={{
      color: "gray",
      fontSize: "20px",
      "&:hover": {
        color: "#00223C",
      },
    }}
    />
  </Link>
) : (
  <> </>
));

export const getSourceIcon = (
  instanceURL: string,
  source: AccountSourceT | undefined,
): JSX.Element => {
  if (!source) return <> </>;
  return source.name === "Salesforce" ? (
    <Link
      target="_blank"
      rel="noreferrer"
      to={getSalesForceLink({ instanceURL, objectId: source.objectId })}
      className="link-with-icon"
    >
      <SalesforceGrayIcon
        width={25}
        height={25}
        className="icon default-icon"
      />
      <SalesforceIcon
        width={25}
        height={25}
        className="icon blue-icon"
      />
    </Link>
  ) : (
    <> </>
  );
};
