import { Box, TextField, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import {
  ChangeEvent, KeyboardEvent, useEffect, useRef, useState,
} from "react";

import { useDebounceEffect } from "hooks";
import useTerritoryFilters from "services/shooks/territoryFilters";
import { MinMaxFilterTypes } from "types/api";
import { RangeFilter } from "types/api/territoryFilters";
import isNumber from "utils/isNumber";

import { formatDateToYYYYMMDD } from "../utils";

interface IErrorDetails {
  errorMessage: string;
  fieldName: string;
}

const getErrorDetails = ({ min, max }: Partial<RangeFilter>, lastFieldNameChanged: string) => {
  const isNegativeMin = isNumber(min) && min !== undefined && min < 0;
  const isNegativeMax = isNumber(max) && max !== undefined && max < 0;
  const isMinBiggerThanMax = isNumber(min) && isNumber(max) && Number(min) > Number(max);

  switch (true) {
    case isNegativeMin:
      return { errorMessage: "The Min Value should be a positive value", fieldName: "min" };

    case isNegativeMax:
      return { errorMessage: "The Max Value should be a positive value", fieldName: "max" };

    case isMinBiggerThanMax:
      return {
        errorMessage: "The Min Value couldn't be bigger than the max value",
        fieldName: lastFieldNameChanged,
      };

    default:
      return { errorMessage: "", fieldName: "" };
  }
};

type Props = {
  valuePath: string;
  label: string;
  type: MinMaxFilterTypes;
  hasActiveFilters: boolean;
  range: RangeFilter | undefined;
  value: RangeFilter | undefined;
}

function MinMaxInput({
  valuePath,
  label,
  type,
  hasActiveFilters,
  range: _range = {} as RangeFilter,
  value: _value,
}: Props) {
  const {
    actions: { onFilterChange },
  } = useTerritoryFilters();
  const cooldown = useRef(false);
  const [value, setValue] = useState<Partial<RangeFilter>>({});
  const [lastFieldNameChanged, setLastFieldNameChanged] = useState("");
  const [{ errorMessage, fieldName }, setErrorDetails] = useState<IErrorDetails>({
    errorMessage: "",
    fieldName: "",
  });

  const minRef = useRef({ value: undefined });
  const maxRef = useRef({ value: undefined });

  const onReset = () => {
    setValue({});
    onFilterChange(valuePath, {});
    minRef.current.value = undefined;
    maxRef.current.value = undefined;
  };

  useEffect(() => {
    const initValue = {} as Partial<RangeFilter>;
    if (_value?.min) initValue.min = _value.min;
    if (_value?.max) initValue.max = _value.max;
    setValue(initValue);
  }, [_value, valuePath]);

  useEffect(() => {
    const timer = setTimeout(() => {
      cooldown.current = true;
    }, 3000);
    return () => clearTimeout(timer);
  }, []);

  useDebounceEffect(
    () => {
      if (!cooldown.current) return;

      if (type === MinMaxFilterTypes.Date) {
        if (value.min && value.max && new Date(value.min) > new Date(value.max)) {
          setErrorDetails({
            errorMessage: "The Min Value couldn't be bigger than the max value",
            fieldName: lastFieldNameChanged,
          });
          return;
        }

        const validValue = {} as Partial<RangeFilter>;
        if (value.min) validValue.min = value.min;
        if (value.max) validValue.max = value.max;
        onFilterChange(valuePath, value);
      } else {
        const errorDetails = getErrorDetails(value, lastFieldNameChanged);
        const isValidValue = !errorDetails.errorMessage;
        setErrorDetails(errorDetails);

        if (isValidValue) {
          const validValue = {} as Partial<RangeFilter>;
          if (isNumber(value?.min)) validValue.min = +value.min!;
          if (isNumber(value?.max)) validValue.max = +value.max!;
          onFilterChange(valuePath, validValue);
        }
      }
    },
    600,
    [value.min, value.max],
  );

  const onFormChange = (e: ChangeEvent<HTMLFormElement>): void => {
    const { name, value } = e.target;
    setValue((oldValue) => ({ ...oldValue, [name]: value }));
    setLastFieldNameChanged(name);
  };

  const onKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    const ignoredKeys = ["e", "+", "-", "."];
    if (ignoredKeys.includes(event.key)) event.preventDefault();
  };

  const handleDateChange = (name: string, date: Date | null) => {
    if (!date) return;
    const formattedDate: string = formatDateToYYYYMMDD(date);
    setValue((oldValue) => ({ ...oldValue, [name]: formattedDate }));
    setLastFieldNameChanged(name);
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          color: "gray",
          fontSize: "14px",
        }}
      >
        {label}
        {hasActiveFilters && (
          <Typography
            onClick={onReset}
            fontWeight="500"
            fontSize="11px"
            color="#2A7AB7"
            sx={{ cursor: "pointer" }}
          >
            Reset
          </Typography>
        )}
      </div>

      {type === MinMaxFilterTypes.Number ? (
        <form
          onChange={onFormChange}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: 20,
          }}
        >
          <TextField
            sx={{ flex: 1 }}
            placeholder="Min value"
            type="number"
            name="min"
            error={fieldName === "min"}
            autoComplete="off"
            onKeyDown={onKeyDown}
            inputRef={minRef}
            value={value.min}
          />

          <TextField
            sx={{ flex: 1 }}
            placeholder="Max value"
            type="number"
            name="max"
            error={fieldName === "max"}
            autoComplete="off"
            inputRef={maxRef}
            value={value.max}
            onKeyDown={onKeyDown}
          />
        </form>

      ) : (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            <div>
              <div style={{
                color: "gray",
                fontSize: "12px",
                marginBottom: "5px",
              }}
              >
                From
              </div>
              <DatePicker
                value={value.min ? new Date(value.min) : null}
                onChange={(date) => handleDateChange("min", date)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{ flex: 1 }}
                    placeholder="Min value"
                    name="min"
                    error={fieldName === "min"}
                    autoComplete="off"
                    onKeyDown={onKeyDown}
                  />
                )}
                views={["year", "month", "day"]}
              />
            </div>
            <div>
              <div style={{
                color: "gray",
                fontSize: "12px",
                marginBottom: "5px",
              }}
              >
                To
              </div>
              <DatePicker
                value={value.max ? new Date(value.max) : null}
                onChange={(date) => handleDateChange("max", date)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{ flex: 1 }}
                    placeholder="Max value"
                    name="max"
                    error={fieldName === "max"}
                    autoComplete="off"
                    onKeyDown={onKeyDown}
                  />
                )}
                PopperProps={{
                  sx: {
                    position: "absolute",
                    left: 100,
                    top: 100,
                  },
                }}
                views={["year", "month", "day"]}
              />
            </div>
          </Box>
        </LocalizationProvider>
      )}

      {errorMessage && (
        <div style={{ color: "#e74c3c", fontSize: 12, marginTop: 6 }}>{errorMessage}</div>
      )}
    </div>
  );
}

export default MinMaxInput;
