import { getFaviconByDomain } from "./urlParsers";

type Email = {
  address: string;
  name?: string;
};

interface IFormatMailLink {
  toEmail: Email;
  recipients: Email[];
  ccList?: Email[];
  subject?: string;
  body?: string;
}

export const formatMailLink = ({
  toEmail,
  recipients,
  ccList,
  body,
  subject,
}: IFormatMailLink): string => {
  const toSection = recipients.map((recipient) => recipient.address).join(",");
  const ccSection = ccList?.map((cc) => cc.address).join(",");
  const subjectEncoded = subject && encodeURIComponent(subject);
  const bodyEncoded = body && encodeURIComponent(body);
  const mailtoSections = [
    `to=${toSection}`,
    ccSection ? `cc=${ccSection}` : "",
    subject ? `subject=${subjectEncoded}` : "",
    body ? `body=${bodyEncoded}` : "",
  ];
  const mailtoUrl = `mailto:${toEmail.address}?${mailtoSections.filter((section) => section).join("&")}`;
  return mailtoUrl;
};

export function getDomainFaviconFromEmail(email?: string) {
  //https://logo.clearbit.com/${account.domain}
  const isEmailValid = email && email.includes("@");
  if (!isEmailValid) return "";
  const domain = email.split("@")[1];
  // this temp fix is to avoid showing the default icon for non business & private emails,
  // and the domain should be passed from the account source instead
  const ignoreDomains = ["gmail.com", "outlook.com", "hotmail.com", "yahoo.com"];
  if (ignoreDomains.includes(domain)) return "";
  return getFaviconByDomain(domain);
}
