import { useAuth0 } from "@auth0/auth0-react";
import { isValid } from "date-fns/esm";
import { useEffect } from "react";

import useLastSignalCount from "services/shooks/useNewActivitiesSignals";

import { useTabActiveStatus } from "./useTabActiveStatus";

const INACTIVE_INTERVAL = process.env.REACT_APP_INACTIVE_INTERVAL
  ? Number(process.env.REACT_APP_INACTIVE_INTERVAL)
  : 1800000;
const useActivityFeedOpenActionListener = () => {
  const { actions, state } = useLastSignalCount();
  const isWindowActive = useTabActiveStatus();
  const { isAuthenticated, user } = useAuth0<User>();

  const updateLastSeenDate = (lastSeenDate?: string) => {
    const lastUserActiveTime = lastSeenDate
      ? new Date(lastSeenDate)
      : new Date(new Date().setDate(new Date().getDate() - 1));
    actions.updateLastSeenDate(lastUserActiveTime.toDateString());
  };

  // @desc:is the page loads and the user is still on the page, update the last seen date after 30 min
  useEffect(() => {
    if (!isAuthenticated) return;
    // waits for 30 min ,and update the last seen date if the user is still on the page
    const interval = setInterval(() => {
      // only if the last seen date is more than 30 min ago
      const loadedAndIdel = !state.lastSeenDate
        || new Date().getTime() - new Date(state.lastSeenDate).getTime() > INACTIVE_INTERVAL;
      const shouldUpdateLastSeenDate = loadedAndIdel && isWindowActive;
      if (shouldUpdateLastSeenDate) {
        updateLastSeenDate(state.lastSeenDate);
      }

    }, INACTIVE_INTERVAL);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  useEffect(() => {
    if (!isAuthenticated) return;
    const timeOut = setTimeout(() => {
      if (state.lastSeenDate && isValid(new Date(state.lastSeenDate))) {
        actions.initialize();
        updateLastSeenDate(state.lastSeenDate);
      }
    }, 5000);
    return () => clearTimeout(timeOut);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.lastSeenDate, isAuthenticated]);
};

export default useActivityFeedOpenActionListener;
