import dayjs, { ManipulateType } from "dayjs";

import { RangeDateFilter } from "types/api/territoryFilters";

export const hasValidDate = (date?: string) => {
  if (!date) return false;
  return !Number.isNaN(Date.parse(date));
};

export const isSameDay = (date1: string, date2: string) => new Date(date1).getDate() === new Date(date2).getDate();

export function getRangeDateValue(period?: string): RangeDateFilter {
  if (period === "not_set") {
    return {
      startDate: null,
      endDate: null,
    };
  }
  if (period === "any") {
    return {
      startDate: dayjs()
        .subtract(20, "year" as ManipulateType)
        .toISOString(),
      endDate: new Date().toISOString(),
    };
  }
  if (period === "-any") {
    return {
      startDate: new Date().toISOString(),
      endDate: dayjs()
        .add(20, "year" as ManipulateType)
        .toISOString(),
    };
  }

  const [count, timeUnit, dateDirection] = period?.split("_") || [];
  const todayDateIso = new Date().toISOString();
  if (!count && !timeUnit) return { startDate: undefined, endDate: todayDateIso };

  if (dateDirection === "+") {
    return {
      startDate: todayDateIso,
      endDate: dayjs()
        .add(Number(count), timeUnit as ManipulateType)
        .toISOString(),
    };
  }
  return {
    startDate: dayjs()
      .subtract(Number(count), timeUnit as ManipulateType)
      .toISOString(),
    endDate: todayDateIso,
  };
}

export const getValueFromRangeDate = (rangeDate: RangeDateFilter) => {
  if (!rangeDate.startDate && !rangeDate.endDate) return "not_set";
  if (rangeDate.startDate && rangeDate.endDate) return "any";
  if (rangeDate.startDate) return "-any";
  const [count, timeUnit] = dayjs(rangeDate.endDate).to(dayjs()).split(" ");
  // @ts-ignore
  const dateDirection = rangeDate.endDate > rangeDate.startDate ? "+" : "-";
  return `${count}_${timeUnit}_${dateDirection}`;
};
