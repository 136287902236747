import { create } from "zustand";

import FiltersAPI, { SavedUserFilter, UserFiltersScopeEnum } from "services/user/filters";

import { StateRecord } from "./main";
import { convertToCompatibleTerritoryFilters } from "./utils";

interface Filters {
  // @note: scopeType is a shared value that will enable use to reuse the functionalities in different scopes
  scopeType?: UserFiltersScopeEnum.Territory;
  filters: SavedUserFilter[];
}

interface FiltersState extends StateRecord {
  isLoading?: boolean;
  state: Filters;
  actions: {
    get: () => Promise<SavedUserFilter[]>;
    saveNewFilter: (payload: Partial<SavedUserFilter>) => Promise<SavedUserFilter>;
    initialize: () => Promise<SavedUserFilter[]>;
    delete: (filterId: string) => Promise<void>;
  };
}

const initialFiltersState = {
  scopeType: UserFiltersScopeEnum.Territory,
} as Filters;

const useUserFilters = create<FiltersState>((setState, getState) => ({
  isLoading: false,
  state: initialFiltersState,
  actions: {
    async get() {
      const { scopeType } = getState().state;
      const filters = await FiltersAPI.get();
      setState({ state: { filters, scopeType } });
      return filters;
    },
    async initialize() {
      const { get: getFilters } = getState().actions;
      const filters = await getFilters();
      return filters;
    },
    async saveNewFilter(payload) {
      const { scopeType, filters } = getState().state;
      const _payload = {
        ...payload,
        filter: convertToCompatibleTerritoryFilters(payload.filter!),
        type: scopeType,
      };
      const filterId: string = await FiltersAPI.save(_payload);
      const addedFilter = { id: filterId, ..._payload } as SavedUserFilter;
      const currentFilters = [addedFilter, ...filters];
      setState({
        state: {
          scopeType,
          filters: currentFilters,
        },
      });
      return payload as SavedUserFilter;
    },
    async delete(filterId: string) {
      const { scopeType, filters } = getState().state;
      await FiltersAPI.delete(filterId);
      const currentFilters = filters.filter((filter) => filter.id !== filterId);
      setState({
        state: {
          scopeType,
          filters: currentFilters,
        },
      });
    },
  },
}));

export default useUserFilters;
