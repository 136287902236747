import { Stack } from "@mui/material";

import { IActivity } from "types/api/activities";

import { PersonLabel } from "./common";
import { ActivityContentInteractions, EmailField } from "../common";

interface IContentViewedProps {
  activity: IActivity;
  onActionTrigger?: (action: ActivityContentInteractions, value?: string, name?: string) => void;
}

export function ContentViewed({ activity, onActionTrigger }: IContentViewedProps) {
  const person = {
    name: activity.secondParty?.personName,
    email: activity.secondParty?.email,
    title: activity.secondParty?.title,
  };

  const onFilterByPerson = (personName?: string, personId?: string) => {
    if (onActionTrigger && personName) {
      onActionTrigger(ActivityContentInteractions.FilterByPerson, personName, personId);
    }
  };

  return (
    <Stack direction="column" sx={{ width: "100%", wordWrap: "break-word" }}>
      <Stack direction="column" sx={{ width: "100%" }}>
        <EmailField
          label="Who:"
          valueSx={{ textTransform: "capitalize" }}
          value={(
            <PersonLabel
              onClick={onFilterByPerson}
              value={person.name}
              fallBackValue="Unknown"
              tipDetails={{
                email: person?.email,
                name: person?.name,
                jobTitle: person?.title,
              }}
            />
          )}
        />
        <EmailField
          label="Viewd:"
          value={(
            <span style={{ "--lines-max": 3 } as any} className="multi-line-truncate">
              {activity.subject}
            </span>
          )}
          valueSx={{ maxWidth: "60%", display: "block" }}
        />
      </Stack>
    </Stack>
  );
}

export default ContentViewed;
