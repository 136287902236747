import { KeyboardArrowDown } from "@mui/icons-material";
import {
  Box, Button, CircularProgress, Fade, Menu, MenuItem,
} from "@mui/material";
import { Stack } from "@mui/system";
import { MouseEvent, useState } from "react";
import { useToggle } from "react-use";

interface IMenuStyles {
  [key: string]: {
    background?: string;
    color: string;
    width: string;
    height: string;
    fontWeight?: string;
    border?: string;
    textTransform?: string;
  };
}
const sharedPriorityBadgeSx = {
  background: "#F5F5F5",
  fontWeight: "700",
  width: "66px",
  height: "24px",
};
export const PriorityBadgeSx: IMenuStyles = {
  A: { color: "#004E87", ...sharedPriorityBadgeSx },
  B: { color: "#2A7AB7", ...sharedPriorityBadgeSx },
  C: { color: "#61ABF2", ...sharedPriorityBadgeSx },
  D: { color: "#61ABF2", ...sharedPriorityBadgeSx },
  none: {
    color: "#4D4D4D", width: "66px", height: "24px", textTransform: "lowercase",
  },
};

interface IPriorityProps {
  priority: string;
  onPriorityChange: any;
  id: string;
}

export function Priority({ priority, onPriorityChange, id }: IPriorityProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isLoading, asyncToggle] = useToggle(false);
  const open = Boolean(anchorEl);

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onMenuChange = (value: string | null) => {
    asyncToggle(onPriorityChange(id, value));
    handleClose();
  };
  const currentStyle = PriorityBadgeSx[priority || "none"] || {};

  return (
    <Box>
      <Button
        id="fade-button"
        aria-controls={open ? "fade-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        endIcon={<KeyboardArrowDown />}
        sx={{
          ...currentStyle,
          padding: "0 7px",
          justifyContent: "flex-start",
          border: "1px solid #ccc",
        }} // mui button are inline flex for some reason
      >
        {isLoading ? (
          <Stack sx={{ m: "0 auto" }}>
            <CircularProgress size="15px" />
          </Stack>
        ) : (
          <Stack sx={{ ml: "10px", flex: 1 }}>{priority || ""}</Stack>
        )}
      </Button>
      <Menu
        id="fade-menu"
        MenuListProps={{
          "aria-labelledby": "fade-button",
          disablePadding: true,
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        <MenuItem
          sx={{ ...PriorityBadgeSx.none, background: "#F5F5F5" }}
          onClick={() => onMenuChange(null)}
        />
        <MenuItem sx={PriorityBadgeSx.A} onClick={() => onMenuChange("A")}>
          A
        </MenuItem>
        <MenuItem sx={PriorityBadgeSx.B} onClick={() => onMenuChange("B")}>
          B
        </MenuItem>
        <MenuItem sx={PriorityBadgeSx.C} onClick={() => onMenuChange("C")}>
          C
        </MenuItem>
        <MenuItem sx={PriorityBadgeSx.D} onClick={() => onMenuChange("D")}>
          D
        </MenuItem>
      </Menu>
    </Box>
  );
}
