import { useCallback, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { getUrlQueryValuesFromUrl, paramsFromStringQuery } from "utils/urlParsers";

const initialQueryStringValue = getUrlQueryValuesFromUrl(window.location.search);
const useSearchQuery = <T = any>(initialValues?: T): [T, (field: T, reset?: boolean) => void] => {
  const [queryFields, setQueryFields] = useState<T>(initialQueryStringValue);
  const [searchParams, setSearchParams] = useSearchParams();

  const updateSearchParam = useCallback(
    (field: T, resetFlag?: boolean) => {
      setSearchParams((prevParamsState) => {
        const preFields = getUrlQueryValuesFromUrl(window.location.search);
        const newQueryFields = resetFlag ? { ...field } : { ...preFields, ...field };
        const nextUrl = paramsFromStringQuery(newQueryFields, resetFlag);
        return nextUrl;
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [searchParams.toString()],
  );

  useEffect(() => {
    setQueryFields(getUrlQueryValuesFromUrl(searchParams.toString()));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams.toString()]);

  return [queryFields, updateSearchParam];
};

export default useSearchQuery;
