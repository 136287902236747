import { AxiosNode } from "utils/axios";

import { PersonContactInfo } from "./shooks/people";

class PeopleAPI {
  static fetchPeopleStats = async (opt: { accountId: string }) => {
    const { accountId } = opt;
    const query = new URLSearchParams({ accountId }).toString();
    const { data } = await AxiosNode.get<any>(`/people/stats?${query}`);
    return data;
  };

  static getPersonContactInfo = async ({
    email,
    personId,
  }: {
    email: string;
    personId?: string;
  }) => {
    const query = new URLSearchParams({ emailAddress: email }).toString();
    const { data } = await AxiosNode.get<{ contactInfo: PersonContactInfo }>(
      `/people/contact-info?${query}`,
    );
    return (data?.contactInfo || {});
  };
}

export default PeopleAPI;
