import { Close } from "@mui/icons-material";
import { Box, Stack, Typography } from "@mui/material";

import FilterControls from "./CustomFilters/FilterControlls";

interface Props {
  onResetFilters?: () => void;
  onHideFilters?: () => void;
  elementRef?: any;
}

function FiltersHead(props: Props) {
  const { onResetFilters, onHideFilters } = props;

  return (
    <Stack
      ref={props.elementRef}
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      p="10px"
      borderBottom="1px solid #F5F5F5"
      boxSizing="border-box"
    >
      <Stack direction="row" gap="10px" alignItems="center">
        <Typography fontSize="20px" fontWeight="bold" color="#333333" lineHeight="28px">
          Filters
        </Typography>
        <FilterControls onResetFilters={onResetFilters} />
      </Stack>
      <Box
        display="flex"
        sx={{ color: "#AAAAAA", "&:hover": { color: "#333333" } }}
        alignItems="center"
      >
        <Close
          onClick={onHideFilters}
          style={{
            color: "currentColor",
            fontSize: "16px",
            cursor: "pointer",
          }}
        />
      </Box>
    </Stack>
  );
}

export default FiltersHead;
