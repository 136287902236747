import { create } from "zustand";

import SettingsAPI from "services/user/settings";
import type { Settings } from "types/api";

import { StateRecord } from "./main";
import { Storage } from "utils/storage";
import { userStorage } from "utils/auth";

interface SettingState extends StateRecord {
  isLoading?: boolean;
  state: Settings;
  actions: {
    get: () => Promise<Settings>;
    update: (payload: Settings) => Promise<Settings>;
    initialize: () => Promise<Settings>;
    setSettings: (payload: Settings) => void;
    updateDismissedActivities: (payload: Partial<Settings["activities"]>) => Promise<void>;
  };
}

const USER_SETTINGS = 'user_settings'
const initialSettingState = {} as Settings;
const useUserSettings = create<SettingState>((set, getState) => ({
  isLoading: false,
  state: userStorage.get(USER_SETTINGS) || initialSettingState,
  actions: {
    async get() {
      set({ isLoading: true });
      const settings = await SettingsAPI.get();
      userStorage.set(USER_SETTINGS, settings)
      set({ isLoading: false, state: settings });
      return settings;
    },
    async initialize() {
      set({ isLoading: true });
      const settings: Settings = await SettingsAPI.get();
      const newState = {
        ...getState().state,
        ...settings,
      };
      userStorage.set(USER_SETTINGS, newState)
      set({ isLoading: false, state: newState });
      return newState;
    },
    async update(payload: Settings) {
      set({ isLoading: true });
      await SettingsAPI.update(payload);
      userStorage.set(USER_SETTINGS, payload)
      set({ isLoading: false, state: payload });
      return payload;
    },
    async updateDismissedActivities(payload: Partial<Settings["activities"]>) {
      const { state } = getState();
      const { activities } = state;
      const newActivities = { ...activities, ...payload };
      const newSettings = { ...state, activities: newActivities };
      await SettingsAPI.update(newSettings);
      userStorage.set(USER_SETTINGS, newSettings)
      set({ state: newSettings });
    },
    setSettings(payload: Settings) {
      userStorage.set(USER_SETTINGS, payload)
      set({ state: payload });
    },
  },
}));

export default useUserSettings;
