export const getUrlQueryValuesFromUrl = <T = any>(url: string): T => {
  const urlQuery = new URLSearchParams(url);
  const params = Object.fromEntries(urlQuery) as unknown as T;
  return params;
};

export const paramsFromStringQuery = (obj: any, shouldCleanEmpty: boolean = true) => {
  const searchParamValues: Record<string, string> = { ...Object.fromEntries(Object.entries(obj)) };
  if (shouldCleanEmpty) {
    Object.keys(searchParamValues).forEach((key) => {
      if (!searchParamValues[key]) {
        delete searchParamValues[key];
      }
    });
  }
  return new URLSearchParams(searchParamValues) as unknown as string;
};

export function getFaviconByDomain(domain: string) {
  // update the resolution to 32x32
  return `https://www.google.com/s2/favicons?domain=${domain}&sz=32`;
}

export function getRandomAvatarForName(email: string) {
  //https://robohash.org/
  return `https://robohash.org/${email}`
}

export function validateImageUrl(url: string): Promise<boolean> {
  return new Promise((resolve) => {
    const img = new Image();

    img.onload = () => resolve(true);  // Image loaded successfully
    img.onerror = () => resolve(false); // Image failed to load

    img.src = url; // Start loading the image
  });
}