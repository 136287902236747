import {
  IAccountRecoedType,
  TerritoryTableColumnTitles,
  TerritoryTableColumnsAccessors,
} from "types/api";
import { CustomFilterOperatorEnum, ICustomFilter } from "types/api/territoryFilters";
import { getFormattedDate } from "utils/dates";

import { rangeOptions } from "./FilterFieldValue/CustomDatePicker";
import { getColumnsWithNames } from "../../utils";

export interface AccountField {
  name: string;
  label: string;
  type: string;
}

enum DataCellType {
  string = "string",
  number = "number",
  date = "date",
  object = "object",
  selection = "selection",
}
// @todo: this list must be removed once we support the loading of columns the their types on the account filters
export const staticColumnsFiledsTypesMap = {
  [TerritoryTableColumnsAccessors.selection]: DataCellType.string,
  [TerritoryTableColumnsAccessors.account]: DataCellType.string,
  [TerritoryTableColumnsAccessors.pipelineSignal]: DataCellType.string,
  [TerritoryTableColumnsAccessors.rankValue]: DataCellType.number,
  [TerritoryTableColumnsAccessors.priority]: DataCellType.string,
  [TerritoryTableColumnsAccessors.accountStatus]: DataCellType.object,
  [TerritoryTableColumnsAccessors.lastSalesActivity]: DataCellType.date,
  [TerritoryTableColumnsAccessors.lastCustomerSignal]: DataCellType.date,
  [TerritoryTableColumnsAccessors.employees]: DataCellType.number,
  [TerritoryTableColumnsAccessors.industry]: DataCellType.string,
  [TerritoryTableColumnsAccessors.annualRevenue]: DataCellType.number,
  [TerritoryTableColumnsAccessors.similarWins]: DataCellType.object,
  [TerritoryTableColumnsAccessors.nextRenewalDate]: DataCellType.date,
  [TerritoryTableColumnsAccessors.annualRecurringRevenue]: DataCellType.number,
  [TerritoryTableColumnsAccessors.ownerName]: DataCellType.string,
  [TerritoryTableColumnsAccessors.whitespace]: DataCellType.number,
  [TerritoryTableColumnsAccessors.sdrPersonName]: DataCellType.string,
  [TerritoryTableColumnsAccessors.OBLastActivitySDR]: DataCellType.date,
  [TerritoryTableColumnsAccessors.AELastActivity]: DataCellType.date,
  [TerritoryTableColumnsAccessors.RecordType]: DataCellType.selection,
  [TerritoryTableColumnsAccessors.emailProvider]: DataCellType.selection,
  [TerritoryTableColumnsAccessors.CRM]: DataCellType.selection,
  [TerritoryTableColumnsAccessors.country]: DataCellType.string,
  [TerritoryTableColumnsAccessors.city]: DataCellType.string,
  [TerritoryTableColumnsAccessors.state]: DataCellType.string,
  [TerritoryTableColumnsAccessors.openOpportunitiesAmount]: DataCellType.number,
  [TerritoryTableColumnsAccessors.closedOpportunitiesAmount]: DataCellType.number,
  [TerritoryTableColumnsAccessors.totalClosedOpportunities]: DataCellType.number,
  [TerritoryTableColumnsAccessors.totalOpenOpportunities]: DataCellType.number,
  [TerritoryTableColumnsAccessors.totalLosesToDate]: DataCellType.number,
  [TerritoryTableColumnsAccessors.totalWinsToDate]: DataCellType.number,
  [TerritoryTableColumnsAccessors.alexaRanking]: DataCellType.number,
  [TerritoryTableColumnsAccessors.renewals]: DataCellType.number,
  [TerritoryTableColumnsAccessors.lastSignalDate]: DataCellType.date,
  [TerritoryTableColumnsAccessors.technologies]: DataCellType.string,
};

const isEnabledAccountFields = [
  TerritoryTableColumnsAccessors.sdrPersonName,
  TerritoryTableColumnsAccessors.OBLastActivitySDR,
  TerritoryTableColumnsAccessors.AELastActivity,
  TerritoryTableColumnsAccessors.RecordType,
  TerritoryTableColumnsAccessors.emailProvider,
  TerritoryTableColumnsAccessors.CRM,
];

const suppoertedTypeByOperator = [
  DataCellType.string,
  DataCellType.number,
  DataCellType.date,
  DataCellType.selection,
];

export const accountFieldsOptions = getColumnsWithNames()
  .filter(({ accessor }) => {
    const accessorType = staticColumnsFiledsTypesMap[accessor as TerritoryTableColumnsAccessors]
      || DataCellType.string;
    const isSupportType = isEnabledAccountFields.includes(
      accessor as TerritoryTableColumnsAccessors,
    );
    return suppoertedTypeByOperator.includes(accessorType) && isSupportType;
  })
  .map(({ name, accessor }) => ({
    value: accessor,
    label: name,
    type: staticColumnsFiledsTypesMap[accessor as TerritoryTableColumnsAccessors],
  }));

export const supportedOperatorsOptions = [
  {
    supportTypes: ["string"],
    key: CustomFilterOperatorEnum.Contains,
    lable: "Contains",
    enabled: true,
  },
  {
    supportTypes: ["string"],
    key: CustomFilterOperatorEnum.StartWith,
    lable: "Starts with",
    enabled: false,
  },
  {
    supportTypes: ["number"],
    key: CustomFilterOperatorEnum.Equal,
    lable: "Equal",
    enabled: false,
  },
  {
    supportTypes: ["string"],
    key: CustomFilterOperatorEnum.EndsWith,
    lable: "Ends with",
    enabled: false,
  },
  {
    supportTypes: ["selection"],
    key: CustomFilterOperatorEnum.Empty,
    lable: "Empty",
    enabled: true,
  },
  {
    supportTypes: [],
    key: CustomFilterOperatorEnum.NotEmpty,
    lable: "Not Empty",
    enabled: false,
  },
  {
    supportTypes: ["date"],
    key: CustomFilterOperatorEnum.DateRange,
    lable: "Date Range",
    enabled: true,
  },
  {
    supportTypes: ["selection"],
    key: CustomFilterOperatorEnum.Selection,
    lable: "Select a value",
    enabled: true,
  },
];

export const doesSelectedOperatorSupportValue = (operatorKey: string) => {
  const operator = supportedOperatorsOptions.find((operator) => operator.key === operatorKey);
  return (
    operator?.supportTypes.includes("string")
    || operator?.supportTypes.includes("number")
    || operator?.supportTypes.includes("date")
    || operator?.supportTypes.includes("selection")
  );
};

export const getHasValidValue = (customFilter: ICustomFilter) => {
  // this condition for the DateRange value
  if (typeof customFilter.value === "object") return Boolean(Object.values(customFilter.value).filter(Boolean).length);
  if (customFilter.operator === CustomFilterOperatorEnum.Empty) return true;
  return Boolean(customFilter.value);
};

export const getCustomFilterLabel = ({
  filedNameLabel,
  value,
  operatorLabel,
  isValueSupported,
  accountRecordTypes,
  operator,
}: any) => {
  if (value?.startDate && value?.endDate) return `${filedNameLabel} from "${getFormattedDate(value.startDate)}" to "${getFormattedDate(value.endDate)}"`;
  if (value?.startDate) return `${filedNameLabel} After "${getFormattedDate(value.startDate)}"`;
  if (value?.endDate) return `${filedNameLabel} Before "${getFormattedDate(value.endDate)}"`;

  if (value?.intervalPeriod) {
    const valueKeylabel = rangeOptions.find(({ key }) => key === value.intervalPeriod)?.label;
    const label = `${filedNameLabel} for the "${valueKeylabel}"`;
    return label;
  }

  if (operator === CustomFilterOperatorEnum.Empty) return `Empty ${filedNameLabel}`;

  if (filedNameLabel === TerritoryTableColumnTitles.RecordType) {
    const optionName = accountRecordTypes?.find(({ Id }: IAccountRecoedType) => Id === value)?.Name;
    const label = `${filedNameLabel} Select "${optionName}"`;
    return label;
  }

  return isValueSupported
    ? `${filedNameLabel} ${operatorLabel} "${value?.trim()}"`
    : `${filedNameLabel} must be ${operatorLabel}`;
};
