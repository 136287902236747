import { Button, Stack } from "@mui/material";

import { Box } from "@mui/system";

import React, { useEffect } from "react";

import TextField from "components/common/Inputs/TextInput";

import ValueTag from "./ValueTag";

interface CustomTextFieldProps {
  values: string[]
  placeholder: string;
  onUpdate: (values: string[]) => void;
  onChange?: (value: string) => void;
  value?: string;
  renderOptions?: React.ReactNode;
  enableReset?: boolean;
  disable?: boolean
  onEnter?: () => void
}

export function CustomTextField(props: CustomTextFieldProps) {
  const { values, onUpdate, placeholder, onChange, renderOptions, enableReset, disable } = props;
  const [inputValue, setInputValue] = React.useState<string | undefined>(props.value);
  const handleAdd = () => {
    if (disable) return;
    if (inputValue) {
      const isExist = values.find((value) => value === inputValue);
      if (!isExist) {
        const newValues = [...values, inputValue];
        onUpdate(newValues);
      }
    }
    setInputValue("");
  };

  const handleDelete = (val: string) => {
    if (disable) return;
    const newValues = values.filter((value) => value !== val);
    onUpdate(newValues);
  };
  const onEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (disable) return;
    if (e.key === "Enter") {
      if (typeof props.onEnter === 'function') {
        props.onEnter?.()
      } else {
        handleAdd();
      }
    }
  };

  useEffect(() => {
    onChange?.(inputValue || "");
  }, [inputValue]);

  useEffect(() => {
    setInputValue(props.value || "");
  }, [props.value]);

  return (
    <Box sx={{
      display: "flex", flexDirection: "column", gap: "10px", maxWidth: "400px",
    }}
    >
      <Box
        sx={{
          display: "flex", flexDirection: "row", gap: "10px", position: "relative",
        }}
        onKeyPress={onEnter}
      >
        <TextField
          placeholder={placeholder}
          variant="outlined"
          value={inputValue}
          autoComplete="off"
          disable={disable}
          onChange={(value) => setInputValue(value)}
          sx={{ width: "300px" }}
          enableReset={enableReset}
        />
        <Button variant="outlined" color="primary" onClick={handleAdd}>Add</Button>
        {renderOptions && renderOptions}
      </Box>
      <Stack direction="row" sx={{ flexWrap: "wrap", gap: "10px" }}>
        {values.map((value, index) => (
          <ValueTag key={index} value={value} onDelete={() => handleDelete(value)} />
        ))}
      </Stack>
    </Box>
  );
}

export default CustomTextField;
