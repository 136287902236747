import { Box, Typography } from "@mui/material";

function Error({ error }: { error: string }) {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
      }}
    >
      <Typography
        variant="h3"
        sx={{
          color: "#333333",
          marginBottom: "30px",
          fontSize: "1.125rem",
          fontWeight: 700,
        }}
      >
        {error}
      </Typography>
    </Box>
  );
}

export default Error;
