import envConfig from "config/envConfig";

export const stringUtils = {
  isString: (field?: unknown) => typeof field === "string",
  toLowerCase: (field?: string) => field?.toLowerCase(),
  compareIgnoreCase: (field1?: string, field2?: string) => stringUtils.toLowerCase(field1) === stringUtils.toLowerCase(field2),
};

export function removeBackendMarkupForLinks(text: string): string {
  if (!stringUtils.isString(text)) {
    return "";
  }

  // Regex to match the specific pattern <link|label> and extract the link part
  const regex = /<([^|]+)\|[^>]+>/g;

  // Replace the matches with just the link part
  const refinedText = text.replace(regex, (match, url) => url);

  return refinedText;
}
/**
 * Refine markdown links to be compatible with markdown rendering
 * @param text - The text to refine
 * @returns The refined text
 * @example
 * refineMarkdownLinks("This is a [link](https://salesdash.ai)")
*/
export function refineMarkdownLinks(rawText: string): string {
  if (!stringUtils.isString(rawText)) {
    return "";
  }

  // Regex to capture links with or without protocol, and email addresses
  const regex = /(?:https?:\/\/)?(?:www\.)?([a-zA-Z0-9.-]+\.[a-zA-Z]{2,})([^\s|>]*)|([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})/g;
  const matches = [...rawText.matchAll(regex)];

  if (matches.length === 0) {
    return rawText;
  }

  const referenceMap = new Map<number, string>();
  let counter = 1;

  const text = removeBackendMarkupForLinks(rawText);
  const refinedText = text.replace(regex, (match, domain, path, email) => {
    const ref = counter++;
    const url = email
      ? `mailto:${email}`
      : `https://${domain}${path}`;
    referenceMap.set(ref, url);
    return `[${email || domain}][${ref}]`;
  });

  const references = Array.from(referenceMap)
    .map(([key, value]) => {
      if (envConfig.isDevEnvironment && value.includes("https://staging.salesdash.ai")) {
        value = value.replace("https://staging.salesdash.ai", "http://localhost:3000");
        if (value.includes('territory'))
          value += `&filters=true`
      }
      return `[${key}]: ${value}`;
    })
    .join("\n");

  return `${refinedText}\n\n${references}`;
}

export function removeSFCustomSuffix(key: string) {
  return key?.replace('__c', '')
};