import {
  Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, SxProps,
  Typography,
} from "@mui/material";
import { CSSProperties, MouseEventHandler, ReactNode, useState } from "react";

import Tooltip from "components/Tooltip";

interface Props {
  title: string;
  hoverTitle: string;
  allowHover: boolean;
  message: string;
  onConfirm: () => void;
  label?: string;
  icon?: ReactNode;
  renderButton?: (props: { onOpen: () => void }) => ReactNode;
  error?: string;
  disableSubmit?: boolean;
  parentSx?: CSSProperties;
  buttonSx?: SxProps;
  dialogTitleSx?: SxProps;
  dialogContentTextSx?: SxProps;
  cancelButtonSx?: SxProps;
  confirmButtonSx?: SxProps;
  labelSx?: SxProps;
  children?: ReactNode;
  cancelBtnLabel?: string;
  confirmBtnLabel?: string;
  disableModalOpening?: boolean;
  onCancel?: () => void;
}

function ConfirmModal({
  title,
  hoverTitle,
  allowHover,
  message,
  onConfirm,
  onCancel,
  label,
  icon,
  error,
  renderButton,
  disableSubmit,
  parentSx,
  buttonSx,
  dialogTitleSx,
  dialogContentTextSx,
  cancelButtonSx,
  confirmButtonSx,
  labelSx,
  children,
  cancelBtnLabel,
  confirmBtnLabel,
  disableModalOpening,
}: Props) {
  const [open, setOpen] = useState(false);
  const [isHovering, setIsHovering] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  }

  const handleClose = () => {
    onCancel?.();
    setOpen(false);
  }
  const handleMouseEnter = () => setIsHovering(allowHover && true);
  const handleMouseLeave = () => setIsHovering(false);
  const handleConfirm = (event: any) => {
    event.preventDefault?.();
    event.stopPropagation?.();
    onConfirm();
    handleClose();
  };


  return (
    <div style={{
      display: !allowHover ? "block" : "none",
      ...parentSx,
    }}
    >
      {renderButton ? renderButton({ onOpen: handleOpen }) : (
        <Tooltip placement="top" hide={!isHovering} title={hoverTitle}>
          <Button
            disabled={disableModalOpening}
            variant="light"
            onClick={handleOpen}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            sx={{ ...buttonSx, height: '46px' }}
            startIcon={icon}
          >
            <Typography sx={labelSx}>{label}</Typography>
          </Button>
        </Tooltip>
      )}
      <Dialog open={open} onClose={handleClose} aria-labelledby="confirmation-dialog-title">
        <DialogTitle id="confirmation-dialog-title" sx={dialogTitleSx}>
          {title}
        </DialogTitle>
        {
          children || (
            <DialogContent sx={{
              width: "400px",
            }}
            >
              <DialogContentText sx={dialogContentTextSx}>
                {message}
                {error && (
                  <div style={{
                    color: "red",
                    fontSize: "14px",
                  }}
                  >
                    {error}
                  </div>
                )}
              </DialogContentText>
            </DialogContent>
          )

        }
        <DialogActions sx={{
          display: "flex",
          justifyContent: "space-around",
          padding: "16px",
          alignItems: "center",

        }}
        >
          <Button onClick={handleClose} sx={cancelButtonSx}>
            {cancelBtnLabel || "Cancel"}
          </Button>
          <Button variant="contained" disabled={disableSubmit} onClick={handleConfirm} autoFocus sx={confirmButtonSx}>
            {confirmBtnLabel || "Confirm"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

ConfirmModal.defaultProps = {
  label: "Delete",
  icon: null,
  error: "",
  disableSubmit: false,
  parentSx: {},
  labelSx: {},
  buttonSx: {},
  dialogTitleSx: {},
  dialogContentTextSx: {},
  cancelButtonSx: {},
  confirmButtonSx: {},
  children: null,
  cancelBtnLabel: "Cancel",
  confirmBtnLabel: "Confirm",
  disableModalOpening: false,
};

export default ConfirmModal;
