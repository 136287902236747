import { SaveOutlined } from "@ant-design/icons";
import { Box, ButtonBase, CircularProgress, Stack, Tooltip, Typography } from "@mui/material";
import useToggle from "hooks/useToggle";
import { useMemo } from "react";

interface FilteringResultsDescriptionProps {
  total: number;
  results?: number;
  page: number;
  pageSize: number;
}

export function FilteringResultsDescription(props: FilteringResultsDescriptionProps) {
  const { total, page = 1, pageSize } = props;
  const offset = (page - 1) * pageSize;
  const hasResult = Boolean(total);

  const pageResults = offset + 1;
  const currentPageSize = offset + pageSize;
  const totalResultsCount = currentPageSize > total ? total : currentPageSize;
  const pageResultsRange = pageResults === totalResultsCount ? pageResults : `${pageResults}-${totalResultsCount}`;

  return (
    <Stack direction="row" gap="5px" fontSize="14px" lineHeight="22px" alignItems='center'>
      {hasResult ? (
        <>
          <span>{`${pageResultsRange} of`}</span>

          <span style={{ fontWeight: "bold" }}>{`${total.toLocaleString()} filtered results`}</span>
        </>
      ) : (
        <span style={{ fontWeight: "bold" }}>No results found</span>
      )}
    </Stack>
  );
}

export function EnabledFilterItem({ label, onClick }: { label: string; onClick?: () => void }) {
  return (
    <Stack
      direction="row"
      alignItems="center"
      gap="20px"
      display="flex"
      justifyContent="space-between"
    >
      <Typography fontSize="12px" fontWeight="500" className="truncate" textTransform="capitalize">
        {label}
      </Typography>
      <ButtonBase
        onClick={onClick}
        sx={{ color: "#2A7AB7", "&:hover": { textDecoration: "underline" } }}
      >
        Reset
      </ButtonBase>
    </Stack>
  );
}

interface SaveViewButtonProps {
  onSaveView: () => Promise<void>;
}
export const SaveViewButton = (props: SaveViewButtonProps) => {
  const { onSaveView } = props
  const [isUpdatingView, , asyncUpdatingViewToggle] = useToggle();

  const onClick = async (event: any) => {
    event.stopPropagation();
    await asyncUpdatingViewToggle(onSaveView?.());
  };

  return useMemo(() => (
    <Tooltip placement="bottom" title="Save filters" >
      <Box sx={{ p: '4px', borderRadius: '3px', '&:hover': { background: 'rgba(0,0,0,.1)' } }} className='pulse-ring' onClick={onClick}>
        {isUpdatingView ? (<CircularProgress size="15px" sx={{ color: "green" }} />) : (<SaveOutlined />)}
      </Box>
    </Tooltip>
  ), [isUpdatingView]);
}