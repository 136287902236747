import { IActivity } from "types/api/activities";

export type Metric = {
  value: any[];
  score: {
    valid: number;
    total: number;
  };
};
// @note: this does not support arrays, arrays currently get transformed to objects! (but we don't need then right now)

export const getMetricsForObject = (
  metrics: Record<string, Metric> = {},
  activity: Record<string, any>,
): Record<string, any> => {
  const result: Record<string, any> = {};
  for (const [key, value] of Object.entries(activity)) {
    if (typeof value === "object" && value !== null) {
      if (metrics[key]) {
        result[key] = getMetricsForObject(metrics[key] as unknown as Record<string, Metric>, value);
      } else {
        result[key] = getMetricsForObject({}, value);
      }
    } else {
      result[key] = {
        value,
        score: metrics[key]
          ? {
            valid: (metrics[key]?.score?.valid || 0) + (value ? 1 : 0),
            total: (metrics[key]?.score?.total || 0) + 1,
          }
          : {
            valid: value ? 1 : 0,
            total: 1,
          },
      };
      if (metrics[key]) {
        result[key].value = metrics[key]?.value?.concat?.(value || []);
      } else {
        result[key].value = value ? [value] : [];
      }
    }
  }
  return result;
};
type NestedMetrics<T> = {
  [key in keyof T]: T[key] extends object ? NestedMetrics<T[key]> : Metric;
};

export function extractActivityMetrics(activities: IActivity[]): NestedMetrics<IActivity> {
  if (!Array.isArray(activities)) return {} as NestedMetrics<IActivity>;
  return activities.reduce((metrics, activity) => {
    // @ts-ignore
    const activityMetrics = getMetricsForObject(metrics, activity);
    for (const key of Object.keys(activityMetrics)) {
      (metrics as any)[key] = activityMetrics[key];
    }
    return metrics;
  }, {} as NestedMetrics<IActivity>);
}
