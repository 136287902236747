import { numberFormats } from "./numbers";

export const listToSelectOptions = (list?: string[], withAllAsAnOption?: boolean) => {
  const allOptions = { label: "All", value: "all" };
  const options = list?.map((item) => ({ value: item, label: item })) || [];
  return withAllAsAnOption ? [allOptions, ...options] : [...options];
};

// @todo: rewrite this with cleaner implementation
// generate marks for slider filter, to get specific steps number
export const generateSliderMarks = (min: number, max: number, steps: number = 1) => {
  const validSteps = steps < 1 ? 1 : steps > 5 ? 5 : steps;
  const step = Math.floor((max - min) / validSteps);
  const marks: { value: number; label: string }[] = [];
  if (min === max || step < 1) return marks;
  for (let i = min; i <= max; i += step) {
    marks.push({
      value: i,
      label: numberFormats.millify(i),
    });
  }
  marks[marks.length - 1] = { value: max, label: numberFormats.millify(max) };
  return marks;
};
