import { TableState } from "react-table";

export interface AccountOpportunities {
  id: string;
  isDeleted: boolean;
  accountId: string;
  account: unknown | null;
  isPrivate: boolean;
  name: string;
  description: string;
  stageName: string;
  amount: number;
  probability: number;
  expectedRevenue: number;
  totalOpportunityQuantity: unknown | null;
  closeDate: string;
  type: string;
  nextStep: string;
  leadSource: string;
  isClosed: boolean;
  isWon: boolean;
  forecastCategory: string;
  forecastCategoryName: string;
  campaignId: string;
  campaign: unknown | null;
  hasOpportunityLineItem: unknown | null;
  pricebook2Id: string;
  pricebook2: unknown | null;
  ownerId: string;
  ownerName: string;
  createdDate: string;
  createdById: string;
  createdBy: unknown | null;
  lastModifiedDate: string;
  lastModifiedById: string;
  lastModifiedBy: unknown | null;
  systemModstamp: string;
  lastActivityDate: unknown | null;
  fiscalQuarter: number;
  fiscalYear: number;
  fiscal: unknown | null;
  contactId: string;
  lastViewedDate: unknown | null;
  lastReferencedDate: unknown | null;
  hasOpenActivity: unknown | null;
  hasOverdueTask: unknown | null;
  lastAmountChangedHistoryId: string;
  lastAmountChangedHistory: unknown | null;
  lastCloseDateChangedHistoryId: string;
  lastCloseDateChangedHistory: unknown | null;
  attributes: unknown | null;
  salesdashUserId: string;
  instanceId: string;
}

export interface AccountOpportunitiesFilters {
  limit?: number;
  offset?: number;
  stage?: ["open", "win", "lose", ""];
  accountId?: string;
  name?: string;
  maxAmount?: number;
  minAmount?: number;
  source?: string;
  type?: string;
  maxClosingDate?: string;
  minClosingDate?: string;
  owner?: string;
  orderBy?: ["name", "value", "stageName", "leadSource", "type", "closeDate", "owner.name"];
  orderDirection?: ["ASC", "DESC"];
}

export interface OpportunitiesColumns extends TableState<OpportunitiesColumns> {
  id: string;
  name: string;
  value: number;
  stageName: string;
  source: string;
  type: string;
  closeDate: string;
  ownerName: string;
}

export interface OpportunitiesFiltersMeta {
  opportunitySources: string[];
  opportunityTypes: string[];
  value: {
    min: number;
    max: number;
  };
}

export enum AccountTabsEnum {
  Overview = "overview",
  Opportunities = "opportunities",
  SimilarWins = "similarWins",
  Products = "products",
  People = "people",
}

export enum OrderDirectionEnum {
  ASC = "Asc",
  DESC = "Desc",
}

// @note: maping the backend values with the fields names in the accounts
export enum WhitespaceAccountOrderByField {
  rank = "rank",
  targetAccount = "targetAccount",
  name = "name",
  ownerName = "ownerName",
  TAMamount = "TAMamount",
  lastCustomerSignal = "lastCustomerSignal",
  lastSalesActivity = "lastSalesActivity",
  nextRenewalDate = "nextRenewalDate",
  employeeCount = "employeeCount",
  annualRevenue = "annualRevenue",
  industry = "industry",
  openOpportunitiesAmount = "openOpportunitiesAmount",
  closedOpportunitiesAmount = "closedOpportunitiesAmount",
  uncapturedWhitespaceAmount = "uncapturedWhitespaceAmount",
}

export enum TerritoryTableColumnsAccessors {
  selection = "id",
  account = "name",
  priority = "priority",
  pipelineSignal = "pipelineSignal",
  lastSalesActivity = "lastSalesActivity",
  lastCustomerSignal = "lastCustomerSignal",
  renews = "nextRenewalDate",
  whitespace = "whitespace",
}

export const TerritoryTableColumnTitles = {
  [TerritoryTableColumnsAccessors.selection]: "Selection",
  [TerritoryTableColumnsAccessors.account]: "Account Name",
  [TerritoryTableColumnsAccessors.priority]: "Priority",
  [TerritoryTableColumnsAccessors.pipelineSignal]: "Pipeline Signal",
  [TerritoryTableColumnsAccessors.lastSalesActivity]: "Last Sales Activity",
  [TerritoryTableColumnsAccessors.lastCustomerSignal]: "Last Customer Signal",
  [TerritoryTableColumnsAccessors.renews]: "Renews",
  [TerritoryTableColumnsAccessors.whitespace]: "Whitespace",
};
