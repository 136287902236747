import { useState } from "react";

const useToggle = (
  initialValue?: boolean,
): [boolean, (v?: boolean) => void, (fn: Promise<any>) => Promise<any>] => {
  const [value, setValue] = useState<boolean>(Boolean(initialValue));
  const toggle = (newValue?: boolean) => setValue((value?: boolean) => (typeof newValue === "boolean" ? newValue : !value));

  const onAwaitToggle = async (fn: Promise<any>) => {
    toggle(true);
    const out = await fn;
    toggle(false);
    return out;
  };

  return [value, toggle, onAwaitToggle];
};

export default useToggle;
