import "./columnsOverrides.css";

import { MouseEvent } from "react";

import { Head } from "components/AccountTables";

import { useSearchQuery } from "hooks";
import useActivities from "services/shooks/activities";
import useUserSettings from "services/shooks/settings";
import useTerritoryFilters from "services/shooks/territoryFilters";
import { IActivitySegment } from "types/ActivityStream";
import { DynFilterColumn, TerritoryTableColumnsAccessors, TerritoryTableColumnsAccessorsOrder, TerritoryTableColumnTitles } from "types/api";

import {
  defaultColumnsMap, makeEditable, removeNonDisplayableColumns, renderUnknowValue,
} from "./baseCols";
import useAccountColumnsSortBy from "./useColumnsSorting";
import { TableColumn } from "./utils";
import {
  FocusWidget, spaceAndCapitalize,
} from "../../utils";
import useAccessManager from "hooks/useAccessManager";
import { removeSFCustomSuffix } from "utils/generics/string";

interface ITerritoryColumnsProps {
  tableMaxWidth?: number;
  visibleColumnsWidth?: number;
  columnsMeta?: DynFilterColumn;
}

export const nonDisplayableCols = ["source", "website", TerritoryTableColumnsAccessors.annualRecurringRevenue, TerritoryTableColumnsAccessors.accountStatus, TerritoryTableColumnsAccessors.alexaRanking]
export const forAllUsersColumns = [TerritoryTableColumnsAccessors.account, TerritoryTableColumnsAccessors.selection]

const useTerritoryColumns = ({
  tableMaxWidth = 0,
  visibleColumnsWidth = 0,
  columnsMeta = {},
}: ITerritoryColumnsProps) => {
  const { actions: activitiesActions } = useActivities();

  const { state: { instanceURL } } = useUserSettings();

  const [_, updateSearchQuery] = useSearchQuery<{
    ac_feed?: boolean;
    activity_segment?: string;
    fw?: FocusWidget;
    accid?: string;
    itemIndex?: string;
    open_tab?: string;
    status?: string;
  }>();

  const { actions: territoryActions } = useTerritoryFilters();

  const hasEmptySpace = Boolean(tableMaxWidth && visibleColumnsWidth) && tableMaxWidth > visibleColumnsWidth;

  const { isFreeUser } = useAccessManager();
  const isForFreeUser = isFreeUser()
  const {
    actions: territoryFiltersActions,
    state: territoryFiltersState,
    isLoading: isLoadingAccounts,
  } = useTerritoryFilters();

  const { sortingPlugin } = useAccountColumnsSortBy({
    onSortColumn: territoryFiltersActions.sort,
    sortingState: {
      orderBy: territoryFiltersState?.filters?.orderBy,
      orderDirection: territoryFiltersState?.filters?.orderDirection,
    },
    isLoading: isLoadingAccounts,
  });

  const onRowSelection = (e: MouseEvent, row?: any) => {
    e.stopPropagation();
    if (!row) return;
    territoryActions.onToggleAccountSelection(row.id);
  };

  const onFilterByAccountLastSignal = (details: { id: string; name: string; segment: IActivitySegment; hasValidSignal?: boolean }) => () => {
    const {
      id, name, segment, hasValidSignal,
    } = details;
    if (id && hasValidSignal) {
      activitiesActions.filterForAccount({
        id,
        name,
        signalSegment: segment,
      });
      updateSearchQuery({ ac_feed: true });
    }
  };

  const onAccountFocus = (accountId?: string) => {
    const { accounts, pagination } = territoryFiltersState;
    const currentIndexInThePage = accounts?.findIndex((account) => account.id === accountId);
    const accountIndex = (pagination.currentPage - 1) * pagination.pageSize + (currentIndexInThePage ?? 0);
    updateSearchQuery({
      fw: FocusWidget.AccountDetails,
      accid: accountId,
      itemIndex: `${accountIndex}`,
    });
  };

  const onAccountOpportunitiesFocus = (accountId: string, filters?: any) => {
    updateSearchQuery({
      ...filters,
      fw: FocusWidget.AccountDetails,
      accid: accountId,
      open_tab: "opportunities",
    });
  }
  const onPriorityChange = async (id: string, priority: string) => territoryFiltersActions.updateAccount({ id, priority });

  const calculateColumnWidth = (columnName: string) => {
    const letterWidth = 8.6;
    const width = (columnName?.length || 11.67) * letterWidth
    return width < 100 ? 100 : width
  };

  const getBaseColumn = (header: string, accessor: string): TableColumn => {

    const columnName = TerritoryTableColumnTitles[accessor as keyof typeof TerritoryTableColumnTitles] || spaceAndCapitalize(removeSFCustomSuffix(header));
    const cellWidth = calculateColumnWidth(columnName);
    return ({
      Header: <Head sx={{
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
        textAlign: "center",
        width: `${cellWidth}px`
      }}
      >
        {columnName}
      </Head>,
      accessor,
      disableSortBy: true,
      className: `collapse-column ${header === "name" ? "sticky-lift-35" : ""}`,
      style: {
        width: `${cellWidth}px`,
        minWidth: `${cellWidth}px`,
        maxWidth: `${cellWidth}px`,
        padding: "10px",
      },
      Cell: ({ value, isEditable }:
        { value: string, isEditable: boolean }) => renderUnknowValue(value, isEditable, accessor),
    })
  };
  const getTerritoryColumns = (): any[] => {
    const availableColumnsMap: Map<string, TableColumn> = defaultColumnsMap({
      instanceURL,
      onAccountFocus,
      onPriorityChange,
      onFilterByAccountLastSignal,
      onRowSelection,
      onAccountOpportunitiesFocus
    });

    return Object.keys(columnsMeta || {})
      .map((key: string) => {
        if (typeof columnsMeta[key] !== 'object') { // handle invalid and old cases
          return null;
        } else if (forAllUsersColumns.includes(key as any)) {// initial value for the default columns
          columnsMeta[key].isViewed = true;
        } else if (nonDisplayableCols.includes(key) || !columnsMeta[key].isViewed) { // hidden columns or columns that use choices not to display
          return null
        } else if (isForFreeUser && columnsMeta[key]?.forFree !== isForFreeUser) { // when free user, show free user columns
          return null;
        }
        let column = columnsMeta[key]
        let tableColumn = availableColumnsMap.has(key) ? availableColumnsMap.get(key)! : getBaseColumn(key, key);
        tableColumn = column.editable ? makeEditable(column, tableColumn) : tableColumn;
        const columnOrder = TerritoryTableColumnsAccessorsOrder[key as keyof typeof TerritoryTableColumnsAccessorsOrder];
        return [columnOrder ?? 30, tableColumn]
      })
      .filter((item): item is [number, TableColumn] => item !== null)
      .sort(([a], [b]) => a - b)
      .map(([, tableColumn]: [number, TableColumn]) => tableColumn);
  };

  const spanColumn = {
    accessor: "--",
    colSpan: "100%",
  };

  const visibleColumns = getTerritoryColumns()
  if (hasEmptySpace) visibleColumns.push(spanColumn);

  const finalColumns = visibleColumns.map(sortingPlugin);

  return {
    columns: finalColumns,
  };
};

export default useTerritoryColumns;
