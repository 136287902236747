import { BugReportOutlined } from "@mui/icons-material";
import { Divider, Stack, Typography } from "@mui/material";

import React from "react";

import PulsEffectBox from "components/common/Animation/PulsEffect";
import { FeaturesEnum } from "config/featureFlagsConfig";
import useFeaturesFlag from "hooks/useFeatureFlag";
import useTerritoryAccounts from "services/shooks/territory";
import useTerritoryFilters from "services/shooks/territoryFilters";

import { CheckBox, CircleBadge, FilterButton } from "./common";
import ActivitySearchBox, { SearchBoxFiltersValue } from "./MultiSelectInput";
import "./style.css";
import { getActivityFiltersOverview } from "./utils";

interface IActivityFiltersHeaderProps {
  signalsCount: number;
  onFilterOnAccountSelectionToggle: () => void;
  isFilterOnAccountSelection: boolean;
  isFiltersShown?: boolean;
  onToggleFilters: () => void;
  isGroupView?: boolean;
  onFilterChange: (filters: SearchBoxFiltersValue) => void;
  filters: SearchBoxFiltersValue;
  selectedAccountsCount: number;
  accountsOptions: any[];
  onToggleMetricsCard?: () => void;
  isMetricsActivityCardShown?: boolean;
}

export function ActivityFiltersHeader(props: IActivityFiltersHeaderProps) {
  const {
    filters, signalsCount, accountsOptions, isGroupView, onToggleFilters, isFiltersShown,
  } = props;
  const {
    state: { selectionState: { isSelectAll } = {} },
  } = useTerritoryFilters();
  const _filters = isSelectAll ? { ...filters, options: [] } : filters;

  const { onToggleMetricsCard, isMetricsActivityCardShown } = props;
  const {
    onFilterOnAccountSelectionToggle,
    selectedAccountsCount,
    onFilterChange,
    isFilterOnAccountSelection,
  } = props;
  const { filtersCount } = getActivityFiltersOverview(_filters);
  const isDebugModeOn = useFeaturesFlag(FeaturesEnum.DebugMode);
  const accountsSignalsActiveValues = useTerritoryAccounts(
    ({ state }) => state?.accountsSignalsActiveValues,
  );
  const onResetActivitySearchBox = isFilterOnAccountSelection
    ? onFilterOnAccountSelectionToggle
    : undefined;

  return (
    <>
      <Stack direction="column" spacing={2}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ p: "10px 20px" }}
        >
          <Stack direction="row" alignItems="center">
            <Typography variant="h5" fontWeight={500}>
              Signals
            </Typography>
            <CircleBadge sx={{ ml: "12px" }}>
              {signalsCount > 99 ? `${99}+` : signalsCount}
            </CircleBadge>
          </Stack>
          <FilterButton
            count={filtersCount}
            onClick={onToggleFilters}
            active={Boolean(isFiltersShown)}
          />
        </Stack>
      </Stack>

      <Divider />

      {isFiltersShown && (
        <Stack direction="column" spacing={2} sx={{ p: "10px 20px " }}>
          <Stack direction="row" alignItems="center" gap="5px">
            <CheckBox
              key={`${isFilterOnAccountSelection}`}
              checked={Boolean(isFilterOnAccountSelection)}
              onChange={onFilterOnAccountSelectionToggle}
            />
            <Typography
              variant="body2"
              component="div"
              sx={{ color: "#262626", fontSize: "12px", fontWeight: "500" }}
            >
              <span>Autofilter by selected</span>
              <PulsEffectBox
                changeDeps={selectedAccountsCount}
                className="number-badge"
                sx={{ position: "relative", display: "inline-block", margin: "0 4px" }}
              >
                {selectedAccountsCount.toLocaleString()}
              </PulsEffectBox>
              <span>accounts</span>
            </Typography>
            {isDebugModeOn && (
              <Stack
                flex={1}
                alignItems="flex-end"
                sx={{
                  "&:hover": {
                    color: "#2A7AB7",
                  },
                  "&:focus": {
                    transform: "scale(1.2)",
                  },
                  pr: "5px",
                  ...(isMetricsActivityCardShown ? { color: "#2A7AB7" } : {}),
                }}
              >
                <BugReportOutlined
                  onClick={onToggleMetricsCard}
                  style={{
                    width: "20px",
                    height: "20px",
                    color: "currentcolor",
                    cursor: "pointer",
                  }}
                />
              </Stack>
            )}
          </Stack>

          {!isGroupView && (
            <ActivitySearchBox
              onFilterChange={onFilterChange}
              filters={_filters}
              // @todo: this need to be removed/refactored and use the filters for represent the single source of truth
              accountsOptions={accountsOptions}
              accountsSignalsActiveValues={accountsSignalsActiveValues}
              onResetActivitySearchBox={onResetActivitySearchBox}
            />
          )}
        </Stack>
      )}
    </>
  );
}
