import { CalendarOutlined } from "@ant-design/icons";
import { MailOutline } from "@mui/icons-material";
import { Tooltip } from "@mui/material";

import { Switcher } from "components/common";
import { ActivityTypes, IActivity } from "types/api/activities";
import { downloadFile } from "utils/file";

import { AddToCalendarAvailableOptions, TooltipContent } from "./common";
import {
  ActivityActionTypes,
  CalendarService,
  addToCalendarFromActivity,
  emailLinkFromActivity,
  onGoToExternalLink,
} from "./utils";

interface ActivityActionProps {
  actionType: ActivityActionTypes;
  actionIcon?: string;
  activity: IActivity;
}

const ActionSupportForActivities = {
  [ActivityActionTypes.SEND_EMAIL]: [
    ActivityTypes.Call,
    ActivityTypes.Email,
    // ActivityTypes.IntentScore,
    ActivityTypes.Lead,
    ActivityTypes.MarketingEmail,
    ActivityTypes.Meeting,
    ActivityTypes.ThirdPartyDownload,
    ActivityTypes.Web,
    ActivityTypes.WinWire,
  ],
  [ActivityActionTypes.ADD_TO_CALENDAR]: [
    ActivityTypes.Email,
    ActivityTypes.Meeting,
    ActivityTypes.ThirdPartyDownload,
    ActivityTypes.Web,
    ActivityTypes.Event,
  ],
};

const isActionSupportedForActivity = (
  actionType: ActivityActionTypes,
  activityType: ActivityTypes,
) => {
  const supportedActivityTypes = ActionSupportForActivities[actionType] || [];
  const isSupported = supportedActivityTypes.includes(activityType);
  return isSupported;
};

function ActivityAction({ actionType, actionIcon, activity }: ActivityActionProps) {
  const emailLink = emailLinkFromActivity(activity);
  const openEmailDialog = () => {
    isSendingEmailApplicable && onGoToExternalLink(emailLink);
  };

  const onAddToCalendar = (targetCalendar: CalendarService) => () => {
    const link = addToCalendarFromActivity(activity, targetCalendar);
    if (!link) return;
    if (targetCalendar === CalendarService.DownloadIcs) {
      return downloadFile(`${activity.subject}.ics`, link);
    }
    onGoToExternalLink(link);
  };
  const isSendingEmailApplicable = Boolean(emailLink);
  if (!isActionSupportedForActivity(actionType, activity.action)) return null;

  return (
    <Switcher active={actionType} fallback={null}>
      {isSendingEmailApplicable && (
        <Switcher.Case id={ActivityActionTypes.SEND_EMAIL}>
          {actionIcon || (
            <Tooltip title="Send an email" enterDelay={1200}>
              <MailOutline
                style={{ fontSize: "16px", cursor: "pointer", color: "currentColor" }}
                onClick={openEmailDialog}
              />
            </Tooltip>
          )}
        </Switcher.Case>
      )}

      <Switcher.Case id={ActivityActionTypes.ADD_TO_CALENDAR}>
        <TooltipContent
          tooltipSx={{ p: "5px" }}
          title={<AddToCalendarAvailableOptions onAdd={onAddToCalendar} />}
        >
          {actionIcon || <CalendarOutlined style={{ fontSize: "14.29px", cursor: "pointer" }} />}
        </TooltipContent>
      </Switcher.Case>
    </Switcher>
  );
}

export default ActivityAction;
