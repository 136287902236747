import { Box, SxProps } from "@mui/material";

import { formatDateDifference, formatRenews, timeFromNow } from "utils/dates";

export enum SupportedFormatTypes {
  Symbol = "symbol",
  Word = "word",
}

interface IDateCellProps {
  value: string;
  onClick?: () => void;
  format: SupportedFormatTypes;
  sx?: SxProps;
}

// @todo: 1- this should be abstracted into a declarative function.
// @todo: we might want to take a different approach by allowing callback functions for more flexibility.
const formatFunctions = {
  word: (value: string) => {
    const dateInfo = timeFromNow(value);
    const renderValue = dateInfo.date
      ? formatDateDifference(dateInfo.date, dateInfo.difference)
      : value;
    return renderValue;
  },
  symbol: (value: string) => {
    const dateInfo = timeFromNow(value);
    const renderValue = dateInfo.date ? formatRenews(dateInfo.date, dateInfo.difference) : value;
    return renderValue;
  },
};

export function DateCell({
  onClick,
  value,
  format = SupportedFormatTypes.Word,
  sx,
}: IDateCellProps) {
  const humanReadableDate = formatFunctions[format](value);

  return (
    <Box
      onClick={onClick}
      sx={{
        display: "flex",
        justifyContent: "center",
        fontSize: "14px",
        cursor: humanReadableDate ? "pointer" : "default",
        ...sx,
      }}
    >
      {humanReadableDate || "--"}
    </Box>
  );
}
