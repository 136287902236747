import { UserOutlined } from "@ant-design/icons";
import { ArrowDropDown, ArrowRight, SupervisorAccount } from "@mui/icons-material";
import {
  Box, Checkbox, ListItemText, MenuItem,
} from "@mui/material";
import { Stack } from "@mui/system";
import {
  ChangeEvent, useEffect, useMemo, useState,
} from "react";

import Highlighter from "react-highlight-words";

import type { Employee } from "types/api";
import { isSubOrEqualString } from "utils/comparison";

import { getCheckStatuses } from "utils/employeeHierarchy";

export type IExtendedEmployee = Employee & { checked: boolean; subordinates: IExtendedEmployee[] };
export interface IRecursiveEmployeeTraverseProps {
  employee: IExtendedEmployee;
  user?: User;
  parentId?: string;
  parentChecked?: boolean;
  parentIndeterminate?: boolean;
  onSelectEmployee: (employee: IExtendedEmployee, checked?: boolean) => void;
  filterKey?: string;
}

const employeeCheckIfFiltered = (employee: IExtendedEmployee, filterKey?: string): boolean => {
  if (!filterKey) return true;
  const hasMatchOrHasSubordinateMatch: boolean = isSubOrEqualString(employee.name, filterKey)
    || employee.subordinates.some((subEmpolyee: IExtendedEmployee) => employeeCheckIfFiltered(subEmpolyee, filterKey));

  return hasMatchOrHasSubordinateMatch;
};

export function HierarchyEmployeeList({
  employee,
  onSelectEmployee,
  filterKey,
}: IRecursiveEmployeeTraverseProps) {
  const [isOpen, setIsOpen] = useState(false);
  const hasSubordinates = Boolean(employee.subordinates.length);
  const isFiltered = employeeCheckIfFiltered(employee, filterKey);

  useEffect(() => {
    if (!(filterKey && isFiltered)) return;
    setIsOpen(true);
    // reset when updates to preserve the last updated status
    return () => setIsOpen(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFiltered]);

  const { isIndeterminate, fullCheck } = useMemo(
    () => getCheckStatuses(employee),
    [JSON.stringify(employee)],
  );

  // temp disabled: it should support the nesting subordinates
  if (!employee || !isFiltered) return null;

  const { subordinates } = employee;

  const onCheckboxChange = (e: ChangeEvent) => {
    e.stopPropagation();
    const checked = fullCheck;
    onSelectEmployee({ ...employee, checked });
  };

  const onToggleOpen = () => setIsOpen(!isOpen);

  const isManager = Boolean(subordinates.length);

  return (
    <Stack direction="column">
      {/* @todo: better styling here. Replace negative margin with an actual fix. */}
      <MenuItem
        sx={{
          padding: `${hasSubordinates ? "0" : "0px 0 0px 18px"}`,
          background: "#fff !important",
          ml: "10px",
        }}
      >
        {hasSubordinates
          && (isOpen ? (
            <ArrowDropDown
              sx={{ cursor: "pointer", color: "#262626", fontSize: "22px" }}
              onClick={onToggleOpen}
            />
          ) : (
            <ArrowRight
              sx={{ cursor: "pointer", color: "#262626", fontSize: "22px" }}
              onClick={onToggleOpen}
            />
          ))}
        <Checkbox
          sx={{ pl: 0 }}
          indeterminate={!fullCheck && isIndeterminate}
          onChange={onCheckboxChange}
          checked={fullCheck}
        />
        {isManager ? (
          <SupervisorAccount
            style={{ color: "#262626", fontSize: "17px" }}
            onClick={onToggleOpen}
          />
        ) : (
          <UserOutlined style={{ color: "#262626", fontSize: "12px" }} />
        )}

        <ListItemText
          primary={(
            <Highlighter
              highlightClassName="search-match-text-highlight truncate"
              searchWords={[filterKey || ""]}
              autoEscape
              caseSensitive={false}
              className="truncate"
              textToHighlight={employee.name || ""}
            />
          )}
          sx={{
            maxWidth: "100%", pl: "5px", fontSize: "14px", width: "500px",
          }}
          onClick={onToggleOpen}
        />
      </MenuItem>
      {isOpen && (
        <Stack
          gap={0}
          direction="column"
          sx={{ borderLeft: "1px solid #F0F0F0", ml: "18px", pl: "5px" }}
        >
          {subordinates.map((subordinate: Employee) => (
            <Box key={subordinate.id}>
              <HierarchyEmployeeList
                filterKey={filterKey}
                onSelectEmployee={onSelectEmployee}
                employee={subordinate as IExtendedEmployee}
              />
            </Box>
          ))}
        </Stack>
      )}
    </Stack>
  );
}
export default HierarchyEmployeeList;
