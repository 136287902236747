import { robotImageIcon } from "assets/icons";
import { FeatureGroupSet } from "./userPermissionsMap";


export enum WorkerNameEnum {
  PERSON_FINDER = "personfinder",
  SIMILAR_ACCOUNTS = "similaraccounts",
  POWER_RANK = "powerrank",
  ACCOUNT_RESEARCHER = "accountresearcher",
  EMAIL_WRITER = "emailwriter",
  SIMILAR_WINS = "similarwins",
  CONTACT_RECOMMENDER = "personalrecommender",
  SEC_WORKER = "secworker",
};

export const botsListSet = [
  {
    title: "People Finder",
    key: WorkerNameEnum.PERSON_FINDER,
    description: "Identify key decision-makers, influencers, and stakeholders.",
    image: robotImageIcon,
    enabled: true,
    accessGroupName: FeatureGroupSet.PERSON_FINDER_WORKER,
  },
  {
    title: "Similar Accounts",
    key: WorkerNameEnum.SIMILAR_ACCOUNTS,
    description: "Search for similar wins by account name(s), add the accounts you want to search for.",
    image: robotImageIcon,
    enabled: true,
    accessGroupName: FeatureGroupSet.SIMILAR_ACCOUNTS_WORKER,
  },
  {
    title: "Power Rank",
    key: WorkerNameEnum.POWER_RANK,
    description: "Rank accounts based on their likelihood to convert.",
    image: robotImageIcon,
    enabled: true,
    accessGroupName: FeatureGroupSet.GENERAL_ACCESS,
  },
  {
    title: "Similar Wins",
    key: WorkerNameEnum.SIMILAR_WINS,
    description: "Type in a win, I'll find you accounts in your territory that look like it",
    image: robotImageIcon,
    enabled: true,
    accessGroupName: FeatureGroupSet.SIMILAR_WINS_WORKER,
  },
  {
    title: "SEC Filings Researcher",
    key: WorkerNameEnum.SEC_WORKER,
    description: "Identify accounts Priorities and Risks.",
    image: robotImageIcon,
    enabled: true,
    accessGroupName: FeatureGroupSet.GENERAL_ACCESS,
  },
  {
    title: "Account Researcher",
    key: WorkerNameEnum.ACCOUNT_RESEARCHER,
    description: "Conduct extensive research of an account to prepare for engagement.",
    image: robotImageIcon,
    enabled: false,
    accessGroupName: FeatureGroupSet.GENERAL_ACCESS,
  }, {
    title: "Email Writer",
    key: WorkerNameEnum.EMAIL_WRITER,
    description: "Generate email drafts for targeted outreach.",
    image: robotImageIcon,
    enabled: false,
    accessGroupName: FeatureGroupSet.GENERAL_ACCESS,
  },
  {
    title: "Contact Recommender",
    key: WorkerNameEnum.CONTACT_RECOMMENDER,
    description: "Recommend contacts to engage with based on previous interactions.",
    image: robotImageIcon,
    enabled: false,
    accessGroupName: FeatureGroupSet.GENERAL_ACCESS,
  },
];
