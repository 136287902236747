import { Box, Stack, SxProps } from "@mui/material";
import React, { useCallback } from "react";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { useNavigate } from "react-router-dom";

import useUserSettings from "services/shooks/settings";
import useLastSignalCount from "services/shooks/useNewActivitiesSignals";
import { ActivityTypes, IActivity } from "types/api/activities";

import { ActivityAbstractContentFactory } from "./ActivityAbstractContentFactory";
import { ActivityCard } from "./ActivityCard";
import { ActivityCardSkelton } from "./ActivityCardSkelton";
import { IntentScoreExtraContent } from "./ActivityContent";
import { EmailExtraContent } from "./ActivityContent/EmailContent";
import { MeetingExtraContent } from "./ActivityContent/MeetingContent";
import { ActivityContentInteractions, ScrollableBox } from "./common";
import DebuggingActivityCardBox from "./DebugMetricsActivity";
import {
  ActivityCardSize,
  formatActivitySubHeadline,
  isNewActivity,
  supportedActivitiesFilter,
} from "./utils";

const skeletonCount = 5;
interface IActivitiesResultsProps {
  activities: IActivity[];
  onActivityFocus?: (activity: IActivity) => void;
  isLoading?: boolean;
  onFilterByAccount?: (accountId: string, name?: string) => void;
  onFilterByPerson?: (personName: string, personId?: string) => void;
  onLoadMore?: () => void;
  isLoadingMore?: boolean;
  hasMoreResults?: boolean;
  showMetricsCard?: boolean;
  isFiltersShown?: boolean;
  checkIsTargetAccount?: (accountId: string) => boolean;
  listSx?: SxProps;
  cardSize?: ActivityCardSize;
  listContainerHeight: number;
}

const renderExtraContent = (activity: IActivity) => {
  switch (activity.action) {
    case ActivityTypes.Email:
      return <EmailExtraContent activity={activity} />;
    // @note: disabled temporarily
    // case ActivityTypes.Event:
    //   return <EventExtraContent activity={activity} />
    case ActivityTypes.IntentScore:
      return <IntentScoreExtraContent activity={activity} />;
    case ActivityTypes.Meeting:
      return <MeetingExtraContent activity={activity} />;
    default:
      return null;
  }
};

export function ActivitiesResults(props: IActivitiesResultsProps) {
  const {
    activities, onActivityFocus, isLoading, showMetricsCard, listContainerHeight,
  } = props;
  const { listSx, checkIsTargetAccount, cardSize = ActivityCardSize.LARGE } = props;
  const lastSeenDate = useLastSignalCount(({ state }) => state.lastSeenDate);
  const { actions: userSettingsActions, state: useSettingState } = useUserSettings();
  const navigator = useNavigate();
  const checkIfActivityIsNew = useCallback(
    (activity: IActivity) => isNewActivity(activity, lastSeenDate!),
    [lastSeenDate],
  );

  const onDismissActivities = (activity: IActivity) => async () => {
    const { id: dismissedActivityId } = activity;
    const currentDismissedActivities = useSettingState?.activities?.dismissedActivities || [];
    await userSettingsActions.updateDismissedActivities({
      dismissedActivities: [...currentDismissedActivities, dismissedActivityId],
    });
  };

  const [loadMoreTriggerRef] = useInfiniteScroll({
    loading: Boolean(props.isLoadingMore),
    hasNextPage: Boolean(props.hasMoreResults),
    onLoadMore: () => props.onLoadMore?.(),
    disabled: Boolean(isLoading),
  });

  if (isLoading && !props.isLoadingMore) {
    return (
      <ScrollableBox
        show
        sx={{
          m: 0, p: 0, pr: "5px", maxHeight: listContainerHeight,
        }}
      >
        <Stack direction="column" gap="8px" sx={{ p: "10px 10px 10px 20px", ...listSx }}>
          {Array.from({ length: skeletonCount }).map((_, index) => (
            <ActivityCardSkelton key={index} />
          ))}
        </Stack>
      </ScrollableBox>
    );
  }

  const hasResults = Boolean(activities?.length);

  const onActivityContentActionClick = (
    action: ActivityContentInteractions,
    value?: string,
    name?: string,
  ) => {
    if (!value) return;
    switch (action) {
      case ActivityContentInteractions.link:
        return navigator(value);
      case ActivityContentInteractions.FilterByAccount:
        return props.onFilterByAccount?.(value, name);
      case ActivityContentInteractions.FilterByPerson:
        return props.onFilterByPerson?.(value, name);
      default:
    }
  };

  const onAccountTitleClick = (accountId: string, name?: string) => () => {
    props.onFilterByAccount?.(accountId, name);
  };

  return (
    <Box flex={1}>
      <ScrollableBox show height={listContainerHeight || "64vh"} showOnHover>
        <Stack direction="column" gap="10px" sx={{ p: "10px 10px 10px 20px", ...listSx }}>
          {showMetricsCard && <DebuggingActivityCardBox activities={activities} />}
          {hasResults ? (
            activities.filter(supportedActivitiesFilter).map((activity, index) => (
              <ActivityCard
                key={`${index}_${activity.id}`}
                type={activity.action}
                title={activity?.secondParty?.accountName}
                createdAt={activity.dateOfActivity}
                isNew={checkIfActivityIsNew(activity)}
                onDismiss={onDismissActivities(activity)}
                expandableInfoRender={renderExtraContent(activity)}
                onClick={() => onActivityFocus?.(activity)}
                activitySubHeadline={formatActivitySubHeadline(activity)}
                source={activity?.source}
                isTargetAccount={checkIsTargetAccount?.(activity?.secondParty?.accountId)}
                onCardTitleClick={onAccountTitleClick(
                  activity?.secondParty?.accountId,
                  activity?.secondParty?.accountName,
                )}
                accountContactEmail={activity?.secondParty?.email || activity?.sender?.address}
                cardSize={cardSize}
                activity={activity}
              >
                <ActivityAbstractContentFactory
                  {...activity}
                  onContentActionClick={onActivityContentActionClick}
                  cardSize={cardSize}
                />
              </ActivityCard>
            ))
          ) : (
            <Box sx={{ display: "flex", justifyContent: "center" }}>No Activities found</Box>
          )}
          {props.hasMoreResults && <ActivityCardSkelton ref={loadMoreTriggerRef} />}
        </Stack>
      </ScrollableBox>
    </Box>
  );
}
