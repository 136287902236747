import { SxProps, Table, TableContainer } from "@mui/material";
import { Ref, forwardRef, useMemo } from "react";
import {
  Column,
  TableState,
  useColumnOrder,
  useGlobalFilter,
  useSortBy,
  useTable,
} from "react-table";

import { DynFilterColumn } from "types/api";

import { TableBody, TableHead } from "./common";

interface DefaultTableProps {
  data: TableState[];
  columns: Column<any>[];
  loading?: boolean;
  initialState?: Partial<TableState<object>>;
  stickyHeader?: boolean;
  updatePaginationProps?: any;
  showFiltersRow?: boolean;
  onRowClick?: (row: any) => void;
  sx?: SxProps;
  cellSX?: SxProps;
  headerBackground?: string;
  customPlugins?: any[];
  noDataMessage?: string;
  memoDeps?: any;
  tbodySX?: SxProps;
  loadingSkeletonOptions?: {
    rowsCount?: number;
    height?: number | string;
  };
  dynColsDetails?: DynFilterColumn;
}

const DefaultTable = forwardRef((props: DefaultTableProps, ref: Ref<HTMLDivElement>) => {
  const {
    data, columns, loading, initialState, sx, noDataMessage, onRowClick, customPlugins, dynColsDetails,
  } = props;
  const {
    stickyHeader = true,
    loadingSkeletonOptions,
    showFiltersRow,
    headerBackground,
    tbodySX,
    cellSX,
  } = props;

  const tableOptions = { columns, data, initialState };
  const tablePlugins = [useColumnOrder, useGlobalFilter, ...(customPlugins || []), useSortBy];
  const {
    prepareRow, rows, getTableProps, getTableBodyProps, headerGroups,
  } = useTable(
    tableOptions,
    ...tablePlugins,
  );
  const tableBodyProps = getTableBodyProps();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const tableProps = useMemo(() => getTableProps(), []);

  return (
    <TableContainer sx={sx} ref={ref}>
      <Table {...tableProps} stickyHeader={stickyHeader}>
        <TableHead
          headerGroups={headerGroups}
          showFiltersRow={showFiltersRow}
          backgroundColor={headerBackground}
          cellSX={cellSX}
          dynColsDetails={dynColsDetails}
        />
        <TableBody
          rows={rows}
          prepareRow={prepareRow}
          tableBodyProps={tableBodyProps}
          loading={Boolean(loading)}
          onRowClick={onRowClick}
          headerGroups={headerGroups}
          showFiltersRow={showFiltersRow}
          noDataMessage={noDataMessage}
          loadingSkeletonOptions={loadingSkeletonOptions}
          sx={tbodySX}
          cellSX={cellSX}
        // memoDeps={memoDeps} // @todo: plan the memo dependencies more carefully and give it a proper type.
        />
      </Table>
    </TableContainer>
  );
});

export default DefaultTable;
