import { Warning } from "@mui/icons-material";
import {
  Box, Button, Stack, Typography,
} from "@mui/material";

import React from "react";

import useToggle from "hooks/useToggle";

interface IDismissActivityDialogProps {
  onCancel: () => void;
  onDismiss: () => Promise<void>;
  show: boolean;
}
export function DismissActivityDialog(props: IDismissActivityDialogProps) {
  const { onCancel, onDismiss, show } = props;
  const [isLoading, , onToggleAsyncLoader] = useToggle(false);
  if (!show) return null;

  const _onDismiss = async () => {
    await onToggleAsyncLoader(onDismiss());
  };
  return (
    <Box
      sx={{
        position: "absolute",
        top: "0",
        left: "0",
        right: "0",
        bottom: "0",
        padding: "4px",
      }}
    >
      <Box
        sx={{
          background: "#4d4d4de6",
          height: "100%",
          width: "100%",
          padding: "6px 12px",
          borderRadius: "4px",
          display: "flex",
        }}
      >
        <Stack flex={1} direction="column" gap="12px">
          <Stack flex={1} direction="column">
            <Warning sx={{ color: "#FFA900", fontSize: "28px" }} />
            <Typography fontSize="12px" fontWeight="600" color="#fff">
              Are you sure you want to dismiss this signal?
            </Typography>
          </Stack>
          <Stack gap="4px" direction="row" justifyContent="flex-end">
            <Button
              onClick={onCancel}
              size="small"
              disabled={isLoading}
              sx={{
                color: "#262626",
                bgcolor: "#fff",
                fontSize: "10px",
                "&:hover": {
                  opacity: 0.8,
                  bgcolor: "#fff",
                  color: "#262626",
                },
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={_onDismiss}
              size="small"
              disabled={isLoading}
              sx={{
                bgcolor: "#DB393A",
                color: "#fff",
                fontSize: "10px",
                "&:hover": {
                  opacity: 0.8,
                  bgcolor: "#DB393A",
                  color: "#fff",
                },
              }}
            >
              {isLoading ? "...processing" : "Dismiss Signal"}
            </Button>
          </Stack>
        </Stack>
      </Box>
    </Box>
  );
}
