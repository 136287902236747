// material-ui
import { CloseOutlined } from "@ant-design/icons";
import { Slider, Stack, TextField } from "@mui/material";

import IconButton from "@mui/material/IconButton";

import { useEffect, useState } from "react";

import Tooltip from "components/Tooltip";
// project import
// assets

import useDebounceEffect from "hooks/useDebounceEffect";
import { userStorage } from "utils/auth";

// import IconButton from 'components/@extended/IconButton';

export function FilterByDays({ column }: any) {
  const { filterValue, setFilter } = column || {};
  const min = 1;
  const max = 40;
  const marks = [
    {
      value: 1,
    },
    {
      value: 7,
    },
    {
      value: 30,
    },
    {
      value: 40,
    },
  ];

  return (
    <Tooltip sx={{ margin: 0 }} title="Feature will be released soon">
      <Stack direction="row" alignItems="center" spacing={1} sx={{ pl: 1, minWidth: 120 }}>
        <Slider
          disabled
          sx={{ paddingBottom: "10px", margin: 0 }}
          value={filterValue || min}
          min={min}
          max={max}
          step={null}
          marks={marks}
          onChange={(event: Event, newValue: number | number[]) => {
            setFilter(newValue);
          }}
          // valueLabelDisplay="auto"
          aria-labelledby="non-linear-slider"
        />
        <IconButton size="small" color="error" onClick={() => setFilter(undefined)}>
          <CloseOutlined />
        </IconButton>
      </Stack>
    </Tooltip>
  );
}

export function FilterByMonths({ column }: any) {
  const { filterValue, setFilter } = column;
  const min = 1;
  const max = 12;
  const marks = [
    {
      value: 1,
    },
    {
      value: 3,
    },
    {
      value: 6,
    },
    {
      value: 12,
    },
  ];

  return (
    <Tooltip sx={{ margin: 0 }} title="Feature will be released soon">
      <Stack direction="row" alignItems="center" spacing={1} sx={{ pl: 1, minWidth: 120 }}>
        <Slider
          disabled
          sx={{ paddingBottom: "10px", margin: 0 }}
          value={filterValue || min}
          min={min}
          max={max}
          step={null}
          marks={marks}
          onChange={(event: Event, newValue: number | number[]) => {
            setFilter(newValue);
          }}
          // valueLabelDisplay="auto"
          aria-labelledby="non-linear-slider"
        />
        <IconButton size="small" color="error" onClick={() => setFilter(undefined)}>
          <CloseOutlined />
        </IconButton>
      </Stack>
    </Tooltip>
  );
}

// todo: rework to filter exclusively with date values before the formatting.
export function filterByDays(rows: any, id: any, filterValue: any) {
  return rows.filter((row: any) => {
    const rowValue = row.values[id];

    // above 30 days just return all the rows
    if (filterValue > 30) return true;

    // transform to number of days to match the break points
    const timeInDays = (date: Date) => (new Date().getTime() - new Date(date).getTime()) / (1000 * 60 * 60 * 24);

    return timeInDays(rowValue) <= filterValue;
  });
}

filterByDays.autoRemove = (val: any) => typeof val !== "number";

export function filterByMonth(rows: any, id: any, filterValue: any) {
  return rows.filter((row: any) => {
    const rowValue = row.values[id];
    // transform to number of days to match the break points
    const timeInMonths = (date: Date) => (new Date(date).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24 * 30);

    return timeInMonths(rowValue) <= filterValue;
  });
}

filterByMonth.autoRemove = (val: any) => typeof val !== "number";

export function TextArea({ column, onBLur }: any) {
  const { filterValue, setFilter } = column;
  const [value, setValue] = useState(filterValue || "");

  useDebounceEffect(
    () => {
      setFilter(value);
    },
    50,
    [value],
  );

  useEffect(() => {
    setValue(filterValue || "");
  }, [filterValue]);

  const onChange = (e: any) => {
    setValue(e.target.value);
  };

  return (
    <TextField
      onFocus={onBLur}
      id="outlined-basic"
      size="small"
      variant="outlined"
      sx={{ width: "100%", padding: "0" }}
      value={value}
      onChange={onChange}
      placeholder={column.placeholder || column.Footer}
    />
  );
}

// todo: rework to work as fuzzy search.
export const textIncludes = (rows: any, id: any, filterValue: any) => rows.filter((row: any) => {
  const rowValue = row.values[id];
  return rowValue !== undefined
    ? String(rowValue).toLowerCase().includes(filterValue.toLowerCase())
    : false;
});

export const filterLessThan = (rows: any, id: any, filterValue: any) => {
  const walletShareFilter = userStorage.get("walletShareFilter") || "uncaptured";
  return rows.filter((row: any) => {
    const rowValue = row.values[id][walletShareFilter];
    return rowValue <= filterValue;
  });
};

export function SliderFilter({ column, title = "" }: any) {
  const { filterValue, setFilter } = column || {};
  const min = 0;
  const max = 40;

  return (
    <Tooltip sx={{ margin: 0 }} title="Feature will be released soon">
      <Stack direction="row" alignItems="center" spacing={1} sx={{ pl: 1, minWidth: 120 }}>
        <Slider
          disabled
          sx={{ paddingBottom: "10px", margin: 0 }}
          value={filterValue || min}
          min={min}
          max={max}
          onChange={(event: Event, newValue: number | number[]) => {
            setFilter(newValue);
          }}
          // valueLabelDisplay="auto"
          aria-labelledby="non-linear-slider"
        />
        <IconButton size="small" color="error" onClick={() => setFilter(undefined)}>
          <CloseOutlined />
        </IconButton>
      </Stack>
    </Tooltip>
  );
}

export const filterNumbersLessThan = (rows: any, id: any, filterValue: any) => rows.filter((row: any) => {
  const rowValue = row.values[id];
  return rowValue <= filterValue;
});
