import { Typography } from "@mui/material";

import { Switcher } from "components/common";
import { ActivityTypes, IActivity } from "types/api/activities";

import {
  CallContent,
  ContentViewed,
  EmailContent,
  EventContent,
  IntentScoreContent,
  LeadContent,
  MeetingContent,
  MilestoneContent,
  ThiredPartyDownloadContent,
  WebContent,
  WinWireContent,
} from "./ActivityContent";
import { ActivityContentInteractions, ActivityContentRenderer } from "./common";
import {
  ActivityCardSize,
  supportedActivitiesFilter,
} from "./utils";

export interface IActivityAbstractContentFactoryProps extends IActivity {
  onContentActionClick?: (
    action: ActivityContentInteractions,
    value?: string,
    name?: string
  ) => void;
  cardSize?: ActivityCardSize;
}
export function ActivityAbstractContentFactory(props: IActivityAbstractContentFactoryProps) {
  const isActivityTypeSupported = supportedActivitiesFilter(props);
  if (!isActivityTypeSupported) return null;

  const commonProps = {
    activity: props,
    onActionTrigger: props.onContentActionClick,
    cardSize: props.cardSize ?? ActivityCardSize.LARGE,
  };
  return (
    <Typography
      variant="body2"
      component="div"
      sx={{ color: "#262626", fontSize: "12px", fontWeight: "500" }}
    >
      <Switcher
        active={props.action}
        fallback={
          <ActivityContentRenderer activity={props} onActionTrigger={props.onContentActionClick} />
        }
      >
        <Switcher.Case id={ActivityTypes.Email}>
          <EmailContent {...commonProps} />
        </Switcher.Case>

        <Switcher.Case id={ActivityTypes.MarketingEmail}>
          <EmailContent {...commonProps} />
        </Switcher.Case>

        <Switcher.Case id={ActivityTypes.Meeting}>
          <MeetingContent {...commonProps} />
        </Switcher.Case>
        <Switcher.Case id={ActivityTypes.Lead}>
          <LeadContent {...commonProps} />
        </Switcher.Case>
        <Switcher.Case id={ActivityTypes.WinWire}>
          <WinWireContent {...commonProps} />
        </Switcher.Case>
        <Switcher.Case id={ActivityTypes.Event}>
          <EventContent {...commonProps} />
        </Switcher.Case>
        <Switcher.Case id={ActivityTypes.IntentScore}>
          <IntentScoreContent {...commonProps} />
        </Switcher.Case>
        <Switcher.Case id={ActivityTypes.Call}>
          <CallContent {...commonProps} />
        </Switcher.Case>
        <Switcher.Case id={ActivityTypes.Web}>
          <WebContent {...commonProps} />
        </Switcher.Case>
        <Switcher.Case id={ActivityTypes.ThirdPartyDownload}>
          <ThiredPartyDownloadContent {...commonProps} />
        </Switcher.Case>
        <Switcher.Case id={ActivityTypes.Milestone}>
          <MilestoneContent {...commonProps} />
        </Switcher.Case>

        <Switcher.Case id={ActivityTypes.ContentViewed}>
          <ContentViewed {...commonProps} />
        </Switcher.Case>
      </Switcher>
    </Typography>
  );
}
