import { styled } from "@mui/material";
// useless file, remove and use the antd icons library instead.

type SvgProps = {
  width: string;
  height: string;
};

const StyledSvg = styled("svg")<SvgProps>(({ width }) => ({
  fill: "none",
  width: width || "20px",
  margin: "auto",
}));

const StyledPath = styled("path")<{ fill: string }>(({ fill }) => ({
  fill,
}));

export function MiniLogo() {
  const handleLogoClick = () => {};
  return (
    <StyledSvg
      onClick={handleLogoClick}
      width="38px"
      height="38px"
      viewBox="0 0 38 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <StyledPath
        d="M28.5502 35.658C29.6258 35.0823 30.5691 34.293 31.3262 33.3476V23.1672H28.5502V35.658Z"
        fill="#54595F"
      />
      <StyledPath
        d="M17.2244 30.4379V33.2297C17.9754 34.1996 18.9193 35.0121 20.0005 35.6079V30.4379H17.2244Z"
        fill="#EB7A6F"
      />
      <StyledPath
        d="M24.775 25.3821V36.7046C25.7495 36.6564 26.6826 36.4502 27.5511 36.1147V25.3821H24.775Z"
        fill="#62BFB7"
      />
      <StyledPath
        d="M20.9996 28.0992V36.0783C21.8671 36.4243 22.7999 36.6395 23.7759 36.6979V28.0992H20.9996Z"
        fill="#FDC414"
      />
      <StyledPath
        d="M24.9311 37.4703C26.5601 37.4703 27.9857 37.0678 29.2079 36.2624C30.4297 35.457 31.3783 34.3699 32.0541 32.9991C32.73 31.6294 33.0676 30.1114 33.0676 28.4453C33.0676 26.7606 32.725 25.2333 32.0402 23.8627C31.3551 22.4931 30.4062 21.4054 29.194 20.5997C27.9811 19.7947 26.5601 19.3918 24.9311 19.3918C23.3203 19.3918 21.853 19.7993 20.5297 20.614C19.2055 21.4289 18.1503 22.5209 17.3635 23.8906C16.577 25.2608 16.1834 26.7788 16.1834 28.4453C16.1834 30.13 16.5862 31.6526 17.3913 33.0133C18.197 34.374 19.2612 35.457 20.5851 36.2624C21.9086 37.0678 23.3571 37.4703 24.9311 37.4703ZM33.5678 0.674545H39.3438V42.3302H33.5678V38.2479C32.6604 39.6923 31.4753 40.8539 30.013 41.7329C28.5503 42.6122 26.8378 43.0524 24.8754 43.0524C22.8575 43.0524 20.9691 42.6724 19.2105 41.9134C17.4513 41.1547 15.9011 40.1042 14.5587 38.7617C13.2163 37.4199 12.166 35.8691 11.407 34.1102C10.6477 32.3514 10.2683 30.4632 10.2683 28.4453C10.2683 26.4273 10.6477 24.534 11.407 22.7658C12.166 20.998 13.2163 19.4431 14.5587 18.1007C15.9011 16.7583 17.4513 15.7083 19.2105 14.9484C20.9691 14.1897 22.8575 13.8103 24.8754 13.8103C26.8378 13.8103 28.5503 14.25 30.013 15.1289C31.4753 16.0088 32.6604 17.1614 33.5678 18.5867V0.674545Z"
        fill="#4092D0"
      />
      <StyledPath
        d="M11.0362 43.1337C9.57349 43.041 8.1479 42.7454 6.75941 42.2452C5.37092 41.7458 4.1534 41.065 3.10747 40.2043C2.06155 39.3433 1.27903 38.3298 0.761169 37.1633L5.67638 35.0526C5.91661 35.5525 6.33832 36.0432 6.93997 36.5246C7.54131 37.0065 8.24962 37.3995 9.06429 37.7047C9.87895 38.0104 10.7307 38.1632 11.6193 38.1632C12.4893 38.1632 13.299 38.0383 14.0491 37.7884C14.7991 37.538 15.4141 37.1543 15.8957 36.6359C16.3768 36.1177 16.618 35.4972 16.618 34.7749C16.618 33.9606 16.3447 33.3268 15.7987 32.8729C15.2527 32.4194 14.5722 32.0725 13.7578 31.8316C12.9425 31.5914 12.1282 31.3595 11.3135 31.1372C9.49898 30.7301 7.83749 30.1701 6.32874 29.4569C4.81998 28.7449 3.62132 27.8282 2.73307 26.7077C1.8442 25.5879 1.39961 24.2133 1.39961 22.584C1.39961 20.8066 1.87604 19.2653 2.82984 17.9603C3.78332 16.655 5.04691 15.6511 6.62059 14.9471C8.19397 14.2438 9.86999 13.8916 11.6471 13.8916C13.8685 13.8916 15.905 14.364 17.7563 15.3079C19.6077 16.2522 21.024 17.5482 22.0053 19.1961L17.4231 21.9174C17.1457 21.3618 16.729 20.8622 16.1737 20.4176C15.6181 19.9737 14.9883 19.6175 14.2853 19.3488C13.5813 19.0805 12.8501 18.928 12.0914 18.8906C11.1469 18.8535 10.2722 18.9599 9.46714 19.2097C8.66175 19.4601 8.01805 19.8537 7.53698 20.3901C7.0556 20.9271 6.81506 21.6123 6.81506 22.4449C6.81506 23.2598 7.10167 23.8661 7.67611 24.2637C8.24962 24.6626 8.97648 24.9816 9.85608 25.2222C10.7347 25.463 11.6375 25.7317 12.5635 26.0276C14.2111 26.546 15.7572 27.1798 17.2011 27.9299C18.6449 28.6793 19.8114 29.601 20.7003 30.693C21.5888 31.7853 22.0146 33.1184 21.9775 34.6918C21.9775 36.4321 21.454 37.9594 20.4084 39.274C19.3625 40.5886 18.0065 41.5882 16.3403 42.273C14.6739 42.9581 12.9061 43.2447 11.0362 43.1337Z"
        fill="#fff"
      />
    </StyledSvg>
  );
}

export function CalendarIcon() {
  return (
    <StyledSvg
      width="20px"
      height="20px"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <StyledPath
        d="M18.2146 2.68128H14.4646V1.25271C14.4646 1.1545 14.3843 1.07414 14.2861 1.07414H13.0361C12.9379 1.07414 12.8575 1.1545 12.8575 1.25271V2.68128H7.14321V1.25271C7.14321 1.1545 7.06285 1.07414 6.96464 1.07414H5.71463C5.61642 1.07414 5.53606 1.1545 5.53606 1.25271V2.68128H1.78606C1.39097 2.68128 1.07178 3.00048 1.07178 3.39557V18.217C1.07178 18.6121 1.39097 18.9313 1.78606 18.9313H18.2146C18.6097 18.9313 18.9289 18.6121 18.9289 18.217V3.39557C18.9289 3.00048 18.6097 2.68128 18.2146 2.68128ZM17.3218 17.3241H2.67892V8.842H17.3218V17.3241ZM2.67892 7.32414V4.28843H5.53606V5.35986C5.53606 5.45807 5.61642 5.53843 5.71463 5.53843H6.96464C7.06285 5.53843 7.14321 5.45807 7.14321 5.35986V4.28843H12.8575V5.35986C12.8575 5.45807 12.9379 5.53843 13.0361 5.53843H14.2861C14.3843 5.53843 14.4646 5.45807 14.4646 5.35986V4.28843H17.3218V7.32414H2.67892Z"
        fill="currentColor"
      />
    </StyledSvg>
  );
}

export function TerritoryIcon() {
  return (
    <StyledSvg
      width="20px"
      height="20px"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <StyledPath
        d="M6.20544 9.10579H13.8394C13.8483 9.10579 13.8595 9.10579 13.8684 9.10355C13.9666 9.08793 14.0313 8.99641 14.0157 8.8982L13.1184 3.36248C13.105 3.27543 13.0291 3.21293 12.9421 3.21293H7.10277C7.01571 3.21293 6.93982 3.27543 6.92643 3.36248L6.02911 8.8982C6.02687 8.90713 6.02687 8.91829 6.02687 8.92722C6.02687 9.02543 6.10723 9.10579 6.20544 9.10579ZM8.24116 4.73079H11.8014L12.2635 7.58793H7.77687L8.24116 4.73079ZM8.29696 11.0411C8.28357 10.954 8.20768 10.8915 8.12062 10.8915H2.28134C2.19428 10.8915 2.11839 10.954 2.105 11.0411L1.20768 16.5768C1.20544 16.5857 1.20544 16.5969 1.20544 16.6058C1.20544 16.704 1.2858 16.7844 1.38402 16.7844H9.01794C9.02687 16.7844 9.03803 16.7844 9.04696 16.7821C9.14518 16.7665 9.20991 16.675 9.19428 16.5768L8.29696 11.0411ZM2.95768 15.2665L3.41973 12.4094H6.98L7.44205 15.2665H2.95768ZM18.7925 16.5768L17.8952 11.0411C17.8818 10.954 17.8059 10.8915 17.7188 10.8915H11.8796C11.7925 10.8915 11.7166 10.954 11.7032 11.0411L10.8059 16.5768C10.8037 16.5857 10.8037 16.5969 10.8037 16.6058C10.8037 16.704 10.884 16.7844 10.9822 16.7844H18.6162C18.6251 16.7844 18.6362 16.7844 18.6452 16.7821C18.7412 16.7665 18.8081 16.675 18.7925 16.5768ZM12.5559 15.2665L13.0179 12.4094H16.5782L17.0403 15.2665H12.5559Z"
        fill="currentColor"
      />
    </StyledSvg>
  );
}

export function StatsIcon() {
  return (
    <StyledSvg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_330_19309)">
        <StyledPath
          d="M18.3925 16.2507H3.03537V2.3221C3.03537 2.22389 2.95501 2.14353 2.85679 2.14353H1.60679C1.50858 2.14353 1.42822 2.22389 1.42822 2.3221V17.6792C1.42822 17.7775 1.50858 17.8578 1.60679 17.8578H18.3925C18.4907 17.8578 18.5711 17.7775 18.5711 17.6792V16.4292C18.5711 16.331 18.4907 16.2507 18.3925 16.2507ZM5.39697 12.8065C5.46617 12.8757 5.57778 12.8757 5.64921 12.8065L8.73626 9.73505L11.5845 12.6011C11.6537 12.6703 11.7675 12.6703 11.8367 12.6011L17.984 6.45603C18.0532 6.38684 18.0532 6.273 17.984 6.2038L17.1001 5.31987C17.0665 5.28664 17.0212 5.268 16.974 5.268C16.9268 5.268 16.8814 5.28664 16.8479 5.31987L11.7139 10.4516L8.87019 7.58996C8.83663 7.55673 8.7913 7.53809 8.74407 7.53809C8.69684 7.53809 8.65152 7.55673 8.61796 7.58996L4.51528 11.6681C4.48204 11.7016 4.4634 11.747 4.4634 11.7942C4.4634 11.8414 4.48204 11.8868 4.51528 11.9203L5.39697 12.8065Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_330_19309">
          <rect width="20" height="20" fill="currentColor" />
        </clipPath>
      </defs>
    </StyledSvg>
  );
}

export function HelpIcon() {
  return (
    <StyledSvg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <StyledPath
        d="M16.0937 4.84236C15.7812 4.15933 15.337 3.54549 14.7701 3.02094C13.6183 1.95174 12.0893 1.36245 10.4687 1.36245C8.8482 1.36245 7.31918 1.95174 6.16739 3.0187C5.60043 3.54549 5.15623 4.1571 4.84373 4.84236C4.51784 5.55665 4.35266 6.31335 4.35266 7.0946V7.69727C4.35266 7.83567 4.46427 7.94728 4.60266 7.94728H5.80802C5.94641 7.94728 6.05802 7.83567 6.05802 7.69727V7.0946C6.05802 4.87361 8.0357 3.06781 10.4687 3.06781C12.9018 3.06781 14.8794 4.87361 14.8794 7.0946C14.8794 8.00531 14.5558 8.86245 13.9419 9.57674C13.3348 10.2843 12.4777 10.791 11.529 11.0053C10.9866 11.1281 10.4978 11.4339 10.1518 11.8714C9.80699 12.3074 9.61907 12.8468 9.61829 13.4026V14.1035C9.61829 14.2419 9.72989 14.3535 9.86829 14.3535H11.0736C11.212 14.3535 11.3236 14.2419 11.3236 14.1035V13.4026C11.3236 13.0522 11.5669 12.7442 11.904 12.6683C13.2076 12.3736 14.3906 11.6705 15.2366 10.6883C15.6629 10.1906 15.9955 9.63701 16.2254 9.03656C16.4643 8.41379 16.5848 7.75977 16.5848 7.0946C16.5848 6.31335 16.4196 5.55442 16.0937 4.84236ZM10.4687 16.1392C9.779 16.1392 9.21873 16.6995 9.21873 17.3892C9.21873 18.079 9.779 18.6392 10.4687 18.6392C11.1585 18.6392 11.7187 18.079 11.7187 17.3892C11.7187 16.6995 11.1585 16.1392 10.4687 16.1392Z"
        fill="currentColor"
      />
    </StyledSvg>
  );
}
