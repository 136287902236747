import { getSecCompaniesFilingList, SecCompaniesList } from "data/companiesSecFiling";
import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import aiWorkersService from "services/aiworkers";
import { AccountSimilarWins, CompanySecData } from "types/api";

export const useSecWorker = () => {
  const [companyDetails, setCompanyDetails] = useState<CompanySecData>()
  const [listOfCompanies, setListOfCompanies] = useState<SecCompaniesList>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const abortSignal = useRef(new AbortController());

  const getCompanyDetails = async (companyTicker: string) => {
    try {
      abortSignal.current.abort();
      setLoading(true);
      setErrorMessage(null);
      abortSignal.current = new AbortController();
      const { data: companySecDetails } = await aiWorkersService.getCompanySecFilings(companyTicker, { signal: abortSignal.current.signal });

      if (!companySecDetails) {
        return setErrorMessage("No results found");
      }
      setCompanyDetails(companySecDetails);
    } catch (e: any) {
      setErrorMessage(e.response?.data?.message || "An error occurred while fetching data");
    } finally {
      setLoading(false);
    }
  };

  const updateSecDataForCompany = async (companyTicker: string) => {
    if (!companyTicker) {
      return;
    }
    const { data: companySecDetails } = await aiWorkersService.getCompanySecFilings(companyTicker, { signal: abortSignal.current.signal });
    if (!companySecDetails) {
      return setErrorMessage("No results found");
    }
    setCompanyDetails(companySecDetails);
  }

  const resetErrorMessage = () => {
    setErrorMessage(null);
  };

  const onCancelRequest = () => {
    abortSignal.current.abort();
    setLoading(false);
    setErrorMessage(null);
  }

  const onResetResults = () => {
    setCompanyDetails(undefined);
    setErrorMessage(null);
  }

  useEffect(() => {
    getSecCompaniesFilingList().then((data) => {
      setListOfCompanies(data as SecCompaniesList);
    }).catch((e) => {
      toast.error(e.response?.data?.message || "An error occurred while fetching data");
    });
  }, []);

  return {
    onFind: getCompanyDetails,
    companyDetails,
    loading,
    listOfCompanies,
    errorMessage,
    resetErrorMessage,
    onCancelRequest,
    updateSecDataForCompany,
    onResetResults
  };
};
