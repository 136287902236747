import { HeadquarterAddress } from "types/api/territoryFilters";

export const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export const pluralize = (count: number, singular: string, plural: string) => {
  // edge cases like 2 months ago might be represented as -2 here.
  const positiveCount = Math.abs(count);
  return `${positiveCount} ${positiveCount > 1 ? plural : singular}`;
};

export const formatAddressFromHeadquarter = (headquarter: HeadquarterAddress) => {
  const { state, country, city } = headquarter;
  const addressParts = [city, state, country].filter(Boolean);
  return addressParts.join(", ");
};

export const transformPipelineSignal = (signal: string) => {
  let pipelineSignal = "";
  switch (signal?.toLowerCase?.()) {
    // cases: strong, medium, low. they should be lowercase
    case "high":
      pipelineSignal = "strong";
      break;
    case "mid":
      pipelineSignal = "medium";
      break;
    case "low":
      pipelineSignal = "low";
      break;
  }
  return pipelineSignal;
};
