const winRow = (data: any) => ({
  win: data,
});

export const makeSimilarWinsData = (selectedAccount: any) => {
  const tableData = selectedAccount?.similarWins?.map((win: any) => ({
    ...winRow(win),
    customer: win.accountName,
    sale: win.opportunityAmount,
    seller: win.sellerName,
    winDate: win.opportunityCloseDate,
    subRows: undefined,
  }));

  return tableData || [];
};

export const makeProductsData = (selectedAccount: any) => {
  if (!Array.isArray(selectedAccount?.products)) return [];
  const tableData = selectedAccount?.products.map((product: any) => ({
    id: selectedAccount.id,
    productId: product.productId,
    saleStatus: product.status || "Unknown Interest",
    productName: product.productName,
    subRows: undefined,
    atRiskOfLosing: product.atRiskOfLosing,
    competitors: product.competitors,
  }));

  return tableData;
};

export const getCompetitorsData = (selectedAccount: any, productName: string) => {
  const products = selectedAccount?.products;
  const selectedProduct = products?.find((product: any) => product.productName === productName);
  const productCompetitors = selectedProduct?.competitors;
  // add required fields for filtering
  if (Array.isArray(productCompetitors)) {
    return productCompetitors.map((competitor) => ({
      ...competitor,
      competitor: competitor.name,
      renews: competitor.renewalDate,
    }));
  }
  return [];
};

export const makePeopleData = (peopleData: any) => {
  const { items } = peopleData;
  return items || [];
};
