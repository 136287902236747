import { AimOutlined, DoubleLeftOutlined } from "@ant-design/icons";
import { Close } from "@mui/icons-material";
import {
  Box, Stack, Tooltip, Typography,
} from "@mui/material";

import React, { ReactNode, useMemo, useRef } from "react";

import { useIsOverflowing } from "hooks";

import useToggle from "hooks/useToggle";
import { ActivityTypes, IActivity } from "types/api/activities";
import { hasValidDate } from "utils/date";
import { getDomainFaviconFromEmail } from "utils/email";

import ActivityAction from "./ActivityActions";
import {
  EToolNames, IconButton, ScrollableBox, SourceLeadIcon,
} from "./common";
import { DismissActivityDialog } from "./DismissActivityDialog";
import {
  ActivityActionTypes,
  ActivityCardSize,
  activityTypesIcons,
  customTimeFormator,
  onGoToExternalLink,
} from "./utils";

interface IActivityCardHeaderProps {
  onShowInfo?: () => void;
  onDismiss?: () => void;
  hasMoreInfo?: boolean;
  isShowInfoActive?: boolean;
  title: string;
  titleIcon?: ReactNode;
  isNewActivity?: boolean;
  source: IActivity["source"];
  onTitleClick?: () => void;
  cardSize?: ActivityCardSize;
  accountFavicon?: string;
}

function ActivityCardHeader(props: IActivityCardHeaderProps) {
  const {
    hasMoreInfo, onTitleClick, cardSize, accountFavicon, titleIcon, onShowInfo,
  } = props;
  const {
    onDismiss,
    title,
    isShowInfoActive,
    isNewActivity,
  } = props;
  const source = (props.source || {}) as IActivity["source"]
  const sourceToolName = String(source?.tool).toLowerCase() as EToolNames;
  const isSalesforceSource = sourceToolName === ("saleforce" as EToolNames) || sourceToolName === EToolNames.Salesforce;
  const isLargeSize = cardSize === ActivityCardSize.LARGE;
  const headerTitleRef = useRef<HTMLDivElement>(null);
  const isOverflowing = useIsOverflowing(headerTitleRef);
  // @todo: refactor this, make it more predictable
  const renderTitleIcon = titleIcon || (accountFavicon ? (
    <img
      loading="lazy"
      src={accountFavicon}
      alt=""
      style={{ width: "16px", height: "16px", borderRadius: "50%" }}
    />
  ) : null);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        background: "#E5EFF5",
        padding: "0 12px",
        paddingLeft: hasMoreInfo ? "0" : "12px",
        minHeight: "28px",
        color: "#1A1A1A",
      }}
    >
      <div
        style={{
          flex: 1,
          display: "flex",
          alignItems: "center",
          gap: hasMoreInfo ? "6px" : 0,
          fontSize: "12px",
          lineHeight: "18px",
          fontWeight: 500,
        }}
        className="truncate"
      >
        {hasMoreInfo && (
          <IconButton
            onClick={onShowInfo}
            sx={{
              borderRight: "1px solid #C0D7E9",
              borderRadius: 0,
              "&:hover": {},
              padding: 0,
              height: "24px",
              width: "24px",
            }}
          >
            <DoubleLeftOutlined
              style={{
                fontSize: "12px",
                color: "#1A1A1A",
                rotate: isShowInfoActive ? "90deg" : "-90deg",
              }}
            />
          </IconButton>
        )}
        <Tooltip
          title={isOverflowing ? title : hasMoreInfo ? "Expand" : ""}
          placement="top"
          enterDelay={2000}
        >
          <Typography
            gap="5.1px"
            flex="1"
            display="flex"
            alignItems="center"
            fontSize={12}
            onClick={onTitleClick}
            sx={{ cursor: hasMoreInfo ? "pointer" : "normal" }}
          >
            {renderTitleIcon}
            <Box
              ref={headerTitleRef}
              sx={{
                maxWidth: isLargeSize
                  ? isNewActivity
                    ? "430px"
                    : "460px"
                  : isNewActivity
                    ? "90px"
                    : "140px",
              }}
              className="truncate"
            >
              {title}
            </Box>
            {Boolean(isNewActivity) && (
              <Typography
                ml="4px"
                className="fade-in"
                fontSize={8}
                lineHeight="9.4px"
                sx={{
                  p: "2px 6px 2px 6px",
                  border: "1px solid currentColor",
                  borderRadius: "4px",
                  bgcolor: "#205423",
                  color: "#ffffff",
                }}
              >
                New
              </Typography>
            )}
          </Typography>
        </Tooltip>
      </div>
      <Stack direction="row" alignItems="center" gap="6px">
        {/* we don't render sales force twice since the current source link from it */}
        {Boolean(source.tool) && !isSalesforceSource && (
          <Tooltip title={`Data source from ${source.tool}`} placement="top">
            <Box sx={{ cursor: "help", display: "flex", alignItems: "center" }}>
              <SourceLeadIcon sourceTool={source.tool as EToolNames} />
            </Box>
          </Tooltip>
        )}
        {Boolean(source.link) && (
          <Tooltip title="See data in Salesforce" placement="top">
            <Box
              onClick={() => onGoToExternalLink(source.link)}
              sx={{ cursor: "alias", display: "flex", alignItems: "center" }}
            >
              <SourceLeadIcon sourceTool={EToolNames.Salesforce} />
            </Box>
          </Tooltip>
        )}
        {onDismiss && (
          <Tooltip title="Dismiss" placement="top">
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <IconButton onClick={onDismiss}>
                <Close style={{ fontSize: "14px", color: "#1A1A1A" }} />
              </IconButton>
            </Box>
          </Tooltip>
        )}
      </Stack>
    </div>
  );
}
interface IActivityCardProps {
  title?: string;
  createdAt: string;
  onDismiss?: () => Promise<void>;
  expandableInfoRender?: ReactNode;
  onClick?: () => void;
  children?: ReactNode;
  type: IActivity["action"];
  isNew?: boolean;
  activitySubHeadline?: string;
  isTargetAccount?: boolean;
  source: IActivity["source"];
  onCardTitleClick?: () => void;
  cardSize?: ActivityCardSize;
  accountContactEmail?: string;
  activity: IActivity;
  expandableInfoHeight?: number;
}

export const ActivityCard = (props: IActivityCardProps) => {
  const {
    children,
    type: activityType,
    expandableInfoRender,
    isNew,
    activity,
    activitySubHeadline = "",
  } = props;
  const {
    onDismiss,
    isTargetAccount,
    expandableInfoHeight,
    cardSize,
    accountContactEmail,
    source,
  } = props;
  const { title = "[Title]", createdAt, type } = props;

  const [expanded, onToggleExpandable] = useToggle(false);
  const [showDismissDialog, onToggleShowDismissDialog] = useToggle(false);
  const Icon = activityTypesIcons[type] || null;

  const onShowMoreInfo = () => {
    onToggleExpandable();
  };

  const onCancelDismissDialog = () => onToggleShowDismissDialog(false);
  const onConfirmDismissDialog = async () => {
    if (onDismiss) {
      await onDismiss();
    }
    onCancelDismissDialog();
  };
  const onOpenDismissDialog = () => onToggleShowDismissDialog(true);

  const isWinWireActivity = activityType === ActivityTypes.WinWire;
  const iconColor = isWinWireActivity ? "#FFA900" : "#3292DB";

  const renderTargetAccountIcon = isTargetAccount ? (
    <Tooltip title="Target Account" placement="top">
      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
        <AimOutlined style={{ fontSize: "16px", color: "#262626" }} />
      </Box>
    </Tooltip>
  ) : null;

  const renderHeaderTitleIcon = isWinWireActivity ? (
    <Box
      sx={{
        height: "12px",
        width: "12px",
        borderRadius: "50%",
        backgroundColor: iconColor,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Icon style={{ fontSize: "12px", color: "#004E87" }} />
    </Box>
  ) : (
    renderTargetAccountIcon
  );

  return useMemo(
    () => (
      <Box
        sx={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          bgcolor: "#fff",
          width: "100%",
          borderRadius: "4px",
          border: "1px solid #F5F5F5",
          overflow: "hidden",
          transition: "box-shadow 0.2s",
          boxShadow: "0px 0px 20px rgba(11, 60, 107, 0.05)",
          "&:hover": {
            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
          },
        }}
      >
        <ActivityCardHeader
          onDismiss={onOpenDismissDialog}
          onShowInfo={onShowMoreInfo}
          hasMoreInfo={Boolean(expandableInfoRender)}
          isShowInfoActive={expanded}
          title={isWinWireActivity ? "NEW WIN!" : title}
          titleIcon={renderHeaderTitleIcon}
          onTitleClick={onShowMoreInfo}
          isNewActivity={isNew}
          source={source}
          cardSize={cardSize}
          accountFavicon={getDomainFaviconFromEmail(accountContactEmail)}
        />
        {Boolean(onDismiss) && (
          <DismissActivityDialog
            show={showDismissDialog}
            onCancel={onCancelDismissDialog}
            onDismiss={onConfirmDismissDialog}
          />
        )}
        <Box>
          {hasValidDate(createdAt) && (
            <Stack
              direction="row"
              alignItems="center"
              justifyContent={activitySubHeadline ? "space-between" : "flex-end"}
              sx={{
                p: "4px 6px",
                bgcolor: "#F5F5F5",
                color: "#4D4D4D",
                fontSize: "10px",
                lineHeight: "11px",
                textTransform: "capitalize",
                borderBottom: "1px solid #F5F5F5",
              }}
            >
              <div className="truncate" style={{ maxWidth: "60%" }}>
                {activitySubHeadline}
              </div>
              <Typography fontSize={10} lineHeight="11px">
                {customTimeFormator(new Date(createdAt) as any, "MM/dd/yyyy, hh:mm a")}
              </Typography>
            </Stack>
          )}
          <Stack direction="row" gap="16px" alignItems="center" sx={{ p: "10px 20px" }}>
            <Icon style={{ fontSize: "36px", color: iconColor }} />
            <Stack direction="column" spacing={0} flex={1}>
              {children}
            </Stack>
            {cardSize === ActivityCardSize.LARGE && (
              <Stack direction="row" alignItems="center" gap="10px" color="#2671B0">
                <ActivityAction actionType={ActivityActionTypes.SEND_EMAIL} activity={activity} />
                <ActivityAction
                  actionType={ActivityActionTypes.ADD_TO_CALENDAR}
                  activity={activity}
                />
              </Stack>
            )}
          </Stack>
          {expandableInfoRender && (
            <ScrollableBox
              height={expandableInfoHeight || "200px"}
              show={expanded}
              sx={{ p: "0 20px", mb: "16px" }}
            >
              {expandableInfoRender}
            </ScrollableBox>
          )}
        </Box>

        {cardSize === ActivityCardSize.SMALL && (
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
            sx={{
              p: "6.8px 6px",
              bgcolor: "#E5EFF5",
            }}
          >
            <Stack direction="row" alignItems="center" gap="10px" color="#2671B0">
              <ActivityAction actionType={ActivityActionTypes.SEND_EMAIL} activity={activity} />
              <ActivityAction
                actionType={ActivityActionTypes.ADD_TO_CALENDAR}
                activity={activity}
              />
            </Stack>
          </Stack>
        )}
      </Box>
    ),
    [activity.id, showDismissDialog, isNew, expanded],
  );
};
