import * as styles from "./cellStyles";

const cellTypes = {
  double: "number",
  integer: "number",
  string: "string",
  boolean: "boolean",
  date: "date",
  datetime: "date",
  time: "date",
  timestamp: "date",
  null: "null",
  undefined: "undefined",
  currency: "number",
  percent: "string",
  phone: "phone",
  email: "email",
  url: "url",
  id: "id",
  reference: "id",
};

export const convertColumnTypes = (columnType: string): string => cellTypes[columnType as keyof typeof cellTypes];

export const getDefaultColumnStyles = (columnType: string) => {
  const type = convertColumnTypes(columnType);
  return styles[type as keyof typeof styles] || styles.defaultStyles;
};

export interface IExpandingColumnCheckParams {
  style: any;
  isLastColumn: boolean;
  columnType: string;
}

export const getExpandingColumnCheck = ({
  style,
  isLastColumn,
  columnType,
}: IExpandingColumnCheckParams) => {
  let className = "";
  if (isLastColumn) {
    // ignore preset styles for last column and allow it to expand both with skeleton loader and when the data returns.
    className += "cell-left";
    style = {};
  } else {
    const shouldCenter = columnType === "date" || columnType === "number" || columnType === "boolean";
    className += "collapse-column";
    if (shouldCenter) className += " cell-center";
    if (columnType === "string") className += " cell-left";
  }

  return {
    className,
    style,
  };
};
