import {
  Card,
  CardContent,
  CardContentProps,
  CardHeader,
  CardHeaderProps,
  CardProps,
  Divider,
} from "@mui/material";
import {
  CSSProperties, ReactNode, Ref, forwardRef,
} from "react";

// types
import { KeyedObject } from "types/root";

// header style
const headerSX = {
  p: 2.5,
  "& .MuiCardHeader-action": { m: "0px auto", alignSelf: "center" },
};

// ==============================|| CUSTOM - MAIN CARD ||============================== //

export interface MainCardProps extends KeyedObject {
  border?: boolean;
  boxShadow?: boolean;
  children: ReactNode | string;
  subheader?: ReactNode | string;
  style?: CSSProperties;
  content?: boolean;
  contentSX?: CardContentProps["sx"];
  darkTitle?: boolean;
  divider?: boolean;
  sx?: CardProps["sx"];
  secondary?: CardHeaderProps["action"];
  shadow?: string;
  elevation?: number;
  title?: ReactNode | string;
  codeHighlight?: boolean;
  codeString?: string;
  modal?: boolean;
}

const MainCard = forwardRef(
  (
    {
      border = true,
      boxShadow,
      children,
      subheader,
      content = true,
      contentSX = {},
      darkTitle,
      divider = true,
      elevation,
      secondary,
      shadow,
      sx = {},
      title,
      codeHighlight = false,
      codeString,
      modal = false,
      onClick,
      ...others
    }: MainCardProps,
    ref: Ref<HTMLDivElement>,
  ) => (
    <Card
      onClick={onClick}
      elevation={elevation || 0}
      ref={ref}
      sx={{
        display: "flex",
        flexDirection: "column",
        border: border ? "1px solid" : "none",
        borderRadius: 1,
        ...sx,
      }}
    >
      {/* card header and action */}
      {title && (
        <CardHeader
          sx={headerSX}
          titleTypographyProps={{ variant: "subtitle1" }}
          title={title}
          action={secondary}
          subheader={subheader}
          style={{ padding: "17px" }}
        />
      )}
      {title && divider && <Divider />}
      {content && <CardContent sx={contentSX}>{children}</CardContent>}
      {!content && children}
    </Card>
  ),
);

export default MainCard;
