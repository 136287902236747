import { TerritoryFilters } from "types/api/territoryFilters";
import { AxiosNode } from "utils/axios";

export enum UserFiltersScopeEnum {
  Territory = "territory",
  Activities = "activities",
}
export interface SavedUserFilter {
  id: string;
  instanceId: string;
  name: string;
  type: UserFiltersScopeEnum;
  userId: string;
  filter: TerritoryFilters;
}

export interface FilterResponseBody {
  userFiltersData: SavedUserFilter[];
}

class FiltersAPI {
  static get = async () => {
    try {
      const { data } = await AxiosNode.get<FilterResponseBody>("/user/filters");
      return data.userFiltersData;
    }
    catch (error) {
      console.log(error);
      return [];
    }
  }
  static save = async (filter: any) => {
    const {
      data: { filterId },
    } = await AxiosNode.post("user/filters", filter);
    return filterId;
  };

  static delete = async (filterId: string) => {
    try {
      const {
        data: { data },
      } = await AxiosNode.delete(`user/filters?id=${filterId}`);
    } catch (error) {
      console.log(error);
    }
  };
}

export default FiltersAPI;
