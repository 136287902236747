import "./columns.module.css";

import type { SelectOption } from "@mui/base";
import { Box } from "@mui/material";
import { format, subMonths } from "date-fns";
import { MouseEvent, useMemo } from "react";

import { SalesforceGrayIcon, SalesforceIcon } from "assets/icons";
import { Head } from "components/AccountTables/Head";
import { defaultTableFilters } from "components/common/Tables";
import Tooltip from "components/Tooltip";
import { dateDefaultFormate } from "constants/dates";
import type { AccountOpportunitiesFilters } from "types/account";
import { truncateString } from "utils/generics";
import { generateSliderMarks } from "utils/helpers";
import { numberFormats } from "utils/numbers";

interface ColumnFilterOptions {
  meta: {
    value: {
      min: number;
      max: number;
    };
    stageOptions: SelectOption<string>[];
    sourceOptions: SelectOption<string>[];
    typeOptions: SelectOption<string>[];
  };
  filters: AccountOpportunitiesFilters;
  onFilterChange: (filters: Partial<AccountOpportunitiesFilters>) => void;
  onSalesforceLinkClick: (e: MouseEvent, id: string) => void;
  // searchQuery: { open_tab: string; prefilter: string; filtered_column: string; ac_feed: boolean };
  // updateSearchQuery: (searchQuery: { open_tab: string; prefilter: string; filtered_column: string; ac_feed: boolean }) => void;
}

const getSelectOptionFromValue = (value: string, options: SelectOption<string>[]) => options.find((option) => option.value === value);

const useOpportunitiesColumns = ({
  meta,
  filters = {},
  onFilterChange,
  onSalesforceLinkClick,
}: ColumnFilterOptions) => {
  const _onFilterChange = (name: string) => (value?: string) => {
    onFilterChange({ ...filters, [name]: value });
  };

  const onColumnSortChange = ({ id: filedName, isSortedDesc, isSorted }: any) => {
    // @todo: this should be maintained, and we should resolve the dealy in sorting
    // @note: this is a temporary solution & is should look weird to you!
    // const orderBy = filedName;
    // const orderDirection = !(isSorted && isSortedDesc) ? isSorted ? 'DESC' : 'ASC' : undefined;
    // const sortingOptions = (orderDirection ? { orderBy, orderDirection } : {}) as { orderBy?: typeof filters.orderBy, orderDirection?: typeof filters.orderDirection };
    // @todo: this should be enabled to sort the data from the backend, but we have issue wit the table state, is should be synced
    // onFilterChange({ ...filters, ...sortingOptions })
  };

  const onClosingDateChange = (value?: number) => {
    if (!value) return;
    const closingDate = new Date(value).toISOString();
    _onFilterChange("maxClosingDate")(closingDate);
  };
  const opportunitiesValueSliderMarks = useMemo(
    () => generateSliderMarks(meta?.value?.min, meta?.value?.max),
    [meta?.value?.min, meta?.value?.max],
  );

  return [
    {
      Header: <Head>Name</Head>,
      accessor: "name",
      hasBorderRight: true,
      canFilter: true,
      className: "cell-left collapse-column",
      onSortChange: onColumnSortChange,
      borderLeft: "1px solid #E5E5E5",
      disableSortBy: false,
      filter: defaultTableFilters.textIncludes,
      filterType: "text",
      filterProps: {
        size: "small",
        sx: { width: "100%" },
        padding: "0 10px",
        value: filters.name,
        onChange: _onFilterChange("name"),
        placeholder: "Opportunity name",
      },
      style: {
        width: "330px",
        minWidth: "330px",
        maxWidth: "330px",
        height: "40px",
      },
      Cell: ({ row }: any) => {
        const { id } = row.original;
        const { name } = row.original;
        const renderValue = truncateString(name, 32);
        return (
          <Box
            sx={{
              width: "300px",
              display: "flex",
              color: "#262626",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "10px 0 10px 10px",
            }}
          >
            <Box
              className="truncate"
              sx={{ display: "flex", alignItems: "center", margin: "0 20px 0 0" }}
            >
              <Tooltip hide={name.length < 32} title={name} placement="top">
                <Box>{renderValue}</Box>
              </Tooltip>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                cursor: "pointer",
              }}
              onClick={(e) => onSalesforceLinkClick(e, id)}
            >
              <div
                className="link-with-icon"
              >
                <SalesforceGrayIcon
                  width={25}
                  height={25}
                  className="icon default-icon"
                />
                <SalesforceIcon
                  width={25}
                  height={25}
                  className="icon blue-icon"
                />
              </div>

            </Box>
          </Box>
        );
      },
    },
    {
      Header: <Head>Amount</Head>,
      borderLeft: "1px solid #E5E5E5",
      style: {
        width: "112px",
        minWidth: "112px",
        maxWidth: "112px",
      },
      className: "cell-left collapse-column",
      Cell: ({ row }: any) => (
        <Box sx={{ color: "#262626", width: "90px" }}>
          $
          {numberFormats.millify(Number(row?.original?.value) || 0)}
        </Box>
      ),
      canFilter: false,
      onSortChange: onColumnSortChange,
      // filterType: 'slider',
      filterProps: {
        value: filters.minAmount,
        onChange: _onFilterChange("minAmount"),
        min: meta?.value?.min,
        max: meta?.value?.max,
        marks: opportunitiesValueSliderMarks,
        valueLabelFormat: (value: number) => numberFormats.millify(value, { precision: 2 }),
        tip: "The opportunity potential value",
        padding: "0 20px,",
      },
      accessor: "value",
    },
    {
      Header: <Head>Stage</Head>,
      accessor: "stageName",
      canFilter: true,
      borderLeft: "1px solid #E5E5E5",
      onSortChange: onColumnSortChange,
      filterType: "select",
      filterProps: {
        options: meta.stageOptions,
        value: getSelectOptionFromValue(`${filters.stage}`, meta.stageOptions),
        onChange: _onFilterChange("stage"),
        sx: {
          height: "30px",
          width: "100%",
        },
      },
      className: "cell-left collapse-column",
      style: {
        width: "160px",
        minWidth: "160px",
        maxWidth: "160px",
      },
      Cell: (cellProps: any) => {
        const label = String(cellProps?.cell?.value).toLowerCase() || "N/A";
        // only the open opportunities should be green. colors of the rest doesn't matter.
        const color = label.includes("won") ? "#000" : label.includes("lost") ? "#000" : "#358700";
        return (
          <Box sx={{
            fontWeight: "600", color, textTransform: "capitalize", width: "130px",
          }}
          >
            {label}
          </Box>
        );
      },
    },
    {
      Header: <Head>Source</Head>,
      accessor: "source",
      canFilter: true,
      borderLeft: "1px solid #E5E5E5",
      onSortChange: onColumnSortChange,
      filterType: "select",
      filterProps: {
        options: meta.sourceOptions,
        value: getSelectOptionFromValue(`${filters.source}`, meta.sourceOptions),
        onChange: _onFilterChange("source"),
        sx: {
          height: "30px",
          width: "100%",
        },
      },
      className: "cell-left collapse-column",
      style: {
        width: "160px",
        minWidth: "160px",
        maxWidth: "160px",
      },
      Cell: (cellProps: any) => cellProps?.cell?.row?.original?.source || "N/A",
    },
    {
      Header: <Head>Type</Head>,
      accessor: "type",
      onSortChange: onColumnSortChange,
      canFilter: true,
      borderLeft: "1px solid #E5E5E5",
      filterType: "select",
      filterProps: {
        options: meta.typeOptions,
        value: getSelectOptionFromValue(`${filters.type}`, meta.typeOptions),
        onChange: _onFilterChange("type"),
        sx: {
          height: "30px",
          width: "100%",
        },
      },
      className: "cell-left collapse-column",
      style: {
        width: "160px",
        minWidth: "160px",
        maxWidth: "160px",
      },
    },
    {
      Header: <Head>Closing</Head>,
      accessor: "closing",
      canFilter: false,
      borderLeft: "1px solid #E5E5E5",
      // filterType: 'slider',
      filterProps: {
        value: filters.maxClosingDate ? new Date(filters.maxClosingDate).getTime() : undefined,
        onChange: onClosingDateChange,
        min: subMonths(new Date(), 3).getTime(),
        max: new Date().getTime(),
        tip: "The opportunity closing date, by months",
        valueLabelFormat: (date: number) => (date ? format(new Date(date), dateDefaultFormate) : ""),
        marks: [
          { value: subMonths(new Date(), 3).getTime(), label: "3 m" },
          { value: subMonths(new Date(), 2).getTime(), label: "2 m" },
          { value: subMonths(new Date(), 1).getTime(), label: "1 m" },
          { value: new Date().getTime(), label: "Now" },
        ],
      },
      className: "cell-left collapse-column",
      style: {
        width: "160px",
        minWidth: "160px",
        maxWidth: "160px",
      },
      Cell: (cellProps: any) => {
        const closeDate = cellProps?.cell?.row?.original?.closeDate;
        // @todo: this should be a global util function with consistent date formats
        const closeToDate = format(new Date(closeDate), dateDefaultFormate);
        return <Box sx={{ color: "#262626", width: "130px" }}>{closeToDate}</Box>;
      },
    },
    {
      Header: <Head>Owner</Head>,
      accessor: "ownerName",
      canFilter: true,
      borderLeft: "1px solid #E5E5E5",
      filterType: "text",
      filterProps: {
        size: "small",
        value: filters.owner,
        onChange: _onFilterChange("owner"),
        placeholder: "Opportunity owner",
      },
      Cell: (cellProps: any) => (
        <Box sx={{ color: "#262626", width: "200px" }}>
          {cellProps?.cell?.row?.original?.ownerName || "N/A"}
        </Box>
      ),
    },
  ];
};

export default useOpportunitiesColumns;
