import { Button, Checkbox, CircularProgress, Grid, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import UserFeatureAccessWrapper from "components/UserFeatureAccessWrapper";
import { botsListSet, WorkerNameEnum } from "data/bots";
import { FeatureGroupSet } from "data/userPermissionsMap";
import SecAccountCard from "./DetailsWidget";
import { useSecWorker } from "./hooks";
import SecWorkerForm from "./SecWorkerForm";
import { ProgressionLoading } from "../shared";
import { AccountSimilarWins } from "types/api";
import DetailsWidget from "./DetailsWidget";
import PrioritiesWidget from "./PrioritiesWidget";
import RisksWidget from "./RisksWidget";

function SecWorker({ widget = WorkerNameEnum.SEC_WORKER }: { widget?: WorkerNameEnum }) {
  const SecWorker = botsListSet.find((bot) => bot.key === WorkerNameEnum.SEC_WORKER);
  const {
    onFind,
    companyDetails,
    loading: isLoading,
    listOfCompanies,
    errorMessage,
    resetErrorMessage,
    onCancelRequest,
    updateSecDataForCompany,
    onResetResults
  } = useSecWorker();


  const onSubmit = (companyTicker: string) => {
    onFind(companyTicker);
  };

  if (!SecWorker) {
    return (
      <Box>
        Bot not found
      </Box>
    );
  }


  return (
    <Box sx={{
      p: "20px", display: "flex", flexDirection: "column", flexGrow: 1,
    }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          padding: "20px",
          backgroundColor: "#ffffff",
          borderRadius: "10px",
          border: "1px solid #e0e0e0",
          marginBottom: "20px",
        }}
      >
        <Box sx={{
          display: "flex", flexDirection: "row", gap: "20px", alignItems: "center",
        }}
        >
          <div style={{
            borderBottom: "1px solid #e0e0e0", paddingBottom: "10px", width: "70px", height: "70px", borderRadius: "50%", backgroundColor: "#f5f5f5", justifyContent: "center", alignItems: "center",
          }}
          >
            <img src={SecWorker.image} alt="Similar wins Finder" style={{ width: "100%" }} />
          </div>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
            <Typography variant="h5">{SecWorker.title}</Typography>
            <Typography variant="body1">{SecWorker.description}</Typography>
          </Box>
        </Box>
        <SecWorkerForm
          onFind={onSubmit}
          onResetErrorMessage={resetErrorMessage}
          isLoading={isLoading}
          onCancelRequest={onCancelRequest}
          onResetForm={onResetResults}
          secCompanies={listOfCompanies}
        />
      </Box>
      {isLoading ? (
        <ProgressionLoading searchEntityTitle="Accounts SEC details" />
      ) : (
        errorMessage ? (
          <Box sx={{
            padding: "20px",
            border: "1px solid #e0e0e0",
            borderRadius: "10px",
            marginBottom: "20px",
            backgroundColor: "#fff",
          }}
          >
            <Typography variant="body1">{errorMessage}</Typography>
          </Box>
        )
          : companyDetails?.companyInfo ? (
            <Stack flex={1} direction='column' gap='20px' flexWrap='wrap' alignContent='flex-start'>
              <DetailsWidget
                details={companyDetails?.companyInfo}
                lastUpdated={companyDetails?.filing?.lastUpdate}
                onUpdateSecDataForCompany={updateSecDataForCompany}
              />
              <Stack direction='row' gap='20px' flexWrap='wrap'>
                <RisksWidget risks={companyDetails?.filing?.risks} />
                <PrioritiesWidget priorities={companyDetails?.filing?.businessPriorities} />
              </Stack>
            </Stack>
          ) : null
      )}
    </Box >
  );
}

function SecWorkerWrapper({ widget = WorkerNameEnum.SEC_WORKER }: { widget?: WorkerNameEnum }) {
  return (
    <UserFeatureAccessWrapper groupName={FeatureGroupSet.GENERAL_ACCESS}>
      <SecWorker widget={widget} />
    </UserFeatureAccessWrapper>
  );
}
export default SecWorkerWrapper;
