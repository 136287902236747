import { Box, CircularProgress } from "@mui/material";

function Spinner() {
  return (
    <Box sx={{
      width: "100%", height: "100%", alignItems: "center", justifyContent: "center",
    }}
    >
      <CircularProgress
        sx={{ position: "absolute", top: "calc(50vh - 20px)", left: "calc(50vw - 20px)" }}
      />
    </Box>
  );
}

export default Spinner;
