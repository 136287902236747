import { Box, Checkbox } from "@mui/material";

interface ITableCheckboxProps {
  id: string;
  isSelected: boolean;
  tableData?: any;
}

export function TableCheckbox({ id, isSelected }: ITableCheckboxProps) {
  return (
    <Box
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "0",
        width: "5px",
        margin: "4px",
      }}
    >
      <Checkbox data-id={id} id={id} checked={isSelected} />
    </Box>
  );
}
