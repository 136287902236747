import { Tooltip } from "@mui/material";
import { ReactElement, useMemo } from "react";

import { IAccountFilAllClientsData } from "types/accountFitTooltip";
import { PipelineSignalEnums } from "types/api/territoryFilters";
import generateRandomId from "utils/generateRandomId";

import { getActiveMessages } from "./utils";

interface IAccountFitTooltip {
  children: ReactElement;
  value: PipelineSignalEnums;
  color: string;
  accountFitData?: IAccountFilAllClientsData;
}

function TooltipTitle({
  accountFitData,
  value,
}: {
  value: PipelineSignalEnums;
  accountFitData?: IAccountFilAllClientsData;
}) {
  const getItemsMessages = useMemo(() => getActiveMessages(accountFitData), [accountFitData]);

  const tooltipContent = useMemo(
    () => (() => {
      switch (value) {
        case PipelineSignalEnums.Strong:
          return [
            {
              title: "Positive Factors",
              items: getItemsMessages({ isPositive: true, limit: 4 }),
              id: generateRandomId(),
            },
          ];

        case PipelineSignalEnums.Medium:
          return [
            {
              title: "Positive Factors",
              items: getItemsMessages({ isPositive: true, limit: 3 }),
              id: generateRandomId(),
            },
            {
              title: "Counter Factors",
              items: getItemsMessages({ isPositive: false, limit: 2 }),
              id: generateRandomId(),
            },
          ];

        case PipelineSignalEnums.Low:
          return [
            {
              title: "Negative Factors",
              items: getItemsMessages({ isPositive: false, limit: 3 }),
              id: generateRandomId(),
            },
          ];

        default:
          return [];
      }
    })(),
    [value],
  );

  return (
    <>
      {tooltipContent.map(({ title, items, id }) => (
        <div key={id}>
          <p style={{ fontWeight: "bold", margin: 0 }}>{title}</p>

          <ul style={{ margin: "0 0 8px 0", paddingLeft: 25 }}>
            {items.map((item) => (
              <li key={item}>{item}</li>
            ))}
          </ul>
        </div>
      ))}
    </>
  );
}

function AccountFitTooltip({
  children, value, color, accountFitData,
}: IAccountFitTooltip) {
  const hasAccountFitData = accountFitData && Object.keys(accountFitData).length;
  if (!hasAccountFitData) return children;

  return (
    <Tooltip
      placement="right-start"
      title={<TooltipTitle value={value} accountFitData={accountFitData} />}
      arrow
      PopperProps={{
        sx: {
          "& .MuiTooltip-tooltip": {
            color: "#000",
            border: `solid ${color} 1px`,
            backgroundColor: "#fff",
          },

          "& .MuiTooltip-arrow": {
            "&::before": {
              backgroundColor: color,
            },
          },
        },
      }}
    >
      {children}
    </Tooltip>
  );
}

export default AccountFitTooltip;
