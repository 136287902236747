import {
  CaretDownOutlined,
  CaretUpOutlined,
  CloseSquareFilled,
  DragOutlined,
} from "@ant-design/icons";
import {
  Box,
  Checkbox,
  Chip,
  FormControl,
  Grid,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Pagination,
  Select,
  SelectChangeEvent,
  Stack,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { Theme, styled, useTheme } from "@mui/material/styles";
import React, {
  ReactNode, forwardRef, useRef, useState,
} from "react";

import { useDrag, useDragLayer, useDrop } from "react-dnd";

import { OrderDirectionEnum } from "types/account";

const DragHeader = styled("div")(({ theme, x, y }: { theme: Theme; x: number; y: number }) => ({
  color: theme.palette.text.secondary,
  position: "fixed",
  pointerEvents: "none",
  left: 12,
  top: 24,
  transform: `translate(${x}px, ${y}px)`,
  opacity: 0.6,
}));

function DragPreview() {
  const theme = useTheme();
  const { isDragging, item, currentOffset } = useDragLayer((monitor: any) => ({
    item: monitor.getItem(),
    itemType: monitor.getItemType(),
    initialOffset: monitor.getInitialSourceClientOffset(),
    currentOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging(),
  }));

  const { x, y } = currentOffset || {};

  return isDragging ? (
    <DragHeader theme={theme} x={x} y={y}>
      {item.header && (
        <Stack direction="row" spacing={1} alignItems="center">
          <DragOutlined style={{ fontSize: "1rem" }} />
          <Typography>{item.header}</Typography>
        </Stack>
      )}
    </DragHeader>
  ) : null;
}

export default DragPreview;