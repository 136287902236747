import {
  useCallback, useEffect, useRef, useState,
} from "react";

import useWindowScreenHeight from "./useWindowScreenHeight";

const useElementBoundingClientRect = (deps?: any[]) => {
  const [fullScreenHeight, fullScreenWidth] = useWindowScreenHeight();
  const [elementRectDetails, setElementRectDetails] = useState<DOMRect | null>(null);
  const ref = useRef<HTMLElement | null>(null);

  const updateElementRectDetails = useCallback(() => {
    if (ref.current) {
      const rectDetails = ref.current.getBoundingClientRect();
      setElementRectDetails(rectDetails);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref.current?.offsetWidth]);

  useEffect(() => {
    updateElementRectDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateElementRectDetails, ...(deps || [])]);

  useEffect(() => {
    const handleResize = () => {
      updateElementRectDetails();
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateElementRectDetails]);

  return {
    ref,
    rect: elementRectDetails,
    screen: { height: fullScreenHeight, width: fullScreenWidth },
  };
};

export default useElementBoundingClientRect;
