import {
  Box, Chip, FormControl, Stack,
} from "@mui/material";
import Multiselect from "multiselect-react-dropdown";
import "./multiSelect.styles.css";
import { Close } from "@mui/icons-material";
import { CaretDownOutlined, CloseCircleFilled } from "@ant-design/icons";
import {
  useEffect, useMemo, useRef, useState,
} from "react";
import Highlighter from "react-highlight-words";

import useToggle from "hooks/useToggle";
import { isSubOrEqualString } from "utils/comparison";

import { CheckBox } from "./common";
import { ActivityTypeOption, activityTypeOptions } from "./utils";

export interface SearchBoxFiltersValue {
  searchText?: string;
  options?: ActivityTypeOption[];
}
interface IActivitySearchBox {
  onFilterChange: (filters: SearchBoxFiltersValue) => void;
  filters: SearchBoxFiltersValue;
  accountsOptions: any[];
  placeholder?: string;
  size?: "small" | "large";
  shownSelectedItemsLimit?: number;
  accountsSignalsActiveValues?: number[];
  onResetActivitySearchBox?: () => void;
}

const LIMIT_PER_SELECT = 100;
function ActivitySearchBox(props: IActivitySearchBox) {
  const {
    onFilterChange,
    filters,
    accountsOptions,
    size,
    placeholder = "Selecte to filter",
    shownSelectedItemsLimit = 8,
    accountsSignalsActiveValues,
    onResetActivitySearchBox,
  } = props;

  const selectInputRef = useRef<Multiselect>(null);
  const [isSelectInputFocused, toggleIsFocused] = useToggle();
  const visableActivityTypeOptions = accountsSignalsActiveValues?.length
    ? activityTypeOptions.filter(({ value }) => accountsSignalsActiveValues?.includes(value))
    : activityTypeOptions;
  const [options, setOptions] = useState<ActivityTypeOption[]>(visableActivityTypeOptions);

  const selectOptions = useMemo(
    () => [...visableActivityTypeOptions, ...accountsOptions],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      JSON.stringify(accountsOptions),
      JSON.stringify(visableActivityTypeOptions),
    ],
  ) as ActivityTypeOption[];

  useEffect(() => {
    const { searchText } = filters;
    const matchOptions = selectOptions?.filter(
      (option: ActivityTypeOption) => !searchText || isSubOrEqualString(option.name as unknown as string, searchText),
    ) || [];
    const slicedOptions = matchOptions?.slice(0, LIMIT_PER_SELECT) || [];
    setOptions(slicedOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectOptions, filters.searchText]);

  const onSelectFocused = () => {
    toggleIsFocused(true);
  };
  const onSelectBlur = () => {
    onFilterChange?.({ searchText: "" });
    toggleIsFocused(false);
  };

  const onReset = () => {
    selectInputRef.current?.resetSelectedValues?.();
    onFilterChange?.({ searchText: "", options: [] });
    onResetActivitySearchBox?.();
  };

  const onRemove = (newOptions: (typeof options)[0] | typeof options) => {
    selectInputRef.current?.resetSelectedValues?.();
    if (Array.isArray(newOptions)) {
      return onFilterChange?.({ options: newOptions, searchText: "" });
    } else {
      const { options } = filters;
      const option = newOptions; // single option
      const updatedOptions = options?.filter((item) => item.value !== option.value) || [];
      onFilterChange?.({ options: updatedOptions, searchText: "" });
    }
  };

  const selectedValueDecorator = (value: string, option: ActivityTypeOption) => {
    const { icon: Icon } = option;
    const renderIcon = Icon ? <Icon className="selected-icon small" /> : null;
    return (
      <Chip
        key={value}
        sx={{
          background: "transparent",
          alignItems: "center",
          display: "flex",
          fontSize: "12px",
          padding: "0px",
          "& .MuiChip-label": {
            padding: "2px 4px",
          },
        }}
        label={(
          <Stack gap="4px" direction="row" alignItems="center">
            {renderIcon}
            <span className="truncate" style={{ maxWidth: "200px", textTransform: "capitalize" }}>
              {value}
              {" "}
            </span>
            <CloseCircleFilled className="close-icon" onClick={() => onRemove(option)} />
          </Stack>
        )}
        size="small"
      />
    );
  };

  const optionValueDecorator = (value: string, option: ActivityTypeOption) => {
    const { icon: Icon } = option;
    const renderIcon = Icon ? <Icon className="selected-icon" /> : null;
    return (
      <Stack
        direction="row"
        alignItems="center"
        gap="8px"
        sx={{
          p: "5px 7px",
          lineHeight: "20px",
          fontSize: "13px",
          color: "currentColor",
        }}
      >
        <CheckBox checked={false} />
        {renderIcon}
        <Highlighter
          highlightClassName="search-match-text-highlight"
          searchWords={[filters.searchText || ""]}
          autoEscape
          textToHighlight={option.name || ""}
        />
      </Stack>
    );
  };

  const onSelectItem = (selectedList: any, selectedItem: any) => {
    onFilterChange?.({ options: selectedList, searchText: "" });
  };

  const onSearch = (searchText: string) => {
    onFilterChange?.({ searchText });
    return searchText;
  };

  const onEnterClick = (e: any) => {
    const { searchText, options = [] } = filters;
    if (e.key === "Enter" && searchText) {
      const firstMatchedOption = options.find((item: ActivityTypeOption) => item.name?.toLowerCase().includes(searchText?.toLowerCase() || ""));
      const isOptionSelected = options.find(
        (item: ActivityTypeOption) => item.value === firstMatchedOption?.value,
      );
      if (firstMatchedOption && !isOptionSelected) {
        onFilterChange?.({ options: [...options, firstMatchedOption], searchText: "" });
      }
    }
  };
  const hasExceededTheLimitOfSelected = (filters?.options?.length || 0) > shownSelectedItemsLimit;
  return (
    <FormControl
      onFocus={onSelectFocused}
      onBlur={onSelectBlur}
      sx={{ margin: "10px 0", position: "relative" }}
      fullWidth
    >
      <Multiselect
        ref={selectInputRef}
        showArrow
        avoidHighlightFirstOption={false}
        hidePlaceholder
        customCloseIcon=" "
        onRemove={onRemove}
        keepSearchTerm={false}
        closeOnSelect={false}
        selectedValueDecorator={selectedValueDecorator}
        optionValueDecorator={optionValueDecorator}
        options={options}
        groupBy="group"
        selectedValues={filters.options}
        hideSelectedList={hasExceededTheLimitOfSelected}
        onSelect={onSelectItem}
        displayValue="name"
        onSearch={onSearch}
        placeholder={placeholder}
        onKeyPressFn={onEnterClick}
        customArrow={null}
        emptyRecordMsg="No items matched your search"
        style={{
          multiselectContainer: {
            color: "#262626",
            gap: "2px",
            maxHeight: "400px",
          },
          searchBox: {
            borderRadius: "4px",
            borderColor: isSelectInputFocused ? "#1890FF" : "#C8C8C8",
            borderWidth: "1px",
            backgroundColor: "white",
            padding: size === "large" ? "9px 36px 9px 12px" : "8px 36px 8px 12px",
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
          },
          inputField: {
            marginTop: "0px",
            color: "#262626",
            width: "100%",
            fontSize: size === "large" ? "14px" : "13px",
            lineHeight: "134%",
          },
          optionContainer: {
            borderColor: "#F5F5F5",
            backgroundColor: "white",
            borderTop: "none",
            padding: "0px",
            boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.1)",
          },
          option: {
            paddingTop: "0px",
            paddingBottom: "0px",
            fontWeight: 400,
            fontSize: "11px",
            lineHeight: 2,
            color: "#262626",
            paddingLeft: "0px !important",
          },
          chips: {
            marginBottom: "5px",
            border: "1px solid #80BFFF",
            background: "#fff",
            backgroundColor: "#fff",
            lineHeight: "20px",
            padding: "0",
            borderRadius: "4px",
            userSelect: "none",
            p: 0,
          },
          groupHeading: {
            fontSize: "12px",
            color: "#1A1A1A",
            backgroundColor: "#E1EDFA",
            padding: "5px 12px",
            lineHeight: "20px",
            fontWeight: 600,
            borderBottom: "1px solid #C0D7E9",
          },
        }}
      />
      {hasExceededTheLimitOfSelected && (
        <Stack
          direction="row"
          alignItems="center"
          gap="8px"
          sx={{
            position: "absolute",
            right: "40px",
            bottom: "0",
            top: "6px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            p: "5px 7px",
            lineHeight: "20px",
            fontSize: "12px",
            height: "24px",
            color: "currentColor",
            borderRadius: "4px",
            border: "1px solid #1890FF",
            backgroundColor: "white",
            padding: "8px 36px 8px 12px",
          }}
        >
          {filters?.options?.length || 0}
          {" "}
          filters applied
        </Stack>
      )}
      <Box
        sx={{
          position: "absolute",
          right: "10px",
          bottom: "0",
          top: "0",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: "#262626",
        }}
      >
        {filters?.options?.length ? (
          <Close onClick={onReset} style={{ fontSize: "16px", cursor: "pointer" }} />
        ) : null}
        <CaretDownOutlined
          style={{
            color: isSelectInputFocused ? "#1A1A1A" : "#C8C8C8",
            fontSize: "12px",
            transition: "transform 0.2s ease-in-out",
            transform: isSelectInputFocused ? "rotate(180deg)" : "rotate(0deg)",
          }}
        />
      </Box>
    </FormControl>
  );
}

export default ActivitySearchBox;
