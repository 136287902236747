import React from 'react';
interface CircularProgressBarProps {
  progress: number; // A value between 0 and 100
  size?: number; // Optional size of the circular progress
  strokeWidth?: number; // Optional thickness of the progress stroke
  gradientColors: string[]; // An array of colors for gradient
  label?: React.ReactNode; // Optional label, can be a component or string
}
const CircularProgressBar: React.FC<CircularProgressBarProps> = ({
  progress: _progress,
  size = 120,
  strokeWidth = 10,
  gradientColors,
  label,
}) => {
  // Ensure progress is between 0 and 100
  const progress = Math.max(0, Math.min(100, _progress));

  // Circle configuration
  const radius = (size - strokeWidth) / 2;
  const circumference = 2 * Math.PI * radius;

  // Calculate the stroke offset based on the progress percentage
  const offset = circumference - (progress / 100) * circumference;
  // Construct the gradient stops
  const gradientId = `gradient-${Math.random()}`; // Unique ID for the gradient
  const gradientStops = gradientColors.map((color, index) => (
    <stop key={index} offset={`${(index / (gradientColors.length - 1)) * 100}%`} stopColor={color} />
  ));
  return (
    <div style={{ width: size, height: size, position: 'relative', userSelect: 'none' }}>
      <svg
        width={size}
        height={size}
        viewBox={`0 0 ${size} ${size}`}
        style={{ transform: 'rotate(-90deg)' }} // Rotate to start from the top
      >
        {/* Define the gradient */}
        <defs>
          <linearGradient id={gradientId} x1="1" y1="0" x2="0" y2="1">
            {gradientStops}
          </linearGradient>
        </defs>
        {/* Background circle */}
        <circle
          cx={size / 2}
          cy={size / 2}
          r={radius}
          stroke="#e6e6e6"
          strokeWidth={strokeWidth}
          fill="none"
        />
        {/* Progress circle */}
        <circle
          cx={size / 2}
          cy={size / 2}
          r={radius}
          stroke={`url(#${gradientId})`}
          strokeWidth={strokeWidth}
          fill="none"
          strokeDasharray={circumference}
          strokeDashoffset={offset}
          strokeLinecap="round"
        />
      </svg>
      {/* Label in the center */}
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          fontSize: size * 0.30, // Adjust font size according to size
          fontWeight: 'bold',
        }}
      >
        {label ? label : `${Math.round(progress)}`}
      </div>
    </div>
  );
};
export default CircularProgressBar;