import { PaginatedPayload } from "services/whitespace";
import { AccountResp } from "types/api";
import { AxiosNode } from "utils/axios";

let territoryAccountsCache = {} as Record<
  string,
  { data: AccountResp; limit: number; offset: number }
>;
export const territoryAccountsCacheController = {
  clearCached: () => {
    territoryAccountsCache = {};
  },
};

export async function requestCacheUtil(
  method: "get" | "post",
  url: string,
  payload: PaginatedPayload,
  {
    isCacheEnabled = true,
    options,
  }: { isCacheEnabled?: boolean; options?: Parameters<typeof AxiosNode.post>[2] },
) {
  const { limit, offset, ...otherFilters } = payload;
  const key = `${method}-${url}-${offset}-${JSON.stringify(otherFilters)}`;
  const cachedData = territoryAccountsCache[key] as {
    data: AccountResp;
    limit: number;
    offset: number;
  };
  if (cachedData && isCacheEnabled) {
    if (cachedData.limit === limit) {
      return cachedData.data;
    }
    if (limit < cachedData.limit) {
      const slicedData = cachedData?.data?.results?.slice(0, limit);
      return { ...cachedData.data, results: slicedData, limit } as AccountResp;
    }
  }
  const { data } = await AxiosNode.post<AccountResp>(
    url,
    payload,
    options,
  );
  territoryAccountsCache[key] = { data, limit, offset } as {
    data: AccountResp;
    limit: number;
    offset: number;
  };
  return data;
}
