export enum FeatureGroupSet {
  GENERAL_ACCESS = "GENERAL_ACCESS",
  VIEW_ONBOARDING_PAGE = "ONBOARDING_PAGE",
  VIEW_HOME_PAGE = "HOME_PAGE",
  VIEW_ADMIN_SETTINGS = "VIEW_ADMIN_SETTINGS",
  VIEW_AI_WORKERS_PAGE = "VIEW_AI_WORKERS_PAGE",
  REVIC_CHAT = "REVIC_CHAT",
  TERRITORY_PAGE = "TERRITORY_PAGE",
  VIEW_AI_WORKERS_CONFIGURATION_PAGE = "VIEW_AI_WORKERS_CONFIGURATION_PAGE",
  CONNECT_INTEGRATIONS = "CONNECT_INTEGRATIONS",
  MANAGE_USERS = "MANAGE_USERS",
  SIMILAR_WINS_WORKER = "SIMILAR_WINS_WORKER",
  SIMILAR_ACCOUNTS_WORKER = "SIMILAR_ACCOUNTS_WORKER",
  ACCOUNT_RESEARCH_WORKER = "ACCOUNT_RESEARCH_WORKER",
  PERSON_FINDER_WORKER = "PERSON_FINDER_WORKER",
  POWER_RANK_WORKER = "POWER_RANK_WORKER",
}

const userPermissionsAllowanceMap = {
  paid_admin: [
    FeatureGroupSet.GENERAL_ACCESS,
    FeatureGroupSet.VIEW_HOME_PAGE,
    FeatureGroupSet.VIEW_ADMIN_SETTINGS,
    FeatureGroupSet.VIEW_AI_WORKERS_PAGE,
    FeatureGroupSet.REVIC_CHAT,
    FeatureGroupSet.TERRITORY_PAGE,
    FeatureGroupSet.VIEW_AI_WORKERS_CONFIGURATION_PAGE,
    FeatureGroupSet.CONNECT_INTEGRATIONS,
    FeatureGroupSet.MANAGE_USERS,
    FeatureGroupSet.SIMILAR_WINS_WORKER,
    FeatureGroupSet.ACCOUNT_RESEARCH_WORKER,
    FeatureGroupSet.PERSON_FINDER_WORKER,
    FeatureGroupSet.POWER_RANK_WORKER,
  ],
  paid_user: [
    FeatureGroupSet.GENERAL_ACCESS,
    FeatureGroupSet.VIEW_HOME_PAGE,
    FeatureGroupSet.VIEW_AI_WORKERS_PAGE,
    FeatureGroupSet.REVIC_CHAT,
    FeatureGroupSet.TERRITORY_PAGE,
    FeatureGroupSet.SIMILAR_WINS_WORKER,
    FeatureGroupSet.ACCOUNT_RESEARCH_WORKER,
    FeatureGroupSet.PERSON_FINDER_WORKER,
    FeatureGroupSet.POWER_RANK_WORKER,
  ],
  free_user: [
    FeatureGroupSet.GENERAL_ACCESS,
    FeatureGroupSet.VIEW_ADMIN_SETTINGS,
    FeatureGroupSet.VIEW_ONBOARDING_PAGE,
    FeatureGroupSet.MANAGE_USERS,
    FeatureGroupSet.VIEW_AI_WORKERS_PAGE,
    FeatureGroupSet.TERRITORY_PAGE,
    FeatureGroupSet.SIMILAR_ACCOUNTS_WORKER,
    FeatureGroupSet.POWER_RANK_WORKER,
  ],
  free_admin: [
    FeatureGroupSet.GENERAL_ACCESS,
    FeatureGroupSet.VIEW_ADMIN_SETTINGS,
    FeatureGroupSet.VIEW_ONBOARDING_PAGE,
    FeatureGroupSet.MANAGE_USERS,
    FeatureGroupSet.VIEW_AI_WORKERS_PAGE,
    FeatureGroupSet.TERRITORY_PAGE,
    FeatureGroupSet.VIEW_AI_WORKERS_CONFIGURATION_PAGE,
    FeatureGroupSet.SIMILAR_ACCOUNTS_WORKER,
    FeatureGroupSet.POWER_RANK_WORKER,
  ],
  trial_user: [
    FeatureGroupSet.GENERAL_ACCESS,
    FeatureGroupSet.VIEW_ADMIN_SETTINGS,
    FeatureGroupSet.VIEW_ONBOARDING_PAGE,
    FeatureGroupSet.VIEW_AI_WORKERS_PAGE,
    FeatureGroupSet.CONNECT_INTEGRATIONS,
    FeatureGroupSet.TERRITORY_PAGE,
    FeatureGroupSet.SIMILAR_ACCOUNTS_WORKER,
    FeatureGroupSet.POWER_RANK_WORKER,
  ]
};

export default userPermissionsAllowanceMap;
