
import { LinkOutlined } from "@mui/icons-material";
import { Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import { SalesforceIcon } from "assets/icons";
import { onGoToExternalLink } from "components/Activity.components/utils";
import { Table } from "components/common/Tables";
import { dateDefaultFormate } from "constants/dates";
import { format } from "date-fns";
import { HeaderCell } from "pages/Settings/UsersManagement/common";
import useUserSettings from "services/shooks/settings";
import { AccountSimilarWins, PowerRankAccount } from "types/api";
import { getSalesForceLink } from "utils/generics";
import { numberFormats } from "utils/numbers";
import { getFaviconByDomain } from "utils/urlParsers";

interface AccountBusinessCardProps {
  accounts: PowerRankAccount[]
}

function PowerRankResultTable({ accounts }: AccountBusinessCardProps) {
  const { instanceURL } = useUserSettings(({ state }) => state);
  const columns = [
    {
      Header: () => (
        <HeaderCell width="100px">
          Account Name
        </HeaderCell>
      ),
      accessor: "accountName",
      Cell: ({ value: accountName, row }: { value: string, row: any }) => {
        const salesforceLink = getSalesForceLink({ instanceURL, objectId: row?.original?.accountId });
        return (
          <Box sx={{ color: "#262626", display: 'flex', alignItems: 'center', gap: '5px' }}>
            <Typography>{accountName}</Typography>
            <a href={salesforceLink} target="_blank" rel="noreferrer" style={{ display: 'flex', alignItems: 'center' }}>
              <SalesforceIcon />
            </a>
          </Box>
        );
      },
    },
    {
      Header: () => (
        <HeaderCell width="100px">
          Account Type
        </HeaderCell>
      ),
      accessor: "type",
      Cell: ({ value: accountType }: { value: string, original: any }) => {
        return (
          <Box sx={{ color: "#262626", display: 'flex', alignItems: 'center', gap: '5px' }}>
            <Typography>{accountType || '--'}</Typography>
          </Box>
        );
      },
    },
    {
      Header: () => (
        <HeaderCell width="130px">
          Opportunity Name
        </HeaderCell>
      ),
      accessor: "opportunityName",
      Cell: ({ value: opportunityName }: { value: string }) => {
        return (
          <Box sx={{ color: "#262626", display: 'flex' }}>
            <Typography>{opportunityName || '--'}</Typography>
          </Box>
        );
      }
    },
    {
      Header: () => (
        <HeaderCell width="150px">
          Opportunity Amount
        </HeaderCell>
      ),
      accessor: "opportunityAmount",
      style: { textAlign: "center", border: 'none' },
      Cell: ({ value: opportunityAmount }: { value: number }) => {

        return (
          <Box sx={{ color: "#262626", display: 'flex', justifyContent: 'center', py: '7px' }}>
            <Typography>{opportunityAmount ? numberFormats.currency(opportunityAmount) : '--'}</Typography>
          </Box>
        );
      }
    },
    {
      Header: () => (
        <HeaderCell sx={{ justifyContent: 'center' }}>
          Close Date
        </HeaderCell>
      ),
      accessor: "closeDate",
      style: { textAlign: "center", border: 'none' },
      Cell: ({ value: closeDate, original }: { value: string, original: any }) => {
        const formattedCloseDate = closeDate ? format(new Date(closeDate), dateDefaultFormate) : '--'
        return (
          <Box sx={{ minWidth: '200px', color: "#262626", display: 'flex', justifyContent: 'center' }}>
            <Typography>{formattedCloseDate}</Typography>
          </Box>
        );
      }
    },
    {
      Header: () => (
        <HeaderCell >
          Rank
        </HeaderCell>
      ),
      accessor: "rank",
      style: { textAlign: "center", border: 'none' },
      Cell: ({ value: rank, original }: { value: string, original: any }) => {
        return (
          <Box sx={{ minWidth: '200px', color: "#262626", display: 'flex', justifyContent: 'center', py: '7px' }}>
            <Typography>{rank || '--'}</Typography>
          </Box>
        );
      }
    }
  ]

  return (
    <Box sx={{
      display: "flex",
      flex: 1,
      flexDirection: "column",
      gap: "10px",
      padding: "20px",
      backgroundColor: "#ffffff",
      borderRadius: "10px",
      border: "1px solid #e0e0e0",
      width: "100%",
    }}
    >
      <Stack flex={1} direction="row" gap="10px" width={'100%'} sx={{ borderRadius: '10px', overflow: 'hidden' }}>
        <Table
          columns={columns as any}
          data={accounts as any}
          noDataMessage="No similar accounts found"
          stickyHeader={false}
          cellSX={{
            padding: "15px 0",
            border: 'none',
          }}
        />
      </Stack>
    </Box>
  );
}

export default PowerRankResultTable;
