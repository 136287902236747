import { useEffect, useRef } from "react";

const useDebounceEffect = (effect: CallableFunction, delay: number, deps: any[]) => {
  const callback = useRef<CallableFunction>();

  useEffect(() => {
    callback.current = effect;
  }, [effect]);

  useEffect(() => {
    let cleanup: CallableFunction;
    const handler = setTimeout(() => (cleanup = callback.current?.()), delay);
    return () => {
      clearTimeout(handler);
      cleanup?.();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [delay, ...deps]);
};

export default useDebounceEffect;
