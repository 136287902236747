import { signalLabels as pipelineSignalLables } from "components/AccountTables/PipelineSignal";
import {
  Account,
  TerritoryTableColumnTitles,
  TerritoryTableColumnWidths,
  TerritoryTableColumnsAccessors,
  DynFilterColumn,
  TerritoryTableColumnsAccessorsOrder,
  DynFilterColumnValue,
} from "types/api";
import {
  PipelineSignalEnums,
  TerritoryFiltersEnums,
} from "types/api/territoryFilters";
import { WhitespaceColumnTitles } from "types/api/whitespace";
import { removeSFCustomSuffix } from "utils/generics/string";
import { getObjectNestedProperty } from "utils/object";
import { forAllUsersColumns, nonDisplayableCols } from "./components/SharedTable/hooks/useTerritoryColumns";
import { spaceAndCapitalize } from "./components/utils";

interface FieldsAndTitlesMap {
  [key: string]: string;
}

const columnsMergedTitlesMap = {
  ...TerritoryTableColumnTitles,
  ...WhitespaceColumnTitles,
  [TerritoryTableColumnsAccessors.pipelineSignal]: "Account Fit",
};

const customFormatedCells = {
  [TerritoryTableColumnsAccessors.accountStatus]: () => ({
    [`${TerritoryTableColumnsAccessors.accountStatus}.activity`]: "Activity",
    [`${TerritoryTableColumnsAccessors.accountStatus}.awareness`]: "Awareness",
    [`${TerritoryTableColumnsAccessors.accountStatus}.engagement`]:
      "Engagement",
    [`${TerritoryTableColumnsAccessors.accountStatus}.opportunities`]:
      "Opportunities",
  }),
  [TerritoryTableColumnsAccessors.whitespace]: () => ({
    TAMamount: "Whitespace",
  }),
  [TerritoryTableColumnsAccessors.annualRecurringRevenue]: () => ({
    openOpportunitiesAmount: "Open Opportunities Amount",
    closedOpportunitiesAmount: "Closed Opportunities Amount",
    TAMamount: "Whitespace",
    // @note this removes the whitespace from the table,since we are using the whitespace column to display the whitespace
    whitespace: false,
  }),
} as { [key: string]: (value: any) => { [key: string]: string | boolean } };

const transofmToBoolean = (value: number) => Boolean(value);

// @note: the key represent the account property name, the value represent the function that transform the value
const dataTransformers = {
  [TerritoryTableColumnsAccessors.pipelineSignal]: (
    value: PipelineSignalEnums
  ) => pipelineSignalLables[value] || "N/A",
  [`${TerritoryTableColumnsAccessors.accountStatus}.activity`]:
    transofmToBoolean,
  [`${TerritoryTableColumnsAccessors.accountStatus}.awareness`]:
    transofmToBoolean,
  [`${TerritoryTableColumnsAccessors.accountStatus}.engagement`]:
    transofmToBoolean,
  [`${TerritoryTableColumnsAccessors.accountStatus}.opportunities`]:
    transofmToBoolean,
  openOpportunitiesAmount: (value) => value || 0,
  closedOpportunitiesAmount: (value) => value || 0,
  // @todo: this calculation should be done on the backend | and we should support a top level requests middleware to handle this
  TAMamount: (value, account: Account) => {
    const uncapturedWhitespace =
      account?.TAMamount -
      ((account?.openOpportunitiesAmount || 0) +
        (account?.closedOpportunitiesAmount || 0));
    return Number(
      uncapturedWhitespace > 0 ? uncapturedWhitespace : 0
    ).toFixed();
  },
  [TerritoryTableColumnsAccessors.similarWins]: (
    value: Account["similarWins"]
  ) =>
    Array.isArray(value)
      ? value
        .map((similarWinAccount) => similarWinAccount.accountName)
        .join(", ")
      : value,
} as { [key: string]: (value: any, account: Account) => any };

const accountObjectToCsvRow =
  (customHandlingPlugins?: typeof dataTransformers) => (account: Account) =>
    Object.entries(account)
      .map(([key, value]) => {
        if (key === "source") {
          return `"${value?.name || ""}"`;
        }
        if (customHandlingPlugins && customHandlingPlugins[key]) {
          return `"${customHandlingPlugins[key]?.(value, account) ?? ""}"`;
        }
        if (typeof value === "string" || typeof value === "boolean")
          return `"${value}"`;
        if (typeof value === "number") return `"${value}"`;
        if (Array.isArray(value) && typeof value?.[0] === "string")
          return `"${value.join(", ")}"`;
        return " ";
      })
      .join(",");

const pickChosenAccountFields = (chosenColumnsMap: any) => (account: Account) =>
  Object.fromEntries(
    Object.entries(chosenColumnsMap).map(([key]) => [
      key,
      key?.includes(".")
        ? getObjectNestedProperty(account, key)
        : account[key as keyof Account],
    ])
  ) as Account;

const modifyWithCustomization = (columnsMap: FieldsAndTitlesMap) => {
  const initialMap = { ...columnsMap };
  for (const propName in customFormatedCells) {
    if (
      initialMap[propName] &&
      typeof customFormatedCells[
      propName as keyof typeof customFormatedCells
      ] === "function"
    ) {
      const fields = customFormatedCells[
        propName as keyof typeof customFormatedCells
      ]?.(initialMap[propName]) as { [key: string]: string };
      delete initialMap[propName];
      for (const flattenFieldName in fields) {
        if (
          typeof fields[flattenFieldName] === "boolean" &&
          fields[flattenFieldName]
        ) {
          delete initialMap[flattenFieldName];
        }
        initialMap[flattenFieldName] = fields[flattenFieldName];
      }
    }
  }
  return initialMap;
};

export function convertAccountsToCsvFormat(
  accounts: Account[],
  activeColumns: DynFilterColumn,
  isForFreeUser?: boolean
) {
  const chosenColumnsEntries = Object.keys(activeColumns)
    .map((key: string) => {
      if (typeof activeColumns[key] !== 'object') { // handle invalid and old cases
        return null;
      }
      else if (forAllUsersColumns.includes(key as any)) {// initial value for the default columns
        activeColumns[key].isViewed = true;
      } else if (nonDisplayableCols.includes(key) || !activeColumns[key].isViewed) { // hidden columns or columns that use choices not to display
        return null

      } else if (isForFreeUser && activeColumns[key]?.forFree !== isForFreeUser) { // when free user, show free user columns
        return null;
      }
      const columnOrder = TerritoryTableColumnsAccessorsOrder[key as keyof typeof TerritoryTableColumnsAccessorsOrder];
      (activeColumns[key] as DynFilterColumnValue).key = key;
      return [columnOrder ?? 30, activeColumns[key]]
    })
    .filter(Boolean)
    .filter((item): item is [number, DynFilterColumnValue] => item !== null)
    .sort(([a], [b]) => a - b)
    .map(([, tableColumn]: [number, DynFilterColumnValue]) => tableColumn)
    .map(({ key, label }) => [
      key,
      label || TerritoryTableColumnTitles[key as keyof typeof TerritoryTableColumnTitles] || spaceAndCapitalize(removeSFCustomSuffix(key!))
    ]);

  const chosenColumnsMap = Object.fromEntries([
    ...chosenColumnsEntries,
    ["id", "Account Id"],
  ]) as FieldsAndTitlesMap;

  const modifiedColumnMap = modifyWithCustomization(chosenColumnsMap);

  // pay attention to the order of the fields
  // add customization for the columns, in case of compex data , data splitting to columns
  // intermediate format to collect the csv table data and handle the format
  const csvFileTitles = Object.entries(modifiedColumnMap)
    .map(([, title]) => title)
    .join(", ");

  const csvContent = accounts
    .map(pickChosenAccountFields(modifiedColumnMap))
    .map(accountObjectToCsvRow(dataTransformers))
    .join("\n");

  const exportedFileDataRows = `${csvFileTitles}\n${csvContent}`;
  const fileRawData = `${exportedFileDataRows}`;
  return fileRawData;
}

export const getTableContainerHeight = (
  containerHeight: number,
  isFocusWidgetOpen: boolean
) => {
  const headerAndSurroundingRealEstate = 295;
  const accountDetailsHeight = 630;
  const tableNewHeight = isFocusWidgetOpen
    ? containerHeight - accountDetailsHeight
    : containerHeight;
  const subtractedHeight = tableNewHeight - headerAndSurroundingRealEstate
  return subtractedHeight < 300 ? 300 : subtractedHeight;
};

export interface FilterGroupsOpenStateMap {
  [TerritoryFiltersEnums.accountOwners]?: boolean;
  [TerritoryFiltersEnums.pipelineAttributeFilters]?: boolean;
  [TerritoryFiltersEnums.firmographicFilters]?: boolean;
  [TerritoryFiltersEnums.technographicFilters]?: boolean;
  [TerritoryFiltersEnums.customFilters]?: boolean;
  [key: string]: boolean | undefined;
}

export const getTableWidth = ({
  territoryTableColumnWidths,
  visibleColumns,
}: {
  territoryTableColumnWidths: typeof TerritoryTableColumnWidths;
  visibleColumns: { [key: string]: boolean };
}) => {
  const visibleColumnKeys = [];
  for (const key in visibleColumns) {
    if (visibleColumns[key]) visibleColumnKeys.push(key);
  }
  // the turnery operator condition here is to account for products columns that are not in the territoryTableColumnWidths object and are generated dynamically. They have a width of 150px + 20 for padding and 1 for borders
  const visibleColumnsWidth = visibleColumnKeys.reduce(
    (acc, key) =>
      territoryTableColumnWidths[key as keyof typeof territoryTableColumnWidths]
        ? acc +
        territoryTableColumnWidths[
        key as keyof typeof territoryTableColumnWidths
        ]
        : acc + 171,
    0
  );
  return visibleColumnsWidth;
};
