import { useEffect, useState } from "react";

import config from "config/envConfig";
import { FeaturesEnum } from "config/featureFlagsConfig";

const { featureFlagsConfig } = config;

const useFeaturesFlag = (feature: FeaturesEnum) => {
  const [flag, setFlag] = useState(false);

  useEffect(() => {
    const isEnabled = featureFlagsConfig[feature];
    setFlag(isEnabled);
  }, [feature]);

  return flag;
};

export default useFeaturesFlag;
