import CircleIcon from "@mui/icons-material/Circle";
import { Box, Stack } from "@mui/material";

import { numberFormats } from "utils/numbers";

import ARRTooltip from "./ARRTooltip";
import WalletShareWidget from "./WalletshareWidget";

interface IWalletShareProps {
  TAM: number;
  closed: number;
  open: number;
  uncapturedWhitespace: number;
}

function FilledBar({ background, height }: { background: string; height?: string }) {
  return (
    <Box sx={{
      height: `${height || "16px"}`, background, width: "100%", margin: 0, padding: 0,
    }}
    />
  );
}
function WalletShareSlice({
  label,
  value,
  color,
}: {
  label: string;
  value: string;
  color: string;
}) {
  return (
    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
      <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <CircleIcon sx={{
          color, width: "10px", height: "10px", margin: "2px 0 0 0",
        }}
        />
        <Box sx={{
          fontSize: "10px", fontWeight: 400, lineHeight: "16px", color: "#1A1A1A",
        }}
        >
          {label}
        </Box>
      </Box>
      <Box sx={{
        fontSize: "10px", fontWeight: 700, lineHeight: "16px", color: "#1A1A1A",
      }}
      >
        {value}
      </Box>
    </Box>
  );
}

export function TooltipIcon({
  open, closed, TAM, uncapturedWhitespace,
}: IWalletShareProps) {
  const whitespace = uncapturedWhitespace;
  return (
    <Box sx={{
      width: "200px", display: "flex", flexDirection: "column", gap: "5px",
    }}
    >
      <WalletShareSlice
        value={`$${numberFormats.millify(closed)}`}
        label="Closed"
        color="#00223C"
      />
      <WalletShareSlice value={`$${numberFormats.millify(open)}`} label="Open" color="#61ABF2" />
      <WalletShareSlice
        value={`$${numberFormats.millify(whitespace)}`}
        label="Whitespace"
        color="#E3E9ED"
      />
    </Box>
  );
}

export function WalletShareClassic({
  TAM = 0, closed = 0, open = 0, uncapturedWhitespace = 0,
}: IWalletShareProps) {
  return (
    <ARRTooltip placement="top" title={<TooltipIcon open={open} closed={closed} TAM={TAM} uncapturedWhitespace={uncapturedWhitespace} />}>
      <Box>
        <WalletShareWidget
          closed={closed}
          open={open}
          TAM={TAM}
          uncapturedWhitespace={uncapturedWhitespace}
        />
      </Box>
    </ARRTooltip>
  );
}

export function WalletShareHeader() {
  return (
    <Stack>
      <Box sx={{ width: "175px", fontSize: "12px" }}>Annual Recurring Revenue</Box>
      <Box sx={{ display: "flex", width: "175px", flexGrow: 1 }}>
        <Box sx={{ width: "33.333%" }}>
          <Box sx={{ fontSize: "9px", fontWeight: 400, textTransform: "capitalize" }}>Closed</Box>
          <FilledBar background="#00223C" height="4px" />
        </Box>
        <Box sx={{ width: "33.333%" }}>
          <Box sx={{ fontSize: "9px", fontWeight: 400, textTransform: "capitalize" }}>Open</Box>
          <FilledBar background="#61ABF2" height="4px" />
        </Box>
        <Box sx={{ width: "33.333%" }}>
          <Box sx={{ fontSize: "9px", fontWeight: 400, textTransform: "capitalize" }}>
            Whitespace
          </Box>
          <FilledBar background="#E3E9ED" height="4px" />
        </Box>
      </Box>
    </Stack>
  );
}
