interface ISalesForceLinkProps {
  instanceURL: string;
  objectId: string;
}

const getSalesForceLink = ({ instanceURL, objectId }: ISalesForceLinkProps) => {
  const salesForceLink = `${instanceURL}/${objectId}`;
  return salesForceLink;
};

export default getSalesForceLink;
