import { CaretDownOutlined } from "@ant-design/icons";
import { Box } from "@mui/material";

import { truncateText } from "utils/generics";

export function SimilarWinsChip({
  accountName,
  shouldRenderIcon,
}: {
  accountName: string;
  shouldRenderIcon: boolean;
}) {
  const renderValue = accountName.length > 13 ? truncateText(accountName, 13) : accountName;
  return (
    <Box
      className="truncate"
      flex={1}
      sx={{
        display: "flex",
        alignItems: "center",
        gap: "10px",
        color: "#1A1A1A",
        backgroundColor: "#E1EDFA",
        padding: "2px 15px 0 15px",
        maxWidth: "110px",
        width: "110px",
        borderRadius: "24px",
        fontSize: "12px",
        height: "24px",
        justifyContent: "center",
      }}
    >
      {renderValue}
      {" "}
      {shouldRenderIcon && <CaretDownOutlined width="10" height="10" />}
    </Box>
  );
}
