import { AiWorker } from "./aiWorkers";

export interface CopilotResponse {
  answer: string;
  payload?: {
    options: string[];
  },
  resultTag: ResultTag;
  worker?: AiWorker
}

export enum ResultTag {
  Options = "options",
  Success = "success",
}
