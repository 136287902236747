export interface ActivitySearchResponse {
  totalResultCount: number;
  activityActionTypes: string[];
  activityGroups: any[];
  activities: Activity[];
}

export interface Activity {
  score: number;
  document: IActivity;
}
export enum EmailActivityDirection {
  Inbound = "inbound",
  Outbound = "outbound",
}

export interface IActivity {
  id: string;
  category: string;
  status?: string;
  oldStatus?: string;
  action: ActivityTypes;
  objectType: ActivityObjectType;
  objectId: string;
  title?: string;
  amount: any;
  dateOfActivity: string;
  dateCreated: string;
  instanceId: string;
  subject: string;
  scope?: string;
  body?: string;
  description?: string;
  direction?: EmailActivityDirection;
  activityGroupId: any;
  isCompleted?: boolean;
  type?: string;
  sentiment: any;
  duration: any;
  newBuyingStage?: string;
  oldBuyingStage?: string;
  newIntentScore?: number;
  oldIntentScore?: number;
  disposition?: string;
  rid: string;
  leadSource?: string;
  source: IActivitySource;
  secondParty: IActivitySecondParty;
  subType?: string;
  sender?: IActivitySender;
  recipient: IActivityRecipient[];
  campaignName?: string;
  campaignSource?: string;
  location?: string;
  meetingStartTime?: string;
  meetingEndTime?: string;
  meetingLinks?: string;
  visitedLink?: string;
}

export interface IActivitySource {
  tool: string;
  companyName: string;
  departmentName: string;
  personId: string;
  personName: string;
  link?: string;
}

export interface IActivitySecondParty {
  whoId: string;
  whoIdType: string;
  accountId: string;
  accountName: string;
  accountType: string;
  personName: string;
  title: any;
  email: string;
}

export interface IActivitySender {
  address: string;
  name: string;
}

export interface IActivityRecipient {
  address: string;
  name: string;
  cc: boolean;
}

// @note: this is a hot fix
enum originalActivityTypesFromDataModel {
  OpportunityWon = 8,
  Email = 11,
  Call = 12,
  Meeting = 13,
  Webinar = 14,
  Web = 15,
  ThirdPartyContentDownload = 16,
  Milestone = 17,
  MarketoEmail = 18,
  IntentChanged = 19,
  Lead = 1,
  ContentViewed = 20,
}

export enum ActivityTypes {
  Email = originalActivityTypesFromDataModel.Email,
  Meeting = originalActivityTypesFromDataModel.Meeting,
  Call = originalActivityTypesFromDataModel.Call,
  Lead = originalActivityTypesFromDataModel.Lead,
  WinWire = originalActivityTypesFromDataModel.OpportunityWon,
  IntentScore = originalActivityTypesFromDataModel.IntentChanged,
  Web = originalActivityTypesFromDataModel.Web,
  ThirdPartyDownload = originalActivityTypesFromDataModel.ThirdPartyContentDownload,
  Event = originalActivityTypesFromDataModel.Webinar,
  MarketingEmail = originalActivityTypesFromDataModel.MarketoEmail,
  Milestone = originalActivityTypesFromDataModel.Milestone,
  ContentViewed = originalActivityTypesFromDataModel.ContentViewed,
}

/*

*/
export declare enum ActivityObjectType {
  Opportunity = 0,
  Lead = 1,
  Contact = 2,
  Task = 3,
  Event = 4,
}

export declare enum WhoIdType {
  Contact = 0,
  Lead = 1
}

export interface ActivitySearchResponseV2 {
  totalResultCount: number
  activityActionTypes: string[]
  activityGroups: any[]
  activities: ActivityV2[]
}

export interface ActivityV2 {
  score: number
  document: IActivityV2
}

export interface IActivityV2 {
  id: string
  instanceId: string
  properties: ActivityProperties
  rid: string
}

export interface ActivityProperties {
  id: string
  category: string
  oldStatus?: string
  customFields: IActivityCustomProperties
  action: ActivityTypes
  subType?: string
  objectType: ActivityObjectType
  objectId: string
  subject: string
  activityGroupId: any
  timestamps: IActivityTimestamp
  secondParty: IActivitySecondParty
  firstParty: IActivityFirstParty
  source: IActivitySource
}

export interface IActivityFirstParty {
  tool: string
  departmentName: string
  personId: string
  personName: string
}

export interface IActivityTimestamp {
  activity: string
  created: string
}

export interface IActivityCustomProperties {
  title?: string
  amount: any
  scope?: string
  body?: string
  status?: string
  description?: string
  direction?: EmailActivityDirection
  type?: string
  sentiment: any
  duration: any
  newBuyingStage?: string;
  oldBuyingStage?: string;
  newIntentScore?: number
  oldIntentScore?: number
  disposition?: string
  isCompleted?: boolean
  sender?: IActivitySender
  recipient: IActivityRecipient[]
  campaignName?: string;
  campaignSource?: string;
  location?: string;
  meetingStartTime?: string
  meetingEndTime?: string;
  meetingLinks?: string
  visitedLink?: string
  leadSource?: string
  initiatorId?: string
}
