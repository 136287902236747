import { Stack, Typography } from "@mui/material";

import { IActivity } from "types/api/activities";

import { PersonLabel } from "./common";
import { ActivityContentInteractions, EmailField } from "../common";
import { ActivitieSubTypes } from "../utils";

interface ILeadContentProps {
  activity: IActivity;
  onActionTrigger?: (action: ActivityContentInteractions, value?: string, name?: string) => void;
}

export function LeadContent({ activity, onActionTrigger }: ILeadContentProps) {
  const lead = {
    name: activity.secondParty?.personName,
    email: activity.secondParty?.email,
    title: activity.secondParty?.title,
  };

  const campaignSource = activity?.campaignName || activity?.leadSource;

  const onFilterByPerson = (personName?: string, personId?: string) => {
    if (onActionTrigger && personName) {
      onActionTrigger(ActivityContentInteractions.FilterByPerson, personName, personId);
    }
  };

  const isLeadStatusChange = activity.subType
    && activity.oldStatus
    && activity.subType === ActivitieSubTypes.lead.LeadStatusChanged;
  return (
    <Stack direction="column" sx={{ width: "100%", wordWrap: "break-word" }}>
      <Stack direction="column" gap="5px" sx={{ maxWidth: "95%" }}>
        <EmailField
          label="Who:"
          valueSx={{ textTransform: lead.name ? "capitalize" : "none" }}
          value={(
            <PersonLabel
              onClick={onFilterByPerson}
              value={lead.name}
              tipDetails={{
                email: lead?.email,
                name: lead?.name,
                jobTitle: lead?.title,
              }}
            />
          )}
        />
        {isLeadStatusChange ? (
          <Stack sx={{ fontSize: "12px", fontWeight: "700" }}>
            {activity.oldStatus}
            {" "}
            →
            {activity.status}
          </Stack>
        ) : (
          activity.status && (
            <EmailField
              label="Status:"
              value={(
                <Typography sx={{ fontSize: "12px", fontWeight: "700" }}>
                  {activity.status}
                </Typography>
              )}
              valueSx={{ width: "50%" }}
            />
          )
        )}
        <EmailField
          label="Campaign Source:"
          value={campaignSource}
          valueSx={{ maxWidth: "50%", display: "block" }}
        />
      </Stack>
    </Stack>
  );
}

export default LeadContent;
