import TimeAgo from "javascript-time-ago";
// English.
import en from "javascript-time-ago/locale/en";

TimeAgo.addDefaultLocale(en);

// Create formatter (English).
export const timeAgo = new TimeAgo("en-US");

// @deperacted. Might be useful to show a reminder in the future, so we're keeping it for now.
export const hasIgnorePeriodExpired = (ignorePeriod: string) => {
  const ignorePeriodDate = new Date(ignorePeriod);
  const currentDate = new Date();
  return currentDate > ignorePeriodDate;
};
