import Box from "@mui/material/Box";

import Typography from "@mui/material/Typography";

import Tooltip from "components/Tooltip";
import { numberFormats } from "utils/numbers";

interface Props {
  isTooltipOpen?: boolean;
  pipeline: {
    pipelineGoal: number;
    openOpportunities: number;
    uncapturedOpportunities: number;
    conversionRate?: number;
  };
}

export const calcThePercent = (value: number, total: number) => (value / total) * 100;

export default function Bar({ pipeline, isTooltipOpen }: Props) {
  const {
    pipelineGoal, openOpportunities, uncapturedOpportunities, conversionRate = 1,
  } = pipeline;
  const gap = pipelineGoal - (openOpportunities + uncapturedOpportunities);

  const aboveGoal = (openOpportunities + uncapturedOpportunities) * conversionRate - pipelineGoal;
  let openOpportunitiesPercentage = calcThePercent(openOpportunities, pipelineGoal);
  let uncapturedOpportunitiesPercentage = calcThePercent(uncapturedOpportunities, pipelineGoal);
  const gapPercentage = calcThePercent(gap, pipelineGoal);
  let aboveGoalPercentage = calcThePercent(aboveGoal, pipelineGoal);

  if (aboveGoalPercentage >= 100) {
    aboveGoalPercentage = 100;
    openOpportunitiesPercentage = 0;
    uncapturedOpportunitiesPercentage = 0;
  }

  if (aboveGoal > 0) {
    const belowGoalPercentage = 1 - aboveGoalPercentage / 100;
    const missingOpportunities = (openOpportunities + uncapturedOpportunities) / belowGoalPercentage;
    openOpportunitiesPercentage = calcThePercent(openOpportunities, missingOpportunities) * conversionRate;
    uncapturedOpportunitiesPercentage = calcThePercent(uncapturedOpportunities, missingOpportunities) * conversionRate;
  }

  return pipelineGoal ? (
    <Box
      sx={{
        border: "1px solid #C8C8C8",
      }}
    >
      <Box sx={{ height: 20, display: "flex", flexDirection: "row" }}>
        <Tooltip
          open={isTooltipOpen}
          sx={{ marginTop: `${isTooltipOpen ? 25 : 0}px !important` }}
          placement={isTooltipOpen ? "bottom-start" : "bottom"}
          title={(
            <Box>
              <Typography
                variant="caption"
                sx={{
                  fontWeight: 700,
                }}
              >
                $
                {numberFormats.millify(openOpportunities)}
                {" "}
                <span style={{ fontWeight: 400 }}>Your</span>
                {" "}
                Open Pipeline
              </Typography>
              <ul style={{ paddingLeft: 20, marginTop: 5 }}>
                {["From Target Accounts", "Open/Active Opportunities", "Grow this value"].map((i) => (
                  <li key={i} style={{ fontSize: 11, lineHeight: 1.45 }}>
                    {i}
                  </li>
                ))}
              </ul>
            </Box>
          )}
        >
          <Box
            sx={{
              height: 20,
              width: `${openOpportunitiesPercentage}%`,
              background: "#358700",
            }}
          />
        </Tooltip>
        <Tooltip
          open={isTooltipOpen}
          sx={{ marginBottom: `${isTooltipOpen ? 25 : 0}px !important` }}
          placement={isTooltipOpen ? "top-start" : "bottom"}
          title={(
            <Box>
              <Typography
                variant="caption"
                sx={{
                  fontWeight: 700,
                }}
              >
                $
                {numberFormats.millify(uncapturedOpportunities)}
                {" "}
                Captured
                {" "}
                <span style={{ fontWeight: 400 }}>in your Plan</span>
              </Typography>
              <ul style={{ paddingLeft: 20, marginTop: 5 }}>
                {[
                  "From Target Accounts",
                  "Not yet Open/Active, but in your plan",
                  "Work to make them Open/Active",
                ].map((i) => (
                  <li key={i} style={{ fontSize: 11, lineHeight: 1.45 }}>
                    {i}
                  </li>
                ))}
              </ul>
            </Box>
          )}
        >
          <Box
            sx={{
              height: 20,
              width: `${uncapturedOpportunitiesPercentage}%`,
              background: "#94DE64",
            }}
          />
        </Tooltip>
        {gapPercentage > 0 && (
          <Tooltip
            open={isTooltipOpen}
            sx={{ marginTop: `${isTooltipOpen ? 25 : 0}px !important` }}
            placement={isTooltipOpen ? "bottom-end" : "bottom"}
            title={(
              <Box>
                <Typography
                  variant="caption"
                  sx={{
                    fontWeight: 700,
                  }}
                >
                  $
                  {numberFormats.millify(gap)}
                  {" "}
                  <span style={{ fontWeight: 400 }}>missing to cover your goal</span>
                </Typography>
                <ul style={{ paddingLeft: 20, marginTop: 5 }}>
                  {[
                    "Your plan is insufficient to cover your goal",
                    "Target more accounts to capture more potential revenue into your plan",
                  ].map((i) => (
                    <li key={i} style={{ fontSize: 11, lineHeight: 1.45 }}>
                      {i}
                    </li>
                  ))}
                </ul>
              </Box>
            )}
          >
            <Box
              sx={{
                height: 20,
                width: `${gapPercentage}%`,
              }}
            />
          </Tooltip>
        )}
        {aboveGoalPercentage > 0 && (
          <Tooltip
            open={isTooltipOpen}
            sx={{ marginTop: `${isTooltipOpen ? 25 : 0}px !important` }}
            placement={isTooltipOpen ? "bottom-end" : "bottom"}
            title={(
              <Box>
                <Typography
                  variant="caption"
                  sx={{
                    fontWeight: 700,
                  }}
                >
                  $
                  {numberFormats.millify(aboveGoal)}
                  {" "}
                  <span style={{ fontWeight: 400 }}>above your goal</span>
                </Typography>
                <ul style={{ paddingLeft: 20, marginTop: 5 }}>
                  {["Surplus from target accounts", "This is good to have!"].map((i) => (
                    <li key={i} style={{ fontSize: 11, lineHeight: 1.45 }}>
                      {i}
                    </li>
                  ))}
                </ul>
              </Box>
            )}
          >
            <Box
              sx={{
                height: 20,
                width: `${aboveGoalPercentage}%`,
                background: "#000",
              }}
            />
          </Tooltip>
        )}
      </Box>
    </Box>
  ) : (
    <></>
  );
}
