import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import ErrorOutlinedIcon from "@mui/icons-material/ErrorOutlined";
import WarningIcon from "@mui/icons-material/Warning";
import {
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  TextField,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import { MouseEvent, useState } from "react";

import { toast } from "react-toastify";

import Tooltip from "components/Tooltip";

import usePipelineState from "services/shooks/pipeline";
import useTerritoryAccounts from "services/shooks/territory";

interface ICompetitorState {
  name: string;
  atRiskOfLosing: boolean;
  date: Date | null;
}

interface ICompetitor {
  name: string;
  renewalDate: Date | null;
}

export interface Dictionary<T> {
  [index: string]: T;
}
// TODO: should be enum
export const backgroundColors: any = {
  "Fully Sold": "#AAAAAA",
  "Partially Sold": "#2A7AB7",
  "Not Now": "#FFFBE6",
  "Not Interested": "#FFEBEB",
  Interested: "#358700",
  Unresponsive: "#E6E6E6",
  Churned: "#E6E6E6",
  "Unknown Interest": "#FFFFFF",
};

// TODO: should be enum
export const textColors: any = {
  "Fully Sold": "#fff",
  "Partially Sold": "#fff",
  "Not Now": "#A86600",
  "Not Interested": "#802929",
  Interested: "#fff",
  Unresponsive: "#757575",
  Churned: "#757575",
  "Unknown Interest": "#000",
};

interface IProductMenu {
  status: string;
  accountId: string;
  isLast?: boolean;
  productId: string;
  atRiskOfLosing: boolean;
  accountCompetitors: ICompetitor[];
  productName: string;
  setSelectedProductName: (productName: string) => void;
}

interface Product {
  productId: string;
  status: string;
  atRiskOfLosing: boolean;
  competitors: ICompetitor[];
}

const statusArray = [
  "Fully Sold",
  "Partially Sold",
  "Not Now",
  "Not Interested",
  "Interested",
  "Unresponsive",
  "Churned",
  "Unknown Interest",
];
const iconColors: Dictionary<string> = {
  "Fully Sold": "#fff",
  "Partially Sold": "#fff",
  "Not Now": "#4D4D4D",
  "Not Interested": "#4D4D4D",
  Interested: "#fff",
  Unresponsive: "#4D4D4D",
  Churned: "#4D4D4D",
  "Unknown Interest": "#4D4D4D",
};

type MenuSteps = "status" | "configure" | "edit";
// todo: change menu items from hardcoded to dynamic
export default function ProductMenu(props: IProductMenu) {
  const {
    accountId,
    status,
    isLast,
    productId,
    atRiskOfLosing,
    accountCompetitors = [],
    setSelectedProductName,
    productName,
  } = props;
  const [currentHover, setCurrentHover] = useState("");
  const [menuSteps, setMenuSteps] = useState<MenuSteps>("status");
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [competitors, setCompetitors] = useState<any>([]);
  const [competitor, setCompetitor] = useState<ICompetitorState>({
    name: "",
    atRiskOfLosing: false,
    date: new Date(),
  });
  const { actions } = useTerritoryAccounts();
  const {
    actions: { get: getPipelineUpdates },
  } = usePipelineState();
  const open = Boolean(anchorEl);

  const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (!anchorEl) setMenuSteps("status");
    setAnchorEl(e.currentTarget);
  };

  const handleDateChange = (newValue: Date | null) => {
    // prevent setting a date in the past
    if (!newValue) return;
    const date = new Date(newValue.toString());
    const isDateInThePast = date.getTime() < Date.now();
    if (isDateInThePast) {
      return toast.error("You can't set a renewal date in the past");
    }
    setCompetitor({ ...competitor, date: newValue });
  };

  const handleClose = (e: MouseEvent) => {
    e.stopPropagation();
    setAnchorEl(null);
  };

  const handleStatusChange = async (e: MouseEvent, accountStatus: string) => {
    e.stopPropagation();
    const products = [
      {
        productId,
        status: accountStatus,
      },
    ];
    setAnchorEl(null);
    onUpdateAccountProducts(accountId, products);
  };
  const onUpdateAccountProducts = async (accountId: string, products: Partial<Product>[]) => {
    const payload = { id: accountId, products } as any;
    await actions.updateAccount(payload);
    setSelectedProductName(productName);
    await getPipelineUpdates({ allAccounts: true });
  };

  const handleSave = async (event: MouseEvent) => {
    event.stopPropagation();
    setAnchorEl(null);
    // attach both the existing object to the newly added one
    const competitorList = competitors
      .map(({ name, date: renewalDate }: ICompetitorState) => ({ name, renewalDate }))
      .concat(accountCompetitors);

    const products = [
      {
        productId,
        atRiskOfLosing: competitor.atRiskOfLosing,
        competitors: competitorList,
      },
    ];

    onUpdateAccountProducts(accountId, products);
  };

  const addNewCompetitor = () => {
    // user can't add a competitor with no name
    if (!competitor.name) return toast.error("Please fill in all the fields");
    const newCompetitors = [
      ...competitors,
      { name: competitor.name, date: competitor.date, atRiskOfLosing: competitor.atRiskOfLosing },
    ];
    setCompetitors(newCompetitors);
  };

  return (
    <Box sx={{ height: "100%" }}>
      <Tooltip
        title={(
          <Box style={{ margin: 0, padding: "0 0 0 5px" }}>
            {/* IMPORTANT: todo: use typography as the default text rendering elements and update it with themes */}
            {/* This seems a bit jarring, see if it can be improved. This shouldn't be its own component so we might keep it as is */}
            <Typography
              variant="body2"
              style={{ color: "#000", fontWeight: 700, fontSize: "12px" }}
            >
              {status || "Unknown interest"}
            </Typography>
            {accountCompetitors.length > 0 ? (
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <WarningIcon sx={{ fontSize: "10px", color: "#262626", margin: "0 5px 0 0" }} />
                <Typography
                  variant="body2"
                  style={{ color: "#000", fontWeight: 400, fontSize: "11px" }}
                >
                  {accountCompetitors.length > 1
                    ? `at risk of losing (${accountCompetitors.length} competitors)`
                    : `at risk of losing (${accountCompetitors[0].name})`}
                </Typography>
              </Box>
            ) : null}
          </Box>
        )}
      >
        <Button
          onClick={handleClick}
          sx={{
            display: "flex",
            position: "relative",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "100%",
            border: "#eee 1px solid",
            fontSize: "12px",
            background: backgroundColors[status] || "#FFFFFF",
            color: textColors[status] || "#000",
            "&:hover": {
              background: backgroundColors[status] || "#FFFFFF",
              color: textColors[status] || "#000",
            },
          }}
        >
          {atRiskOfLosing && (
            <WarningIcon
              sx={{
                fontSize: "10px",
                position: "absolute",
                top: "5px",
                right: "5px",
                color: iconColors[status],
              }}
            />
          )}
          {status || "Unknown interest"}
        </Button>
      </Tooltip>
      <Menu
        anchorOrigin={{ vertical: "bottom", horizontal: isLast ? "right" : "left" }}
        transformOrigin={{ vertical: "top", horizontal: isLast ? "right" : "left" }} // if we want to flip it horizontally, vertically...etc
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={(e) => e.stopPropagation()}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {menuSteps === "status"
          && statusArray.map((status) => (
            <MenuItem
              key={status}
              sx={{
                background: backgroundColors[status],
                color: textColors[status] || "#000",
                "&:hover": {
                  background: backgroundColors[status],
                },
                minWidth: "180px",
                display: "flex",
                justifyContent: "space-between",
              }}
              onClick={(e) => handleStatusChange(e, status)}
              onMouseEnter={() => {
                setCurrentHover(status);
              }}
              onMouseLeave={() => {
                setCurrentHover("");
              }}
            >
              {status}
              {" "}
              {currentHover === status && (
                <ErrorOutlinedIcon
                  sx={{ color: "#000" }}
                  fontSize="small"
                  onClick={(e) => {
                    e.stopPropagation();
                    setMenuSteps("configure");
                  }}
                />
              )}
            </MenuItem>
          ))}
        {menuSteps === "configure" && (
          <MenuItem
            sx={{
              background: "#fff",
              "&:hover": {
                background: "#fff",
              },
            }}
          >
            <Box>
              <Typography
                sx={{ fontSize: "#262626", fontWeight: 700 }}
                variant="subtitle1"
                color="textPrimary"
              >
                {status}
              </Typography>
              <FormGroup>
                <FormControlLabel
                  control={(
                    <Checkbox
                      defaultChecked={atRiskOfLosing}
                      onChange={(e) => {
                        e.stopPropagation();
                        setCompetitor({ ...competitor, atRiskOfLosing: e.target.checked });
                      }}
                    />
                  )}
                  label="At risk of losing"
                />
                <TextField
                  sx={{ margin: "10px 0 0 0" }}
                  onKeyDown={(e: any) => {
                    e.stopPropagation();
                  }}
                  onChange={(e: any) => {
                    e.stopPropagation();
                    setCompetitor({ ...competitor, name: e.target.value });
                  }}
                  onBlur={(e: any) => setCompetitor({ ...competitor, name: e.target.value })}
                  id="outlined-basic"
                  label="Competitor name"
                />
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                      margin: "20px 0 0 0",
                    }}
                  >
                    <DatePicker
                      label="Renewal Date"
                      value={competitor.date}
                      onChange={handleDateChange}
                      renderInput={(params) => <TextField {...params} />}
                    />
                    <AddCircleRoundedIcon
                      onClick={(e) => {
                        e.stopPropagation();
                        addNewCompetitor();
                      }}
                    />
                  </Box>
                </LocalizationProvider>
                <Box sx={{ margin: "20px 0 0 0" }}>
                  {competitors.map((competitor: any) => (
                    <Box key={competitor.name} sx={{ padding: "10px 0 0 0" }}>
                      <Typography
                        sx={{ fontSize: "12px", fontWeight: 400, color: "#262626" }}
                        variant="subtitle1"
                        color="textPrimary"
                      >
                        {competitor.name}
                      </Typography>
                      <Typography
                        sx={{ fontSize: "9px", fontWeight: 400, color: "#B22424" }}
                        variant="subtitle1"
                        color="textPrimary"
                      >
                        Renews
                        {" "}
                        {competitor?.date?.toString().split(" ").slice(1, 4).join(" ")}
                      </Typography>
                      <Divider sx={{ padding: "10px 0 0 0" }} />
                    </Box>
                  ))}
                  {accountCompetitors.map((competitor: any) => (
                    <Box key={competitor.name} sx={{ padding: "10px 0 0 0" }}>
                      <Typography
                        sx={{ fontSize: "12px", fontWeight: 400, color: "#262626" }}
                        variant="subtitle1"
                        color="textPrimary"
                      >
                        {competitor.name}
                      </Typography>
                      <Typography
                        sx={{ fontSize: "9px", fontWeight: 400, color: "#B22424" }}
                        variant="subtitle1"
                        color="textPrimary"
                      >
                        Renews
                        {" "}
                        {competitor?.renewalDate?.toString().split("T")[0]}
                      </Typography>
                      <Divider sx={{ padding: "10px 0 0 0" }} />
                    </Box>
                  ))}
                </Box>
                <Box sx={{ display: "flex", margin: "20px 0 0 0" }}>
                  <Button
                    onClick={handleClose}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "50%",
                      height: "62px",
                      border: "#eee 1px solid",
                      background: "#FFFFFF",
                      color: "#333333",
                      "&:hover": {
                        background: "#FFFFFF",
                        color: "#2A7AB7",
                      },
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={handleSave}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "50%",
                      height: "62px",
                      border: "#eee 1px solid",
                      background: "#FFFFFF",
                      color: "#333333",
                      "&:hover": {
                        background: "#FFFFFF",
                        color: "#2A7AB7",
                      },
                    }}
                  >
                    Save
                  </Button>
                </Box>
              </FormGroup>
            </Box>
          </MenuItem>
        )}
      </Menu>
    </Box>
  );
}
