import { create } from "zustand";

import { PipelineAPI } from "services";

import { StateRecord } from "./main";

export interface PipelineState {
  TAM: number;
  pipelineGoal: number;
  revenueGoal?: number;
  openOpportunities: number;
  uncapturedOpportunities: number;
  conversionRate: number;
  closedOpportunities?: number;
  totalPipeline?: PipelineState;
  organizationConversionRate?: number;
}

interface IPipelineState extends StateRecord {
  isLoading?: boolean;
  state: PipelineState;
  errors?: any;
  actions: {
    get: (
      payload: Parameters<typeof PipelineAPI.getTheGoal>[0]
    ) => Promise<Parameters<typeof PipelineAPI.getTheGoal>[0]>;
    update: (
      payload: Parameters<typeof PipelineAPI.updateGoal>[0]
    ) => Promise<Parameters<typeof PipelineAPI.updateGoal>[0]>;
    initialize: (payload?: Parameters<typeof PipelineAPI.getTheGoal>[0]) => Promise<void>;
  };
}

const initialPipelineState = {
  TAM: 0,
  pipelineGoal: 0,
  revenueGoal: 0,
  openOpportunities: 0,
  uncapturedOpportunities: 0,
  conversionRate: 0,
  organizationConversionRate: 0,
  totalPipeline: undefined,
} as PipelineState;

const usePipelineState = create<IPipelineState>((set, getState) => ({
  isLoading: false,
  state: initialPipelineState,
  actions: {
    get: async (payload: Parameters<typeof PipelineAPI.getTheGoal>[0]) => {
      set({ isLoading: true });
      const triggerActions = [
        PipelineAPI.getTheGoal({ allAccounts: true, userIds: payload?.userIds }),
        PipelineAPI.getTheGoal({ allAccounts: false, userIds: payload?.userIds }),
      ];
      const [pipelineForAllAccounts, pipelineGoalForTargetAccounts] = await Promise.all(triggerActions);
      const _state = {
        ...pipelineGoalForTargetAccounts,
        openOpportunities: pipelineForAllAccounts.openOpportunities,
        totalPipeline: pipelineForAllAccounts,
      } as PipelineState;
      // const response = await PipelineAPI.getTheGoal(payload);
      set({ isLoading: false, state: _state });
      return payload;
    },
    initialize: async (payload: Parameters<typeof PipelineAPI.getTheGoal>[0]) => {
      set({ isLoading: true });
      const triggerActions = [
        PipelineAPI.getTheGoal({ allAccounts: true, userIds: payload?.userIds }),
        PipelineAPI.getTheGoal({ allAccounts: false, userIds: payload?.userIds }),
      ];
      const [pipelineForAllAccounts, pipelineGoalForTargetAccounts] = await Promise.all(triggerActions);
      const _state = {
        ...pipelineGoalForTargetAccounts,
        openOpportunities: pipelineForAllAccounts.openOpportunities,
        totalPipeline: pipelineForAllAccounts,
      } as PipelineState;
      set({ isLoading: false, state: _state });
    },
    update: async (payload: Parameters<typeof PipelineAPI.updateGoal>[0]) => {
      set({ isLoading: true });
      try {
        const curretnState = getState().state;
        const response = await PipelineAPI.updateGoal(payload);
        const _state = {
          ...curretnState,
          pipelineGoal: response.pipelineGoal,
          conversionRate: response.conversionRate,
        } as PipelineState;

        set({ isLoading: false, state: _state });
      } catch (error) {
        set({ isLoading: false, errors: error });
      }
      return payload;
    },
  },
}));

export default usePipelineState;
