import { DeleteOutline } from "@mui/icons-material";
import { Tooltip, Typography } from "@mui/material";
import { Box } from "@mui/system";

export function ValueTag({ value, onDelete }: { value: string, onDelete: () => void }) {
  return (
    <Box sx={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "#f5f5f5",
      borderRadius: "5px",
      padding: "3px 8px",
      fontSize: "12px",
      textTransform: "capitalize",
      color: "#333333",
      border: "1px solid #e0e0e0",
    }}
    >
      <Typography
        sx={{
          fontSize: "12px",
          color: "#333333",
          maxWidth: "150px",
          overflow: "hidden",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
        }}
      >
        {value}
      </Typography>
      <Tooltip title="Delete Selection">
        <Box sx={{
          "&:hover": { color: "tomato" }, height: "16px", width: "16px", userSelect: "none",
        }}
        >
          <DeleteOutline
            onClick={onDelete}
            sx={{
              cursor: "pointer", marginLeft: "5px", color: "currentColor", fontSize: "16px", height: "16px", width: "16px",
            }}
          />
        </Box>
      </Tooltip>
    </Box>
  );
}

export default ValueTag;
