import { IExtendedEmployee } from "../types";

export const findMatchesInTree = (
  employeeList: IExtendedEmployee[],
  match: (employee: IExtendedEmployee) => boolean,
) => {
  let matches: IExtendedEmployee[] = [];
  employeeList?.forEach((employee: IExtendedEmployee) => {
    if (match(employee)) {
      matches.push(employee);
    }
    if (employee.subordinates.length) {
      matches = [...matches, ...findMatchesInTree(employee.subordinates, match)];
    }
  });
  return matches;
};

export default findMatchesInTree;
