import { AxiosNode } from "utils/axios";

interface ConversionRateState {
  conversionRate: number;
  orgConversionRate?: number;
}

export const getUserConversionRateDetails = async ({ userIds }: { userIds: string[] }) => {
  const { data } = await AxiosNode.post<Promise<ConversionRateState>>(
    "/opportunities/conversion-rate",
    { userIds },
  );
  return data;
};
