import { CloseCircleFilled } from "@ant-design/icons";
import { Stack, Typography } from "@mui/material";
import { uniqBy } from "lodash";
import React, { useEffect, useMemo } from "react";

import useTerritoryFilters from "services/shooks/territoryFilters";
import { MinMaxFilterTypes, TerritoryFiltersMetaData } from "types/api";
import { FirmographicDefaultFilters, HeadquarterAddress, RangeFilter } from "types/api/territoryFilters";
import { formatAddressFromHeadquarter } from "utils/general";

import { getFiltersThatDontMatchDefaultValues } from "utils/territory";

import {
  FilterInputGroup, FiltersGroupBox, SearchField,
} from "./common";
import StringFilter from "./DynamicFilterComps/StringFilter";
import MinMaxInput from "./MinMaxInput";
import SliderFilter from "./SliderFilter";
import { getHeadquarterAddress, normalizeAddress } from "../utils";

interface FirmographicsProps {
  filtersMetaData?: TerritoryFiltersMetaData;
  isLoadingAutoCompleteData?: boolean;
  isFilterBoxOpen?: boolean;
  onToggleFilterBox: (isOpen?: boolean) => void;
}

// @todo: move to data or utils
const employeeMap = {
  Any: [0, 0],
  "1-10": [1, 10],
  "11-50": [11, 50],
  "51-200": [51, 200],
  "201-500": [201, 500],
  "501-1000": [501, 1000],
  "1001-5000": [1001, 5000],
  "5001-10000": [5001, 10000],
  "10001+": [10001, 10000000],
};

export function SelectItem(props: { label?: string; onClick: () => void }) {
  return (
    <Stack
      alignItems="center"
      direction="row"
      onClick={props.onClick}
      gap="6px"
      sx={{ cursor: "pointer", color: "#AAAAAA", "&:hover": { color: "#333333" } }}
    >
      <CloseCircleFilled style={{ fontSize: "14px", color: "currentcolor" }} />
      <Typography
        fontSize="12px"
        lineHeight="22px"
        sx={{ color: "#333333", maxWidth: "100%" }}
        className="truncate"
      >
        {props.label}
      </Typography>
    </Stack>
  );
}

function Firmographics(props: FirmographicsProps) {
  const {
    filtersMetaData, isLoadingAutoCompleteData, isFilterBoxOpen, onToggleFilterBox,
  } = props;

  const { actions, state, initialState } = useTerritoryFilters();
  const { onFilterChange, setInitialFilterValues, setAllFiltersDetails } = actions;
  const { filters } = state;

  const { firmographicFilters } = filters || {};
  const { headquarters, annualRevenueMinMax } = filtersMetaData || {};

  const defaultMaxValue: number = annualRevenueMinMax?.max ?? 10e7;

  const industries = filtersMetaData?.industries?.filter(Boolean);
  const filterName = "firmographicFilters";

  const onFieldChange = (name: string, value: any) => {
    onFilterChange(`${filterName}.${name}`, value);
  };

  const onIndustrySelected = (value?: string) => {
    const industry = firmographicFilters?.industry || [];
    const uniqueList = [...new Set([...industry, value])];
    onFieldChange("industry", uniqueList);
  };

  const onHeadquarterSelect = (value?: string) => {
    const { city, country, state } = getHeadquarterAddress(value || "");

    const headquarters = firmographicFilters?.headquarters || [];
    const uniqueList = uniqBy(
      [...headquarters, { city, state, country }],
      ({ city, state, country }) => `${city}_${state}_${country}`,
    );

    onFieldChange("headquarters", uniqueList);
  };

  const onRemoveSelectedIndustry = (value?: string) => () => {
    if (!value) return onFieldChange("industry", []);

    const industries = firmographicFilters?.industry;
    const filtered = industries?.filter((industry) => industry !== value);
    onFieldChange("industry", filtered);
  };

  const onRemoveSelectedHeadquarter = (address?: HeadquarterAddress) => () => {
    if (!address) return onFieldChange("headquarters", []);

    const headquarters = firmographicFilters?.headquarters || [];
    const filtered = headquarters?.filter((headquarter) => !(
      headquarter.country === address.country
        && headquarter.state === address.state
        && headquarter.city === address.city
    ));
    onFieldChange("headquarters", filtered);
  };

  const headquartersOptions = useMemo(() => normalizeAddress(headquarters), [headquarters]);

  const defaultFilters = initialState?.filters?.firmographicFilters;
  const filtersThatDontMatchDefaultValues = getFiltersThatDontMatchDefaultValues({
    filters: firmographicFilters,
    defaultFilters,
  });
  const activeFilters = Object.keys(filtersThatDontMatchDefaultValues || {}).length;
  const hasEmpolyeesActiveFilters = Boolean(filtersThatDontMatchDefaultValues?.employees?.min)
    || Boolean(filtersThatDontMatchDefaultValues?.employees?.max);

  useEffect(() => {
    const temp = (filtersMetaData as any).firmographics;
    const presetFilters: Partial<FirmographicDefaultFilters> = {
      annualRevenue: temp?.annualRevenue?.value,
      employees: temp?.employees?.value,
      headquarters: [{
        country: temp?.country?.value,
      }],
    };
    const firmographicFilters = {
      ...defaultFilters,
      ...presetFilters,
    };

    setInitialFilterValues({
      firmographicFilters,
    });
  }, []);

  useEffect(() => {
    setAllFiltersDetails({ name: filterName, value: activeFilters });
  }, [activeFilters]);

  return (
    <FiltersGroupBox
      title="Firmographics"
      activeFilterCount={activeFilters}
      isOpen={isFilterBoxOpen}
      onToggle={onToggleFilterBox}
    >
      <SliderFilter
        annualRevenueMinMax={
          annualRevenueMinMax
        }
        firmographicFilters={firmographicFilters}
        onFieldChange={onFieldChange}
      />

      <MinMaxInput
        valuePath="firmographicFilters.employees"
        type={MinMaxFilterTypes.Number}
        label="Employee count"
        hasActiveFilters={hasEmpolyeesActiveFilters}
        range={(filtersMetaData as any).firmographics?.employees?.value as RangeFilter}
        value={undefined}
      />

      <StringFilter
        valuePath="industry"
        values={firmographicFilters?.industry || []}
        label="Industry"
        options={industries || []}
        isLoadingAutoCompleteData={!!isLoadingAutoCompleteData}
        onRemoveSelectedOption={onRemoveSelectedIndustry}
        onOptionSelected={onIndustrySelected}
      />

      <FilterInputGroup label="Headquarter Location">
        <SearchField
          options={headquartersOptions?.map((address) => ({ value: address, label: address }))}
          isLoading={isLoadingAutoCompleteData}
          autoHighlight
          inputProps={{
            "aria-label": "Headquarter Location",
            name: "headquarterLocation",
            placeholder: "Search location",
          }} // @ts-ignore
          onSelect={onHeadquarterSelect}
        />
        <Stack gap="5px" direction="column">
          {firmographicFilters?.headquarters?.map((headquarter) => {
            const formattedAddress = formatAddressFromHeadquarter(headquarter);
            return (
              <SelectItem
                key={formattedAddress}
                label={formattedAddress}
                onClick={onRemoveSelectedHeadquarter(headquarter)}
              />
            );
          })}
        </Stack>
      </FilterInputGroup>
    </FiltersGroupBox>
  );
}

export default Firmographics;
